import { useState } from "react";
import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  useSelect,
  Select,
  Row,
  Col,
  UploadProps,
  Upload,
  Button,
  Icons,
  Image,
} from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";

// Interface
import { IVideoResourcesCategories } from "interfaces";

// Util
import { nhost } from "utility/nhost";
import moment from "moment";
import { DatePicker } from "antd";


type props = {
  modalProps: ModalProps;
  formProps: FormProps;
  form:any
};

const { TextArea } = Input;
const { UploadOutlined } = Icons;

export const EditVideoResource: React.FC<props> = ({
  modalProps,
  formProps,
  form,
}) => {
  // User
  const { data: user } = useGetIdentity();

  // State
  const [fileList, setFileList] = useState<any>([]);

  // Form Watch
  const formWatch = {
    thumbnail: Form?.useWatch("thumbnail", formProps.form),
  };

  const [selectedHostAt, setSelectedHostAt] = useState<any>(
    form?.getFieldValue("host_at")
  );


  function checkTime(x: any) {
    let currTime = moment().format("HH:mm");
    let currDate = moment().toDate().toString().slice(8, 10);
    // console.log("Selected time :::", x);
    let selectedDate = x._d.toString();
    let currHrTime = Number(currTime.slice(0, 2));
    let currMinTime = Number(currTime.slice(3, 5));
    if (selectedDate === currDate) {
      if (x.$H < currHrTime) {
        alert("Please select future time");
      }
      if (x.$H === currHrTime && x.$m < currMinTime) {
        alert("Please select future time");
      }
    }
  }

  // Category Select
  const { selectProps: videoCategorySelectProps } =
    useSelect<IVideoResourcesCategories>({
      resource: "indiacharts_video_resources_categories",
      optionLabel: "category",
      optionValue: "id",
      pagination: { pageSize: 999 },
      metaData: {
        fields: ["id", "category"],
      },
    });

  // Image Upload
  const handleImageUpload = async (file: File) => {
    let fileResponse = await nhost.storage.upload({ file, bucketId: "public" });
    const imageUrl = await nhost.storage.getPublicUrl({
      fileId: fileResponse.fileMetadata?.id || "",
    });
    formProps?.form?.setFieldsValue({ thumbnail: imageUrl });
    return imageUrl;
  };

  const thumbProps: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      handleImageUpload(file);
      return false;
    },
    fileList,
  };

  return (
    <Modal {...modalProps} title="Edit Info Video">
      <Form
        {...formProps}
        size="large"
        layout="vertical"
        onFinish={(data: any) => {
          if (formProps.onFinish) {
            formProps?.onFinish({
              ...data,
              thumbnail: formWatch?.thumbnail,
            });
            setFileList([]);
          }
        }}
      >
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                },
                {
                  // pattern: new RegExp("^\\S*$"), // No Space Allowed
                  pattern: new RegExp(/^[^\s]/), // Check For Space At Beginning Only
                  message: "No spaces allowed",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Video Link"
              name="link"
              rules={[
                {
                  required: true,
                },
                {
                  // pattern: new RegExp("^\\S*$"), // No Space Allowed
                  pattern: new RegExp(/^[^\s]/), // Check For Space At Beginning Only
                  message: "No spaces allowed",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Category"
              name="category_id"
              rules={[{ required: true }]}
            >
              <Select {...videoCategorySelectProps} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              name="duration"
              label="Duration"
              rules={[
                { required: true },
                {
                  pattern: new RegExp(/^[^\s]/), // Check For Space At Beginning Only
                  message: "No spaces allowed",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item name="sort_index" label="Sort Order">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Date"
              name="host_at"
              getValueProps={(value) => ({
                value: value ? moment(value) : moment(),
              })}
              rules={[{ required: true }]}
            >
              <DatePicker
                format="YYYY-MM-DD HH:mm"
                showTime
                // disabledDate={disabledDate}
                onOk={(value: any) => {
                  checkTime(value);
                }}
                onChange={(value: any) => {
                  // console.log("Changed");
                  setSelectedHostAt(value.format("YYYY-MM-DD HH:mm"));
                  // form?.setFieldsValue({ duration: "" });
                }}
              />
            </Form.Item>
          </Col>

        </Row>
        <Row gutter={[16, 0]}>
          <Col>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                { required: true },
                {
                  pattern: new RegExp(/^[^\s]/), // Check For Space At Beginning Only
                  message: "No spaces allowed",
                },
              ]}
            >
              <TextArea cols={120} rows={5} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              name="thumbnail"
              label="Thumbnail"
              rules={[{ required: false }]}
            >
              <Upload {...thumbProps}>
                <Button icon={<UploadOutlined />}>Select File To Upload</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item label="Thumbnail Preview" name="presenter_img">
              {formWatch.thumbnail !== undefined ? (
                <Image
                  width={120}
                  height={120}
                  src={formWatch.thumbnail}
                  alt={"Icon"}
                />
              ) : (
                <p>No Image Available For Preview</p>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="updated_by" initialValue={user?.id} hidden>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
