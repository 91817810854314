import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  InputNumber,
  useSelect,
  Select,
  Row,
  Col,
  FormInstance,
  UploadProps,
  Upload,
  Button,
  Icons,
  Radio,
} from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";
import { useState } from "react";
import { nhost } from "utility";

type CreateAnalystsProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  form: FormInstance;
};

const { UploadOutlined } = Icons;

export const CreateAnalysts: React.FC<CreateAnalystsProps> = ({
  modalProps,
  formProps,
  form,
}) => {
  const { data: user } = useGetIdentity();
  const { TextArea } = Input;

  const [proImgList, setProImgList] = useState<any>([]);

  const handleProImageUpload = async (file: File) => {
    let res = await nhost.storage.upload({ file, bucketId: "public" });
    const url = await nhost.storage.getPublicUrl({
      fileId: res.fileMetadata?.id || "",
    });
    form.setFieldsValue({ image_url: url });
    return url;
  };

  const proImgProps: UploadProps = {
    onRemove: (file) => {
      const index = proImgList.indexOf(file);
      const newProImg = proImgList.slice();
      newProImg.splice(index, 1);
      newProImg.splice(newProImg);
    },
    beforeUpload: (file) => {
      setProImgList([...proImgList, file]);
      handleProImageUpload(file);
      return false;
    },
    fileList: proImgList,
  };

  return (
    <Modal {...modalProps} title="Create Analysts">
      <Form {...formProps} size="large" layout="vertical">
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item label="Email" name="email" rules={[{ required: false }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Position"
              name="position"
              rules={[{ required: false }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Contact no"
              name="contact_no"
              rules={[{ required: false }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Active"
              name="active"
              rules={[{ required: false }]}
              initialValue={true}
            >
              <Radio.Group defaultValue={true}>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col>
            <Form.Item
              label="Biography"
              name="biography"
              rules={[{ required: true }]}
            >
              <TextArea cols={120} rows={6} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Guest"
              name="external"
              rules={[{ required: true }]}
            >
              <Select
                options={[
                  {
                    value: true,
                    label: "Yes",
                  },
                  {
                    value: false,
                    label: "No",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Organisation"
              name="organisation"
              rules={[{ required: false }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Profile image"
              name="image_url"
              rules={[{ required: false }]}
            >
              <Upload {...proImgProps}>
                <Button icon={<UploadOutlined />}>Select File</Button>
              </Upload>
              <Input style={{ display: "none" }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
