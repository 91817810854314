import { Col, Form, FormProps, Input, Modal, ModalProps, Row, Select, useSelect } from "@pankod/refine-antd";
import React from "react";

interface PlanEditManagementProps {
    formProps: FormProps;
    modalProps: ModalProps;
}

const PlanEditManagement: React.FC<PlanEditManagementProps> = ({ formProps, modalProps }) => {
    const { selectProps } = useSelect({
        resource: "indiacharts_subscription_plans",
        metaData: {
            fields: ["id", "name", "label"],
        },
        optionLabel: "label",
        optionValue: "id",
    });
    const onFinish = async (data: any) => {
        if (formProps?.onFinish) {
            formProps?.onFinish({
                ...data,
            });
        }
    };
    return (
        <Modal {...modalProps} title="Configure Plan">
            <Form {...formProps} onFinish={onFinish} size="large" layout="vertical">
                <Row gutter={16}>
                    <Col lg={8} xs={24}>
                        <Form.Item
                            name="plan_id"
                            label="Plan"
                            rules={[
                                {
                                    required: true,
                                    message: "Field Required !",
                                },
                            ]}
                        >
                            <Select {...selectProps} />
                        </Form.Item>
                    </Col>
                    <Col lg={8} xs={24}>
                        <Form.Item
                            name="cost"
                            label="Cost"
                            rules={[
                                {
                                    required: true,
                                    message: "Field Required !",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col lg={8} xs={24}>
                        <Form.Item
                            name="duration"
                            label="Duration"
                            rules={[
                                {
                                    required: true,
                                    message: "Field Required !",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default PlanEditManagement;
