import { nhost } from "utility";

export const indicatorDataAPI = async (body: object) => {
  await fetch(
    `${process.env.REACT_APP_BULKAPI_URL}/v1/bulkapi/webhook/sentimentindicatorupdates`,
    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    }
  );
};

export const sentimentIndicatorAPI = async () => {
  await fetch(
    `${process.env.REACT_APP_BULKAPI_URL}/v1/bulkapi/sentimentindicator/refresh`,
    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + nhost.auth.getAccessToken(),
      },
      body: JSON.stringify({}),
    }
  );
};

export const diffusionIndicatorAPI = async () => {
  await fetch(
    `${process.env.REACT_APP_BULKAPI_URL}/v1/bulkapi/diffusionindicator/refresh`,
    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + nhost.auth.getAccessToken(),
      },
      body: JSON.stringify({}),
    }
  );
};

export const indicatorAPI = async (category: any) => {
  const categoryValue =
    category === "diffusion" ? "diffusionindicator" : "sentimentindicator";

  await fetch(
    `${process.env.REACT_APP_BULKAPI_URL}/v1/bulkapi/${categoryValue}/refresh`,
    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({}),
    }
  );
};
