import {
    Form,
    Input,
    ModalProps,
    FormProps,
    Modal, Row, Col
  } from "@pankod/refine-antd";
  import { useGetIdentity } from "@pankod/refine-core";

  type EditHolidaysProps = {
    modalProps: ModalProps;
    formProps: FormProps;
  }

  export const EditHolidays: React.FC<EditHolidaysProps> = ({modalProps, formProps}) =>{

  const { data: user } = useGetIdentity();
  return(
    <Modal {...modalProps} title="Edit Holiday">
        <Form {...formProps} size="large" layout="vertical">
        <Row gutter={[16,0]}>
                    <Col xs={8} lg={8}>
                        <Form.Item
                            label="Exchange"
                            name="exchange_code"
                            rules={[{ required: true }]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16,0]}>
                    <Col xs={8} lg={8}>
                        <Form.Item
                            label="Purpose"
                            name="purpose"
                            rules={[{ required: true }]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col xs={8} lg={8}>
                        <Form.Item 
                            label="date"
                            name="holiday_date"
                            rules={[{ required: true }]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
        </Form>
    </Modal>
  )


}