import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  Row,
  Col,
} from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";

type CreateStockNewsProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  closeCreateShow: any;
};

export const CreateStockNews: React.FC<CreateStockNewsProps> = ({
  modalProps,
  formProps,
  closeCreateShow,
}) => {
  const { data: user } = useGetIdentity();
  return (
    <Modal
      {...modalProps}
      title="Edit Stock News"
      onCancel={() => {
        formProps?.form?.resetFields();
        closeCreateShow();
      }}
    >
      <Form {...formProps} size="large" layout="vertical">
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Company code"
              name="company_code"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Headlines"
              name="headlines"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Source name"
              name="source_name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Exchange"
              name="exchange"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="News Classification"
              name="news_classification"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Content"
              name="content"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="News type"
              name="news_type"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="offered_share_value"
              name="offered_share_value"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
