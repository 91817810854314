import {
  Form,
  message,
  Input,
  ModalProps,
  FormProps,
  notification,
  Modal,
  Upload,
  Icons,
  Row,
  Col,
  DatePicker,
  Select,
  useSelect,
  UploadProps,
  useImport,
  ImportButton,
  Button,
  UploadFile,
  RcFile,
  Progress,
  Space,
} from "@pankod/refine-antd";
import { useGetIdentity, useNotification } from "@pankod/refine-core";
import dayjs from "dayjs";
import {
  IIndicator,
  IIndicatorData,
  IIndicatorDataCSV,
  IIndices,
} from "interfaces";
import { useState } from "react";
import { parse, ParseConfig } from "papaparse";
import { GraphQLClient, gql } from "graphql-request";
import { nhost } from "utility";

// Webhook
import { indicatorDataAPI } from "utility/webhook";

const { Dragger } = Upload;
const { InboxOutlined, UploadOutlined } = Icons;

type ImportIndexDataProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  close: Function;
};
const { TextArea } = Input;

export const ImportIndicatorData: React.FC<ImportIndexDataProps> = ({
  modalProps,
  formProps,
  close,
}) => {
  const { data: user } = useGetIdentity();
  let token = nhost.auth.getAccessToken();
  const { open } = useNotification();

  const [csvFile, setCSVFile] = useState<UploadFile>();
  const [records, setRecords] = useState<Array<IIndicatorDataCSV>>([]);
  const [uploading, setUploading] = useState(false);
  const [iterationCount, setIterationCount] = useState(0);
  const [iteration, setIteration] = useState(0);

  const { selectProps: indicatorSelectProps } = useSelect<IIndicator>({
    resource: "indiacharts_indicators",
    optionLabel: "name",
    optionValue: "id",
    pagination: { current: 1, pageSize: 25 },
    onSearch: (value) => [
      {
        field: "name",
        operator: "startswith",
        value,
      },
    ],
    metaData: {
      fields: ["id", "name"],
    },
  });

  const { buttonProps, uploadProps } = useImport<
    IIndicatorDataCSV,
    IIndicatorData
  >({
    resourceName: "indiacharts_indicator_data",
    batchSize: 50,
    mapData: (item) => {
      return {
        date: dayjs(item.Date, "MM/DD/YY").format("YYYY-MM-DD"),
        value: item.Value,
        indicator_id: formProps.form?.getFieldValue("indicator_id"),
        created_by: user?.id,
      };
    },
    onFinish: (results) => {
      console.log(results);
      indicatorDataAPI({
        indicator_id: formProps.form?.getFieldValue("indicator_id")
      });
      close();
    },
  });

  const handleUpload = (e: any) => {
    console.log("inside handle uploads");
    console.log(e);
    setUploading(true);
    setIteration(0);
    setIterationCount(0);
    parse(csvFile as any, {
      complete: async ({ data }: { data: unknown[][] }) => {
        // console.log(data);
        if (data[0][0] == "Date") data.splice(0, 1);
        data = data.filter(
          (_d) => _d[1] && _d[1] != "" && (_d[1] as string).trim() != ""
        );
        let rec = data.map((item: Array<any>) => {
          let format = "MM/DD/YY";
          if ((item[0] as string).includes("-")) format = "DD-MM-YYYY";
          return {
            date: dayjs(item[0], format).format("YYYY-MM-DD"),
            value: item[1],
            indicator_id: formProps.form?.getFieldValue("indicator_id"),
            created_by: user?.id,
          };
        });
        let affected_rows = 0;
        const graphQLClient = new GraphQLClient(
          `${process.env.REACT_APP_NHOST_BACKEND}/v1/graphql`,
          {
            headers: {
              authorization: "Bearer " + token,
            },
          }
        );
        const mutation = gql`
          mutation InsertIndicatorData(
            $objects: [indiacharts_indicator_data_insert_input!]!
          ) {
            response: insert_indiacharts_indicator_data(
              objects: $objects
              on_conflict: {
                constraint: indicator_data_pkey
                update_columns: [value]
              }
            ) {
              affected_rows
            }
          }
        `;
        setIterationCount(Math.ceil(rec.length / 50));
        let c = 0;
        try {
          if (rec.length > 50) {
            let _in = rec.splice(0, 50);
            while (_in.length > 0) {
              c++;
              const result = await graphQLClient.request(mutation, {
                objects: _in,
              });
              setIteration(c);
              if (result.response && result.response.affected_rows) {
                affected_rows += Number(result.response.affected_rows);
              }
              _in = rec.splice(0, 50);
            }
          } else {
            c++;
            const result = await graphQLClient.request(mutation, {
              objects: rec,
            });
            setIteration(c);
            if (result.response && result.response.affected_rows) {
              affected_rows += Number(result.response.affected_rows);
            }
          }
          if (open) {
            open({
              key: `indicator-data-file-import`,
              message: "",
              type: "success",
              description: `Imported ${affected_rows} Records.`,
              undoableTimeout: 2000,
            });
          }
        } catch (error) {
          console.error(error);
          if (open) {
            open({
              key: `indicator-data-file-import`,
              message: "",
              type: "error",
              description: `Facing Issue while uploading your records.`,
              undoableTimeout: 2000,
            });
          }
        }
        setUploading(false);
        setCSVFile(undefined);
        indicatorDataAPI({
          indicator_id: formProps.form?.getFieldValue("indicator_id")
        });
        formProps.form?.resetFields();
        close();
      },
    });
  };

  const props: UploadProps = {
    maxCount: 1,
    onRemove: (file) => {
      setCSVFile(undefined);
    },
    beforeUpload: (file) => {
      setCSVFile(file);
      return false;
    },
    fileList: csvFile ? [csvFile] : undefined,
  };

  return (
    <Modal {...modalProps} title="Bulk Indicator Data">
      <Form
        {...formProps}
        size="large"
        layout="vertical"
        onFinish={handleUpload}
      >
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Indicator"
              name="indicator_id"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select {...indicatorSelectProps} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>Upload CSV</Button>
          </Upload>
          {/* <ImportButton uploadProps={uploadProps} buttonProps={buttonProps}  /> */}
        </Row>
        <Form.Item name="created_by" initialValue={user?.id}>
          <Input type="hidden" />
        </Form.Item>
      </Form>
      {uploading ? (
        <Progress percent={Math.round((iteration / iterationCount) * 100)} />
      ) : (
        <Space></Space>
      )}
    </Modal>
  );
};
