import {
  Col,
  Form,
  FormProps,
  Input,
  Modal,
  ModalProps,
  Row,
  Select,
  Switch,
  Tree,
  TreeProps,
} from "@pankod/refine-antd";
import React, { useState, useEffect } from "react";

interface PlanEditProps {
  form: any;
  formProps: FormProps;
  modalProps: ModalProps;
  pages: any;
}

const PlanEdit: React.FC<PlanEditProps> = ({
  form,
  formProps,
  modalProps,
  pages,
}) => {
  const [mapping_obj, set_mapping_obj] = useState<any>(null);
  const [isFreeTrue, setIsFreeTrue] = useState<boolean>(true);

  const isFree = form?.getFieldValue("is_free");

  useEffect(() => {
    setIsFreeTrue(isFree);
  }, [isFree]);

  useEffect(() => {
    set_mapping_obj(formProps?.initialValues?.plan_config);
  }, [formProps]);

  const page = pages?.map((p: any) => ({
    value: p?.key,
    label: p?.title,
  }));

  const onCheck: TreeProps["onCheck"] = (
    checkedKeys: any | undefined,
    info: any | undefined
  ) => {
    // console.log("form_check", checkedKeys, info);
    if (info?.halfCheckedKeys?.length > 0) {
      const merge_object = {
        checked: [...checkedKeys],
        halfChecked: [...info?.halfCheckedKeys],
      };
      set_mapping_obj({
        ...merge_object,
      });
      const description_mapping: any = Object.values(merge_object)?.flat(2);
      const res: any = pages?.filter((p: any) =>
        description_mapping?.find((desc: any) => desc === p?.key)
      );
      formProps?.form?.setFieldsValue({
        plan_description: res?.map((r: any) => ({
          value: r?.key,
          label: r?.title,
        })),
      });
    } else {
      const object = {
        checked: [...checkedKeys],
        halfChecked: [],
      };
      set_mapping_obj({
        ...object,
      });
      const description_mapping: any = Object.values(object)?.flat(2);
      const res: any = pages?.filter((p: any) =>
        description_mapping?.find((desc: any) => desc === p?.key)
      );
      formProps?.form?.setFieldsValue({
        plan_description: res?.map((r: any) => ({
          value: r?.key,
          label: r?.title,
        })),
      });
    }
  };
  const onSelect = (selectedKeysValue: React.Key[], info: any) => {};
  const onFinish = async (data: any) => {
    const { label, name, plan_description, is_free, trial_period_days } = data;
    if (formProps?.onFinish) {
      formProps?.onFinish({
        name,
        label,
        plan_config: mapping_obj,
        plan_description,
        is_free,
        trial_period_days: is_free ? trial_period_days : null,
      });
    }
    set_mapping_obj(null);
  };
  return (
    <Modal {...modalProps} title="Edit Plans">
      <Form {...formProps} onFinish={onFinish} size="large" layout="vertical">
        <Row gutter={16}>
          <Col lg={8} xs={24}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Field Required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={8} xs={24}>
            <Form.Item
              name="label"
              label="Label"
              rules={[
                {
                  required: true,
                  message: "Field Required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={8} xs={24}>
            <Form.Item
              name="is_free"
              label="Is Free"
              rules={[
                {
                  required: true,
                  message: "Field Required",
                },
              ]}
            >
              <Switch
                defaultChecked
                checked={isFreeTrue}
                onChange={() => setIsFreeTrue(!isFreeTrue)}
              />
            </Form.Item>
          </Col>
          {isFreeTrue && (
            <Col lg={8} xs={24}>
              <Form.Item
                name="trial_period_days"
                label="Trial Period day's"
                rules={[
                  {
                    required: true,
                    message: "Field Required",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          )}
          <Col lg={24} xs={24}>
            <Form.Item
              name="plan_description"
              label="Description"
              rules={[
                {
                  required: true,
                  message: "Field Required",
                },
              ]}
            >
              <Select options={page} mode="multiple" />
            </Form.Item>
          </Col>
          <Col lg={24} xs={24}>
            <Tree
              checkable
              checkedKeys={{
                checked: mapping_obj?.checked,
                halfChecked: mapping_obj?.halfChecked,
              }}
              // defaultCheckedKeys={defaultcheckkeys}
              autoExpandParent
              onCheck={onCheck}
              onSelect={onSelect}
              treeData={pages}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default PlanEdit;
