import {
  Col,
  Form,
  FormProps,
  Input,
  Modal,
  ModalProps,
  Radio,
  Row,
  Select,
  useSelect,
} from "@pankod/refine-antd";
import React from "react";

interface ChildrenEditProps {
  form: any;
  formProps: FormProps;
  modalProps: ModalProps;
}

const ChildrenEdit: React.FC<ChildrenEditProps> = ({
  form,
  formProps,
  modalProps,
}) => {
  const page_id = Form.useWatch("page_id", form);
  const pod_id = Form.useWatch("pod_id", form);
  // const header_id = Form.us
  const { selectProps: pageProps } = useSelect({
    resource: "indiacharts_pages",
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "active", "name"],
    },
    filters: [
      {
        field: "active",
        operator: "eq",
        value: true,
      },
    ],
  });
  const { selectProps: podProps } = useSelect({
    resource: "indiacharts_pods",
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "active", "name"],
    },
    filters: [
      {
        field: "active",
        operator: "eq",
        value: true,
      },
      {
        field: "page_id",
        operator: "eq",
        value: page_id,
      },
    ],
    queryOptions: {
      enabled: !!page_id,
    },
  });
  const { selectProps: headerProps } = useSelect({
    resource: "indiacharts_sub_headers",
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "active", "name"],
    },
    filters: [
      {
        field: "active",
        operator: "eq",
        value: true,
      },
      {
        field: "pod_id",
        operator: "eq",
        value: pod_id,
      },
    ],
    queryOptions: {
      enabled: !!pod_id,
    },
  });
  return (
    <Modal {...modalProps} title="Edit Childrens">
      <Form {...formProps} size="large" layout="vertical">
        <Row gutter={16}>
          <Col lg={8} xs={24}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Field Required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={8} xs={24}>
            <Form.Item
              name="page_id"
              label="Page"
              rules={[
                {
                  required: true,
                  message: "Field Required",
                },
              ]}
            >
              <Select {...pageProps} />
            </Form.Item>
          </Col>
          <Col lg={8} xs={24}>
            <Form.Item
              name="pod_id"
              label="Pods"
              rules={[
                {
                  required: true,
                  message: "Field Required",
                },
              ]}
            >
              <Select {...podProps} disabled={page_id === undefined} />
            </Form.Item>
          </Col>
          <Col lg={8} xs={24}>
            <Form.Item
              name="sub_header_id"
              label="Sub Headers"
              rules={[
                {
                  required: true,
                  message: "Field Required",
                },
              ]}
            >
              <Select {...headerProps} disabled={pod_id === undefined} />
            </Form.Item>
          </Col>
          <Col lg={8} xs={24}>
            <Form.Item
              label="Private Access"
              name="is_private"
              rules={[
                {
                  required: true,
                  message: "Field Required",
                },
              ]}
            >
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={8} xs={24}>
            <Form.Item
              label="Active"
              name="active"
              rules={[
                {
                  required: true,
                  message: "Field Required",
                },
              ]}
            >
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={8} xs={24}>
            <Form.Item
              name="slug"
              label="Slug"
              // rules={[
              //     {
              //         required: true,
              //         message: "Field Required",
              //     },
              // ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ChildrenEdit;
