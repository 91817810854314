// Interfaces
import { ICreateSubscription } from "interfaces";

// Utility
import { nhost } from "utility";
import { defaultHeaders, postData } from "utility/utils";

export async function createSubscription({
  user_id,
  plan_id,
  plan_mapping_id,
  active,
  start_date,
  expiry_date,
  amount,
  transaction_id,
  payment_method,
  plan_name,
  duration,
}: ICreateSubscription) {
  const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/v1/api/subscription`;
  const token = nhost?.auth?.getAccessToken();
  const body = JSON.stringify({
    user_id,
    plan_id,
    plan_mapping_id,
    active,
    start_date,
    expiry_date,
    amount,
    transaction_id,
    payment_method,
    plan_name,
    duration,
  });
  try {
    const response = await fetch(apiUrl, {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      method: "POST",

      body,
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status:${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error while creating subscription:", error);
    return null;
  }
}

// Unsubscriptions List
export const getUnsubscriptionsList = async (queryKey: any) => {
  if (queryKey.length === 1) queryKey.push({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_key, queryParams] = queryKey;
  const response = await postData(
    `${process.env.REACT_APP_BACKEND_URL}/v1/api/subscription/getall`,
    queryParams,
    defaultHeaders()
  );
  return response;
};
