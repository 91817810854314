import {
    Form,
    Input,
    ModalProps,
    FormProps,
    Modal,
    InputNumber,
    useSelect,
    Select,Row,Col,DatePicker
  } from "@pankod/refine-antd";
  import { useGetIdentity } from "@pankod/refine-core";
  
  
  type EditWidgetProps = {
    modalProps: ModalProps;
    formProps: FormProps;
  };
  const { TextArea } = Input;

  
  export const EditWidget: React.FC<EditWidgetProps> = ({
    modalProps,
    formProps,
  }) => {
    const { data: user } = useGetIdentity();
  
  
    return (
      <Modal {...modalProps} title="Edit Widget">
        <Form {...formProps} size="large" layout="vertical">
        <Row gutter={[16, 0]}>
        <Col xs={8} lg={8}>
            <Form.Item
              label="Name "
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Component"
              name="component_id"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Description "
              name="description"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

          </Col>
          <Col xs={24} lg={24}>
            <Form.Item
              label="Config "
              name="config"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <TextArea />
            </Form.Item>

          </Col>
        </Row>

       

        <Form.Item name="created_by"
          initialValue={user?.id}
        >
          <Input type="hidden" />
        </Form.Item>
        </Form>
      </Modal>
    );
  };
  