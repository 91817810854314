import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  Row,
  Col,
  DatePicker,
  Select,
  useSelect,
  Radio,
  FormInstance,
} from "@pankod/refine-antd";

import { useGetIdentity } from "@pankod/refine-core";
import { IVideoResourcesCategories } from "interfaces";

type CreateVideoResourceCategories = {
  modalProps: ModalProps;
  formProps: FormProps;
  createForm: FormInstance;
  closeCreateModal: any;
  currentUser: any;
};

const { TextArea } = Input;

export const CreateVideoResourceCategories: React.FC<
  CreateVideoResourceCategories
> = ({ modalProps, formProps, createForm, closeCreateModal, currentUser }) => {
  const { data: user } = useGetIdentity();

  const { selectProps: videoCategorySelectProps } =
    useSelect<IVideoResourcesCategories>({
      resource: "indiacharts_video_resources_categories",
      optionLabel: "category",
      optionValue: "id",
      pagination: { pageSize: 999 },
      metaData: {
        fields: ["id", "category"],
      },
    });

  return (
    <Modal
      {...modalProps}
      title="Create Info Video Category"
      onOk={() => {
        createForm?.resetFields();
      }}
      onCancel={() => {
        createForm?.resetFields();
        closeCreateModal();
      }}
    >
      <Form {...formProps} size="large" layout="vertical">
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Category"
              name="category"
              rules={[
                { required: true },
                {
                  // pattern: new RegExp("^\\S*$"), // No Space Allowed
                  pattern: new RegExp(/^[^\s]/), // Check For Space At Beginning Only
                  message: "No spaces allowed",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Active"
              name="is_active"
              rules={[{ required: false }]}
              initialValue={true}
            >
              <Radio.Group defaultValue={true}>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Created By"
          name="created_by"
          initialValue={user?.id}
          hidden={true}
        ></Form.Item>
      </Form>
    </Modal>
  );
};
