/*

This component fetches the data for a data feed when the name of the datafeed is provided

*/

import { IResourceComponentsProps, useExport, useNavigation } from "@pankod/refine-core";
import { List, RefreshButton, Table, useTable, DateField, getDefaultSortOrder, Icons, TableColumnType } from "@pankod/refine-antd";


const { PlusOutlined } = Icons;

const columns = [
    {
        title: 'Name',
        dataIndex: 'agencyname',
        key: 'name',
    },
    {
        title: 'companycode',
        dataIndex: 'companycode',
        key: 'companycode',
    },
    {
        title: 'Modified Date',
        dataIndex: 'modifieddate',
        key: 'modifieddate'
    },
];

export const getColumns = (
    datafeedfields: any
): any[] | undefined => {
    var tableColumns: any = [];
    for (var i = 0; i < datafeedfields?.length; i++) {
        tableColumns.push({
            title: datafeedfields[i].label,
            dataIndex: datafeedfields[i].name,
            key: datafeedfields[i].name,
        })
    }
    return tableColumns;
};

export const getMetadata = (
    datafeedfields: any
): any[] | undefined => {
    var metaData: any = [];
    for (var i = 0; i < datafeedfields?.length; i++) {
        metaData.push(datafeedfields[i].name)
    }
    return metaData;
};



export const DataFeedTable: React.FC<IResourceComponentsProps> = ({ initialData }) => {
    const { show } = useNavigation()
    const datafeed = initialData?.datafeed;
    const datafeedfields = initialData?.datafeedfields;
    const { tableProps, sorter, tableQueryResult } = useTable({
       // initialSorter: [{ field: "modifieddate", order: "desc", }],
        resource: datafeed?.name,
        metaData: { fields: getMetadata(datafeedfields) },
        
    });
    return (
        
            <Table {...tableProps} rowKey="id" size="small" columns={getColumns(datafeedfields)} />


        
    );
};



