import dayjs from "dayjs";
import { nhost } from "./nhost";

export async function fetchUserData(
  fromDate: dayjs.Dayjs | null = null,
  toDate?: dayjs.Dayjs | null
) {
  const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/v1/api/stats/user-registrations`;
  const token = nhost?.auth?.getAccessToken();

  if (!fromDate || !toDate) {
    toDate = dayjs(); // Current date
    fromDate = toDate.subtract(1, "week");
  }

  const fromDateStr = fromDate.format("YYYY-MM-DD");
  const toDateStr = toDate.format("YYYY-MM-DD");

  const apiURLWithDates = `${apiUrl}?from_date=${fromDateStr}&to_date=${toDateStr}`;

  try {
    const response = await fetch(apiURLWithDates, {
      headers: { Authorization: "Bearer " + token },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
}
