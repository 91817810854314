import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  Select,
  Row,
  Col,
} from "@pankod/refine-antd";

type Props = {
  modalProps: ModalProps;
  formProps: FormProps;
};

export const EditIndex: React.FC<Props> = ({ modalProps, formProps }) => {
  return (
    <Modal {...modalProps} title="Edit Index">
      <Form {...formProps} size="large" layout="vertical">
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Security Code"
              name="security_code"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Exchange"
              name="exchange"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                // defaultValue="NSE"
                // onChange={handleChange}
                options={[
                  {
                    value: "NSE",
                    label: "NSE",
                  },
                  {
                    value: "BSE",
                    label: "BSE",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Symbol"
              name="symbol"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="IC Active"
              name="ic_active"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                // defaultValue="true"
                // onChange={handleChange}
                options={[
                  {
                    value: true,
                    label: "True",
                  },
                  {
                    value: false,
                    label: "False",
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
