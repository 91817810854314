import {
  IResourceComponentsProps,
  useGetIdentity,
  useList,
} from "@pankod/refine-core";
import {
  List,
  RefreshButton,
  CreateButton,
  ShowButton,
  Table,
  useTable,
  EditButton,
  DeleteButton,
  DateField,
  getDefaultSortOrder,
  useModalForm,
  Icons,
  Menu,
  Form,
  Col,
  Input,
  useForm,
  MenuProps,
} from "@pankod/refine-antd";

import debounce from "lodash/debounce";

// antd
import { Select, Space } from "antd";

// Interface
import { IIndicator, IIndices, ISentimentIndicatorHeader } from "interfaces";

// Component
import { CreateIndicator } from "./create";
import { EditIndicator } from "./edit";
import { useState } from "react";

// Webhook
import { indicatorAPI } from "utility/webhook";

// Icons
const { PlusOutlined } = Icons;

export const IndicatorList: React.FC<IResourceComponentsProps> = () => {
  const [searchTerm, setSearchTerm] = useState<any>("");

  const { form, formProps: tableForm } = useForm();

  const [filterValue, setfilterValue] = useState<any>();

  const { data: user } = useGetIdentity();

  const [object, setObject] = useState<IIndicator | any>({});
  const [current, setCurrent] = useState("true");

  const onMenuClick: MenuProps["onClick"] = (e: any) => {
    console.log("click ", e);
    setCurrent(e.key);
  };

  const postListQueryResult = useList<IIndices>({
    resource: "indiacharts_indices",
    metaData: {
      fields: ["id", "name"],
    },
    config: {
      pagination: { current: 1, pageSize: 250 },
    },
  });

  const sentimentHeaderQueryResult = useList<ISentimentIndicatorHeader>({
    resource: "indiacharts_sentiment_indicator_headers",
    metaData: {
      fields: ["id", "label"],
    },
    config: {
      pagination: { current: 1, pageSize: 20 },
    },
  });

  // Table
  const { tableProps, sorter, tableQueryResult, setFilters, searchFormProps } =
    useTable<IIndicator>({
      resource: "indiacharts_indicators",
      initialSorter: [
        {
          field: "name",
          order: "asc",
        },
      ],
      initialPageSize: 20,
      metaData: {
        fields: [
          "id",
          "name",
          "overbought",
          "sortno",
          "isactive",
          "oversold",
          "indexes",
          "headers",
          "category",
          "db_field",
          "created_at",
          "created_by",
          "updated_at",
          "updated_by",
          "overbought_zones",
          "oversold_zones",
          "plot_type",
          "dual_color_enabled",
        ],
      },
      initialFilter: [
        {
          field: "isactive",
          operator: "eq",
          value: "true",
        },
      ],
    });

  // Create
  const {
    formProps,
    modalProps,
    show: showModal,
    close: closeModal,
  } = useModalForm({
    resource: "indiacharts_indicators",
    action: "create",
    redirect: false,
    metaData: {
      fields: [
        "id",
        "name",
        "overbought",
        "oversold",
        "isactive",
        "indexes",
        "category",
        "headers",
        "created_at",
        "created_by",
        "sortno",
        "updated_at",
        "updated_by",
        "db_field",
        "overbought_zones",
        "oversold_zones",
        "plot_type",
        "dual_color_enabled",
      ],
    },
  });

  // console.log("Table data :::", tableQueryResult)

  // Edit
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "indiacharts_indicators",
    action: "edit",
    redirect: false,
    metaData: {
      fields: [
        "id",
        "name",
        "overbought",
        "oversold",
        "indexes",
        "headers",
        "category",
        "created_at",
        "created_by",
        "sortno",
        "isactive",
        "updated_at",
        "updated_by",
        "db_field",
        "overbought_zones",
        "oversold_zones",
        "plot_type",
        "dual_color_enabled",
      ],
    },
  });

  const handleSearch = debounce((e: any) => {
    const searchTerm = e;
    setFilters([
      {
        field: "name",
        operator: "contains",
        value: `%${searchTerm}%`,
      },
    ]);
  }, 500);

  return (
    <List
      pageHeaderProps={{
        extra: [
          <span>Filter:</span>,
          <Select
            // defaultValue="Filter Category"
            value={filterValue}
            placeholder="Category"
            style={{ width: 180 }}
            onChange={(value: string) => {
              if (value === "sentiment") {
                setFilters([
                  {
                    field: "category",
                    operator: "eq",
                    value: "sentiment",
                  },
                ]);
              } else if (value === "diffusion") {
                setFilters([
                  {
                    field: "category",
                    operator: "eq",
                    value: "diffusion",
                  },
                ]);
              } else setFilters([], "replace");
            }}
            options={[
              { value: "sentiment", label: "Sentiment" },
              { value: "diffusion", label: "Diffusion" },
              // { value: "all", label: "All" },
            ]}
          />,
          ,
          <RefreshButton
            onClick={() => {
              setfilterValue(null);
              setFilters([
                {
                  field: "category",
                  operator: "eq",
                  value: undefined,
                },
              ]);
              handleSearch("");
              // tableQueryResult?.refetch();
              form?.resetFields(["name"]);
            }}
          />,
          <CreateButton
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => showModal()}
          />,
        ],
      }}
    >
      <Form {...tableForm}>
        <Col span={6}>
          <Form.Item name="name">
            <Input
              onChange={(e: any) => {
                handleSearch(e?.target?.value);
              }}
              placeholder="Search by name"
              value={searchTerm}
              prefix={<Icons.SearchOutlined />}
            />
          </Form.Item>
        </Col>
      </Form>
      {/* Table */}
      <Menu
        selectedKeys={[current]}
        onClick={onMenuClick}
        mode="horizontal"
        onSelect={(props) => {
          setFilters([
            {
              field: "isactive",
              operator: "eq",
              value: props.key,
            },
          ]);
          tableQueryResult?.refetch();
        }}
      >
        <Menu.Item key="true">Active</Menu.Item>
        <Menu.Item key="false">In Active</Menu.Item>
      </Menu>
      <Table
        {...tableProps}
        rowKey="id"
        size="small"
        scroll={{ x: 2000 }}
        // columns={columns}
      >
        <Table.Column width={80} dataIndex="sortno" title="Sort No." />
        <Table.Column<IIndicator>
          width={250}
          dataIndex="name"
          title="Name"
          render={(_, record) => (
            <Space>
              <ShowButton
                size="small"
                type="link"
                icon={null}
                resource="indiacharts_indicators"
                recordItemId={record.id}
              >
                {record.name}
              </ShowButton>
            </Space>
          )}
        />
        {/* <Table.Column dataIndex="overbought" title="Over Bought" />
        <Table.Column dataIndex="oversold" title="Over Sold" /> */}
        <Table.Column<IIndicator>
          dataIndex="indexes"
          title="Linked Index"
          render={(_, record) => (
            <Space>
              {postListQueryResult.isFetching
                ? record.indexes?.join(", ")
                : postListQueryResult?.data?.data
                    ?.filter((_p) => record.indexes.includes(_p.id))
                    .map((_p) => _p.name)
                    .join(",")}
            </Space>
          )}
        />
        <Table.Column width={100} dataIndex="category" title="Category" />
        {/* <Table.Column dataIndex="plot_type" title="Plot type" /> */}
        <Table.Column dataIndex="db_field" title="DB Field" />
        <Table.Column<IIndicator>
          dataIndex="headers"
          title="Sentiment Headers"
          render={(_, record) => (
            <Space>
              {sentimentHeaderQueryResult.isFetching
                ? record.headers?.join(", ")
                : sentimentHeaderQueryResult?.data?.data
                    ?.filter((_p) => record.headers.includes(_p.id))
                    .map((_p) => _p.label)
                    .join(",")}
            </Space>
          )}
        />
        <Table.Column
          dataIndex="overbought_zones"
          title="Overbought 1"
          render={(value: any) => {
            const firstValue = value[0]?.value;
            // console.log(firstTitle);
            return <span>{firstValue}</span>;
          }}
        />
        <Table.Column
          dataIndex="oversold_zones"
          title="Oversold 1"
          render={(value: any) => {
            const firstValue = value[0]?.value;
            // console.log(firstTitle);
            return <span>{firstValue}</span>;
          }}
        />
        <Table.Column
          dataIndex="overbought_zones"
          title="Overbought 2"
          render={(value: any) => {
            const firstValue = value[1]?.value;
            // console.log(firstTitle);
            return <span>{firstValue}</span>;
          }}
        />
        <Table.Column
          dataIndex="oversold_zones"
          title="Oversold 2"
          render={(value: any) => {
            const firstValue = value[1]?.value;
            return <span>{firstValue}</span>;
          }}
        />
        <Table.Column
          dataIndex="created_at"
          title="Created Date"
          render={(value) => <DateField value={value} format="lll" />}
          defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="updated_at"
          title="Updated Date"
          render={(value) => <DateField value={value} format="lll" />}
          defaultSortOrder={getDefaultSortOrder("updated_at", sorter)}
          sorter
        />
        <Table.Column<IIndicator>
          width={100}
          fixed="right"
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton
                hideText
                size="small"
                type="link"
                recordItemId={record.id}
                onClick={() => setObject(record)}
              />
              <DeleteButton
                hideText
                size="small"
                type="link"
                recordItemId={record.id}
                resourceNameOrRouteName="indiacharts_indicators"
                onClick={async () => {
                  indicatorAPI(record?.category);
                }}
              />
            </Space>
          )}
        />
      </Table>

      {/* Create */}
      <CreateIndicator
        modalProps={modalProps}
        formProps={formProps}
        refetchTable={tableQueryResult}
        closeModal={closeModal}
        currentUser={user?.id}
      />

      {/* Edit */}
      <EditIndicator
        modalProps={editModalProps}
        formProps={editFormProps}
        dataObject={object}
        setDataObject={setObject}
        tableQueryResult={tableQueryResult}
      />
    </List>
  );
};
