import { IResourceComponentsProps } from "@pankod/refine-core";
import { useTable, List, RefreshButton, Table, useModalForm, Icons, CreateButton, EditButton, DeleteButton, Space, Button, DateField, BooleanField } from "@pankod/refine-antd";
import { IBusinessGroups } from "interfaces";
import { CreateBusinessGroup } from "./create";
import { EditBusinessGroup } from "./edit";
const { PlusOutlined,CheckCircleOutlined,CloseCircleOutlined } = Icons;

export const BusinessGroupsList: React.FC<IResourceComponentsProps> = () =>{

    const {tableProps, tableQueryResult}= useTable<IBusinessGroups>({
        resource: "indiacharts_business_group",
        metaData:{
            fields:[
                "id",
                "code",
                "name",
            ]
        }
    })
    // console.log("comp", tableProps);

    const { formProps, modalProps, show: showModal } = useModalForm({
        resource: "indiacharts_business_group",
        action: "create",
        metaData:{
            fields:[
                "id",
                "code",
                "name",
            ]
        }
    })

    const { formProps: editFormProps, modalProps: editModalProps, show: editShow } = useModalForm({
        resource: "indiacharts_business_group",
        action: "edit",
        metaData:{
            fields:[
                "id",
                "code",
                "name",
            ]
        }
    })
    
    return(
        <List
            headerProps={{
                extra: [
                    <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
                    <CreateButton type="primary" icon={<PlusOutlined />} onClick={() => showModal()} />,
                ],
            }}
        >
            <Table {...tableProps} size="small">
                <Table.Column
                    dataIndex="code"
                    title="Code"
                />
                <Table.Column
                    dataIndex="name"
                    title="Name"
                />
                <Table.Column<IBusinessGroups>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record)=>(
                        <Space>
                            <EditButton hideText
                            size="small"
                            type="link"
                            recordItemId={record.id}
                            onClick={()=> editShow(record.id)}
                        />
                        <DeleteButton hideText
                            size="small"
                            type="link"
                            recordItemId={record.id}
                            resourceNameOrRouteName="indiacharts_business_group"
                        />
                        </Space>
                    )}
                />
            </Table>
            <CreateBusinessGroup modalProps={modalProps} formProps={formProps} />
            <EditBusinessGroup modalProps={editModalProps} formProps={editFormProps} />
        </List>
    )
}