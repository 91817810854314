import React from "react";
import { Modal, Form } from "@pankod/refine-antd";
import { Row, Col, InputNumber, Input, notification } from "antd";
import { DatePicker } from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import moment from "moment";

// Interface
import { IIndexData } from "interfaces";

// Mutation
import { updateIndexDataByPK } from "graphql/mutation";

type props = {
  dataObject: IIndexData;
  setDataObject: React.Dispatch<React.SetStateAction<IIndexData | any>>;
  tableQueryResult: any;
};

export const IndexDataEdit: React.FC<props> = ({
  dataObject,
  setDataObject,
  tableQueryResult,
}) => {
  // Initialize Form
  const [form] = Form.useForm();

  // Disable Future Date
  const disabledFutureDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current > moment().endOf("day");
  };

  // Form Submit
  const onFinish = async (formData: any) => {
    await updateIndexDataByPK(
      {
        ticker: formData?.ticker,
        symbol: formData?.symbol,
        open_price: formData?.open_price,
        low_price: formData?.low_price,
        high_price: formData?.high_price,
        close_price: formData?.close_price,
        date: moment(formData?.date).format("YYYY-MM-DD"),
      },
      {
        date: dataObject?.date,
        index_id: dataObject?.index_id,
      }
    )
      .then((res: any) => {
        if (
          res?.error &&
          res?.error[0]?.extensions?.code === "constraint-violation"
        ) {
          notification.open({
            message: "Error",
            type: "error",
            description: "Primary key duplication error",
          });
        }
        tableQueryResult.refetch();
        setDataObject({});
      })
      .catch((err) => {
        notification.open({
          message: "Error",
          type: "error",
          description: "Error while updating index data.",
        });
        console.log("ERR ::: ", err);
      });
  };

  return (
    <Modal
      title="Edit Index Data"
      width={1000}
      visible={Object.keys(dataObject).length > 0 ? true : false}
      onCancel={() => {
        setDataObject({});
      }}
      onOk={() =>
        onFinish({
          ticker: form?.getFieldValue("ticker"),
          symbol: form?.getFieldValue("symbol"),
          date: form?.getFieldValue("date"),
          open_price: form?.getFieldValue("open_price"),
          low_price: form?.getFieldValue("low_price"),
          high_price: form?.getFieldValue("high_price"),
          close_price: form?.getFieldValue("close_price"),
        })
      }
    >
      <Form
        form={form}
        size="large"
        layout="vertical"
        initialValues={{
          ticker: dataObject?.ticker,
          symbol: dataObject?.symbol,
          date: moment(dataObject?.date),
          open_price: dataObject?.open_price,
          low_price: dataObject?.low_price,
          high_price: dataObject?.high_price,
          close_price: dataObject?.close_price,
        }}
      >
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Ticker"
              name="ticker"
              rules={[{ required: true, message: "Ticker is required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Symbol"
              name="symbol"
              rules={[{ required: true, message: "Symbol is required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Date"
              name="date"
              rules={[
                {
                  required: true,
                  message: "Date is required",
                },
              ]}
            >
              <DatePicker
                format={"YYYY-MM-DD"}
                style={{ width: "100%" }}
                placement="bottomLeft"
                disabledDate={disabledFutureDate}
              ></DatePicker>
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Open Price"
              name="open_price"
              rules={[{ required: true, message: "Open price is required" }]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Low Price"
              name="low_price"
              rules={[{ required: true, message: "Low price is required" }]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="High Price"
              name="high_price"
              rules={[{ required: true, message: "High price is required" }]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Close Price"
              name="close_price"
              rules={[{ required: true, message: "Close price is required" }]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
