import React from "react";
import {
  Table,
  useTable,
  List,
  RefreshButton,
  useModalForm,
  Space,
  EditButton,
  CreateButton,
  Icons,
  DeleteButton,
} from "@pankod/refine-antd";
import { useParams } from "react-router-dom";
import { useShow } from "@pankod/refine-core";

// Interface
import { IBroadIndustry, IIndustries } from "interfaces";

// Component
import { CreateIndustry } from "./industries_create";
import { EditIndustry } from "./industries_edit";

// Icons
const { PlusOutlined } = Icons;

export const IndustriesList: React.FC = () => {
  // Route Param
  const routeParams = useParams();

  // Broad Industry By ID
  const { queryResult: broadIndustryQueryResult } = useShow<IBroadIndustry>({
    resource: "indiacharts_broad_industry",
    id: routeParams?.id,
    metaData: {
      fields: ["id", "name", "code"],
    },
  });

  // Industries Table
  const { tableProps, tableQueryResult } = useTable<IIndustries>({
    resource: "indiacharts_industries",
    metaData: {
      fields: ["id", "name", "code", "broad_industry_code"],
    },
    initialPageSize: 20,
    initialSorter: [
      {
        field: "name",
        order: "asc",
      },
    ],
    permanentFilter: [
      {
        field: "broad_industry_code",
        operator: "eq",
        value: broadIndustryQueryResult?.data?.data?.code,
      },
    ],
  });

  // Create
  const {
    formProps: createFormProps,
    modalProps: createModalProps,
    show: createShowModal,
  } = useModalForm({
    resource: "indiacharts_industries",
    action: "create",
    redirect: false,
    metaData: {
      fields: ["id", "name", "code", "broad_industry_code"],
    },
  });

  // Edit
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShowModal,
  } = useModalForm({
    resource: "indiacharts_industries",
    redirect: false,
    action: "edit",
    metaData: {
      fields: ["id", "name", "code"],
    },
  });

  return (
    <>
      {/* List */}
      <List
        title={""}
        breadcrumb={false}
        headerProps={{
          extra: [
            <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
            <CreateButton
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => createShowModal()}
            />,
          ],
        }}
      >
        <Table
          {...tableProps}
          rowKey={(record, index) => record.id}
          size="small"
        >
          <Table.Column<IBroadIndustry> dataIndex="name" title="Name" />
          <Table.Column<IBroadIndustry> dataIndex="code" title="Code" />
          <Table.Column<IBroadIndustry>
            title="Actions"
            dataIndex="actions"
            key="actions"
            render={(_, record: IBroadIndustry) => (
              <Space>
                {/* Edit Button */}
                <EditButton
                  hideText
                  size="small"
                  recordItemId={record.id}
                  onClick={() => editShowModal(record.id)}
                />

                {/* Delete Button */}
                <DeleteButton
                  hideText
                  size="small"
                  type="link"
                  recordItemId={record.id}
                  resourceNameOrRouteName="indiacharts_industries"
                />
              </Space>
            )}
          />
        </Table>

        {/* Create */}
        <CreateIndustry
          formProps={createFormProps}
          modalProps={createModalProps}
          broad_industry_code={broadIndustryQueryResult?.data?.data?.code}
        />

        {/* Edit */}
        <EditIndustry modalProps={editModalProps} formProps={editFormProps} />
      </List>
    </>
  );
};
