import React, { useEffect, useState } from "react";
import { Icons } from "@pankod/refine-antd";

// Icons
import { LoadingOutlined } from "@ant-design/icons";
import { getSubsDetail } from "graphql/queries";

const { ContactsFilled } = Icons;

const DashboardNavs = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [subscribersData, setSubscribersData] = useState<any>([]);

  // Loading elements
  const loadingElement = (
    <LoadingOutlined style={{ fontSize: 30, color: "black" }} spin />
  );

  useEffect(() => {
    getSubsDetail().then((data: any) => {
      setSubscribersData(data);
      setIsLoading(false);
    });
  }, []);

  return (
    <div className="dashboard_container">
      <h1>Dashboard</h1>
      <div className="dashboard_container-navs">
        <div className="dashboard_container-navs-nav">
          <div className="dashboard_container-navs-nav-icon">
            <ContactsFilled />
          </div>
          <div className="dashboard_container-navs-nav-text">
            <div className="dashboard_container-navs-nav-text-value">
              {isLoading
                ? loadingElement
                : subscribersData?.totalUsers?.aggregate?.count}
            </div>
            <div className="dashboard_container-navs-nav-text-header">
              Total Users
            </div>
          </div>
        </div>
        <div className="dashboard_container-navs-nav">
          <div className="dashboard_container-navs-nav-icon">
            <ContactsFilled />
          </div>
          <div className="dashboard_container-navs-nav-text">
            <div className="dashboard_container-navs-nav-text-value">
              {isLoading
                ? loadingElement
                : subscribersData?.duration_1_month?.aggregate?.count}
            </div>
            <div className="dashboard_container-navs-nav-text-header">
              1 Month
            </div>
          </div>
        </div>
        <div className="dashboard_container-navs-nav">
          <div className="dashboard_container-navs-nav-icon">
            <ContactsFilled />
          </div>
          <div className="dashboard_container-navs-nav-text">
            <div className="dashboard_container-navs-nav-text-value">
              {isLoading
                ? loadingElement
                : subscribersData?.duration_6_month?.aggregate?.count}
            </div>
            <div className="dashboard_container-navs-nav-text-header">
              6 Month
            </div>
          </div>
        </div>

        <div className="dashboard_container-navs-nav">
          <div className="dashboard_container-navs-nav-icon">
            <ContactsFilled />
          </div>
          <div className="dashboard_container-navs-nav-text">
            <div className="dashboard_container-navs-nav-text-value">
              {isLoading
                ? loadingElement
                : subscribersData?.duration_1_year?.aggregate?.count}
            </div>
            <div className="dashboard_container-navs-nav-text-header">
              1 Year
            </div>
          </div>
        </div>
        <div className="dashboard_container-navs-nav">
          <div className="dashboard_container-navs-nav-icon">
            <ContactsFilled />
          </div>
          <div className="dashboard_container-navs-nav-text">
            <div className="dashboard_container-navs-nav-text-value">
              {isLoading
                ? loadingElement
                : subscribersData?.duration_2_year?.aggregate?.count}
            </div>
            <div className="dashboard_container-navs-nav-text-header">
              2 Years
            </div>
          </div>
        </div>
        <div className="dashboard_container-navs-nav">
          <div className="dashboard_container-navs-nav-icon">
            <ContactsFilled />
          </div>
          <div className="dashboard_container-navs-nav-text">
            <div className="dashboard_container-navs-nav-text-value">
              {isLoading
                ? loadingElement
                : subscribersData?.duration_5_year?.aggregate?.count}
            </div>
            <div className="dashboard_container-navs-nav-text-header">
              5 Years
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardNavs;
