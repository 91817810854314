import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  InputNumber,
  useSelect,
  Select,
  Row,
  Col,
  DatePicker,
} from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";

type CreatePodProps = {
  modalProps: ModalProps;
  formProps: FormProps;
};
const { TextArea } = Input;

export const CreatePod: React.FC<CreatePodProps> = ({
  modalProps,
  formProps,
}) => {
  const { data: user } = useGetIdentity();

  return (
    <Modal {...modalProps} title="Create Pod">
      <Form
        {...formProps}
        size="large"
        layout="vertical"
        initialValues={{ creadet_by: user?.id }}
      >
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Name "
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Config"
              name="config"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <TextArea />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="created_by" initialValue={user?.id}>
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
