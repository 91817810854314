import React from "react";
import { ModalProps, FormProps, Modal, Form } from "@pankod/refine-antd";
import { Row, Col, Input, notification } from "antd";
import { useParams } from "react-router-dom";
import { useGetIdentity } from "@pankod/refine-core";
import { DatePicker } from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import moment from "moment";

// Interface
import { IIndicatorData } from "interfaces";

// Mutation
import { insertIndicatorDataOne } from "graphql/mutation";

// Webhook
import { indicatorDataAPI } from "utility/webhook";

type props = {
  createModalProps: ModalProps;
  createFormProps: FormProps;
  close: any;
  tableQueryResult: any;
};

export const IndicatorDataCreate: React.FC<props> = ({
  createModalProps,
  createFormProps,
  close,
  tableQueryResult,
}) => {
  // Route Param
  const routeParams = useParams();

  // User Identity
  const { data: user } = useGetIdentity();

  // Disable Future Date
  const disabledFutureDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current > moment().endOf("day");
  };

  // Form Submit
  const onFinish = async (formData: IIndicatorData) => {
    await insertIndicatorDataOne({
      ...formData,
      date: moment(formData?.date).format("YYYY-MM-DD"),
      created_by: user?.id,
      indicator_id: String(routeParams?.id),
    })
      .then((res: any) => {
        if (
          res?.error &&
          res?.error[0]?.extensions?.code === "constraint-violation"
        ) {
          notification.open({
            message: "Duplicate key violation",
            type: "error",
            description: "Primary key duplication error",
          });
        }
        indicatorDataAPI({
          indicator_id: String(routeParams?.id)
        });
        tableQueryResult?.refetch();
        createFormProps?.form?.resetFields(["date", "value"]);
        close();
      })
      .catch((err) => {
        notification.open({
          message: "Error",
          type: "error",
          description: "Error while creating indicator data.",
        });
        console.log("ERR ::: ", err);
      });
  };

  return (
    <Modal {...createModalProps} title="Create Indicator Data">
      <Form
        {...createFormProps}
        size="large"
        layout="vertical"
        onFinish={onFinish}
      >
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Value"
              name="value"
              rules={[{ required: true, message: "Value is required" }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={8} lg={8}>
            <Form.Item
              label="Date"
              name="date"
              initialValue={moment()}
              rules={[
                {
                  required: true,
                  message: "Date is required",
                },
              ]}
            >
              <DatePicker
                format={"YYYY-MM-DD"}
                style={{ width: "100%" }}
                placement="bottomLeft"
                disabledDate={disabledFutureDate}
              ></DatePicker>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
