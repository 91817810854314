import {
  IResourceComponentsProps,
  useExport,
  useNavigation,
  useImport,
} from "@pankod/refine-core";

import {
  List,
  RefreshButton,
  CreateButton,
  ExportButton,
  ShowButton,
  Table,
  useTable,
  Space,
  EditButton,
  DeleteButton,
  DateField,
  getDefaultSortOrder,
  useModalForm,
  Icons,
  ImportButton,
} from "@pankod/refine-antd";
const { PlusOutlined } = Icons;

export interface IFiiDetails {
  Date: Date;
  conversionvalue: number;
  debt_sell_primary: number;
  debt_sell_secondary: number;
  equity_sell_primary: number;
  equity_sell_secondary: number;
  debt_purchase_primary: number;
  debt_purchase_secondary: number;
  debt_netinvestment_primary: number;
  debt_netinvestment_secondary: number;
  equity_netinvestment_primary: number;
  equity_netinvestment_secondary: number;
}

export const FiiDetails: React.FC<any> = () => {
  const { tableProps, sorter, tableQueryResult } = useTable<IFiiDetails>({
    resource: "fiidetails",
    metaData: {
      fields: [
        "Date",
        "conversionvalue",
        "debt_sell_primary",
        "debt_sell_secondary",
        "equity_sell_primary",
        "equity_sell_secondary",
        "debt_purchase_primary",
        "debt_purchase_secondary",
        "equity_netinvestment_primary",
        "equity_netinvestment_secondary",
        "debt_netinvestment_primary",
        "debt_netinvestment_secondary",
        "equity_purchase_primary",
        "equity_purchase_secondary",
      ],
    },
  });

  return (
    <List
      pageHeaderProps={{
        extra: [
          // <ExportButton onClick={triggerExport} loading={exportLoading}/>,
          <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
          <CreateButton type="primary" icon={<PlusOutlined />} />,
        ],
      }}
    >
      <Table {...tableProps}>
        <Table.Column dataIndex="Date" title="Date" />
        <Table.Column dataIndex="conversionvalue" title="Conversion Value" />
        <Table.Column dataIndex="debt_sell_primary" title="Debt Sell Primary" />
        <Table.Column
          dataIndex="debt_sell_secondary"
          title="Debt Sell Secondary"
        />
        <Table.Column
          dataIndex="equity_sell_primary"
          title="Equity Sell Primary"
        />
        <Table.Column
          dataIndex="equity_sell_secondary"
          title="Equity Sell Secondary"
        />
        <Table.Column
          dataIndex="debt_purchase_primary"
          title="Debt Purchase Primary"
        />
        <Table.Column
          dataIndex="debt_purchase_secondary"
          title="Debt Purchase Secondary"
        />
        <Table.Column
          dataIndex="debt_netinvestment_primary"
          title="Debt Netinvestment Primary"
        />
        <Table.Column
          dataIndex="debt_netinvestment_secondary"
          title="Debt Netinvestment Secondary"
        />
        <Table.Column
          dataIndex="equity_netinvestment_primary"
          title="Equity Netinvestment Primary"
        />
        <Table.Column
          dataIndex="equity_netinvestment_secondary"
          title="Equity Netinvestment Secondary"
        />
        <Table.Column
          dataIndex="equity_purchase_primary"
          title="Equity Purchase Primary"
        />
        <Table.Column
          dataIndex="equity_purchase_secondary"
          title="Equity Purchase Secondary"
        />
      </Table>
    </List>
  );
};
