import React from "react";
import { ModalProps, FormProps, Modal, Form } from "@pankod/refine-antd";
import { Row, Col, Input, InputNumber } from "antd";

type props = {
  editModalProps: ModalProps;
  editFormProps: FormProps;
};

export const IndicesStocksEdit: React.FC<props> = ({
  editModalProps,
  editFormProps,
}) => {
  return (
    <Modal {...editModalProps} title="Edit Indices Stock">
      <Form {...editFormProps} size="large" layout="vertical">
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Ticker Name"
              name="ticker_name"
              rules={[{ required: true, message: "Ticker name is required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Security Code"
              name="security_code"
              rules={[{ required: true, message: "Security code is required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Company Code"
              name="company_code"
              rules={[{ required: true, message: "Company code is required" }]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="ISIN Code"
              name="isincode"
              rules={[{ required: true, message: "ISIN code is required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Symbol"
              name="symbol"
              rules={[{ required: true, message: "Symbol is required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true, message: "Description is required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
