import { useRef, useEffect, useCallback } from "react";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useResource } from "@pankod/refine-core";
import { useDispatch, useSelector } from "react-redux";

// Components
import CustomAgGrid from "components/CustomAgGrid";

// Configs
import { settlementPaidHistoryHeader } from "components/partner-program/config";

// API
import { getSettlementPaidHistory } from "rest/partner-program";

// Query
import { getUserEmailByID } from "graphql/queries";

// Redux
import { setEmail } from "store/partner-program/partnerProgramSlice";

const SettlementHistoryTable = () => {
  // Ref
  const gridRef = useRef<any>(null);

  // Dispatch
  const dispatch = useDispatch();

  // URL Params
  const { id: partner_id } = useResource();

  // Redux
  const { email } = useSelector((store: any) => store.PartnerProgram);
  const { totalAmount } = useSelector(
    (store: any) => store.PartnerProgram?.settlement
  );

  // Variable
  const LIMIT = 50;

  // API
  const { data: userEmail } = useQuery<any>({
    queryKey: ["GetUserEmailByID", partner_id],
    queryFn: () => getUserEmailByID(partner_id),
    enabled: true,
  });

  const { fetchNextPage, isLoading } = useInfiniteQuery({
    queryKey: [
      "GET_SETTLEMENT_PAID_HISTORY",
      {
        offset: 0,
        limit: LIMIT,
        email: email,
      },
    ],
    queryFn: ({ pageParam = 0 }) =>
      getSettlementPaidHistory([
        "GET_SETTLEMENT_PAID_HISTORY",
        {
          offset: pageParam * LIMIT,
          limit: LIMIT,
          email: email,
        },
      ]),
    getNextPageParam: (lastPage: any, pages: any) => lastPage.nextCursor,
    getPreviousPageParam: (firstPage: any, pages: any) => firstPage.prevCursor,
    enabled: email === null ? false : true,
  });

  // On Grid Ready
  const onGridReady = useCallback((params: any) => {
    const dataSource = {
      getRows: (params: any) => {
        const page = params?.request?.endRow / LIMIT ?? 0;
        fetchNextPage({
          pageParam: page - 1,
        }).then((res: any) => {
          let lastRow = -1;

          const rowData = res?.data?.pages?.[page - 1]?.data;
          params.success({ rowData, lastRow });
        });
      },
    };

    gridRef.current = params;
    params.api.setServerSideDatasource(dataSource);
    params.api.sizeColumnsToFit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // UseEffect
  useEffect(() => {
    if (gridRef.current?.api && email !== null) {
      gridRef.current?.api?.refreshServerSide({
        route: undefined,
        purge: true,
      });
    }
  }, [gridRef, partner_id, email, totalAmount]);

  useEffect(() => {
    if (userEmail) {
      dispatch(setEmail(userEmail?.email));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEmail]);

  return (
    <div className="table">
      <CustomAgGrid
        ref={gridRef}
        columnDefs={settlementPaidHistoryHeader}
        isLoading={isLoading}
        gridOptions={{
          rowModelType: "serverSide",
          onGridReady,
          cacheBlockSize: LIMIT,
          serverSideInfiniteScroll: true,
          getRowId: (params: any) => params.data.id,
        }}
      />
    </div>
  );
};

export default SettlementHistoryTable;
