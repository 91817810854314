import {
  Col,
  Form,
  FormProps,
  Input,
  Modal,
  ModalProps,
  Radio,
  Row,
  Select,
  useSelect,
} from "@pankod/refine-antd";
import React from "react";

interface PodsProps {
  formProps: FormProps;
  modalProps: ModalProps;
  closeCreateShow: any;
}

const PodsCreate: React.FC<PodsProps> = ({
  formProps,
  modalProps,
  closeCreateShow,
}) => {
  const { selectProps } = useSelect({
    resource: "indiacharts_pages",
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "active", "name"],
    },
    pagination: {
      pageSize: 9999,
    },
  });
  return (
    <Modal
      {...modalProps}
      title="Create Pods"
      onCancel={() => {
        formProps?.form?.resetFields();
        closeCreateShow();
      }}
    >
      <Form {...formProps} size="large" layout="vertical">
        <Row gutter={16}>
          <Col lg={12} xs={24}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Field Required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item
              name="page_id"
              label="Page"
              rules={[
                {
                  required: true,
                  message: "Field Required",
                },
              ]}
            >
              <Select {...selectProps} />
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item
              label="Private Access"
              name="is_private"
              rules={[
                {
                  required: true,
                  message: "Field Required",
                },
              ]}
            >
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item
              label="Route Name"
              name="route_name"
              rules={[
                {
                  required: true,
                  message: "Field Required",
                },
                {
                  pattern: new RegExp(/^[^\s]/), // Check For Space At Beginning Only
                  message: "No spaces allowed",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default PodsCreate;
