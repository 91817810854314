import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Divider,
  Input,
  message,
  Typography,
} from "@pankod/refine-antd";
import { useResource } from "@pankod/refine-core";
import { useQuery as useReactQuery } from "@tanstack/react-query";

// API
import { getReferralCode } from "rest";

const { Search } = Input;
const { Text } = Typography;

const ReferralCode = () => {
  // URL Params
  const { id: partner_id } = useResource();

  // State
  const [referralLink, setReferralLink] = useState("");

  const { data } = useReactQuery({
    queryKey: [
      "GET_REFERRAL_CODE",
      {
        partner_id: partner_id,
      },
    ],
    queryFn: getReferralCode,
    refetchOnWindowFocus: false,
    enabled: true,
  });

  // UseEffect
  useEffect(() => {
    if (data?.status) {
      const link = `https://web.strike.money/signup?referral_code=${data?.referralCode}`;
      setReferralLink(link);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col lg={24} md={24} sm={24}>
          <Text strong>Referral Link</Text>
          <Search
            placeholder="Referral Link"
            enterButton="Copy"
            size="large"
            value={referralLink}
            readOnly={true}
            onSearch={(value) => {
              navigator.clipboard.writeText(value).then(() => {
                message.success("Copied to clipboard");
              });
            }}
          />
        </Col>
      </Row>
      <Divider />
    </>
  );
};

export default ReferralCode;
