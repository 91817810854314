import { Row, Col } from "@pankod/refine-antd";

// Components
import PastTransactionsFilter from "components/partner-program/PastTransactionsFilter";
import PastTransactionsTable from "components/partner-program/PastTransactionsTable";

const PastTransactions = () => {
  return (
    <div className="main-card past-transaction">
      <Row gutter={[20, 20]}>
        <Col
          lg={24}
          md={24}
          sm={24}
          style={{
            borderRight: "1px solid #e5e5e5",
          }}
        >
          <PastTransactionsFilter />
          <PastTransactionsTable />
        </Col>
      </Row>
    </div>
  );
};

export default PastTransactions;
