import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useShow } from "@pankod/refine-core";
import { PageHeader, ListButton, Tabs, Icons } from "@pankod/refine-antd";

// Interface
import { IIndices } from "interfaces";

// Component
import { IndicesStocksList } from "./indices_stocks_list";
import { IndexDataList } from "./index_data_list";

const { FundOutlined } = Icons;

export const ShowIndex: React.FC = () => {
  // Route Param
  const routeParams = useParams();

  const [currentBreadCrumb, setCurrentBreadCrumb] = useState("Stocks");

  // Indices Data By ID
  const { queryResult: indicesQueryResult } = useShow<IIndices>({
    resource: "indiacharts_indices",
    id: routeParams?.id,
    metaData: {
      fields: ["id", "name", "ticker", "symbol", "exchange", "created_at"],
    },
  });

  const handleTabChange = (key: string) => {
    setCurrentBreadCrumb(key);
  };

  return (
    <>
      <div className="breadcrumb">
        <FundOutlined /> Indexes / {currentBreadCrumb}
      </div>
      <PageHeader
        title={indicesQueryResult?.data?.data?.name}
        onBack={() => window.history.back()}
        extra={[<ListButton />]}
        footer={
          <Tabs onChange={handleTabChange}>
            <Tabs.TabPane tab="Stocks" key="Stocks">
              <IndicesStocksList />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Index Data" key="Index Data">
              <IndexDataList />
            </Tabs.TabPane>
          </Tabs>
        }
      ></PageHeader>
    </>
  );
};
