import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  Row,
  Col,
  Radio,
} from "@pankod/refine-antd";
import { useGetIdentity, useCreate } from "@pankod/refine-core";

// Interface
import { IWebinarCategories } from "interfaces";

type Props = {
  modalProps: ModalProps;
  formProps: FormProps;
  tableQueryResult: any;
  closeModal: any;
  currentUser: any;
};

export const CreateWebinarCategory: React.FC<Props> = ({
  modalProps,
  formProps,
  tableQueryResult,
  closeModal,
  currentUser,
}) => {
  const { data: user } = useGetIdentity();

  // Create Plan
  const { mutateAsync: createCategory } = useCreate<any>();

  // Form Submit
  const onFinish = async (formData: IWebinarCategories) => {
    await createCategory({
      resource: "indiacharts_webinar_categories",
      values: {
        category: formData.category,
        is_active: formData.is_active,
        created_by: currentUser,
      },
    }).then(() => {
      tableQueryResult.refetch();
      formProps?.form?.resetFields();
      closeModal();
    });
  };

  // Form Submit Failed
  const onFinishFailed = async (err: any) => {
    console.log("Form Submit Error : ", err);
  };

  return (
    <Modal
      {...modalProps}
      title="Create Webinar Category"
      onCancel={() => {
        formProps?.form?.resetFields();
        closeModal();
      }}
    >
      <Form
        {...formProps}
        size="large"
        layout="vertical"
        initialValues={{ is_active: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Category"
              name="category"
              rules={[
                { required: true },
                {
                  // pattern: new RegExp("^\\S*$"), // No Space Allowed
                  pattern: new RegExp(/^[^\s]/), // Check For Space At Beginning Only
                  message: "No spaces allowed",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Active"
              name="is_active"
              rules={[{ required: false }]}
              initialValue={true}
            >
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="created_by" initialValue={currentUser} hidden>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
