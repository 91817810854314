import { Icons, Select } from "@pankod/refine-antd";
import { CustomerTicketsList } from "pages/customer_tickets";
import React from "react";
import SubscribersChart from "./SubscribersChart";
import dayjs from "dayjs";
import { useQuery } from "@tanstack/react-query";
import { getTotalPayments } from "graphql/queries";

const { ContactsFilled, ArrowRightOutlined, UserOutlined } = Icons;

const DashboardTables = () => {
  const startYear = "2023";

  const totalYears = dayjs().diff(startYear, "years");
  console.log(totalYears, ":test");
  const yearPeriodOption = () => {
    const arr = [];
    for (let i = 0; i < totalYears + 1; i++) {
      const currentYear = Number(startYear) + i;
      arr.push({
        label: `${currentYear}-${currentYear + 1}`,
        value: `${currentYear}-${currentYear + 1}`,
      });
    }
    return arr;
  };

  const periodOptions = yearPeriodOption();

  const { data } = useQuery({
    queryKey: ["GET_TOTAL_PAYMENTS"],
    queryFn: getTotalPayments,
  });

  const totalPayment = data ? data : 0;
  const affiliateCommision = 0;
  return (
    <div className="table_container">
      <div className="table_container-table">
        {/* <CustomerTicketsList /> */}
        <SubscribersChart />
      </div>
      <div className="table_container-details">
        <div className="table_container-details-title">Statistics</div>
        <div className="table_container-details-table">
          <div className="table_container-details-table-column">
            <Select style={{ width: "200px" }} placeholder="Select Year">
              {periodOptions?.map((i: any, idx) => (
                <Select.Option key={idx} value={i?.value}>
                  {i?.label}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="table_container-details-table-column">
            <span className="table_container-details-table-column-text">
              Total Sales:
            </span>
            <span className="table_container-details-table-columne-value">
              {totalPayment.toLocaleString("en-IN")}
            </span>
          </div>
          {/* <div className="table_container-details-table-column">
            <span className="table_container-details-table-column-text">
              <ArrowRightOutlined /> Subscriptions:
            </span>
            <span className="table_container-details-table-columne-value">
              50000
            </span>
          </div>
          <div className="table_container-details-table-column">
            <span className="table_container-details-table-column-text">
              <ArrowRightOutlined /> Mentorships:
            </span>
            <span className="table_container-details-table-columne-value">
              50000
            </span>
          </div> */}
          <div className="table_container-details-table-column">
            <span className="table_container-details-table-column-text">
              Affiliate Commissions:
            </span>
            <span className="table_container-details-table-columne-value">
              {affiliateCommision.toLocaleString("en-IN")}
            </span>
          </div>
          <div className="table_container-details-table-column">
            <span className="table_container-details-table-column-text">
              Total Sales Post Commissions:
            </span>
            <span className="table_container-details-table-columne-value">
              {(totalPayment - affiliateCommision).toLocaleString("en-IN")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardTables;
