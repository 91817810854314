import React from "react";
import DashboardNavs from "components/dashboard/DashboardNavs";
import DashboardSubscribers from "components/dashboard/DashboardSubscribers";
import DashboardTables from "components/dashboard/DashboardTables";
import SubscribersChart from "components/dashboard/SubscribersChart";

const DashboardList: React.FC = () => {
  return (
    <>
      <DashboardNavs />
      {/* Subscribers Details */}
      <DashboardSubscribers />
      {/* Table */}
      <DashboardTables />
      {/* Chart */}
      {/* <SubscribersChart /> */}
    </>
  );
};

export default DashboardList;
