import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

// Slice
import User from "./user/userSlice";
import PartnerProgram from "./partner-program/partnerProgramSlice";

const reducer = combineReducers({
  User,
  PartnerProgram,
});

const store = configureStore({
  reducer,
});

export default store;
