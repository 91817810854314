import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  Row,
  Col,
} from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";

type CreateDataFeedFieldProps = {
  modalProps: ModalProps;
  formProps: FormProps;
};
const { TextArea } = Input;

export const CreateDataFeedField: React.FC<CreateDataFeedFieldProps> = ({
  modalProps,
  formProps,
}) => {
  const { data: user } = useGetIdentity();

  return (
    <Modal {...modalProps} title="Create DataFeedField">
      <Form {...formProps} size="large" layout="vertical">
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Name "
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Label"
              name="label"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Sort Order"
              name="sort_order"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="created_by" initialValue={user?.id}>
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
