import { IResourceComponentsProps } from "@pankod/refine-core";
import { useDelete } from "@pankod/refine-core";
import { useTable, List, RefreshButton, Table, useModalForm, Icons, CreateButton, EditButton, DeleteButton, Space, Button, DateField } from "@pankod/refine-antd";
import { IEnumTicketCategories } from "interfaces";
import { mutateCompanyDividend } from "graphql/mutation";
import { CreateEnumTicketCategories } from "./create";
import { EditEnumTicketCategories } from "./edit";
const { PlusOutlined } = Icons;

export const EnumTicketCategoriesList: React.FC<IResourceComponentsProps> = () =>{
    const {tableProps, tableQueryResult, sorter, setFilters }= useTable<IEnumTicketCategories>({
        resource: "indiacharts_enum_ticket_categories",
        metaData:{
            fields:[
                "name",
                "comments",
            ]
        }
    })

    // console.log("tic", tableProps);
    

    const { formProps, modalProps, show: showModal } = useModalForm({
        resource: "indiacharts_enum_ticket_categories",
        action: "create",
        metaData:{
            fields:[
                "name",
                "comments",
            ]
        }
    })

    const { formProps: editFormProps, modalProps: editModalProps, show: editShow } = useModalForm({
        resource: "indiacharts_enum_ticket_categories",
        action: "edit",
        metaData:{
            fields:[
                "name",
                "comments",
            ]
        }
    })

    return(
        <List
            headerProps={{
                extra: [
                    <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
                    <CreateButton type="primary" icon={<PlusOutlined />} onClick={() => showModal()} />,
                ],
            }}
        >
            <Table {...tableProps} size="small">
                <Table.Column
                    dataIndex="name"
                    title="Name"
                />
                <Table.Column
                    dataIndex="comments"
                    title="Comments"
                />
                <Table.Column<IEnumTicketCategories>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record)=>(
                        <Space>
                            <EditButton hideText
                            size="small"
                            type="link"
                            recordItemId={record?.name}
                            onClick={()=> editShow(record.name)}
                        />
                        <DeleteButton hideText
                            size="small"
                            type="link"
                            recordItemId={record?.name}
                            resourceNameOrRouteName="indiacharts_enum_ticket_categories"
                        />
                        </Space>
                    )}
                />
            </Table>
            <EditEnumTicketCategories modalProps={editModalProps} formProps={editFormProps} />
            <CreateEnumTicketCategories modalProps={modalProps} formProps={formProps} />
        </List>
    )
}