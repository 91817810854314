import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  Row,
  Col,
  Radio,
} from "@pankod/refine-antd";

type Props = {
  modalProps: ModalProps;
  formProps: FormProps;
};

export const EditWebinarCategory: React.FC<Props> = ({
  modalProps,
  formProps,
}) => {
  return (
    <Modal
      {...modalProps}
      title="Edit Webinar Category"
      // onCancel={() => {
      //   formProps?.form?.resetFields();
      // }}
    >
      <Form {...formProps} size="large" layout="vertical">
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Category"
              name="category"
              rules={[
                { required: true },
                {
                  pattern: new RegExp(/^[^\s]/), // Check For Space At Beginning Only
                  message: "No spaces allowed",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Active"
              name="is_active"
              rules={[{ required: false }]}
            >
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Sorting number"
              name="sort_no"
              rules={[{ required: false }]}
            >
            <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
