import {
  useModalForm,
  List,
  RefreshButton,
  Table,
  useTable,
  Icons,
  Show,
  Button,
  Typography,
  TagField,
  Row,
  Col,
  Modal,
  Form,
  Input,
  RcFile,
  UploadProps,
  ImageField,
  Space,
  notification,
  DateField,
} from "@pankod/refine-antd";
import { ICustomerTicket, ITicketComment, ITicketResource } from "interfaces";
import { useCreateMany, useGetIdentity, useShow } from "@pankod/refine-core";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { nhost } from "utility";
import { useNavigation } from "@pankod/refine-core";
import { useState } from "react";
import { EditTicketStatus } from "./editStatus";

// ANTD
import { Popconfirm } from "antd";
import { tempDeleteCustTicket } from "graphql/mutation";

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;

// Icons
const {
  PlusOutlined,
  ArrowLeftOutlined,
  TagsOutlined,
  TagOutlined,
  MessageOutlined,
  DeleteOutlined,
} = Icons;

export const ShowCustomerTicket: React.FC = () => {
  const routeParams = useParams();
  const { push } = useNavigation();
  const { data: user } = useGetIdentity();
  const { mutate } = useCreateMany<ITicketResource>();

  const { queryResult } = useShow<ICustomerTicket>({
    resource: "indiacharts_customer_tickets",
    id: routeParams?.id,
    metaData: {
      fields: [
        "id",
        "customer_id",
        { customer: ["email", "displayName"] },
        "category",
        { enum_ticket_category: ["name", "comments"] },
        "type",
        { enum_ticket_type: ["name", "comments"] },
        "descriptions",
        "state",
        { enum_ticket_status: ["name", "comments", "hex_color"] },
        "title",
        "uid",
        "rejection_reason",
        "created_by",
        "updated_by",
        "created_at",
        "updated_at",
        { handler: ["email", "displayName"] },
        { creator: ["email"] },
        { modifier: ["email"] },
        {
          ticket_resources: [
            "id",
            "name",
            "link",
            "created_by",
            "deleted_by",
            "path",
            "file_id",
            { file: ["mimeType"] },
          ],
        },
      ],
    },
  });

  const { data, isLoading, refetch } = queryResult;
  // console.log("Delete id :::", queryResult?.data?.data?.id);
  const record = data?.data;
  const [fileList, setFileList] = useState<any>([]);
  const [uploadedFilesList, setUploadedFilesList] = useState<any>([]);

  const uploadDaggerProps: UploadProps = {
    name: "file",
    method: "post",
    listType: "picture",
    maxCount: 5,
    multiple: true,
    customRequest: async ({ file, onError, onSuccess }) => {
      const rcFile = file as RcFile;

      const { fileMetadata, error } = await nhost.storage.upload({
        file: rcFile,
      });
      if (error) {
        console.error(error);
        return onError?.(error);
      }

      if (fileMetadata) {
        // console.log(fileMetadata);
        setUploadedFilesList([...uploadedFilesList, file]);
        setFileList([...fileList, fileMetadata]);
        const url = nhost.storage.getUrl({ fileId: fileMetadata.id });
        // console.log(url);
        onSuccess?.({ url, fileId: fileMetadata.id }, new XMLHttpRequest());
      }
    },
    // eslint-disable-next-line
    onRemove: async (props: any) => {
      console.log("onRemove", props);
      await nhost.storage.delete({ fileId: props.fileId });
      if (fileList && fileList.length > 0) {
        let _ind = fileList.find((_f: any) => _f.if == props.fileId);
        if (_ind != -1) {
          fileList.splice(_ind, 1);
          uploadedFilesList.splice(_ind, 1);
          setFileList(fileList);
          setUploadedFilesList(uploadedFilesList);
        }
      }
      return true;
    },
    fileList: uploadedFilesList,
  };

  // Image download function

  // const FileDownloadHandler = async (fileId: any, name: any) => {
  //   axios({
  //     url: `${process.env.REACT_APP_NHOST_BACKEND}/v1/storage/files/${fileId}`,
  //     method: "GET",
  //     responseType: "blob",
  //   })
  //     ?.then((response: any) => {
  //       const href = URL.createObjectURL(response?.data);

  //       const link = document.createElement("a");
  //       link.href = href;
  //       link.setAttribute("download", name);
  //       document.body.appendChild(link);
  //       link.click();

  //       document.body.removeChild(link);
  //       URL.revokeObjectURL(href);
  //     })
  //     ?.catch((err: any) => "");
  // };

  // PDF download
  function downloadPDF(url: any, name: any) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  }

  const { tableProps, tableQueryResult } = useTable<ITicketComment>({
    resource: "indiacharts_ticket_comments",
    metaData: {
      fields: [
        "id",
        "ticket_id",
        "comment",
        "created_by",
        "created_at",
        { creator: ["email", "displayName"] },
        { ticket_resources: ["id", "name", "file_id", "link"] },
      ],
    },
    initialSorter: [{ field: "created_at", order: "asc" }],
    permanentFilter: [
      {
        field: "ticket_id",
        operator: "eq",
        value: routeParams?.id,
      },
    ],
  });

  const {
    formProps: editStatusFormProps,
    modalProps: editStatusModalProps,
    show: editStatusShow,
  } = useModalForm({
    resource: "indiacharts_customer_tickets",
    redirect: false,
    action: "edit",
    metaData: {
      fields: [
        "id",
        "customer_id",
        "category",
        "type",
        "descriptions",
        "state",
        "handler_id",
        { handler: ["id", "displayName"] },
        // "title",
        // "rejection_reason",
        // "created_by",
        // "updated_by",
        // "created_at",
        // "updated_at",
        // "creator"
      ],
    },
  });

  const {
    formProps,
    modalProps: addCommentModalProps,
    show: addCommentShow,
  } = useModalForm({
    resource: "indiacharts_ticket_comments",
    action: "create",
    redirect: false,
    defaultFormValues: () => {
      return { created_by: user?.id };
    },
    onMutationSuccess: (data, variables, context) => {
      console.log(
        "onMutationSuccess",
        data,
        variables,
        context,
        fileList,
        uploadedFilesList
      );
      // add the attachements to ticket_Resources table with comment_id.
      if (fileList.length > 0) {
        mutate(
          {
            resource: "indiacharts_ticket_resources",
            values: fileList.map((_f: any) => {
              return {
                name: _f.name,
                ticket_id: routeParams?.id,
                etag: _f.etag,
                file_id: _f.id,
                comment_id: data?.data.id,
                created_by: user?.id,
              };
            }),
          },
          {
            onError: (error, variables, context) => {
              console.error(error, variables);
            },
            onSuccess: (data, variables, context) => {
              console.log(data, variables);
            },
          }
        );
      }
    },
    metaData: {
      fields: ["id", "comment"],
    },
  });
  addCommentModalProps.afterClose = () => {
    console.log("onclose");
    setFileList([]);
    setUploadedFilesList([]);
    formProps.form?.resetFields();
  };

  const commentsColumns = [
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      render: (text: any, record: any) => (
        <Paragraph>
          <div className="ticket_comment">
            <div className="ticket_comment-header">
              <span className="ticket_comment-header-icon">
                <MessageOutlined />
              </span>
              <span className="ticket_comment-header-user_name">
                {record?.creator?.displayName || record?.creator.email}
              </span>
              <span
                className="ticket_comment-header-time"
                // style={{ fontSize: "0.8rem", opacity: "0.7" }}
              >
                {<Text>{dayjs(record?.created_at).format("lll")}</Text>}
              </span>
            </div>
            <div className="ticket_comment-value">{text}</div>
          </div>
        </Paragraph>
      ),
    },
  ];

  // Delete ticket temporarily
  const temporaryDeleteTicket = async (ticket_id: any, status: any) => {
    await tempDeleteCustTicket(ticket_id, status)
      .then(() => {
        tableQueryResult?.refetch();
        notification.open({
          message: "Successfully Deleted Customer Ticket",
          type: "success",
        });
        push(`/tickets/customer_tickets`);
      })
      .catch((error) => {
        console.log("Error while deleteing ticket", error);
      });
  };

  return (
    <>
      <div className="breadcrumb">
        <TagsOutlined /> Tickets / <TagOutlined /> Customer Tickets / #
        {record?.uid}
      </div>
      <div className="ticket-title">
        <span
          className="ticket-title-go_back"
          onClick={() => {
            push(`/tickets/customer_tickets`);
          }}
        >
          <ArrowLeftOutlined />
        </span>
        <span>Ticket Number: #{record?.uid}</span>
      </div>
      <div className="ticket_details_wrapper">
        <div className="ticket_details_wrapper-details">
          <div className="ticket_details_wrapper-details-title">
            <span>{record?.title}</span>
            <span>
              <Popconfirm
                placement="top"
                title="Delete Ticket?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => {
                  temporaryDeleteTicket(queryResult?.data?.data?.id, true);
                  refetch();
                }}
              >
                <Button
                  icon={<DeleteOutlined style={{ color: "red" }} />}
                  type="link"
                />
              </Popconfirm>
            </span>
          </div>

          <div className="ticket_details_wrapper-details-row_1">
            <div className="ticket_details_wrapper-details-row_1-category">
              <div className="ticket_details_wrapper-details-row_1-category-text details_header_text">
                Category:
              </div>
              <div className="ticket_details_wrapper-details-row_1-category-value details_header_value">
                {record?.enum_ticket_category?.comments}
              </div>
            </div>
            <div className="ticket_details_wrapper-details-row_1-type">
              <div className="ticket_details_wrapper-details-row_1-Type-text details_header_text">
                Type:
              </div>
              <div className="ticket_details_wrapper-details-row_1-Type-value details_header_value">
                {record?.enum_ticket_type?.comments}
              </div>
            </div>
          </div>
          <div className="ticket_details_wrapper-details-row_2">
            <div className="ticket_details_wrapper-details-row_2-name">
              <div className="ticket_details_wrapper-details-row_2-name-text details_header_text">
                Customer name:
              </div>
              <div className="ticket_details_wrapper-details-row_2-name-value details_header_value">
                {record?.customer?.displayName}
              </div>
            </div>
            <div className="ticket_details_wrapper-details-row_2-email">
              <div className="ticket_details_wrapper-details-row_2-email-text details_header_text">
                Customer email:
              </div>
              <div className="ticket_details_wrapper-details-row_2-email-value details_header_value">
                {record?.customer?.email}
              </div>
            </div>
          </div>
          <div className="ticket_details_wrapper-details-row_3">
            {/* <div className="ticket_details_wrapper-details-row_3-handler">
              <div className="ticket_details_wrapper-details-row_3-handler-text details_header_text">
                Handler:
              </div>
              <div className="ticket_details_wrapper-details-row_3-handler-value details_header_value">
                <Text>
                  {record?.handler?.displayName ? (
                    <span style={{ marginRight: "0.5rem" }}>
                      {record?.handler?.displayName}
                    </span>
                  ) : null}
                  <TagField
                    style={{
                      padding: "0.3rem",
                      cursor: "pointer",
                      fontSize: "1rem",
                    }}
                    value="Assign to"
                    onClick={() => editStatusShow(record?.id)}
                  />
                </Text>
              </div>
            </div> */}
            <div className="ticket_details_wrapper-details-row_3-status">
              <div className="ticket_details_wrapper-details-row_3-status-text details_header_text">
                Status:
              </div>
              <div className="ticket_details_wrapper-details-row_3-status-value details_header_value details_header_value">
                <TagField
                  style={{
                    padding: "0.3rem",
                    cursor: "pointer",
                    fontSize: "1rem",
                  }}
                  value={record?.enum_ticket_status?.comments}
                  color={record?.enum_ticket_status?.hex_color}
                />
                <TagField
                  style={{
                    padding: "0.3rem",
                    cursor: "pointer",
                    fontSize: "1rem",
                  }}
                  value="Change status"
                  onClick={() => editStatusShow(record?.id)}
                />
              </div>
           
            </div>
            <div className="ticket_details_wrapper-details-row_2-email">
              <div className="ticket_details_wrapper-details-row_2-email-text details_header_text">
              Created At
              </div>
              <div className="ticket_details_wrapper-details-row_2-email-value details_header_value">
               <DateField value= {record?.created_at} format="lll" />
              </div>
            </div>
          </div>
          <div className="ticket_details_wrapper-details-row_4">
            <div className="ticket_details_wrapper-details-row_4-description">
              <div className="ticket_details_wrapper-details-row_4-description-text details_header_text">
                Description:
              </div>
              <div className="ticket_details_wrapper-details-row_4-description-value details_header_value">
                <textarea
                  disabled
                  className="description-text-area"
                  value={record?.descriptions}
                />
              </div>
            </div>
          </div>
          <div className="ticket_details_wrapper-details-row_5">
            <div className="ticket_details_wrapper-details-row_4-attachments">
              <div className="ticket_details_wrapper-details-row_4-attachments-text details_header_text">
                Attachments:
              </div>
            </div>
            <div className="ticket_details_wrapper-details-row_4-attachments-value">
              {record?.ticket_resources?.map((_tr: any) => {
                console.log("User _tr :::", _tr);
                return !_tr.comment_id &&
                  (_tr.name.includes(".jpg") ||
                    _tr.name.includes(".png") ||
                    _tr.name.includes(".jpeg")) ? (
                  <span className="ticket_attachment">
                    <ImageField
                      style={{ objectFit: "contain" }}
                      value={_tr?.link}
                      title={_tr.name}
                      width={100}
                      height={100}
                    />
                  </span>
                ) : (
                  <div className="ticket_attachment">
                    <TagField
                      style={{
                        padding: "0.3rem",
                        cursor: "pointer",
                        fontSize: "1rem",
                      }}
                      value={_tr?.name}
                      onClick={() => downloadPDF(_tr?.link, _tr?.name)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {/* <Show
        isLoading={isLoading}
        title={<Typography>Ticket #{record?.uid}</Typography>}
        breadcrumb={null}
      > */}
      <div className="ticket_comments_wrapper">
        <List
          breadcrumb=""
          title="Comments"
          headerProps={{
            extra: [
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => addCommentShow()}
              >
                Add comment
              </Button>,
              <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
            ],
          }}
        >
          <Table
            {...tableProps}
            rowKey="id"
            size="small"
            columns={commentsColumns}
            showHeader={false}
          />
        </List>
      </div>
      <Modal {...addCommentModalProps} title="Create Customer Ticket">
        <Form {...formProps} size="large" layout="vertical">
          <Row gutter={[20, 0]}>
            <Col lg={24} md={24} sm={24}>
              <Form.Item
                label="Comment"
                name="comment"
                rules={[{ required: true }]}
              >
                <TextArea rows={10} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={8} lg={8}>
              <Form.Item name="ticket_id" initialValue={routeParams?.id} hidden>
                <Input />
              </Form.Item>
              <Form.Item name="created_by" initialValue={user?.id} hidden>
                <Input />
              </Form.Item>
              <Form.Item name="created_at" initialValue={dayjs()} hidden>
                <Input type="hidden" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <EditTicketStatus
        formProps={editStatusFormProps}
        modalProps={editStatusModalProps}
      />
      {/* </Show> */}
    </>
  );
};
