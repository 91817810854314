import { defaultHeaders, postData, getData } from "utility/utils";

// Referred User

export const getRefereesOverview = async ({ queryKey }: any) => {
  if (queryKey.length === 1) queryKey.push({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_key, queryParams] = queryKey;
  const response = await getData(
    `${process.env.REACT_APP_BACKEND_URL}/v1/api/partner/overview`,
    queryParams,
    defaultHeaders()
  );
  return response;
};

export const getMonthlyEarnings = async ({ queryKey }: any) => {
  if (queryKey.length === 1) queryKey.push({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_key, queryParams] = queryKey;
  const response = await postData(
    `${process.env.REACT_APP_BACKEND_URL}/v1/api/partner/monthly_earnings`,
    queryParams,
    defaultHeaders()
  );
  return response;
};

export const getReferees = async (queryKey: any) => {
  if (queryKey.length === 1) queryKey.push({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_key, queryParams] = queryKey;
  const response = await postData(
    `${process.env.REACT_APP_BACKEND_URL}/v1/api/partner/referees`,
    queryParams,
    defaultHeaders()
  );
  return response;
};

export const getReferralCode = async ({ queryKey }: any) => {
  if (queryKey.length === 1) queryKey.push({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_key, queryParams] = queryKey;
  const response = await getData(
    `${process.env.REACT_APP_BACKEND_URL}/v1/api/user/referral_code`,
    queryParams,
    defaultHeaders()
  );
  return response;
};

// Past Transaction

export const getTransactionFilter = async ({ queryKey }: any) => {
  if (queryKey.length === 1) queryKey.push({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_key, queryParams] = queryKey;
  const response = await getData(
    `${process.env.REACT_APP_BACKEND_URL}/v1/api/partner/transaction_filter`,
    queryParams,
    defaultHeaders()
  );
  return response;
};

export const getRefereesTransaction = async (queryKey: any) => {
  if (queryKey.length === 1) queryKey.push({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_key, queryParams] = queryKey;
  const response = await postData(
    `${process.env.REACT_APP_BACKEND_URL}/v1/api/partner/referee_transactions`,
    queryParams,
    defaultHeaders()
  );
  return response;
};

// Settlement History

export const getSettlementPaidHistory = async (queryKey: any) => {
  if (queryKey.length === 1) queryKey.push({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_key, queryParams] = queryKey;
  const response = await postData(
    `${process.env.REACT_APP_BACKEND_URL}/v1/api/partner/settlement/paid_history`,
    queryParams,
    defaultHeaders()
  );
  return response;
};

export const getSettlementTotalPaid = async ({ queryKey }: any) => {
  if (queryKey.length === 1) queryKey.push({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_key, queryParams] = queryKey;
  const response = await postData(
    `${process.env.REACT_APP_BACKEND_URL}/v1/api/partner/settlement/total_paid_amount`,
    queryParams,
    defaultHeaders()
  );
  return response;
};

export const settlementPay = async (queryKey: any) => {
  if (queryKey.length === 1) queryKey.push({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_key, queryParams] = queryKey;
  const response = await postData(
    `${process.env.REACT_APP_BACKEND_URL}/v1/api/partner/settlement/pay`,
    queryParams,
    defaultHeaders()
  );
  return response;
};
