import { useState } from "react";
import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  Select,
  Row,
  Col,
  InputNumber,
  Upload,
  Button,
  Icons,
  UploadProps,
  notification,
  Image,
  message,
  useSelect,
} from "@pankod/refine-antd";
import { useGetIdentity, useUpdate } from "@pankod/refine-core";

// Utility
import { nhost } from "utility/nhost";

// Interface
import { ISentimentIndicatorHeader } from "interfaces";

// Webhook
import { diffusionIndicatorAPI } from "utility/webhook";

// Icons
const { UploadOutlined } = Icons;

type Props = {
  modalProps: ModalProps;
  formProps: FormProps;
  closeModal: any;
  refetchTable: any;
};

export const EditDiffusionIndicatorHeader: React.FC<Props> = ({
  modalProps,
  formProps,
  closeModal,
  refetchTable,
}) => {
  const { data: user } = useGetIdentity();

  // State
  const [fileList, setFileList] = useState<any>([]);
  const [fileList2, setFileList2] = useState<any>([]);

  // Form Watch
  const formWatch = {
    icon_link: Form.useWatch("lightmode_icon_link", formProps.form),
  };

  const formDarkWatch = {
    dark_icon_link: Form.useWatch("darkmode_icon_link", formProps.form),
  };

  const { selectProps: selectInfoVideo } = useSelect({
    resource: "indiacharts_video_resources",
    optionLabel: "title",
    optionValue: "id",
    metaData: {
      fields: ["id", "title"],
    },
    pagination: {
      pageSize: 9999,
    },
    onSearch: (value) => [
      {
        field: "title",
        operator: "contains",
        value: `%${value}%`,
      },
    ],
  });

  // Icon Upload
  const handleImageUpload = async (file: File) => {
    let file_response = await nhost.storage.upload({
      file,
      bucketId: "public",
    });
    let icon_url = await nhost.storage.getPublicUrl({
      fileId: file_response.fileMetadata?.id || "",
    });
    formProps?.form?.setFieldsValue({
      lightmode_icon_link: icon_url,
      lightmode_file_id: file_response.fileMetadata?.id,
    });
  };

  const handleDarkImageUpload = async (file: File) => {
    let file_response = await nhost.storage.upload({
      file,
      bucketId: "public",
    });
    let icon_url = await nhost.storage.getPublicUrl({
      fileId: file_response.fileMetadata?.id || "",
    });
    formProps?.form?.setFieldsValue({
      darkmode_icon_link: icon_url,
      darkmode_file_id: file_response.fileMetadata?.id,
    });
  };

  const thumbProps: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const isValidFileFormat =
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/svg+xml";
      if (!isValidFileFormat) {
        message.error(`only .png, .jpeg & .svg files are allowed to upload`);
        return isValidFileFormat;
      }
      setFileList([...fileList, file]);
      handleImageUpload(file);
      return false;
    },
    fileList,
  };

  const darkThumbProps: UploadProps = {
    onRemove: (file) => {
      const index = fileList2.indexOf(file);
      const newFileList = fileList2.slice();
      newFileList.splice(index, 1);
      setFileList2(newFileList);
    },
    beforeUpload: (file) => {
      const isValidFileFormat =
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/svg+xml";
      if (!isValidFileFormat) {
        message.error(`only .png, .jpeg & .svg files are allowed to upload`);
        return isValidFileFormat;
      }
      setFileList2([...fileList2, file]);
      handleDarkImageUpload(file);
      return false;
    },
    fileList: fileList2,
  };

  // Edit
  const { mutateAsync: editSentimentIndicator } =
    useUpdate<ISentimentIndicatorHeader>();

  // Form Submit
  const onFinish = async (formData: ISentimentIndicatorHeader) => {
    await editSentimentIndicator({
      resource: "indiacharts_sentiment_indicator_headers",
      id: formProps?.initialValues?.id,
      values: {
        label: formData?.label,
        sortno: formData?.sortno,
        active: formData?.active,
        category: formData?.category,
        lightmode_icon_link: formData?.lightmode_icon_link,
        lightmode_file_id: formData?.lightmode_file_id,
        darkmode_icon_link: formData?.darkmode_icon_link,
        darkmode_file_id: formData?.darkmode_file_id,
        updated_by: user?.id,
        info_video_id:
          formData?.info_video_id === undefined
            ? null
            : formData?.info_video_id,
      },
    })
      .then(async () => {
        // Check if file_id is not null
        if (formProps?.initialValues?.lightmode_file_id !== null) {
          // Delete Previously Uploaded Icon From NHost
          await nhost.storage
            .delete({
              fileId: formProps?.initialValues?.lightmode_icon_link,
            })
            .then(() => {})
            .catch(() => {
              console.log(
                "Error while deleting the previously uploaded icon..."
              );
            });
        }
        refetchTable.refetch();
        diffusionIndicatorAPI();
        closeModal();
      })
      .then(async () => {
        // Check if file_id is not null
        if (formProps?.initialValues?.darkmode_file_id !== null) {
          // Delete Previously Uploaded Icon From NHost
          await nhost.storage
            .delete({
              fileId: formProps?.initialValues?.darkmode_icon_link,
            })
            .then(() => {})
            .catch(() => {
              console.log(
                "Error while deleting the previously uploaded icon..."
              );
            });
        }
        refetchTable.refetch();
        closeModal();
      });
  };

  // Form Failed
  const onFinishFailed = (err: any) => {
    notification.open({
      message: "Error while editing sentiment indicator",
      type: "error",
    });
    console.log("ERR : ", err);
  };

  return (
    <Modal {...modalProps} title="Edit Diffusion Indicator Header">
      <Form
        {...formProps}
        size="large"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Label"
              name="label"
              rules={[
                { required: true },
                {
                  // pattern: new RegExp("^\\S*$"), // No Space Allowed
                  pattern: new RegExp(/^[^\s]/), // Check For Space At Beginning Only
                  message: "No spaces allowed",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={8} lg={8}>
            <Form.Item
              label="Sorting No"
              name="sortno"
              rules={[{ required: true }]}
            >
              <InputNumber style={{ width: "100%" }} type="number" />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Is Active"
              name="active"
              rules={[{ required: true }]}
            >
              <Select
                options={[
                  {
                    value: true,
                    label: "True",
                  },
                  {
                    value: false,
                    label: "False",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item label="Upload Light Mode Icon">
              <Upload
                {...thumbProps}
                accept="image/png, image/jpeg, image/svg+xml"
              >
                <Button icon={<UploadOutlined />}>Click To Upload Icon</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item name="lightmode_icon_link" label="Light mode Icon Link">
              <Input disabled />
            </Form.Item>
          </Col>
          {/* <Col xs={8} lg={8}>
            <Form.Item name="lightmode_file_id" label="Light Mode File ID">
              <Input disabled />
            </Form.Item>
          </Col> */}
          <Col xs={8} lg={8}>
            <Form.Item label="Light Mode Icon Preview">
              {formWatch.icon_link !== null ? (
                <Image
                  width={120}
                  height={120}
                  src={formWatch.icon_link}
                  alt={"Icon"}
                />
              ) : (
                <p>No Image Available For Preview</p>
              )}
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item label="Upload Dark Mode Icon">
              <Upload
                {...darkThumbProps}
                accept="image/png, image/jpeg, image/svg+xml"
              >
                <Button icon={<UploadOutlined />}>Click To Upload Icon</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item name="darkmode_icon_link" label="Dark Mode Icon Link">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item label="Dark Mode Icon Preview">
              {formDarkWatch.dark_icon_link !== null ? (
                <Image
                  width={120}
                  height={120}
                  src={formDarkWatch.dark_icon_link}
                  alt={"Icon"}
                />
              ) : (
                <p>No Image Available For Preview</p>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item name="info_video_id" label="Info video">
              <Select allowClear {...selectInfoVideo} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          {/* <Col xs={8} lg={8}>
            <Form.Item name="darkmode_file_id" label="Dark Mode File ID">
              <Input disabled />
            </Form.Item>
          </Col> */}
        </Row>
      </Form>
    </Modal>
  );
};
