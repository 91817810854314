import dayjs from "dayjs";

const getPartnerProgramEarningsChartOptions = ({ data }: any) => {
  return {
    chart: {
      type: "column",
      animation: false,
      //   width: windowDimensions.width - 50,
      //   height: windowDimensions.height - 280,
      marginTop: 20,
    },
    tooltip: {
      formatter: function (this: any) {
        let formattedNumber = new Intl.NumberFormat("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(this.y);

        return (
          dayjs(this.x).format("MMMM") +
          " " +
          this.series.name +
          `<br/><span style="color:${this.series.color}" >\u25CF </span>` +
          "<b>" +
          "₹" +
          formattedNumber +
          `</b>`
        );
      },
    },
    title: {
      text: "",
      align: "left",
      floating: true,
    },
    subtitle: {
      text: "",
      align: "left",
      floating: true,
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      crosshair: false,
      labels: {
        overflow: "justify",
        formatter: function (this: any) {
          return dayjs(this.value).format("MMM");
        },
      },
    },
    yAxis: {
      title: {
        text: "",
        align: "middle",
      },
      labels: {
        overflow: "justify",
      },
    },
    plotOptions: {
      series: {
        // pointPlacement: "on",
        states: {
          hover: {
            enabled: false,
          },
        },
      },
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [...data],
  };
};
export default getPartnerProgramEarningsChartOptions;
