import {
  CreateButton,
  DateField,
  EditButton,
  getDefaultSortOrder,
  Icons,
  List,
  // Menu,
  message,
  notification,
  RefreshButton,
  Space,
  Table,
  Tag,
  useModalForm,
  useTable,
  useSelect,
  Select,
  Button,
  DeleteButton,
  Form,
  Col,
  Input,
  useForm,
} from "@pankod/refine-antd";
import CustomTag from "components/CustomTag";
import React, { useEffect, useState } from "react";
import SubHeadersCreate from "./create";
import SubHeadersEdit from "./edit";
import { useList } from "@pankod/refine-core";

import type { MenuProps } from "antd";
import { Menu } from "antd";

// Utility
import { nhost } from "utility/nhost";
import debounce from "lodash/debounce";

const { PlusOutlined } = Icons;

const SubHeadersList: React.FC = () => {
  // States
  const [selectedPod, setSelectedPod] = useState<any>(null);
  const [selectedPage, setSelectedPage] = useState<any>("");
  const [current, setCurrent] = useState("all");
  const { form: tableForm, formProps: subHeadersTableForm } = useForm();

  const { tableProps, tableQueryResult, sorter, setFilters, filters } =
    useTable({
      resource: "indiacharts_sub_headers",
      initialSorter: [
        {
          field: "created_at",
          order: "asc",
        },
      ],
      initialPageSize: 10,
      metaData: {
        fields: [
          "id",
          "active",
          "name",
          "updated_at",
          "pod_id",
          "page_id",
          "info_video_id",
          {
            page: ["id", "name"],
            pod: ["id", "name"],
          },
        ],
      },
      ...(!!selectedPod && {
        permanentFilter: [
          {
            field: "pod_id",
            operator: "eq",
            value: selectedPod || undefined,
          },
        ],
      }),
    });
  const {
    form: createForm,
    formProps: createFormProps,
    modalProps: createModalProps,
    show: createShow,
    close: closeCreateModal,
  } = useModalForm({
    resource: "indiacharts_sub_headers",
    action: "create",
    metaData: {
      fields: [
        "id",
        "active",
        "name",
        "page_id",
        "pod_id",
        "is_private",
        "slug",
        "lightmode_icon_link",
        "lightmode_file_id",
        "darkmode_icon_link",
        "darkmode_file_id",
      ],
    },
    redirect: false,
    successNotification: false,
    onMutationSuccess: () => {
      // message.success("Sub Headers Added !");
      notification.open({
        type: "success",
        message: "Sub Headers Added !",
      });
    },
  });
  const {
    form: editForm,
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
    close: closeEditModal,
  } = useModalForm({
    resource: "indiacharts_sub_headers",
    action: "edit",
    metaData: {
      fields: [
        "id",
        "active",
        "name",
        "page_id",
        "pod_id",
        "is_private",
        "slug",
        "lightmode_icon_link",
        "darkmode_icon_link",
        "lightmode_file_id",
        "darkmode_file_id",
        "info_video_id",
      ],
    },
    redirect: false,
    successNotification: false,
    onMutationSuccess: () => {
      // message.success("Sub Headers Added !");
      notification.open({
        type: "success",
        message: "Sub Header Updated Successfully !",
      });
    },
  });

  const pageListQuery = useList({
    resource: "indiacharts_pages",
    metaData: {
      fields: ["id", "name"],
    },
    config: {
      pagination: {
        pageSize: 999,
      },
    },
  });

  // console.log("Page lists :::", pageListQuery);

  const { selectProps: selectPodProps, queryResult } = useSelect({
    resource: "indiacharts_pods",
    metaData: {
      fields: ["id", "name"],
    },
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value: `%${value}%`,
      },
    ],
  });

  const onClick: MenuProps["onClick"] = (e) => {
    // console.log("click ", e.key);
    setCurrent(e.key);
  };

  const handleSearch = debounce((e: any) => {
    const searchValue = e;
    setFilters([
      {
        field: "name",
        operator: "contains",
        value: `%${searchValue}%`,
      },
    ]);
  }, 500);

  return (
    <List
      pageHeaderProps={{
        extra: [
          <span>Filter:</span>,
          <Select
            {...selectPodProps}
            showSearch
            placeholder="Select pod"
            style={{ width: "200px" }}
            onSelect={(e: any) => {
              setSelectedPod(e);
            }}
            value={selectedPod}
          />,
          <RefreshButton
            onClick={() => {
              tableQueryResult?.refetch();
              setSelectedPod(null);
            }}
          />,
          <CreateButton
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => createShow()}
          />,
        ],
      }}
    >
      <Form {...subHeadersTableForm}>
        <Col span={6}>
          <Form.Item name="name">
            <Input
              onChange={(e: any) => handleSearch(e?.target?.value)}
              placeholder="Search by name"
              prefix={<Icons.SearchOutlined />}
            />
          </Form.Item>
        </Col>
      </Form>
      <Menu
        onClick={(e: any) => {
          setSelectedPage(e.key);
          onClick(e);
          // console.log("E from menu :::", e);
          // console.log("Current key from menu :::", e.key);
        }}
        selectedKeys={[current]}
        mode="horizontal"
        onSelect={(props) => {
          if (props.key === "all") {
            setFilters([], "replace");
            tableQueryResult?.refetch();
          } else {
            setFilters([
              {
                field: "page_id",
                operator: "eq",
                value: props.key,
              },
            ]);
          }
        }}
      >
        <Menu.Item key="all">All</Menu.Item>
        {pageListQuery?.data?.data?.map((_f) => (
          <Menu.Item key={_f.id}>{_f.name}</Menu.Item>
        ))}
      </Menu>
      <Table {...tableProps} rowKey="id" size="small" scroll={{ x: 1000 }}>
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column
          dataIndex="active"
          title="Is Active"
          render={(value: any) => (
            <CustomTag type={value} text={value ? "Active" : "In Active"} />
          )}
        />
        <Table.Column
          dataIndex="page"
          title="Page"
          render={(record: any) => {
            return <Tag color="default">{record?.name}</Tag>;
          }}
        />
        <Table.Column
          dataIndex="pod"
          title="Pod"
          render={(record: any) => {
            return <Tag color="cyan">{record?.name}</Tag>;
          }}
        />
        <Table.Column
          dataIndex="updated_at"
          title="Updated Date"
          render={(value) => <DateField value={value} format="lll" />}
          defaultSortOrder={getDefaultSortOrder("updated_at", sorter)}
          sorter
        />
        <Table.Column
          fixed="right"
          width={100}
          title="Actions"
          render={(record) => (
            <Space>
              <EditButton
                hideText
                size="small"
                type="link"
                recordItemId={record?.id}
                onClick={() => editShow(record?.id)}
              />
              {/* <DeleteButton
                hideText
                size="small"
                type="link"
                recordItemId={record?.id}
                resourceNameOrRouteName="indiacharts_sub_headers"
                onClick={async () => {
                  // Delete Icon From NHost
                  let lightmode_file_id = record?.lightmode_file_id;
                  let darkmode_file_id = record?.darkmode_file_id;
                  if (
                    lightmode_file_id !== "" ||
                    lightmode_file_id !== null ||
                    lightmode_file_id !== undefined
                  ) {
                    await nhost.storage.delete({
                      fileId: lightmode_file_id,
                    });
                  }
                  if (
                    darkmode_file_id !== "" ||
                    darkmode_file_id !== null ||
                    darkmode_file_id !== undefined
                  ) {
                    await nhost.storage.delete({
                      fileId: darkmode_file_id,
                    });
                  }
                }}
              /> */}
            </Space>
          )}
        />
      </Table>
      <SubHeadersCreate
        formProps={createFormProps}
        modalProps={createModalProps}
        form={createForm}
        closeModal={closeCreateModal}
        refetchTable={tableQueryResult}
      />
      <SubHeadersEdit
        form={editForm}
        formProps={editFormProps}
        modalProps={editModalProps}
        closeModal={closeEditModal}
        refetchTable={tableQueryResult}
      />
    </List>
  );
};

export default SubHeadersList;
