import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const initialState = {
  subscribersList: [],
  plansList: [],
  filterBy: {
    userName: null,
    plan: null,
    status: null,
    dateRange: "past-month",
    startDate: dayjs().subtract(1, "month"),
    endDate: dayjs(),
  },
  stats: {
    totalCommission: 0,
    totalReferees: 0,
    monthlyAverageCommission: 0,
  },
  email: null,
  settlement: {
    totalAmount: 0,
  },
};

export const PartnerProgramSLice = createSlice({
  name: "PartnerProgram",
  initialState,
  reducers: {
    setSubscribersList: (state, action) => {
      state.subscribersList = action.payload;
    },
    setPlansList: (state, action) => {
      state.plansList = action.payload;
    },
    setFilterBy: (state, action) => {
      state.filterBy = {
        ...state.filterBy,
        ...action.payload,
      };
    },
    setStats: (state, action) => {
      state.stats = {
        ...state.stats,
        ...action.payload,
      };
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setSettlement: (state, action) => {
      state.settlement = {
        ...state.settlement,
        ...action.payload,
      };
    },
  },
});

export const {
  setSubscribersList,
  setPlansList,
  setFilterBy,
  setStats,
  setEmail,
  setSettlement,
} = PartnerProgramSLice.actions;

export default PartnerProgramSLice.reducer;
