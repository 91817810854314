import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  Row,
  Col,
  DatePicker,
  useSelect,
  Select,
  InputNumber,
  notification,
} from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";
import dayjs from "dayjs";
import { IIndicatorData } from "interfaces";
import moment from "moment";
import { updateIndicatorDataByPK } from "graphql/mutation";
import { useEffect } from "react";

// Webhook
import {indicatorDataAPI} from 'utility/webhook'

// type EditIndicatorDataProps =
//   dataObject: IIndicatorData;
//   setDataObject: React.Dispatch<React.SetStateAction<IIndicatorData | any>>;
//   modalProps: ModalProps;
//   formProps: FormProps;
// };
type props = {
  dataObject: IIndicatorData;
  setDataObject: React.Dispatch<React.SetStateAction<IIndicatorData | any>>;
  tableQueryResult: any;
};
const { TextArea } = Input;

export const EditIndicatorData: React.FC<props> = ({
  dataObject,
  setDataObject,
  tableQueryResult,
}) => {
  const { data: user } = useGetIdentity();
  const [form] = Form.useForm();
  useEffect(() => {
    form?.setFieldsValue({
      value: dataObject?.value,
      indicator_id: dataObject?.indicator_id,
      date: moment(dataObject?.date),
    });
  });

  const { selectProps: indicatorSelectProps } = useSelect<IIndicatorData>({
    resource: "indiacharts_indicators",
    optionLabel: "name",
    optionValue: "id",
    pagination: { current: 1, pageSize: 25 },
    onSearch: (value) => [
      {
        field: "name",
        operator: "startswith",
        value,
      },
    ],
    metaData: {
      fields: ["id", "name"],
    },
  });

 

  // Form Submit
  const onFinish = async (formData: any) => {
    await updateIndicatorDataByPK(
      {
        value: formData?.value,
        indicator_id: formData?.indicator_id,
        date: moment(formData?.date).format("YYYY-MM-DD"),
      },
      {
        date: dataObject?.date,
        indicator_id: dataObject?.indicator_id,
      }
    )
      .then((res: any) => {
        if (
          res?.error &&
          res?.error[0]?.extensions?.code === "constraint-violation"
        ) {
          notification.open({
            message: "Error",
            type: "error",
            description: "Primary key duplication error",
          });
        }
        notification.open({
          message: "Success",
          type: "success",
          description: "Successfully Updated Indicator Data",
        });
        tableQueryResult.refetch();
        indicatorDataAPI({
          indicator_id: formData?.indicator_id
        })
        setDataObject({});
      })
      .catch((err: any) => {
        notification.open({
          message: "Error",
          type: "error",
          description: "Error while updating indicator data.",
        });
        console.log("ERR ::: ", err);
      });
  };

  return (
    <Modal
      title="Edit Indicator Data"
      width={1000}
      visible={Object.keys(dataObject).length > 0 ? true : false}
      onCancel={() => {
        setDataObject({});
      }}
      onOk={() =>
        onFinish({
          date: form?.getFieldValue("date"),
          value: form?.getFieldValue("value"),
          indicator_id: form?.getFieldValue("indicator_id"),
        })
      }
    >
      <Form
        form={form}
        size="large"
        layout="vertical"
        // initialValues={{
        //   date: moment(dataObject?.date),
        //   indicator_id: dataObject?.indicator_id,
        //   value: dataObject?.value
        // }}
      >
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Indicator"
              name="indicator_id"
              rules={[{ required: true }]}
            >
              <Select {...indicatorSelectProps} />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Date"
              name="date"
              rules={[{ required: true }]}
              getValueProps={(value) => ({
                value: value ? dayjs(value) : "",
              })}
            >
              <DatePicker />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item label="Value" name="value" rules={[{ required: true }]}>
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
        {/* <Form.Item name="created_by" initialValue={user?.id}>
          <Input type="hidden" />
        </Form.Item> */}
      </Form>
    </Modal>
  );
};
