import { useDeferredValue, useEffect, useState } from "react";
import { IResourceComponentsProps, useNavigation } from "@pankod/refine-core";

import {
  List,
  Table,
  Space,
  DateField,
  Row,
  Col,
  Input,
  Button,
  Icons,
  RefreshButton,
  notification,
  getDefaultSortOrder,
  ShowButton,
  Select,
} from "@pankod/refine-antd";

import { IUsers } from "interfaces";
import { CreateUsers } from "./create";
import { EditUsers } from "./edit";
import { useQuery } from "@tanstack/react-query";
import { getUsers } from "graphql/queries";

import { Switch } from "antd";
import { mutateUserDisabled } from "graphql/mutation";

import debounce from "lodash/debounce";

const { PlusOutlined, EditOutlined } = Icons;

export const UsersList: React.FC<IResourceComponentsProps> = () => {
  const { push } = useNavigation();

  // const {show}= useNavigation;
  const [pageSize, setPageSize] = useState(50);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [offset, setOffset] = useState(0);
  const defSearchText = useDeferredValue(searchText);
  const [lastPage, setLastPage] = useState("1");
  const [dataObject, setDataObject] = useState<any>({});
  const [listType, setListType] = useState<any>("all");

  const { data, isLoading, isFetching, refetch } = useQuery<any>({
    queryKey: ["get-users", defSearchText, offset],
    queryFn: () =>
      getUsers({
        ...(listType === "all"
          ? {
              where: {
                _or: [
                  { displayName: { _ilike: `%${defSearchText}%` } },
                  { email: { _ilike: `%${defSearchText}%` } },
                  { phoneNumber: { _ilike: `%${defSearchText}%` } },
                ],
              },
            }
          : {
              where: {
                users_referrals_aggregate: {
                  count: {
                    predicate: {
                      _gt: 0,
                    },
                  },
                },
              },
            }),
        offset,
        limit: pageSize,
        order_by: { createdAt: `desc` },
      }),
  });

  // Refetch on change of pageSize
  useEffect(() => {
    refetch();
  }, [pageSize, listType]);

  const debouncedSearch = debounce((e: any) => {
    setSearchText(e);
    setOffset(0);
  }, 500);

  return (
    <List
      headerProps={{
        extra: [
          <span>Filter: </span>,
          <Select
            style={{ width: "200px" }}
            onChange={(e: any) => {
              setListType(e);
            }}
            placeholder="Filter with user type"
            value={listType}
          >
            <option key="all" value="all">
              All Users
            </option>
            <option key="partner_user" value="partner_user">
              Partners
            </option>
          </Select>,
          <RefreshButton onClick={() => refetch()} />,
          // <CreateButton type="primary" icon={<PlusOutlined />} onClick={() => showModal()} />,
          <Button type="primary" onClick={() => setShowModal(true)}>
            <PlusOutlined />
            Create
          </Button>,
        ],
      }}
    >
      <Row gutter={[16, 16]}>
        <Col lg={6} xs={24}>
          <Input
            // style={{ backgroundColor: "red" }}
            className="table-search-input"
            placeholder="Search by name, email, phone"
            prefix={<Icons.SearchOutlined />}
            onChange={(e) => {
              debouncedSearch(e?.target?.value);
            }}
          />
        </Col>
        <Col lg={24} xs={24}>
          <Table
            scroll={{ x: 1000 }}
            loading={isFetching || isLoading}
            rowKey="id"
            dataSource={data?.users || []}
            pagination={{
              total: data?.usersAggregate?.aggregate?.count,
              pageSize,
              onChange: (page, pageSize) => {
                // console.log("Page :::", page);
                // console.log("Page size :::", pageSize);
                setOffset((page - 1) * pageSize);
              },
              current: offset / pageSize + 1,
              onShowSizeChange: (current, size) => {
                // console.log("Page size :::", size);
                setPageSize(size);
                refetch();
              },
            }}
          >
            <Table.Column
              dataIndex="displayName"
              title="Name"
              render={(value: any, record: any) => (
                // <Button
                //   type="link"
                //   onClick={() =>
                //     push(`/partner-program?partner_id=${record?.id}`)
                //   }
                // >
                //   {record?.displayName}
                // </Button>

                <ShowButton
                  size="small"
                  type="link"
                  icon={null}
                  recordItemId={record?.id}
                >
                  {record?.displayName}
                </ShowButton>
              )}
            />
            <Table.Column dataIndex="email" title="Email" />
            <Table.Column dataIndex="phoneNumber" title="Phone No." />
            <Table.Column
              dataIndex="createdAt"
              title="Joining date"
              render={(value) => <DateField value={value} format="lll" />}
              // defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
              // sorter
            />
            <Table.Column dataIndex="defaultRole" title="Role" />
            {/* <Table.Column dataIndex="id" title="ID" /> */}
            {/* <Table.Column
                dataIndex="createdAt"
                title="created_at"
                render={(value) => <DateField format="LLL" value={value} />}
              /> */}
            {/* <Table.Column
              dataIndex="updatedAt"
              title="updated_at"
              render={(value) => <DateField format="LLL" value={value} />}
            /> */}
            <Table.Column
              dataIndex="disabled"
              title="Disabled"
              render={(value, record: any) => (
                <Switch
                  checked={value}
                  onClick={() => {
                    console.log("Current record :::", record?.id);
                    console.log("Current value :::", value);
                    mutateUserDisabled(record?.id, !value).then((res) => {
                      notification.open({
                        message: "Updated user",
                        type: "success",
                      });
                      refetch();
                    });
                  }}
                />
              )}
              // render={(value:any, record:any)=>{
              //   <Switch/>
              // }}
            />
            <Table.Column<IUsers>
              title="Actions"
              width={100}
              fixed="right"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <Button
                    // type="link"
                    // icon={<EditOutlined />}
                    onClick={() => {
                      // console.log("Record :::", record);
                      setDataObject(record);
                      setShowEditModal(true);
                    }}
                  >
                    Edit
                  </Button>
                  {/* <DeleteButton
                    hideText
                    size="small"
                    type="link"
                    recordItemId={record.id}
                    resourceNameOrRouteName="users"
                  /> */}
                </Space>
              )}
            />
          </Table>

          {showModal && (
            <CreateUsers show={showModal} setShowModal={setShowModal} />
          )}

          {showEditModal && (
            <EditUsers
              show={showEditModal}
              setShowEditModal={setShowEditModal}
              dataObject={dataObject}
              setDataObject={setDataObject}
              refetchTable={refetch}
            />
          )}
        </Col>
      </Row>
    </List>
  );
};
