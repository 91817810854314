import { Form, Input, Row, Col, notification } from "@pankod/refine-antd";

import { Modal } from "antd";
import { useEffect } from "react";
import {
  updateUserByPK,
  mutatePartnerConfig,
  getPartnerConfigByEmail,
} from "graphql/mutation";

type EditUsersProps = {
  show: boolean;
  setShowEditModal: any;
  dataObject: any;
  setDataObject: any;
  refetchTable: any;
};

export const EditUsers: React.FC<EditUsersProps> = ({
  show,
  setShowEditModal,
  dataObject,
  setDataObject,
  refetchTable,
}) => {
  const [form] = Form.useForm();

  const formWatch = {
    commission_percentage: Form.useWatch("commission_percentage", form),
    discount_percentage: Form.useWatch("discount_percentage", form),
    free_trial_in_days: Form.useWatch("free_trial_in_days", form),
    email: Form.useWatch("email", form),
  };

  useEffect(() => {
    getPartnerConfigByEmail(dataObject?.email).then((res: any) => {
      form?.setFieldsValue({
        commission_percentage:
          res?.data?.auth_partner_config[0]?.commission_percentage || 0,
        discount_percentage:
          res?.data?.auth_partner_config[0]?.discount_percentage || 0,
        free_trial_in_days:
          res?.data?.auth_partner_config[0]?.free_trial_in_days || 0,
      });
    });

    form?.setFieldsValue({
      displayName: dataObject?.displayName,
      phoneNumber: dataObject?.phoneNumber,
      email: dataObject?.email,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataObject]);

  const onFinish = async (formData: any) => {
    const name = form?.getFieldValue("displayName");
    const email = form?.getFieldValue("email");
    const phone_no = form?.getFieldValue("phoneNumber");

    await updateUserByPK(
      { displayName: name, email: email, phoneNumber: phone_no },
      { id: dataObject?.id }
    )
      .then(async (res: any) => {
        if (res?.error?.length > 0) {
          notification.open({
            message: "Duplicate email/phone number found",
            type: "error",
          });
        } else {
          await mutatePartnerConfig({
            objects: [
              {
                commission_percentage: parseInt(
                  formWatch?.commission_percentage
                ),
                discount_percentage: parseInt(formWatch?.discount_percentage),
                free_trial_in_days: parseInt(formWatch?.free_trial_in_days),
                email: formWatch?.email,
              },
            ],
          });

          refetchTable();
          setShowEditModal(false);
        }
      })
      .catch((err: any) => {
        console.log("User Update Fail ::", err);
      });
  };

  return (
    <Modal
      title="Edit User"
      visible={show}
      onCancel={() => {
        setShowEditModal(false);
        setDataObject({});
      }}
      width={1000}
      onOk={onFinish}
    >
      <Form form={form} size="large" layout="vertical">
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8} md={8}>
            <Form.Item
              label="Name"
              name="displayName"
              rules={[
                // { required: true },
                {
                  pattern: new RegExp(/^[^\s]/), // Check For Space At Beginning Only
                  message: "No spaces allowed",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8} md={8}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                // { required: true },
                {
                  pattern: new RegExp(/^[^\s]/), // Check For Space At Beginning Only
                  message: "No spaces allowed",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8} md={8}>
            <Form.Item
              label="Phone number"
              name="phoneNumber"
              rules={[
                // { required: true },
                {
                  pattern: new RegExp(/^[^\s]/), // Check For Space At Beginning Only
                  message: "No spaces allowed",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8} md={8}>
            <Form.Item
              label="Discount Percentage"
              name="discount_percentage"
              rules={[
                // {
                //   required: true,
                // },
                {
                  pattern: new RegExp(/^\d+$/),
                  message: "Only numbers are allowed",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={8} lg={8} md={8}>
            <Form.Item
              label="Comission Percentage"
              name="commission_percentage"
              rules={[
                // {
                //   required: true,
                // },
                {
                  pattern: new RegExp(/^\d+$/),
                  message: "Only numbers are allowed",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={8} lg={8} md={8}>
            <Form.Item
              label="Free Trial ( In Days )"
              name="free_trial_in_days"
              rules={[
                // {
                //   required: true,
                // },
                {
                  pattern: new RegExp(/^\d+$/),
                  message: "Only numbers are allowed",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
