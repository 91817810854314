import { useEffect } from "react";
import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  Select,
  Row,
  Col,
  InputNumber,
  useSelect,
  notification,
  Radio,
} from "@pankod/refine-antd";
import { useGetIdentity, useUpdate } from "@pankod/refine-core";

// Interface
import { IIndicator, IIndices, ISentimentIndicatorHeader } from "interfaces";

// Webhook
import { sentimentIndicatorAPI, diffusionIndicatorAPI } from "utility/webhook";

type Props = {
  modalProps: ModalProps;
  formProps: FormProps;
  dataObject: any;
  setDataObject: React.Dispatch<React.SetStateAction<IIndicator | any>>;
  refetchTable: any;
};

export const EditIndicator: React.FC<Props> = ({
  modalProps,
  formProps,
  dataObject,
  setDataObject,
  refetchTable,
}) => {
  const { data: user } = useGetIdentity();
  const [form] = Form.useForm();

  useEffect(() => {
    form?.setFieldsValue({
      // id: dataObject?.id,
      name: dataObject?.name,
      headers: dataObject?.headers,
      indexes: dataObject?.indexes,
      category: dataObject?.category,
      plot_type: dataObject?.plot_type,
      isactive: dataObject?.isactive,
      dual_color_enabled: dataObject?.dual_color_enabled,
      sortno: dataObject?.sortno,
      db_field: dataObject?.db_field,
      overbought_zone1_title: dataObject?.overbought_zones?.[0]?.title,
      overbought_zone1_value: dataObject?.overbought_zones?.[0]?.value,
      overbought_zone1_color: dataObject?.overbought_zones?.[0]?.color,
      overbought_zone2_title: dataObject?.overbought_zones?.[1]?.title,
      overbought_zone2_value: dataObject?.overbought_zones?.[1]?.value,
      overbought_zone2_color: dataObject?.overbought_zones?.[1]?.color,
      oversold_zone1_title: dataObject?.oversold_zones?.[0]?.title,
      oversold_zone1_value: dataObject?.oversold_zones?.[0]?.value,
      oversold_zone1_color: dataObject?.oversold_zones?.[0]?.color,
      oversold_zone2_title: dataObject?.oversold_zones?.[1]?.title,
      oversold_zone2_value: dataObject?.oversold_zones?.[1]?.value,
      oversold_zone2_color: dataObject?.oversold_zones?.[1]?.color,
    });
  }, [dataObject]);

  // Over sold and bought colors
  const ObOsColors = [
    {
      key: "red",
      value: "#ff0000",
      label: "Red",
    },
    {
      key: "blue",
      value: "#0000ff",
      label: "Blue",
    },
    {
      key: "green",
      value: "#00ff00",
      label: "Green",
    },
    {
      key: "yellow",
      value: "#ffff00",
      label: "Yellow",
    },
    {
      key: "orange",
      value: "#ff6600",
      label: "Orange",
    },
    {
      key: "cyan",
      value: "#00ffff",
      label: "Cyan",
    },
    {
      key: "violet",
      value: "#9317B5",
      label: "Violet",
    },
    {
      key: "pink",
      value: "#ff0066",
      label: "Pink",
    },
  ];

  // Plot types
  const plotTypes = [
    {
      key: "line",
      value: "line",
      label: "Line",
    },
    {
      key: "histogram",
      value: "histogram",
      label: "Histogram",
    },
    {
      key: "cross",
      value: "cross",
      label: "Cross",
    },
    {
      key: "area",
      value: "area",
      label: "Area",
    },
    {
      key: "columns",
      value: "columns",
      label: "Columns",
    },
    {
      key: "circles",
      value: "circles",
      label: "Circles",
    },
    {
      key: "line_with_breaks",
      value: "line_with_breaks",
      label: "Line with Breaks",
    },
    {
      key: "area_with_breaks",
      value: "area_with_breaks",
      label: "Area with Breaks",
    },
    {
      key: "step_line",
      value: "step_line",
      label: "Step Line",
    },
  ];

  const { selectProps } = useSelect<IIndices>({
    resource: "indiacharts_indices",
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    pagination: { current: 1, pageSize: 250 },
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value: `%${value}%`,
      },
    ],
  });

  const { selectProps: headerSelector } = useSelect<ISentimentIndicatorHeader>({
    resource: "indiacharts_sentiment_indicator_headers",
    optionLabel: "label",
    optionValue: "id",
    fetchSize: 999,
    metaData: {
      fields: ["id", "label"],
    },
    onSearch: (value) => [
      {
        field: "label",
        operator: "contains",
        value: `%${value}%`,
      },
    ],
  });

  const { mutateAsync: editIndicator } = useUpdate<IIndicator>();

  const onFinish = async (dataObject: any) => {
    const name = form?.getFieldValue("name");
    const sortno = form?.getFieldValue("sortno");
    const indexes = form?.getFieldValue("indexes");
    const plot_type = form?.getFieldValue("plot_type");
    const isactive = form?.getFieldValue("isactive");
    const dual_color_enabled = form?.getFieldValue("dual_color_enabled");
    const category = form?.getFieldValue("category");
    const headers = form?.getFieldValue("headers");
    const db_field = form?.getFieldValue("db_field");
    const ob1_title = form?.getFieldValue("overbought_zone1_title");
    const ob1_value = form?.getFieldValue("overbought_zone1_value");
    const ob1_color = form?.getFieldValue("overbought_zone1_color");
    const ob2_title = form?.getFieldValue("overbought_zone2_title");
    const ob2_value = form?.getFieldValue("overbought_zone2_value");
    const ob2_color = form?.getFieldValue("overbought_zone2_color");
    const os1_title = form?.getFieldValue("oversold_zone1_title");
    const os1_value = form?.getFieldValue("oversold_zone1_value");
    const os1_color = form?.getFieldValue("oversold_zone1_color");
    const os2_title = form?.getFieldValue("oversold_zone2_title");
    const os2_value = form?.getFieldValue("oversold_zone2_value");
    const os2_color = form?.getFieldValue("oversold_zone2_color");

    let newOb: any = [];
    let newOs: any = [];

    // Overbought
    if (ob1_value) {
      newOb.push({ title: ob1_title, color: ob1_color, value: ob1_value });
    }
    if (ob2_value) {
      newOb.push({ title: ob2_title, color: ob2_color, value: ob2_value });
    }
    if (ob1_value && ob2_value) {
      newOb = [
        { title: ob1_title, color: ob1_color, value: ob1_value },
        { title: ob2_title, color: ob2_color, value: ob2_value },
      ];
    }

    // Oversold
    if (os1_value) {
      newOs.push({ title: os1_title, color: os1_color, value: os1_value });
    }
    if (os2_value) {
      newOs.push({ title: os2_title, color: os2_color, value: os2_value });
    }
    if (os1_value && os2_value) {
      newOs = [
        { title: os1_title, color: os1_color, value: os1_value },
        { title: os2_title, color: os2_color, value: os2_value },
      ];
    }

    await editIndicator({
      resource: "indiacharts_indicators",
      id: dataObject?.id,
      values: {
        name: name,
        sortno: sortno,
        overbought_zones: newOb,
        oversold_zones: newOs,
        isactive: isactive,
        dual_color_enabled: dual_color_enabled,
        indexes: indexes,
        headers: headers,
        db_field: db_field,
        category: category,
        plot_type: plot_type,
      },
    })
      .then(async () => {
        sentimentIndicatorAPI();
        diffusionIndicatorAPI();
        setDataObject({});
        refetchTable();
      })
      .catch((err: any) => {
        console.log("error");
        notification.open({
          message: "Error",
          type: "error",
          description: "Error while updating indicator",
        });
        console.log("ERR ::: ", err);
      });
  };

  return (
    <Modal
      // {...modalProps}
      title="Edit Indicator"
      width={1000}
      visible={Object.keys(dataObject).length > 0 ? true : false}
      onCancel={() => {
        setDataObject({});
      }}
      onOk={() => {
        onFinish(dataObject);
      }}
    >
      <Form
        // {...formProps}
        form={form}
        size="large"
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                },
                {
                  // pattern: new RegExp("^\\S*$"), // No Space Allowed
                  pattern: new RegExp(/^[^\s]/), // Check For Space At Beginning Only
                  message: "No spaces allowed",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Headers"
              name="headers"
              rules={[{ required: false }]}
            >
              <Select showSearch {...headerSelector} mode="multiple" />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Category"
              name="category"
              rules={[{ required: false }]}
            >
              <Select>
                <option key="diffusion" value="diffusion">
                  diffusion
                </option>
                <option key="sentiment" value="sentiment">
                  sentiment
                </option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Active"
              name="isactive"
              rules={[{ required: false }]}
            >
              <Radio.Group defaultValue={true}>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Dual color enabled"
              name="dual_color_enabled"
              rules={[{ required: false }]}
            >
              <Radio.Group defaultValue={true}>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Plot type"
              name="plot_type"
              rules={[{ required: true }]}
              initialValue={true}
            >
              <Select>
                {plotTypes.map((item: any) => (
                  <option key={item?.key} value={item?.value}>
                    {item?.label}
                  </option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Linked Indexes"
              name="indexes"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select showSearch {...selectProps} mode="multiple" />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item label="Sorting No" name="sortno">
              <InputNumber style={{ width: "100%" }} type="number" />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item name="db_field" label="DB field">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item name="overbought_zone1_title" label="Overbought title 1">
              <Input />
            </Form.Item>
          </Col>

          <Col xs={8} lg={8}>
            <Form.Item name="overbought_zone1_color" label="Overbought color 1">
              <Select>
                {ObOsColors.map((item: any) => (
                  <option key={item?.key} value={item?.value}>
                    {item?.label}
                  </option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item name="overbought_zone1_value" label="Overbought value 1">
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item name="oversold_zone1_title" label="Oversold title 1">
              <Input />
            </Form.Item>
          </Col>

          <Col xs={8} lg={8}>
            <Form.Item name="oversold_zone1_color" label="Oversold color 1">
              <Select>
                {ObOsColors.map((item: any) => (
                  <option key={item?.key} value={item?.value}>
                    {item?.label}
                  </option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item name="oversold_zone1_value" label="Oversold value 1">
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item name="overbought_zone2_title" label="Overbought title 2">
              <Input />
            </Form.Item>
          </Col>

          <Col xs={8} lg={8}>
            <Form.Item name="overbought_zone2_color" label="Overbought color 2">
              <Select>
                {ObOsColors.map((item: any) => (
                  <option key={item?.key} value={item?.value}>
                    {item?.label}
                  </option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item name="overbought_zone2_value" label="Overbought value 2">
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item name="oversold_zone2_title" label="Oversold title 2">
              <Input />
            </Form.Item>
          </Col>

          <Col xs={8} lg={8}>
            <Form.Item name="oversold_zone2_color" label="Oversold color 2">
              <Select>
                {ObOsColors.map((item: any) => (
                  <option key={item?.key} value={item?.value}>
                    {item?.label}
                  </option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item name="oversold_zone2_value" label="Oversold value 2">
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Form.Item name="updated_by" initialValue={user?.id}>
        <Input type="hidden" />
      </Form.Item>
    </Modal>
  );
};
