import {
    Form,
    Input,
    ModalProps,
    FormProps,
    Modal,
    useForm,
    InputNumber,
    useSelect,
    Select,Row,Col,DatePicker, Edit, ListButton, RefreshButton
  } from "@pankod/refine-antd";
  import { useGetIdentity } from "@pankod/refine-core";
  import { IResourceComponentsProps } from "@pankod/refine-core";
  import { IQuery } from "interfaces";
  type EditQueryProps = {
    modalProps: ModalProps;
    formProps: FormProps;
    
  };
  const { TextArea } = Input;

 
  export const EditQuery: React.FC<IResourceComponentsProps> = () => {
    const { data: user } = useGetIdentity();
    const { formProps, saveButtonProps, queryResult } = useForm<IQuery>({
        metaData: {
          fields: ["id", "name", "description", "created_by","query_type", "config"],
        },
    });  

   
    
 
  
    return (
      
      <Edit
            pageHeaderProps={{
                extra: (
                    <>
                        
                        <RefreshButton onClick={() => queryResult?.refetch()} />
                    </>
                ),
            }}
            saveButtonProps={saveButtonProps}
        >
        <Form {...formProps} size="large" layout="vertical">
        <Row gutter={[24, 0]}>
        <Col xs={12} lg={12}>
            <Form.Item
              label="Name "
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

          </Col>
          <Col xs={12} lg={12}>
            <Form.Item
              label="Description "
              name="description"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

          </Col>
           <Col xs={24} lg={24}>
            <Form.Item
              label="Config"
              name="config"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <TextArea     />
            </Form.Item>
              
          </Col>
        </Row>

       

        <Form.Item name="created_by"
          initialValue={user?.id}
        >
          <Input type="hidden" />
        </Form.Item>
        </Form>
      </Edit>
    );
  };
  