import { AuthProvider } from "@pankod/refine-core";

import { nhost } from "utility";

export const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    const roles = ["admin", "super_admin", "manager", "sales"];

    const { error, session } = await nhost.auth.signIn({
      email: username,
      password,
    });

    if (session !== null && !roles.includes(`${session?.user?.defaultRole}`)) {
      await authProvider.logout({});

      return Promise.reject({
        message: "No Access",
        status: 401,
      });
    }

    if (error) {
      return Promise.reject(error);
    }

    if (session !== null && roles.includes(`${session?.user?.defaultRole}`)) {
      localStorage.setItem("user", JSON.stringify(session?.user));

      return Promise.resolve({
        success: true,
        user: session,
      });
    }

    return Promise.resolve();
  },
  logout: async () => {
    const { error } = await nhost.auth.signOut();

    localStorage.removeItem("user");

    if (error) {
      return Promise.reject(error);
    }

    return Promise.resolve("/");
  },
  checkError: (error) => {
    if (error.status === 401) {
      return nhost.auth.refreshSession();
    }
    return Promise.resolve();
  },
  checkAuth: async () => {
    const isAuthenticated = await nhost.auth.isAuthenticatedAsync();
    if (isAuthenticated) {
      const user = nhost.auth.getUser();

      if (user) {
        localStorage.setItem("user", JSON.stringify(user));
      }

      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: () => {
    const user = nhost.auth.getUser();
    if (user) {
      return Promise.resolve(user.roles);
    }

    return Promise.resolve([]);
  },
  getUserIdentity: () => {
    const user = nhost.auth.getUser();
    if (user) {
      return Promise.resolve({
        ...user,
        name: user.displayName,
        avatar: user.avatarUrl,
      });
    }

    return Promise.resolve(null);
  },
};
