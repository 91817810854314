import {
  IResourceComponentsProps,
  useExport,
  useList,
} from "@pankod/refine-core";
import {
  List,
  RefreshButton,
  CreateButton,
  ShowButton,
  Table,
  Menu,
  useTable,
  useSelect,
  Space,
  EditButton,
  DateField,
  getDefaultSortOrder,
  useModalForm,
  Icons,
  Button,
  Typography,
  Select,
  notification,
} from "@pankod/refine-antd";

// GraphQL
import { mutateIndicatorData } from "graphql/mutation";

// Interface
import { IIndicator, IIndicatorData } from "interfaces";

// Component
import { CreateIndicatorData } from "./create";
import { EditIndicatorData } from "./edit";
import { ImportIndicatorData } from "./import";

import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { Popconfirm } from "antd";

// Webhook
import { indicatorDataAPI } from "utility/webhook";

const { PlusOutlined, ImportOutlined, DeleteOutlined } = Icons;
const { Text } = Typography;

export const IndicatorDataList: React.FC<IResourceComponentsProps> = () => {
  // States
  const [current, setCurrent] = useState<any>();
  const [object, setObject] = useState<IIndicatorData | any>({});

  const { selectProps: indicatorsSelectProps } = useSelect<IIndicator>({
    resource: "indiacharts_indicators",
    metaData: {
      fields: ["id", "name"],
    },
    pagination: {
      pageSize: 999,
    },
    optionLabel: "name",
    optionValue: "id",
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value: `%${value}%`,
      },
    ],
  });

  // Table
  const { filters, setFilters, tableProps, sorter, tableQueryResult } =
    useTable<IIndicatorData>({
      resource: "indiacharts_indicator_data",
      initialSorter: [
        {
          field: "updated_at",
          order: "desc",
        },
      ],
      initialPageSize: 20,
      metaData: {
        fields: [
          "value",
          "date",
          "indicator_id",
          "created_by",
          "created_at",
          { indicator: ["id", "name"] },
        ],
      },
      ...(!!current && {
        permanentFilter: [
          {
            field: "indicator_id",
            operator: "eq",
            value: current,
          },
        ],
      }),
    });

  // Create
  const {
    formProps,
    modalProps,
    show: showModal,
    close: closeModal,
  } = useModalForm({
    resource: "indiacharts_indicator_data",
    action: "create",
    redirect: false,
    metaData: {
      fields: ["value", "date", "indicator_id", "created_by", "created_at"],
    },
  });

  // Import
  const {
    formProps: importFormProps,
    modalProps: importModalProps,
    show: showBulkInsertModal,
    close: closeBulkInsertModal,
  } = useModalForm({
    resource: "indiacharts_indicator_data",
    action: "create",
    redirect: false,
    metaData: {
      fields: ["value", "date", "indicator_id", "created_by", "created_at"],
    },
  });

  // Edit
  // const {
  //   formProps: editFormProps,
  //   modalProps: editModalProps,
  //   show: editShow,
  // } = useModalForm({
  //   resource: "indiacharts_indicator_data",
  //   action: "edit",
  //   metaData: {
  //     fields: ["value", "date", "indicator_id", "created_by", "created_at"],
  //   },
  // });

  // Export
  const { triggerExport, isLoading: exportLoading } = useExport<IIndicatorData>(
    {
      mapData: (item) => {
        return {
          date: item.date,
          value: item.value,
          indicator_id: item.indicator_id,
          created_by: item.created_by,
        };
      },
    }
  );

  return (
    <List
      headerProps={{
        extra: [
          <span>Filter:</span>,
          <Select
            {...indicatorsSelectProps}
            showSearch
            placeholder="Filter with indicator"
            style={{ width: "15rem" }}
            value={current}
            onSelect={(e: any) => {
              if (e === "all") {
                setCurrent("");
                tableQueryResult?.refetch();
              } else {
                setCurrent(e);
              }
            }}
            // filterOption={(input: any, option: any) => {
            //   const inputValue = input.toLowerCase();
            //   const optionValue = option.value.toLowerCase();
            //   return optionValue.includes(inputValue);
            // }}
          >
            {/* <option key="all" value="all">
              All
            </option>
            {indicatorsSelectProps?.options?.map((_f: any) => (
              <option key={_f.value} value={_f.value}>
                {_f.label}
              </option>
            ))} */}
          </Select>,
          <RefreshButton
            onClick={() => {
              setCurrent(null);
              tableQueryResult?.refetch();
            }}
          />,
          <CreateButton
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              showModal();
            }}
          />,
          <CreateButton
            icon={<ImportOutlined />}
            children={<Text>Import</Text>}
            onClick={() => {
              showBulkInsertModal();
            }}
          />,
        ],
      }}
    >
      {/* <Menu
        selectedKeys={[current]}
        mode="horizontal"
        onSelect={(props) => {
          if (props.key === "all") {
            setFilters([], "replace");
            tableQueryResult?.refetch();
          } else {
            setFilters([
              {
                field: "indicator_id",
                operator: "eq",
                value: props.key,
              },
            ]);
          }
          // tableQueryResult?.refetch()
        }}
      >
        <Menu.Item key="all">All</Menu.Item>
        {indicatorListQuery?.data?.map((_f: any) => (
          <Menu.Item key={_f.id}>{_f.name}</Menu.Item>
        ))}
      </Menu> */}
      <Table
        {...tableProps}
        // rowKey="date"
        rowKey={(record, index) => record.indicator_id + "_" + record.date}
        size="small"
        scroll={{ x: 1000 }}
      >
        <Table.Column<IIndicatorData>
          dataIndex="value"
          title="Value"
          // render={(_, record) => (
          //   <Space>
          //     <ShowButton
          //       size="small"
          //       type="link"
          //       icon={null}
          //       resource="indiacharts_indicator_data"
          //       recordItemId={record.indicator_id + "_" + record.date}
          //     >
          //       {record.value}
          //     </ShowButton>
          //   </Space>
          // )}
        />
        <Table.Column<IIndicatorData>
          width={300}
          dataIndex="indicator_id"
          title="Indicator"
          render={(_, record) => <Space>{record?.indicator?.name}</Space>}
        />
        <Table.Column
          dataIndex="date"
          title="Date"
          render={(value) => <DateField value={value} format="lll" />}
          defaultSortOrder={getDefaultSortOrder("date", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="created_at"
          title="Created At"
          render={(value) => <DateField value={value} format="lll" />}
          defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="updated_at"
          title="Updated At"
          render={(value) => <DateField value={value} format="lll" />}
          defaultSortOrder={getDefaultSortOrder("updated_at", sorter)}
          sorter
        />
        <Table.Column<IIndicatorData>
          width={100}
          fixed="right"
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton
                hideText
                size="small"
                type="link"
                // recordItemId={record.indicator_id + "_" + record.date}
                onClick={() => {
                  console.log("Edit this record :::", record);
                  setObject(record);
                }}
              />
              <Popconfirm
                placement="top"
                title="Delete Indicator Data?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => {
                  mutateIndicatorData(record?.date, record?.indicator_id).then(
                    () => {
                      notification.open({
                        message: "Deleted Indicator Data Successfully",
                        type: "success",
                      });
                      indicatorDataAPI({
                        indicator_id: record?.indicator_id,
                      });
                      tableQueryResult?.refetch();
                    }
                  );
                }}
              >
                <Button
                  type="link"
                  icon={<DeleteOutlined style={{ color: "#fa541c" }} />}
                />
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
      <CreateIndicatorData
        modalProps={modalProps}
        formProps={formProps}
        refetchTable={tableQueryResult}
        closeModal={closeModal}
      />
      <EditIndicatorData
        dataObject={object}
        setDataObject={setObject}
        tableQueryResult={tableQueryResult}
      />
      <ImportIndicatorData
        modalProps={importModalProps}
        formProps={importFormProps}
        close={closeBulkInsertModal}
      />
    </List>
  );
};
