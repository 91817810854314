import {
    CreateButton,
    DateField,
    EditButton,
    getDefaultSortOrder,
    Icons,
    List,
    message,
    notification,
    RefreshButton,
    Space,
    Table,
    useModalForm,
    useTable,
} from "@pankod/refine-antd";
import CustomTag from "components/CustomTag";
import React from "react";
import ChildrenCreate from "./create";
import ChildrenEdit from "./edit";

const { PlusOutlined } = Icons;

const ChildrenList: React.FC = () => {
    const { tableProps, tableQueryResult, sorter } = useTable({
        resource: "indiacharts_childrens",
        initialSorter: [
            {
                field: "name",
                order: "desc",
            },
        ],
        initialPageSize: 10,
        metaData: {
            fields: ["id", "active", "name", "updated_at"],
        },
    });
    const {
        form: createForm,
        formProps: createFormProps,
        modalProps: createModalProps,
        show: createShow,
    } = useModalForm({
        resource: "indiacharts_childrens",
        action: "create",
        metaData: {
            fields: ["id", "active", "name", "sub_header_id", "pod_id", "page_id", "is_private", "slug"],
        },
        redirect: false,
        successNotification: false,
        onMutationSuccess: () => {
            message.success("Children Added !");
        },
    });
    const {
        form: editForm,
        formProps: editFormProps,
        modalProps: editModalProps,
        show: editShow,
    } = useModalForm({
        resource: "indiacharts_childrens",
        action: "edit",
        metaData: {
            fields: ["id", "active", "name", "sub_header_id", "pod_id", "page_id", "is_private", "slug"],
        },
        redirect: false,
        successNotification: false,
        onMutationSuccess: () => {
            message.success("Childrens updated !");
        },
    });
    return (
        <List
            pageHeaderProps={{
                extra: [
                    <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
                    <CreateButton type="primary" icon={<PlusOutlined />} onClick={() => createShow()} />,
                ],
            }}
        >
            <Table {...tableProps} rowKey="id" size="small">
                <Table.Column dataIndex="name" title="Name" />
                <Table.Column
                    dataIndex="active"
                    title="Is Active"
                    render={(value: any) => <CustomTag type={value} text={value ? "Active" : "In Active"} />}
                />
                <Table.Column
                    dataIndex="updated_at"
                    title="Updated Date"
                    render={(value) => <DateField value={value} format="lll" />}
                    defaultSortOrder={getDefaultSortOrder("updated_at", sorter)}
                    sorter
                />
                <Table.Column
                    title="Actions"
                    render={(record) => (
                        <Space>
                            <EditButton hideText size="small" type="link" recordItemId={record?.id} onClick={() => editShow(record?.id)} />
                            {/* <DeleteButton hideText size="small" type="link" recordItemId={record?.id} /> */}
                        </Space>
                    )}
                />
            </Table>
            <ChildrenCreate form={createForm} formProps={createFormProps} modalProps={createModalProps} />
            <ChildrenEdit form={editForm} formProps={editFormProps} modalProps={editModalProps} />
        </List>
    );
};

export default ChildrenList;
