import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  Row,
  Col,
  DatePicker,
  Select,
  FormInstance,
} from "@pankod/refine-antd";
import { useCreate, useGetIdentity } from "@pankod/refine-core";
import dayjs from "dayjs";
import { IApplicationUpdates } from "interfaces";
import moment from "moment";
import { useState } from "react";

type Props = {
  modalProps: ModalProps;
  formProps: FormProps;
  closeModal: any;
  form: FormInstance;
  refetchTable: any;
  currentUser: any;
};

export const CreateAppUpdate: React.FC<Props> = ({
  modalProps,
  formProps,
  closeModal,
  refetchTable,
  form,
  currentUser,
}) => {
  const { data: user } = useGetIdentity();
  const [releaseBgColor, setReleaseBgColor] = useState("#FB9650");
  const [updateBgColor, setUpdateBgColor] = useState("#166531");
  const [textColor, setTextColor] = useState("#FFFFFF");

  const { mutateAsync: createApplicationUpdate } =
    useCreate<IApplicationUpdates>();

  // Form Submit
  const onFinish = async (formData: IApplicationUpdates) => {
    await createApplicationUpdate({
      resource: "indiacharts_application_updates",
      values: {
        description: formData?.description,
        date: moment(formData?.date).format("YYYY-MM-DD"),
        tag: formData?.tag,
        tag_bg_color: formData?.tag_bg_color,
        tag_text_color: formData?.tag_text_color,
        created_by: currentUser,
        updated_by: currentUser,
      },
    }).then(() => {
      formProps?.form?.resetFields();
      refetchTable.refetch();
      closeModal();
    });
  };

  return (
    <Modal
      {...modalProps}
      title="Create Application Update"
      onCancel={() => {
        formProps?.form?.resetFields();
        closeModal();
      }}
    >
      <Form
        {...formProps}
        layout="vertical"
        form={form}
        size="large"
        onFinish={onFinish}
      >
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                { required: true },
                {
                  // pattern: new RegExp("^\\S*$"), // No Space Allowed
                  // pattern: new RegExp(/^(?![\s-])[\w\s-]+$/), // Check For Space At Beginning Only
                  pattern: new RegExp(/^[^\s]/),
                  message: "No spaces allowed",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item name="tag" label="Tag" rules={[{ required: true }]}>
              <Select
                onSelect={(e: any) => {
                  console.log("From select :::", e);
                  if (e === "Release") {
                    // console.log("Selected release")
                    form?.setFieldsValue({
                      tag_bg_color: releaseBgColor,
                      tag_text_color: textColor,
                    });
                  }
                  if (e === "Update") {
                    form?.setFieldsValue({
                      tag_bg_color: updateBgColor,
                      tag_text_color: textColor,
                    });
                  }
                }}
              >
                <option key="Release" value="Release">
                  Release
                </option>
                <option key="Update" value="Update">
                  Update
                </option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col>
            <Form.Item
              label="Date"
              name="date"
              rules={[{ required: true }]}
              initialValue={dayjs()}
            >
              <DatePicker />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item name="tag_bg_color" label="Background Color" hidden>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item name="tag_text_color" label="Text Color" hidden>
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.Item name="created_by" initialValue={currentUser}>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item name="updated_by" initialValue={currentUser}>
            <Input type="hidden" />
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
};
