import dayjs from "dayjs";
import { nhost } from "utility";

export const isLowerCase = (data: string) => {
  return data === data?.toLowerCase();
};

export const isEmpty = (value: any) =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

export const formatNumbers = ({
  value,
  prefix = null,
  suffix = null,
  isThousandSeparated = false,
}: any) => {
  if (!isEmpty(value) && !isNaN(value)) {
    let _value = value;

    if (isThousandSeparated) {
      _value = _value.toLocaleString("en-IN", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    if (prefix && !suffix) {
      _value = `${prefix}${_value}`;
    }
    if (suffix && !prefix) {
      _value = `${_value}${suffix}`;
    }
    if (suffix && prefix) {
      _value = `${prefix}${_value}${suffix}`;
    }

    return _value;
  } else {
    return "-";
  }
};

export const formatDate = (date: any, isDropdown = false) => {
  if (dayjs(date).isValid()) {
    if (isDropdown) {
      return dayjs(date).format("DD-MM-YYYY");
    } else {
      return dayjs(date).format("DD MMM YYYY");
    }
  } else {
    return null;
  }
};

export const defaultHeaders = () => {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...(!isEmpty(nhost.auth.getAccessToken()) && {
      Authorization: `Bearer ${nhost.auth.getAccessToken()}`,
    }),
  };
};

export async function postData(
  url = "",
  data = {},
  headers = {
    "Content-Type": "application/json",
  },
  maxRetries = 3,
  retryDelay = 1000 // Delay in milliseconds between retries
) {
  for (let retry = 0; retry <= maxRetries; retry++) {
    try {
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers,
        referrerPolicy: "no-referrer",
        body: JSON.stringify(data),
      });
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(`HTTP error, status = ${response.status}`);
      }
    } catch (error: any) {
      console.error(`Request failed on attempt ${retry + 1}: ${error.message}`);
    }
    if (retry < maxRetries) {
      // If the request failed and we have more retries left, wait for the specified delay before retrying.
      await new Promise((resolve) => setTimeout(resolve, retryDelay));
    }
  }
  throw new Error(`Max retries reached for POST request to ${url}`);
}

export async function getData(
  url = "",
  params = {},
  headers = {
    "Content-Type": "application/json",
  }
) {
  let _url = url;
  if (Object.keys(params).length) {
    _url += `?${new URLSearchParams(params).toString()}`;
  }
  const response = await fetch(_url, {
    method: "GET",
    mode: "cors",
    headers,
    referrerPolicy: "no-referrer",
  });

  if (!response.ok) {
    throw new Error(`HTTP error, status = ${response.status}`);
  }

  return response.json();
}
