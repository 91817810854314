import { useEffect } from "react";
import { Row, Col, Statistic, Typography } from "@pankod/refine-antd";
import { useResource } from "@pankod/refine-core";
import { useQuery as useReactQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

// Icons
import RupeeLight from "assets/icons/rupeeLight.svg";
import ReferredUserLight from "assets/icons/referredUserLight.svg";
import CommisionLight from "assets/icons/commisionLight.svg";

// Redux
import { setStats } from "store/partner-program/partnerProgramSlice";

// API
import { getRefereesOverview } from "rest";

const { Title } = Typography;

const Stats = () => {
  // URL Params
  const { id: partner_id } = useResource();

  // Dispatch
  const dispatch = useDispatch();

  // Redux
  const { totalCommission, totalReferees, monthlyAverageCommission } =
    useSelector((store: any) => store.PartnerProgram?.stats);

  const { data } = useReactQuery({
    queryKey: [
      "GET_REFEREES_OVERVIEW",
      {
        partner_id: partner_id,
      },
    ],
    queryFn: getRefereesOverview,
    refetchOnWindowFocus: false,
    enabled: true,
  });

  // UseEffect
  useEffect(() => {
    if (data !== undefined && Object.keys(data).length) {
      dispatch(
        setStats({
          totalCommission: parseFloat(data?.totalCommission).toFixed(2),
          totalReferees: data?.totalReferees,
          monthlyAverageCommission: parseFloat(
            data?.monthlyAverageCommission
          ).toFixed(2),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="stats">
      <Row>
        <Col lg={8} md={24} sm={24}>
          <div className="card">
            <img src={RupeeLight} alt="Rupee" className="icon" />
            <Statistic value={totalCommission} />
            <Title level={4} className="title">
              Total Earnings
            </Title>
          </div>
        </Col>
        <Col lg={8} md={24} sm={24}>
          <div className="card">
            <img src={ReferredUserLight} alt="Rupee" className="icon" />
            <Statistic value={totalReferees} />
            <Title level={4} className="title">
              Total Referred Users
            </Title>
          </div>
        </Col>
        <Col lg={8} md={24} sm={24}>
          <div className="card">
            <img src={CommisionLight} alt="Rupee" className="icon" />
            <Statistic value={monthlyAverageCommission} />
            <Title level={4} className="title">
              Average Referral Commission
            </Title>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Stats;
