import { IResourceComponentsProps } from "@pankod/refine-core";
import { useTable, List, RefreshButton, Table, useModalForm, Icons, CreateButton, EditButton, DeleteButton, Space, Button, DateField, BooleanField, ShowButton } from "@pankod/refine-antd";
import { IIndustries } from "interfaces";
import { CreateIndustry } from "./create";
import { EditIndustry } from "./edit";
import { useQuery } from "@tanstack/react-query";
import { getIndustry } from "graphql/queries";

const { PlusOutlined,CheckCircleOutlined,CloseCircleOutlined } = Icons;

export const IndustriesList: React.FC<IResourceComponentsProps> = () =>{
    
    const {tableProps, tableQueryResult}= useTable<IIndustries>({
        resource: "indiacharts_industries",
        metaData:{
            fields:[
                "id",
                "code",
                "name",
                "broad_industry_code"
            ]
        }
    })
    // console.log("comp", tableProps);

    const { formProps, modalProps, show: showModal } = useModalForm({
        resource: "indiacharts_industries",
        action: "create",
        metaData:{
            fields:[
                "id",
                "code",
                "name",
                "broad_industry_code"
            ]
        }
    })

    const { formProps: editFormProps, modalProps: editModalProps, show: editShow } = useModalForm({
        resource: "indiacharts_industries",
        action: "edit",
        metaData:{
            fields:[
                "id",
                "code",
                "name",
                "broad_industry_code"
            ]
        }
    })
    
    return(
        <List
            headerProps={{
                extra: [
                    <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
                    <CreateButton type="primary" icon={<PlusOutlined />} onClick={() => showModal()} />,
                ],
            }}
        >
            <Table {...tableProps} size="small">
                <Table.Column
                    dataIndex="code"
                    title="Code"
                />
                <Table.Column
                    dataIndex="name"
                    title="Name"
                    render={(_, record: any) => (
                        <Space>
                          <ShowButton
                            size="small"
                            type="link"
                            icon={null}
                            resource="indiacharts_industries"
                            recordItemId={record?.id}
                            // onClick={()=> getIndustry(record?.id)}
                          >
                            {record.name}
                          </ShowButton>
                        </Space>
                      )}
                />
                <Table.Column
                    dataIndex="broad_industry_code"
                    title="Industry code"
                />
                <Table.Column<IIndustries>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record)=>(
                        <Space>
                            <EditButton hideText
                            size="small"
                            type="link"
                            recordItemId={record.id}
                            onClick={()=> editShow(record.id)}
                        />
                        <DeleteButton hideText
                            size="small"
                            type="link"
                            recordItemId={record.id}
                            resourceNameOrRouteName="indiacharts_industries"
                        />
                        </Space>
                    )}
                />
            </Table>
            <CreateIndustry modalProps={modalProps} formProps={formProps} />
            <EditIndustry modalProps={editModalProps} formProps={editFormProps} />
        </List>
    )
}