import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  Row, Col, DatePicker, Select, useSelect, InputNumber
} from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";
import dayjs from 'dayjs';
import { IIndexData, IIndices } from "interfaces";


type CreateIndexDataProps = {
  modalProps: ModalProps;
  formProps: FormProps;
};
const { TextArea } = Input;

export const CreateIndexData: React.FC<CreateIndexDataProps> = ({ modalProps, formProps, }) => {
  const { data: user } = useGetIdentity();

  const { selectProps: indicesSelectProps } = useSelect<IIndices>({
    resource: "indiacharts_indices",
    optionLabel: "name",
    optionValue: "id",
    fetchSize: 10,
    metaData: {
      fields: ["id", "name"],
    },
    filters: [
      {
        field: "ic_active",
        operator: "eq",
        value: true,
      },
    ],
    onSearch: (value) => [
      {
        field: "name",
        operator: "startswith",
        value,
      },
    ],
  });

  return (
    <Modal {...modalProps} title="Create Index Data">
      <Form {...formProps} size="large" layout="vertical">
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Index"
              name="index_id"
              rules={[{ required: true }]}            >
              <Select {...indicesSelectProps} />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Date"
              name="date"
              rules={[{ required: true }]}
              initialValue={dayjs()}            >
              <DatePicker />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Open Price"
              name="open_price"
              rules={[{ required: true }]} >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Close Price"
              name="close_price"
              rules={[{ required: true }]} >
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Low Price"
              name="low_price"
              rules={[{ required: true }]} >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="High Price"
              name="high_price"
              rules={[{ required: true }]} >
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="created_by" initialValue={user?.id} >
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
