import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select, DatePicker } from "antd";
import dayjs from "dayjs";
import { useResource } from "@pankod/refine-core";
import { useQuery as useReactQuery } from "@tanstack/react-query";

// Config
import {
  statusDropdown,
  dateRangeDropdown,
  getDateRange,
} from "components/partner-program/config";

// Redux
import {
  setFilterBy,
  setSubscribersList,
  setPlansList,
} from "store/partner-program/partnerProgramSlice";

// API
import { getTransactionFilter } from "rest";

const { RangePicker } = DatePicker;

const PastTransactionsFilter = () => {
  // Dispatch
  const dispatch = useDispatch();

  // URL Params
  const { id: partner_id } = useResource();

  // Redux
  const { userName, plan, status, dateRange, startDate, endDate } = useSelector(
    (store: any) => store.PartnerProgram?.filterBy
  );
  const { subscribersList, plansList } = useSelector(
    (store: any) => store.PartnerProgram
  );

  // API
  const { data } = useReactQuery({
    queryKey: ["GET_TRANSACTION_FILTER", { partner_id: partner_id }],
    queryFn: getTransactionFilter,
    refetchOnWindowFocus: false,
    enabled: true,
  });

  // Filter Change Handler
  const onFilterChange = (key: string, value: any) => {
    dispatch(
      setFilterBy({
        [key]: value,
      })
    );
  };

  // UseEffect
  useEffect(() => {
    if (data?.subscribers) {
      let subscribers = data?.subscribers?.map((i: any) => ({
        label: i?.user_name,
        value: i?.user_id,
      }));

      dispatch(setSubscribersList(subscribers));
    }

    if (data?.plans) {
      let plans = data?.plans?.map((i: any) => ({
        label: i?.plan_label,
        value: i?.plan_id,
      }));

      dispatch(setPlansList(plans));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, partner_id]);

  return (
    <div className="filter-menu">
      <Select
        value={userName}
        placeholder="Username"
        allowClear
        onClear={() => onFilterChange("status", null)}
        onChange={(v) => onFilterChange("userName", v)}
      >
        {subscribersList?.map((i: any, idx: number) => (
          <option key={idx} value={i?.value}>
            {i?.label}
          </option>
        ))}
      </Select>

      <Select
        value={plan}
        placeholder="Plan"
        allowClear
        onClear={() => onFilterChange("status", null)}
        onChange={(v) => onFilterChange("plan", v)}
      >
        {plansList?.map((i: any, idx: number) => (
          <option key={idx} value={i?.value}>
            {i?.label}
          </option>
        ))}
      </Select>

      <Select
        value={status}
        placeholder="Status"
        allowClear
        onClear={() => onFilterChange("status", null)}
        onChange={(v) => onFilterChange("status", v)}
      >
        {statusDropdown?.map((i: any, idx: number) => (
          <option key={idx} value={i?.value}>
            {i?.label}
          </option>
        ))}
      </Select>

      <Select
        value={dateRange}
        placeholder="Date Range"
        onChange={(v) => {
          if (v === "custom") {
            dispatch(
              setFilterBy({
                dateRange: v,
                startDate: dayjs().subtract(7, "day"),
                endDate: dayjs(),
              })
            );
          } else {
            const { startDate, endDate } = getDateRange(v);
            dispatch(
              setFilterBy({
                dateRange: v,
                startDate,
                endDate,
              })
            );
          }
        }}
      >
        {dateRangeDropdown?.map((i: any, idx: number) => (
          <option key={idx} value={i?.value}>
            {i?.label}
          </option>
        ))}
      </Select>

      {dateRange === "custom" && (
        <RangePicker
          value={[startDate, endDate]}
          format={"DD-MM-YYYY"}
          onChange={(date: any) => {
            dispatch(
              setFilterBy({
                startDate: dayjs(date[0]),
                endDate: dayjs(date[0]),
              })
            );
          }}
        />
      )}
    </div>
  );
};

export default PastTransactionsFilter;
