import {
  useTable,
  List,
  RefreshButton,
  Table,
  useModalForm,
  Icons,
  CreateButton,
  EditButton,
  DeleteButton,
  Space,
  DateField,
  BooleanField,
  useForm,
  Form,
  Col,
  Input,
  getDefaultSortOrder,
  Select,
  useSelect,
} from "@pankod/refine-antd";
import { useSelector } from "react-redux";

// Interface
import { ISubscriptions } from "interfaces";
import { CreateSubscriptions } from "./create";
import { EditSubscriptions } from "./edit";

import debounce from "lodash/debounce";
import { useState } from "react";

// Icon
const {
  PlusOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
} = Icons;

export const SubscriptionsList: React.FC = () => {
  // Redux
  const { userData }: any = useSelector((state: any) => state.User);

  // Roles
  const roleAccess: any = ["super_admin", "admin"];

  const { form: tableForm, formProps: subscribersTableForm } = useForm();
  const [rotating, setRotating] = useState(false);
  const [pageId, setpageId] = useState<any>(null);
  const [selectedPlan, setselectedPlan] = useState<any>(null);
  const [selectedPlanMappingId, setselectedPlanMappingId] = useState<any>(null);

  const { tableProps, tableQueryResult, setFilters, sorter } =
    useTable<ISubscriptions>({
      resource: "indiacharts_subscriptions",
      metaData: {
        fields: [
          "id",
          "active",
          "plan_id",
          "start_date",
          "expiry_date",
          "plan_mapping_id",
          "updated_at",
          "created_at",
          "user_id",
          { user: ["displayName", "id", "email", "phoneNumber"] },
          { plan: ["name", "id", "label"] },
          { plan_mapping: ["id", "duration", "cost"] },
          { orders: ["amount"] },
        ],
      },
      initialSorter: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    });

  const {
    formProps,
    modalProps,
    show: showModal,
    close: closeModal,
    form,
  } = useModalForm({
    resource: "indiacharts_subscriptions",
    action: "create",
    redirect: false,
    metaData: {
      fields: [
        "id",
        "active",
        "plan_id",
        "start_date",
        "expiry_date",
        "plan_mapping_id",
        "updated_at",
        "created_at",
        "user_id",
        { user: ["displayName", "id"] },
        { plan: ["name", "id"] },
      ],
    },
  });

  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    form: editForm,
    close: closeEditModal,
    show: editShow,
  } = useModalForm({
    resource: "indiacharts_subscriptions",
    action: "edit",
    redirect: false,
    metaData: {
      fields: [
        "id",
        "active",
        "plan_id",
        "start_date",
        "expiry_date",
        "plan_mapping_id",
        "updated_at",
        "created_at",
        "user_id",
        { user: ["displayName", "id"] },
        { plan: ["name", "id"] },
        { plan_mapping: ["id", "duration", "cost"] },
      ],
    },
  });

  const handleSearch = debounce((e: any) => {
    const searchValue = e;
    setFilters([
      {
        operator: "or",
        value: [
          {
            field: `user.displayName`,
            operator: "contains",
            value: `%${searchValue}%`,
          },
          {
            field: `user.email`,
            operator: "contains",
            value: `%${searchValue}%`,
          },

          {
            field: `user.phoneNumber`,
            operator: "contains",
            value: `%${searchValue}%`,
          },
        ],
      },
    ]);
  }, 500);

  // Refresh button func
  const handleRefresh = () => {
    setRotating(true);

    setTimeout(() => {
      setRotating(false);
    }, 500);
  };

  // List
  const { selectProps: selectSubPlan } = useSelect({
    resource: "indiacharts_subscription_plans",
    metaData: {
      fields: ["id", "name", "label"],
    },
    filters: [
      {
        operator: "eq",
        field: "active",
        value: true,
      },
    ],
    optionLabel: "label",
    optionValue: "id",
    pagination: {
      pageSize: 9999,
    },
  });

  const { selectProps: selectPlanMapping } = useSelect({
    resource: "indiacharts_plan_mappings",
    metaData: {
      fields: ["id", "duration", { plan: ["name", "id", "label"] }],
    },
    optionLabel: "duration",
    optionValue: "id",
    pagination: {
      pageSize: 9999,
    },
    filters: [
      {
        field: "plan.id",
        operator: "eq",
        value: pageId || undefined,
      },
    ],
  });

  return (
    <List
      pageHeaderProps={{
        extra: [
          <span>Filters: </span>,
          <Select
            showSearch
            {...selectSubPlan}
            // defaultValue="all"
            style={{ width: "200px" }}
            onChange={(e: any) => {
              setpageId(e);
              setselectedPlan(e);
              if (e === "all") {
                setFilters([
                  {
                    field: "plan_id",
                    operator: "eq",
                    value: null,
                  },
                ]);
              } else
                setFilters([
                  {
                    field: "plan_id",
                    operator: "eq",
                    value: e,
                  },
                ]);
            }}
            placeholder="Filter with plan"
            value={selectedPlan}
            // filterOption={(input: any, option: any) => {
            //   const inputValue = input.toLowerCase();
            //   const optionValue = option.value.toLowerCase();
            //   return optionValue.includes(inputValue);
            // }}
          >
            <option key="all" value="all">
              All Plans
            </option>
          </Select>,
          <Select
            {...selectPlanMapping}
            showSearch
            // defaultValue="all"
            disabled={selectedPlan === null}
            style={{ width: "200px" }}
            onChange={(e: any) => {
              // console.log("Filter change :::", e);
              setselectedPlanMappingId(e);
              if (e === "all") {
                setFilters([
                  {
                    field: "plan_mapping_id",
                    operator: "eq",
                    value: null,
                  },
                ]);
              } else
                setFilters([
                  {
                    field: "plan_mapping_id",
                    operator: "eq",
                    value: e,
                  },
                ]);
            }}
            placeholder="Duration (months)"
            value={selectedPlanMappingId}
            // filterOption={(input: any, option: any) => {
            //   const inputValue = input.toLowerCase();
            //   const optionValue = option.value.toLowerCase();
            //   return optionValue.includes(inputValue);
            // }}
          >
            <option key="all" value="all">
              All Durations
            </option>
          </Select>,
          <RefreshButton
            onClick={() => {
              setselectedPlan(null);
              setselectedPlanMappingId(null);
              setFilters([
                {
                  field: "plan_id",
                  operator: "eq",
                  value: undefined,
                },
              ]);
              setFilters([
                {
                  field: "plan_mapping_id",
                  operator: "eq",
                  value: undefined,
                },
              ]);
              handleRefresh();
              handleSearch("");
              tableForm?.resetFields(["name"]);
              // tableQueryResult?.refetch();
            }}
          />,
          <>
            {roleAccess.includes(userData?.defaultRole) && (
              <CreateButton
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => showModal()}
              />
            )}
          </>,
        ],
      }}
    >
      <Form {...subscribersTableForm}>
        <Col span={6}>
          <Form.Item name="name">
            <Input
              onChange={(e: any) => handleSearch(e?.target?.value)}
              placeholder="Search by name,email and phone number"
              prefix={<Icons.SearchOutlined />}
            />
          </Form.Item>
        </Col>
      </Form>
      {/* Table */}
      <Table {...tableProps} size="small">
        <Table.Column dataIndex={["user", "displayName"]} title="Name" />
        <Table.Column dataIndex={["user", "email"]} title="Email" />
        <Table.Column dataIndex={["user", "phoneNumber"]} title="Phone no." />
        <Table.Column
          dataIndex={["plan", "label"]}
          title="Plan"
          render={(value: any) => {
            if (value === "free") {
              return <Space>Free</Space>;
            }
            return value;
          }}
        />
        <Table.Column
          // dataIndex="plan_mapping_id"
          dataIndex={["plan_mapping", "duration"]}
          title="Duration (months)"
          render={(value: any) => {
            if (value === null) {
              return <Space>-</Space>;
            }
            return value;
          }}
        />
        <Table.Column
          dataIndex="active"
          title="Active"
          render={(value) => (
            <BooleanField
              value={value === true}
              trueIcon={<CheckCircleOutlined style={{ color: "green" }} />}
              falseIcon={<CloseCircleOutlined style={{ color: "red" }} />}
              valueLabelTrue="true"
              valueLabelFalse="false"
            />
          )}
        />
        <Table.Column dataIndex={["orders", "amount"]} title="Amount" />
        {/* <Table.Column title="Updated By" dataIndex={["user", "displayName"]} />
         */}
        <Table.Column
          dataIndex="start_date"
          title="Start Date"
          defaultSortOrder={getDefaultSortOrder("start_date", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="expiry_date"
          title="End Date"
          defaultSortOrder={getDefaultSortOrder("end_date", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="created_at"
          title="Created At"
          defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
          sorter
          render={(value) => (
            <DateField format="MMM DD,YYYY hh:mm a" value={value} />
          )}
        />

        {roleAccess.includes(userData?.defaultRole) && (
          <Table.Column<ISubscriptions>
            title="Actions"
            fixed="right"
            width={100}
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                <EditButton
                  hideText
                  size="small"
                  type="link"
                  recordItemId={record.id}
                  onClick={() => editShow(record.id)}
                />
                <DeleteButton
                  hideText
                  size="small"
                  type="link"
                  recordItemId={record.id}
                  resourceNameOrRouteName="indiacharts_subscriptions"
                />
              </Space>
            )}
          />
        )}
      </Table>

      {/* Create */}
      <CreateSubscriptions
        formProps={formProps}
        modalProps={modalProps}
        closeModal={closeModal}
        tableQueryResult={tableQueryResult}
        form={form}
      />
      {/* Edit */}
      <EditSubscriptions
        modalProps={editModalProps}
        formProps={editFormProps}
        form={editForm}
        tableQueryResult={tableQueryResult}
        closeModal={closeEditModal}
      />
    </List>
  );
};
