import {
  IResourceComponentsProps,
  useExport,
  useList,
} from "@pankod/refine-core";
import {
  List,
  RefreshButton,
  CreateButton,
  Table,
  Menu,
  useTable,
  Space,
  EditButton,
  DateField,
  getDefaultSortOrder,
  useModalForm,
  Icons,
  Typography,
  Button,
} from "@pankod/refine-antd";

// Interface
import { IIndexData, IIndexDataCSV, IIndices } from "interfaces";

// Component
import { CreateIndexData } from "./create";
import { EditIndexData } from "./edit";
import { ImportIndexData } from "./import";

// GraphQL
import { mutateIndexData } from "graphql/mutation";

const { PlusOutlined, ImportOutlined, DeleteOutlined } = Icons;
const { Text } = Typography;

export const IndexDataList: React.FC<IResourceComponentsProps> = () => {
  const indexListQuery = useList<IIndices>({
    resource: "indiacharts_indices",
    metaData: {
      fields: ["id", "name"],
    },
    config: {
      pagination: { current: 1, pageSize: 250 },
    },
  });

  // Table
  const { filters, setFilters, tableProps, sorter, tableQueryResult } =
    useTable<IIndexData>({
      resource: "indiacharts_index_data",
      initialSorter: [
        {
          field: "date",
          order: "desc",
        },
      ],
      initialPageSize: 20,
      metaData: {
        fields: [
          "high_price",
          "low_price",
          "open_price",
          "close_price",
          "date",
          "index_id",
          "created_by",
          "created_at",
          { index: ["id", "name"] },
        ],
      },
    });

  // Create
  const {
    formProps,
    modalProps,
    show: showModal,
  } = useModalForm<IIndexData>({
    resource: "indiacharts_index_data",
    action: "create",
    metaData: {
      fields: [
        "high_price",
        "low_price",
        "open_price",
        "close_price",
        "date",
        "index_id",
        "created_by",
        "created_at",
      ],
    },
  });

  // Import
  const {
    formProps: importFormProps,
    modalProps: importModalProps,
    show: showBulkInsertModal,
    close: closeBulkInsertModal,
  } = useModalForm<IIndexData>({
    resource: "indiacharts_index_data",
    action: "create",
    metaData: {
      fields: [
        "high_price",
        "low_price",
        "open_price",
        "close_price",
        "date",
        "index_id",
        "created_by",
        "created_at",
      ],
    },
  });

  // Edit
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm<IIndexData>({
    resource: "indiacharts_index_data",
    action: "edit",
    metaData: {
      fields: ["close_price", "date", "index_id", "created_by", "created_at"],
    },
  });

  // Export
  const { triggerExport, isLoading: exportLoading } = useExport<IIndexData>({
    mapData: (item) => {
      return {
        date: item.date,
        close_price: item.close_price,
        open_price: item.open_price,
        high_price: item.high_price,
        low_price: item.low_price,
        index_id: item.index_id,
        created_by: item.created_by,
      };
    },
  });

  return (
    <List
      headerProps={{
        extra: [
          // <ExportButton onClick={triggerExport} loading={exportLoading}/>,
          <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
          <CreateButton
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => showModal()}
          />,
          <CreateButton
            icon={<ImportOutlined />}
            children={<Text>Import</Text>}
            onClick={() => showBulkInsertModal()}
          />,
        ],
      }}
    >
      <Menu
        mode="horizontal"
        onSelect={(props) => {
          setFilters([
            {
              field: "index_id",
              operator: "eq",
              value: props.key,
            },
          ]);
          tableQueryResult?.refetch();
        }}
      >
        {indexListQuery?.data?.data?.map((_f) => (
          <Menu.Item key={_f.id}>{_f.name}</Menu.Item>
        ))}
      </Menu>
      <Table
        {...tableProps}
        rowKey={(record, index) => record.index_id + "_" + record.date}
        size="small"
      >
        <Table.Column<IIndexData>
          dataIndex="index_id"
          title="Index"
          render={(_, record) => <Space>{record?.index?.name}</Space>}
        />
        <Table.Column<IIndexData> dataIndex="close_price" title="Close Price" />
        <Table.Column<IIndexData> dataIndex="open_price" title="Open Price" />
        <Table.Column<IIndexData> dataIndex="low_price" title="Low Price" />
        <Table.Column<IIndexData> dataIndex="high_price" title="High Price" />
        <Table.Column
          dataIndex="date"
          title="Date"
          render={(value) => <DateField value={value} format="YYYY-MM-DD" />}
          defaultSortOrder={getDefaultSortOrder("date", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="created_at"
          title="Created At"
          render={(value) => <DateField value={value} format="lll" />}
          defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
          sorter
        />
        {/* <Table.Column
                    dataIndex="created_by"
                    title="Created By"
                    render={(value) => <DateField value={value} format="lll" />}
                    defaultSortOrder={getDefaultSortOrder("updated_at", sorter)}
                    sorter
                /> */}
        <Table.Column<IIndexData>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton
                hideText
                size="small"
                type="link"
                recordItemId={record.date}
                onClick={() => editShow(record.index_id + "_" + record.date)}
              />
              {/* <DeleteButton hideText
                                size="small"
                                type="link"
                                recordItemId={record.date}
                            /> */}
              <Button
                type="link"
                icon={<DeleteOutlined style={{ color: "#fa541c" }} />}
                onClick={() =>
                  mutateIndexData(record?.date, record?.index_id).then((_r) => {
                    tableQueryResult?.refetch();
                  })
                }
              />
            </Space>
          )}
        />
      </Table>
      <CreateIndexData modalProps={modalProps} formProps={formProps} />
      <EditIndexData modalProps={editModalProps} formProps={editFormProps} />
      <ImportIndexData
        modalProps={importModalProps}
        formProps={importFormProps}
        close={closeBulkInsertModal}
      />
    </List>
  );
};
