import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  List,
  RefreshButton,
  ShowButton,
  Table,
  useTable,
  Space,
  EditButton,
  DeleteButton,
  DateField,
  getDefaultSortOrder,
  useModalForm,
  Icons,
  CreateButton,
  Form,
  Col,
  Input,
} from "@pankod/refine-antd";
import { CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";

import debounce from "lodash/debounce";

// Interface
import { ISentimentIndicatorHeader } from "interfaces";

// Utility
import { nhost } from "utility/nhost";

// Component
import { CreateDiffusionIndicatorHeader } from "./create";
import { EditDiffusionIndicatorHeader } from "./edit";

// Webhook
import { diffusionIndicatorAPI } from "utility/webhook";

// Antd
import { Avatar } from "antd";

// Icons
const { PlusOutlined } = Icons;

export const DiffusionIndicatorHeaderList: React.FC<
  IResourceComponentsProps
> = () => {
  // Table
  const { tableProps, sorter, tableQueryResult, setFilters, searchFormProps } =
    useTable<ISentimentIndicatorHeader>({
      resource: "indiacharts_sentiment_indicator_headers",
      liveMode: "auto",
      initialSorter: [
        {
          field: "sortno",
          order: "asc",
        },
      ],
      initialPageSize: 20,
      metaData: {
        fields: [
          "id",
          "label",
          "sortno",
          "active",
          "lightmode_icon_link",
          "darkmode_icon_link",
          "lightmode_file_id",
          "darkmode_file_id",
          "parent_header_id",
          "created_at",
          "created_by",
          "updated_at",
          "updated_by",
          "category",
        ],
      },
      permanentFilter: [
        {
          field: "category",
          operator: "eq",
          value: "diffusion",
        },
      ],
    });

  // Create
  const {
    formProps,
    modalProps,
    show: showModal,
    close: closeModal,
  } = useModalForm({
    resource: "indiacharts_sentiment_indicator_headers",
    action: "create",
    redirect: false,
    metaData: {
      fields: [
        "label",
        "sortno",
        "active",
        "lightmode_icon_link",
        "lightmode_file_id",
        "darkmode_icon_link",
        "darkmode_file_id",
        "created_by",
        "updated_by",
        "category",
      ],
    },
  });

  // Edit
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
    close: editClose,
  } = useModalForm({
    resource: "indiacharts_sentiment_indicator_headers",
    action: "edit",
    redirect: false,
    metaData: {
      fields: [
        "id",
        "label",
        "sortno",
        "active",
        "lightmode_icon_link",
        "darkmode_icon_link",
        "darkmode_file_id",
        "lightmode_file_id",
        "parent_header_id",
        "created_at",
        "created_by",
        "updated_at",
        "updated_by",
        "info_video_id",
      ],
    },
  });

  const handleSearch = debounce((e: any) => {
    const searchValue = e?.target?.value;
    setFilters([
      {
        field: "label",
        operator: "contains",
        value: `%${searchValue}%`,
      },
    ]);
  }, 500);

  return (
    <List
      headerProps={{
        extra: [
          <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
          <CreateButton
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => showModal()}
          />,
        ],
      }}
    >
      <Form {...searchFormProps}>
        <Col span={6}>
          <Form.Item name="name">
            {/* <Input
              onChange={(e) =>
                setFilters([
                  {
                    field: "label",
                    operator: "startswith",
                    value: e.target.value,
                  },
                ])
              }
              placeholder="Search by name"
              prefix={<Icons.SearchOutlined />}
            /> */}
            <Input
              // onChange={handleInputChange}
              onChange={(e: any) => handleSearch(e)}
              // value={searchTerm}
              placeholder="Search by name"
              prefix={<Icons.SearchOutlined />}
            />
          </Form.Item>
        </Col>
        {/* <SaveButton onClick={searchFormProps.form?.submit} /> */}
      </Form>
      {/* Table */}
      <Table {...tableProps} rowKey="id" size="small">
        <Table.Column dataIndex="sortno" title="Sort No" />
        <Table.Column<ISentimentIndicatorHeader>
          dataIndex="label"
          title="Label"
          render={(_, record) => (
            <Space>
              <ShowButton
                type="link"
                icon={null}
                resource="indiacharts_sentiment_indicator_headers"
                recordItemId={record.id}
              >
                {record.label}
              </ShowButton>
            </Space>
          )}
        />
        <Table.Column<ISentimentIndicatorHeader>
          dataIndex="active"
          title="Is Active"
          render={(value) =>
            value ? (
              <CheckCircleOutlined style={{ color: "green" }} />
            ) : (
              <CloseCircleOutlined style={{ color: "red" }} />
            )
          }
        />
        {/* <Table.Column
          dataIndex="created_at"
          title="Created Date"
          render={(value) => <DateField value={value} format="lll" />}
          defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
          sorter
        /> */}
        <Table.Column
          dataIndex="updated_at"
          title="Updated Date"
          render={(value) => <DateField value={value} format="lll" />}
          defaultSortOrder={getDefaultSortOrder("updated_at", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="lightmode_icon_link"
          title="Light Icon Link"
          render={(value: any) => <Avatar src={value} />}
        />
        <Table.Column
          dataIndex="darkmode_icon_link"
          title="Dark Icon Link"
          render={(value: any) => <Avatar src={value} />}
        />
        <Table.Column<ISentimentIndicatorHeader>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton
                hideText
                size="small"
                type="link"
                recordItemId={record.id}
                onClick={() => editShow(record.id)}
              />
              <DeleteButton
                hideText
                size="small"
                type="link"
                recordItemId={record.id}
                resourceNameOrRouteName="indiacharts_sentiment_indicator_headers"
                onClick={async () => {
                  // Delete Icon From NHost
                  let lightmode_file_id = record?.lightmode_file_id;
                  let darkmode_file_id = record?.darkmode_file_id;
                  if (
                    lightmode_file_id !== "" ||
                    lightmode_file_id !== null ||
                    lightmode_file_id !== undefined
                  ) {
                    await nhost.storage.delete({
                      fileId: lightmode_file_id,
                    });
                  }
                  if (
                    darkmode_file_id !== "" ||
                    darkmode_file_id !== null ||
                    darkmode_file_id !== undefined
                  ) {
                    await nhost.storage.delete({
                      fileId: darkmode_file_id,
                    });
                  }

                  diffusionIndicatorAPI();
                }}
              />
            </Space>
          )}
        />
      </Table>

      {/* Create */}
      <CreateDiffusionIndicatorHeader
        modalProps={modalProps}
        formProps={formProps}
        closeModal={closeModal}
        refetchTable={tableQueryResult}
      />

      {/* Edit */}
      <EditDiffusionIndicatorHeader
        modalProps={editModalProps}
        formProps={editFormProps}
        closeModal={editClose}
        refetchTable={tableQueryResult}
      />
    </List>
  );
};
