import {
  Col,
  Form,
  FormProps,
  Input,
  Modal,
  ModalProps,
  Row,
  Select,
  message,
  useSelect,
} from "@pankod/refine-antd";
import { useCreateMany } from "@pankod/refine-core";
import dayjs from "dayjs";
import React from "react";
import { DatePicker } from "antd";
import type { RangePickerProps } from "antd/es/date-picker";

interface DiscountCreateProps {
  formProps: FormProps;
  modalProps: ModalProps;
  onClose: () => void;
}

const DiscountCreate: React.FC<DiscountCreateProps> = ({
  formProps,
  modalProps,
  onClose,
}) => {
  const { mutate } = useCreateMany();
  const plan = Form.useWatch("plan", formProps?.form);
  const { selectProps: planProps } = useSelect({
    resource: "indiacharts_subscription_plans",
    optionLabel: "label",
    optionValue: "id",
    metaData: {
      fields: ["id", "active", "label", "name"],
    },
    queryOptions: {
      enabled: plan?.label !== "Free",
    },
  });
  // fetching plan_mapping by plan
  const { selectProps: planmappingProps } = useSelect({
    resource: "indiacharts_plan_mappings",
    optionLabel: "duration",
    optionValue: "id",
    metaData: {
      fields: ["id", "duration"],
    },
    filters: [
      {
        field: "plan_id",
        operator: "eq",
        value: plan?.value,
      },
    ],
  });

  const { selectProps: brokerProps } = useSelect({
    resource: "indiacharts_brokers",
    optionLabel: "label",
    optionValue: "id",
    metaData: {
      fields: ["id", "name","label"],
    },
   });


  const onFinish = async (data: any) => {
    const { plan_mapping_id, plan, start_date, expiry_date, ...rest } = data;
    if (formProps?.onFinish) {
      const request_object = plan_mapping_id?.map((d: any) => ({
        plan_id: plan?.value,
        plan_mapping_id: d,
        start_date: dayjs(start_date)?.format("YYYY-MM-DD"),
        expiry_date: dayjs(expiry_date)?.format("YYYY-MM-DD"),
        ...rest,
      }));
      await mutate(
        {
          resource: "indiacharts_discounts",
          values: request_object,
          successNotification: false,
        },
        {
          onSuccess: () => {
            onClose();
            formProps?.form?.resetFields();
            message.success("Discount Added");
          },
        }
      );
    }
  };
  // eslint-disable-next-line arrow-body-style
  const disabledDate: RangePickerProps["disabledDate"] = (current: any) => {
    // Can not select days before today and today
    return current && current < dayjs().add(-1, "day");
  };
  return (
    <Modal {...modalProps} title="Create Discounts">
      <Form
        {...formProps}
        size="large"
        layout="vertical"
        form={formProps?.form}
        onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col lg={6} xs={24}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Field Required !",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={6} xs={24}>
            <Form.Item
              name="discount_type"
              label="Discount Type"
              rules={[
                {
                  required: true,
                  message: "Field Required !",
                },
              ]}
            >
              <Select
                options={[
                  {
                    label: "Percentage",
                    value: "percentage",
                  },
                  {
                    label: "Amount",
                    value: "amount",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col lg={6} xs={24}>
            <Form.Item
              name="discount_value"
              label="Discount Value"
              rules={[
                {
                  required: true,
                  message: "Field Required !",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={6} xs={24}>
            <Form.Item name="plan" label="Plans">
              <Select
                {...planProps}
                onChange={(v: any, option: any) => {
                  formProps?.form?.setFieldsValue({
                    plan: option,
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col lg={6} xs={24}>
            <Form.Item name="plan_mapping_id" label="Plan Duration">
              <Select
                {...planmappingProps}
                disabled={
                  plan?.label === "Free" || plan === undefined ? true : false
                }
                mode="multiple"
              />
            </Form.Item>
          </Col>
          <Col lg={6} xs={24}>
            <Form.Item name="start_date" label="Start Date">
              <DatePicker
                size="large"
                style={{
                  width: "100%",
                }}
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
          <Col lg={6} xs={24}>
            <Form.Item name="expiry_date" label="End Date">
              <DatePicker
                size="large"
                style={{
                  width: "100%",
                }}
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
          <Col lg={6} xs={24}>
            <Form.Item name="promocode" label="Promo Code">
              <Input />
            </Form.Item>
          </Col>
          <Col lg={6} xs={24}>
            <Form.Item name="broker_id" label="Broker">
              <Select
                {...brokerProps}
            />
            </Form.Item>
          </Col>
          <Col lg={24} xs={24}>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  required: true,
                  message: "Field Required !",
                },
              ]}
            >
              <Input.TextArea rows={5} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default DiscountCreate;
