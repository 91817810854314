
import { IResourceComponentsProps, useExport, useNavigation } from "@pankod/refine-core";

import {
    List,
    RefreshButton,
    CreateButton,
    ExportButton,
    ShowButton,
    Table,
    useTable,
    Space,
    EditButton,
    DeleteButton,
    DateField,
    getDefaultSortOrder,
    useModalForm, Icons
} from "@pankod/refine-antd";




import { IPod } from "interfaces";
import { CreatePod } from "./create";
import { EditPod } from "./edit";
const { PlusOutlined } = Icons;

export const PodList: React.FC<IResourceComponentsProps> = () => {
    const { show } = useNavigation()

    const { tableProps, sorter, tableQueryResult } = useTable<IPod>({
        initialSorter: [
            {
                field: "name",
                order: "asc",
            },
        ],
        metaData: {
            fields: ["name", "description", "created_by","pod_type"],
        },
    });
    const { formProps, modalProps, show: showModal } = useModalForm({
        resource: "pods",
        action: "create",
        metaData: {
            fields: ["name", "description","config", "created_by","pod_type"],
        },
    });
    const {
        formProps: editFormProps,
        modalProps: editModalProps,
        show: editShow,
    } = useModalForm({
        resource: "pods",
        action: "edit",
        metaData: {
            fields: ["name", "description","config", "created_by","pod_type"],
        },
    });

    const { triggerExport, isLoading } = useExport<IPod>();


    return (
        <List
            pageHeaderProps={{
                extra: [
                    //  <ExportButton onClick={triggerExport} loading={isLoading} />,
                    <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
                    <CreateButton type="primary" icon={<PlusOutlined />} onClick={() => showModal()} />,
                ],

            }}
        >
            <Table {...tableProps} rowKey="id" size="small">
                <Table.Column dataIndex="name" title="Name" />
                <Table.Column dataIndex="pod_type" title="Type" />

                <Table.Column dataIndex="description" title="Description" />


                <Table.Column
                    dataIndex="created_at"
                    title="Created Date"
                    render={(value) => <DateField value={value} format="lll" />}
                    defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
                    sorter
                />
                <Table.Column<IPod>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                size="small"
                                type="link"
                                icon={null}
                                recordItemId={record.id}
                                onClick={() => editShow(record.id)}
                                
                            />
                            <DeleteButton
                                size="small"
                                type="link"
                                icon={null}
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
            <CreatePod modalProps={modalProps} formProps={formProps} />
            <EditPod modalProps={editModalProps} formProps={editFormProps} />

        </List>
    );
};
