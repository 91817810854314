import {
  Form,
  Input,
  InputNumber,
  ModalProps,
  FormProps,
  Modal,
  Row,
  Col,
  useSelect,
  Select,
} from "@pankod/refine-antd";

// Interface
import { ISectors } from "interfaces";

type Props = {
  modalProps: ModalProps;
  formProps: FormProps;
};

export const EditBroadIndustry: React.FC<Props> = ({
  modalProps,
  formProps,
}) => {
  const { selectProps: sectorSelector } = useSelect<ISectors>({
    resource: "indiacharts_sector",
    optionLabel: "name",
    optionValue: "code",
    fetchSize: 10,
    metaData: {
      fields: ["id", "code", "name"],
    },
    onSearch: (value) => [
      {
        field: "name",
        operator: "startswith",
        value,
      },
    ],
  });

  return (
    <Modal {...modalProps} title="Edit Broad Industry">
      <Form {...formProps} size="large" layout="vertical">
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={8} lg={8}>
            <Form.Item
              label="Code"
              name="code"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} type={"number"} />
            </Form.Item>
          </Col>

          <Col xs={8} lg={8}>
            <Form.Item
              label="Sector Code"
              name="sector_code"
              rules={[{ required: true }]}
            >
              <Select {...sectorSelector} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
