import { IResourceComponentsProps, useList } from "@pankod/refine-core";
import {
  useTable,
  List,
  Table,
  useModalForm,
  Icons,
  CreateButton,
  EditButton,
  Space,
  BooleanField,
  Menu,
  Col,
  Input,
  DateField,
  useSelect,
  Select,
  Button,
  useForm,
  notification,
  MenuProps,
} from "@pankod/refine-antd";

import debounce from "lodash/debounce";

// Interface
import { IWebinars } from "interfaces";

// Component
import { CreateWebinars } from "./create";
import { EditWebinars } from "./edit";
import { Avatar, Form } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useState } from "react";
import {
  mutateWebinars,
  mutateWebinarsFavourites,
  mutateWebinarsNotes,
} from "graphql/mutation";

import { Popconfirm } from "antd";

const { PlusOutlined, CheckCircleOutlined, CloseCircleOutlined } = Icons;

export const WebinarsList: React.FC<IResourceComponentsProps> = () => {
  const [selectedCategory, setSelectedCategory] = useState<any>();
  const [rotating, setRotating] = useState(false);
  const [current, setCurrent] = useState("true");
  const [filterValue, setfilterValue] = useState<any>();
  const { DeleteOutlined } = Icons;
  const { form: tableForm, formProps: webinarTableForm } = useForm();

  const onMenuClick: MenuProps["onClick"] = (e: any) => {
    console.log("click ", e);
    setCurrent(e.key);
  };

  // Table
  const { tableProps, tableQueryResult, setFilters, searchFormProps } =
    useTable<IWebinars>({
      resource: "indiacharts_webinars",
      metaData: {
        fields: [
          "id",
          "title",
          "description",
          "presenter",
          "presenter_img",
          "link",
          "link_source",
          "recorded",
          "host_at",
          "created_at",
          "updated_at",
          "created_by",
          "updated_by",
          "active",
          "thumbnail",
          "duration",
          "category_id",
          "end_at",
          {
            category: ["category"],
          },
        ],
      },
      initialFilter: [
        {
          field: "active",
          operator: "eq",
          value: "true",
        },
      ],
      initialSorter: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
      ...(!!selectedCategory && {
        permanentFilter: [
          {
            field: "category_id",
            operator: "eq",
            value: selectedCategory,
          },
        ],
      }),
    });

  const { selectProps: webinarCategoryList } = useSelect({
    resource: "indiacharts_webinar_categories",
    metaData: {
      fields: ["id", "category"],
    },
    pagination: { pageSize: 99999 },
    optionValue: "id",
    optionLabel: "category",
    // config: {
    //   pagination: { current: 1, pageSize: 250 },
    // },
    onSearch: (value) => [
      {
        field: "category",
        operator: "contains",
        value: `%${value}%`,
      },
    ],
  });

  // Create
  const {
    formProps,
    modalProps,
    show: showModal,
    close: closeModal,
    form,
  } = useModalForm({
    resource: "indiacharts_webinars",
    action: "create",
    redirect: false,
    metaData: {
      fields: [
        "id",
        "title",
        "description",
        "presenter",
        "presenter_img",
        "link",
        "link_source",
        "recorded",
        "host_at",
        "created_at",
        "updated_at",
        "created_by",
        "updated_by",
        "active",
        "thumbnail",
        "duration",
        "category_id",
        "presenter_id",
        "end_at",
      ],
    },
  });

  // Edit
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
    form: editForm,
  } = useModalForm({
    resource: "indiacharts_webinars",
    action: "edit",
    redirect: false,
    metaData: {
      fields: [
        "id",
        "title",
        "description",
        "presenter",
        "presenter_img",
        "link",
        "link_source",
        "recorded",
        "host_at",
        "created_at",
        "updated_at",
        "created_by",
        "updated_by",
        "active",
        "thumbnail",
        "duration",
        "category_id",
        "presenter_id",
        "end_at",
      ],
    },
  });

  const handleSearch = debounce((e: any) => {
    const searchValue = e;
    setFilters([
      {
        field: "title",
        operator: "contains",
        value: `%${searchValue}%`,
      },
    ]);
  }, 500);

  // Refresh button func
  const handleRefresh = () => {
    setRotating(true);

    setTimeout(() => {
      setRotating(false);
    }, 500);
  };

  const onClickButton = async (id: any) => {
    try {
      await mutateWebinarsNotes(id);
      await mutateWebinarsFavourites(id);
      await mutateWebinars(id)
        .then(() => {
          notification.open({
            type: "success",
            message: "Successfully deleted webinar!",
          });
        })
        .catch((error) => {
          notification.open({
            type: "error",
            message: "Error while deleting webinar",
          });
        });
      tableQueryResult?.refetch();
    } catch (error) {
      console.error("Error occurred during mutation:", error);
    }
  };

  return (
    <List
      headerProps={{
        extra: [
          // <ExportButton onClick={triggerExport} loading={exportLoading}/>,
          <span>Filter:</span>,
          <Select
            {...webinarCategoryList}
            style={{ width: "200px" }}
            value={filterValue}
            onChange={(e: any) => {
              setfilterValue(e);
              if (e === "all") {
                setFilters([
                  {
                    field: "category_id",
                    operator: "eq",
                    value: null,
                  },
                ]);
              } else
                setFilters([
                  {
                    field: "category_id",
                    operator: "eq",
                    value: e,
                  },
                ]);
            }}
            placeholder="Filter with Category"
          >
            {/* <option key="all" value="all">
              All
            </option> */}
            {/* {webinarCategoryList?.data?.data?.map((item: any) => (
              <option key={item?.id} value={item?.id}>
                {item?.category}
              </option>
            ))} */}
          </Select>,
          <Button
            type="default"
            icon={<ReloadOutlined spin={rotating} />}
            onClick={() => {
              setfilterValue(null);
              handleRefresh();
              handleSearch("");
              setFilters([
                {
                  field: "category_id",
                  operator: "eq",
                  value: undefined,
                },
              ]);
              tableForm?.resetFields(["name"]);
              // tableQueryResult?.refetch();
            }}
          >
            Refresh
          </Button>,
          <CreateButton
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => showModal()}
          />,
        ],
      }}
    >
      <Form {...webinarTableForm}>
        <Col span={6}>
          <Form.Item name="name">
            <Input
              onChange={(e: any) => handleSearch(e?.target?.value)}
              placeholder="Search by name"
              prefix={<Icons.SearchOutlined />}
            />
          </Form.Item>
        </Col>
      </Form>
      <Menu
        // defaultActiveFirst
        selectedKeys={[current]}
        onClick={onMenuClick}
        mode="horizontal"
        onSelect={(props) => {
          setFilters([
            {
              field: "active",
              operator: "eq",
              value: props.key,
            },
          ]);
          tableQueryResult?.refetch();
        }}
      >
        <Menu.Item key="true">Active</Menu.Item>
        <Menu.Item key="false">In Active</Menu.Item>
      </Menu>

      {/* Table */}
      <Table {...tableProps} size="small" scroll={{ x: 1000 }}>
        <Table.Column
          dataIndex="presenter_img"
          title="Presenter img"
          render={(value) => <Avatar src={value} alt="img" size={50} />}
        />
        <Table.Column dataIndex="title" title="Title" />
        {/* <Table.Column dataIndex="description" title="Description" /> */}
        <Table.Column dataIndex="presenter" title="Presenter" />
        <Table.Column dataIndex="link" title="Link" />
        {/* <Table.Column
          dataIndex="thumbnail"
          title="Thumbnail"
          render={(value) => <Avatar src={value} alt="img" size={50} />}
        /> */}
        <Table.Column dataIndex="duration" title="Duration" />
        <Table.Column
          dataIndex="host_at"
          title="Host at"
          render={(value) => <DateField format="lll" value={value} />}
        />
        <Table.Column
          dataIndex="end_at"
          title="End at"
          render={(value) => <DateField format="lll" value={value} />}
        />
        <Table.Column dataIndex={["category", "category"]} title="Category" />
        <Table.Column dataIndex="link_source" title="Link source" />
        <Table.Column
          dataIndex="recorded"
          title="Recorded"
          render={(value) => (
            <BooleanField
              value={value === true}
              trueIcon={<CheckCircleOutlined style={{ color: "green" }} />}
              falseIcon={<CloseCircleOutlined style={{ color: "red" }} />}
              valueLabelTrue="true"
              valueLabelFalse="false"
            />
          )}
        />
        <Table.Column<IWebinars>
          title="Actions"
          fixed="right"
          width={100}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton
                hideText
                size="small"
                type="link"
                recordItemId={record.id}
                onClick={() => editShow(record.id)}
              />
              <Popconfirm
                placement="topLeft"
                title="Delete Webinar?"
                okText="Yes"
                okButtonProps={{
                  style: {
                    backgroundColor: "transparent",
                    color: "red",
                    borderColor: "red",
                  },
                }}
                cancelText="No"
                onConfirm={() => onClickButton(record?.id)}
              >
                <Button
                  style={{ color: "red" }}
                  type="link"
                  icon={<DeleteOutlined />}
                  // onClick={() => onClickButton(record?.id)}
                />
              </Popconfirm>
            </Space>
          )}
        />
      </Table>

      {/* Create */}
      <CreateWebinars
        modalProps={modalProps}
        formProps={formProps}
        form={form}
        closeModal={closeModal}
      />

      {/* Edit */}
      <EditWebinars
        modalProps={editModalProps}
        formProps={editFormProps}
        form={editForm}
      />
    </List>
  );
};
