import React from "react";
import {
  Table,
  useTable,
  List,
  RefreshButton,
  CreateButton,
  useModalForm,
  Space,
  EditButton,
  DeleteButton,
  Icons,
  ShowButton,
} from "@pankod/refine-antd";

// Interface
import { ISectors } from "interfaces";

// Component
import { CreateSector } from "./create";
import { EditSector } from "./edit";

// Icons
const { PlusOutlined } = Icons;

export const SectorsList: React.FC = () => {
  // Sector Table
  const { tableProps, tableQueryResult } = useTable<ISectors>({
    resource: "indiacharts_sector",
    liveMode: "auto",
    metaData: {
      fields: ["id", "code", "name"],
    },
    initialPageSize: 20,
    initialSorter: [
      {
        field: "name",
        order: "desc",
      },
    ],
  });

  // Create
  const {
    formProps: createFormProps,
    modalProps: createModalProps,
    show: createShowModal,
  } = useModalForm({
    resource: "indiacharts_sector",
    action: "create",
    redirect: false,
    metaData: {
      fields: ["id", "name", "code"],
    },
  });

  // Edit
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "indiacharts_sector",
    action: "edit",
    redirect: false,
    metaData: {
      fields: ["name", "code"],
    },
  });

  return (
    <>
      {/* List */}
      <List
        title="Sectors"
        headerProps={{
          extra: [
            <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
            <CreateButton
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => createShowModal()}
            />,
          ],
        }}
      >
        {/* Table */}
        <Table
          {...tableProps}
          rowKey={(record, index) => record.id}
          size="small"
        >
          <Table.Column<ISectors>
            dataIndex="name"
            title="Name"
            render={(_, record) => (
              <Space>
                <ShowButton
                  size="small"
                  type="link"
                  icon={null}
                  resource="indiacharts_sector"
                  recordItemId={record.id}
                >
                  {record.name}
                </ShowButton>
              </Space>
            )}
          />
          <Table.Column<ISectors> dataIndex="code" title="Code" />
          <Table.Column<ISectors>
            title="Actions"
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                {/* Edit Button */}
                <EditButton
                  hideText
                  size="small"
                  type="link"
                  recordItemId={record.id}
                  onClick={() => editShow(record.id)}
                />
                {/* Delete Button */}
                <DeleteButton
                  hideText
                  size="small"
                  type="link"
                  recordItemId={record.id}
                  resourceNameOrRouteName="indiacharts_sector"
                />
              </Space>
            )}
          />
        </Table>
      </List>

      {/* Create */}
      <CreateSector formProps={createFormProps} modalProps={createModalProps} />

      {/* Edit */}
      <EditSector modalProps={editModalProps} formProps={editFormProps} />
    </>
  );
};
