import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  Upload,
  Icons,
  Row,
  useSelect,
  UploadProps,
  Button,
  UploadFile,
  Progress,
  Space,
} from "@pankod/refine-antd";
import { useGetIdentity, useNotification } from "@pankod/refine-core";
import dayjs from "dayjs";
import { IHolidays } from "interfaces";
import { useState } from "react";
import { parse } from "papaparse";
import { GraphQLClient, gql } from "graphql-request";
import { nhost } from "utility";
const { UploadOutlined } = Icons;

type ImportHolidaysProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  close: Function;
};

export const ImportHolidays: React.FC<ImportHolidaysProps> = ({
  modalProps,
  formProps,
  close,
}) => {
  const { data: user } = useGetIdentity();
  let token = nhost.auth.getAccessToken();
  const { open } = useNotification();

  const [csvFile, setCSVFile] = useState<UploadFile>();
  // const [records, setRecords] = useState<Array<IIndexDataCSV>>([]);
  const [uploading, setUploading] = useState(false);
  const [iterationCount, setIterationCount] = useState(0);
  const [iteration, setIteration] = useState(0);

  const { selectProps } = useSelect<IHolidays>({
    resource: "indiacharts_holidays",
    optionLabel: "Exchange",
    optionValue: "exchange_code",
    metaData: {
      fields: ["exchange_code"],
    },
    onSearch: (value) => [
      {
        field: "exchange_code",
        operator: "eq",
        value,
      },
    ],
  });

  const handleUpload = (e: any) => {
    console.log("inside handle uploads");
    console.log(e);
    setUploading(true);
    setIteration(0);
    setIterationCount(0);
    parse(csvFile as any, {
      complete: async ({ data }: { data: unknown[][] }) => {
        console.log(data);
        if (data[0][0] == "ExchangeCode") data.splice(0, 1);
        data = data.filter(
          (_d) => _d[1] && _d[1] != "" && (_d[1] as string).trim() != ""
        );
        let rec = data.map((item: Array<any>) => {
          let format = "MM/DD/YY";
          if (item[0].includes("-")) format = "DD-MM-YYYY";
          return {
            exchange_code: item[0],
            holiday_date: item[1],
            purpose: item[2],
            modified_at: item[3],
          };
        });
        let affected_rows = 0;
        const graphQLClient = new GraphQLClient(
          `${process.env.REACT_APP_NHOST_BACKEND}/v1/graphql`,
          {
            headers: { authorization: "Bearer " + token },
          }
        );
        const mutation = gql`
          mutation InsertHolidays(
            $objects: [indiacharts_holidays_insert_input!]!
          ) {
            response: insert_indiacharts_holidays(
              objects: $objects
              on_conflict: {
                constraint: holidays_master_pkey
                update_columns: [created_at]
              }
            ) {
              affected_rows
            }
          }
        `;
        setIterationCount(Math.ceil(rec.length / 50));
        let c = 0;
        try {
          if (rec.length > 50) {
            let _in = rec.splice(0, 50);
            while (_in.length > 0) {
              c++;
              const result = await graphQLClient.request(mutation, {
                objects: _in,
              });
              setIteration(c);
              if (result.response && result.response.affected_rows) {
                affected_rows += Number(result.response.affected_rows);
              }
              setIteration(iteration + 1);
              _in = rec.splice(0, 50);
            }
          } else {
            c++;
            const result = await graphQLClient.request(mutation, {
              objects: rec,
            });
            setIteration(c);
            if (result.response && result.response.affected_rows) {
              affected_rows += Number(result.response.affected_rows);
            }
          }
          if (open) {
            open({
              key: `holidays-file-import`,
              message: "",
              type: "success",
              description: `Imported ${affected_rows} Records.`,
              undoableTimeout: 2000,
            });
          }
        } catch (error) {
          console.error(error);
          if (open) {
            open({
              key: `holidays-file-import`,
              message: "",
              type: "error",
              description: `Facing Issue while uploading your records.`,
              undoableTimeout: 2000,
            });
          }
        }
        setUploading(false);
        formProps.form?.resetFields();
        setCSVFile(undefined);
        close();
      },
    });
  };

  const props: UploadProps = {
    maxCount: 1,
    onRemove: (file) => {
      setCSVFile(undefined);
    },
    beforeUpload: (file) => {
      setCSVFile(file);
      return false;
    },
    fileList: csvFile ? [csvFile] : undefined,
  };

  return (
    <Modal {...modalProps} title="Bulk Holiday master Upload">
      <Form
        {...formProps}
        size="large"
        layout="vertical"
        onFinish={handleUpload}
      >
        {/* <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Exchange"
              name="exchange_code"
              rules={[{ required: true }]}
            >
              <Select {...selectProps} />
            </Form.Item>
          </Col>
        </Row> */}
        <Row>
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>Upload CSV</Button>
          </Upload>
        </Row>
        <Form.Item name="created_at" initialValue={dayjs()}>
          <Input type="hidden" />
        </Form.Item>
      </Form>
      {uploading ? (
        <Progress percent={Math.round((iteration / iterationCount) * 100)} />
      ) : (
        <Space></Space>
      )}
    </Modal>
  );
};
