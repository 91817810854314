import {
  Col,
  Form,
  FormProps,
  Input,
  Modal,
  ModalProps,
  Row,
  Upload,
  Button,
  UploadProps,
  Icons,
  Image,
} from "@pankod/refine-antd";
import React, { useState } from "react";
import { nhost } from "utility";

interface DiscountEditProps {
  formProps: FormProps;
  modalProps: ModalProps;
  form: any;
  onClose: any;
}

const { UploadOutlined } = Icons;

const BrokerEdit: React.FC<DiscountEditProps> = ({
  formProps,
  modalProps,
  form,
  onClose,
}) => {
  // State
  const [proImgList, setProImgList] = useState<any>([]);

  // Form Watch
  const formWatch = {
    logoUrl: Form?.useWatch("logo_url", formProps.form),
  };

  const handleProImageUpload = async (file: File) => {
    let res = await nhost.storage.upload({ file, bucketId: "public" });
    const url = await nhost.storage.getPublicUrl({
      fileId: res.fileMetadata?.id || "",
    });
    form.setFieldsValue({ logo_url: url });
    return url;
  };

  const proImgProps: UploadProps = {
    onRemove: (file) => {
      const index = proImgList.indexOf(file);
      const newProImg = proImgList.slice();
      newProImg.splice(index, 1);
      newProImg.splice(newProImg);
    },
    beforeUpload: (file) => {
      setProImgList([file]);
      handleProImageUpload(file);
      return false;
    },
    fileList: proImgList,
  };

  return (
    <Modal
      {...modalProps}
      title="Edit Broker"
      onCancel={() => {
        setProImgList([]);
        onClose();
      }}
    >
      <Form
        {...formProps}
        size="large"
        layout="vertical"
        form={formProps?.form}
        //   onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col lg={6} xs={24}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Field Required !",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={6} xs={24}>
            <Form.Item
              name="label"
              label="Label"
              rules={[
                {
                  required: true,
                  message: "Field Required !",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col lg={6} xs={24}>
            <Form.Item label="Logo" name="logo_url">
              <Upload {...proImgProps} maxCount={1}>
                <Button icon={<UploadOutlined />}>Select File</Button>
              </Upload>
              <Input style={{ display: "none" }} />
            </Form.Item>
          </Col>

          <Col lg={6} xs={24}>
            <Form.Item label="Logo Preview" name="logo_url">
              {formWatch.logoUrl !== undefined ? (
                <Image
                  width={120}
                  height={120}
                  src={formWatch.logoUrl}
                  alt={"Icon"}
                />
              ) : (
                <p>No Image Available For Preview</p>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default BrokerEdit;
