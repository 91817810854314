import {
  List,
  Form,
  Input,
  Row,
  Col,
  Button,
  notification,
} from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";

import { nhost } from "utility";

export const ChangePassword = () => {
  const { push } = useNavigation();

  async function formSubmit(formData: any) {
    if (formData.newPassword === formData.confirmPassword) {
      await nhost.auth
        .changePassword({ newPassword: formData.newPassword })
        .then(() => {
          push(`/dashboard`);
        });
    } else {
      notification.open({
        message: "Change Password",
        type: "error",
        description: "Confirm password is not matching",
      });
    }
  }

  return (
    <List title="Change Password" headerProps={{}}>
      <Form
        style={{ padding: "1rem" }}
        initialValues={{
          newPassword: "",
          confirmPassword: "",
        }}
        size="large"
        layout="vertical"
        onFinish={(values: any) => {
          formSubmit(values);
        }}
        onFinishFailed={(err) => {
          console.log("Change Password Error :: ", err);
        }}
      >
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input type="password" />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input type="password" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Button type="primary" htmlType="submit">
              Change Password
            </Button>
          </Col>
        </Row>
      </Form>
    </List>
  );
};
