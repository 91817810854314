import { useRef, useMemo, useEffect, useCallback, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useResource } from "@pankod/refine-core";

// Components
import CustomAgGrid from "components/CustomAgGrid";

// API
import { getReferees, getRefereesTransaction } from "rest/partner-program";

// Utility
import { formatDate, formatNumbers } from "utility";

const ReferredUserTable = () => {
  // Ref
  const gridRef = useRef<any>(null);

  // URL Params
  const { id: partner_id } = useResource();

  // State
  const [refereeId, setRefereeId] = useState<any>(null);

  // Variables
  const LIMIT = 50;

  // Column Def
  const referredUsertableHeader = [
    {
      field: "user_id",
      headerName: "User ID",
      headerClass: "ag-header-start",
      hide: true,
      rowGroup: true,
      cellRenderer: (params: any) => {
        return <span>{params?.data?.user_name}</span>;
      },
    },
    {
      field: "last_transaction_date",
      headerName: "LAST TRANSACTION",
      headerClass: "ag-header-start",
      cellRenderer: (params: any) => formatDate(params?.value),
      flex: 1,
    },
    {
      field: "plan",
      headerName: "PLAN",
      headerClass: "ag-header-start",
      flex: 1,
      cellRenderer: (params: any) => {
        return params.value === "Active" ? (
          <span className="active">{params?.value}</span>
        ) : params.value === "Inactive" ? (
          <span className="inactive">{params?.value}</span>
        ) : (
          <span>{params?.value}</span>
        );
      },
    },
    {
      field: "amount",
      headerName: "TOTAL AMOUNT PAID",
      headerClass: "ag-right-aligned-header",
      cellClass: "ag-right-aligned-cell",
      flex: 1,
      cellRenderer: (params: any) =>
        formatNumbers({
          value: params?.value,
          prefix: "₹",
          isThousandSeparated: true,
          fixedTo: 2,
        }),
    },
    {
      field: "commission",
      headerName: "TOTAL EARNINGS",
      headerClass: "ag-right-aligned-header",
      cellClass: "ag-right-aligned-cell",
      flex: 1,
      cellRenderer: (params: any) =>
        formatNumbers({
          value: params?.value,
          prefix: "₹",
          isThousandSeparated: true,
          fixedTo: 2,
        }),
    },
  ];

  // API - Referee
  const { fetchNextPage: fetchNextPageReferee } = useInfiniteQuery({
    queryKey: [
      "GET_REFEREES",
      {
        limit: LIMIT,
        offset: 0,
        partner_id: partner_id,
      },
    ],
    queryFn: ({ pageParam = 0 }) =>
      getReferees([
        "GET_REFEREES",
        {
          limit: LIMIT,
          offset: pageParam * LIMIT,
          partner_id: partner_id,
        },
      ]),
    getNextPageParam: (lastPage, pages) => lastPage.nextCursor,
    getPreviousPageParam: (firstPage, pages) => firstPage.prevCursor,
    enabled: false,
  });

  // API Call - Referred User
  const { fetchNextPage: fetchNextPageReferredUser } = useInfiniteQuery({
    queryKey: [
      "GET_REFEREES_TRANSACTION",
      {
        limit: LIMIT,
        offset: 0,
        refereeId: refereeId,
        planId: null,
        isActive: null,
        startDate: null,
        endDate: null,
        partner_id: partner_id,
      },
    ],
    queryFn: ({ pageParam = 0 }) =>
      getRefereesTransaction([
        "GET_REFEREES_TRANSACTION",
        {
          limit: LIMIT,
          offset: pageParam * LIMIT,
          refereeId: refereeId,
          planId: null,
          isActive: null,
          startDate: null,
          endDate: null,
          partner_id: partner_id,
        },
      ]),
    getNextPageParam: (lastPage, pages) => lastPage.nextCursor,
    getPreviousPageParam: (firstPage, pages) => firstPage.prevCursor,
    enabled: false,
  });

  // On Grid Ready
  const onGridReady = useCallback((params: any) => {
    const dataSource = {
      getRows: (params: any) => {
        // Referee
        if (params?.request?.groupKeys?.length === 0) {
          const page = params?.request?.endRow / LIMIT ?? 0;
          fetchNextPageReferee({
            pageParam: page - 1,
          }).then((res) => {
            let lastRow = -1;
            const rowData = res?.data?.pages?.[page - 1].map((d: any) => ({
              group: true,
              user_id: d?.user_id,
              user_name: d?.user_name,
              last_transaction_date: d?.last_transaction_date,
              amount: parseFloat(d?.amount).toFixed(2),
              commission: parseFloat(d?.commission).toFixed(2),
              plan: d?.is_active ? "Active" : "Inactive",
            }));
            params?.success({
              rowData,
              lastRow,
            });
          });
        }
        // Referred User
        else {
          var key = params?.request?.groupKeys[0];
          setRefereeId(key);

          const page = params?.request?.endRow / LIMIT ?? 0;

          setTimeout(function () {
            fetchNextPageReferredUser({
              pageParam: page - 1,
            })
              .then((res) => {
                let lastRow = -1;
                const rowData = res?.data?.pages?.[page - 1].map((d: any) => ({
                  // user_name: d?.purchasedBy,
                  user_name: "",
                  user_id: "",
                  last_transaction_date: d?.date,
                  amount: parseFloat(d?.amount).toFixed(2),
                  commission: parseFloat(d?.commission).toFixed(2),
                  plan: d?.planName,
                }));

                params?.success({
                  rowData: rowData || [],
                  lastRow,
                });
              })
              .catch(() => {
                params?.success({
                  rowData: [],
                  lastRow: null,
                });
              });
          }, 300);
        }
      },
    };

    gridRef.current = params;
    params.api.setServerSideDatasource(dataSource);
    params.api.sizeColumnsToFit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Auto Group Column Def
  const autoGroupColumnDef = useMemo(() => {
    return {
      field: "user_name",
      headerName: "NAME",
      headerClass: "ag-header-start",
      cellRenderer: "agGroupCellRenderer",
      flex: 1,
      unSortIcon: false,
      cellRendererParams: {
        suppressSizeToFit: true,
        suppressMenu: true,
        suppressCount: false,
      },
    };
  }, []);

  const handleGroupOpen = useCallback((params: any) => {
    // eslint-disable-next-line eqeqeq
    return params.rowNode?.id == 0;
  }, []);

  useEffect(() => {
    if (gridRef.current?.api) {
      (async () => {
        gridRef.current.api.refreshServerSide({
          route: undefined,
          purge: true,
        });
      })();
    }
  }, [gridRef]);

  return (
    <CustomAgGrid
      isLoading={false}
      ref={gridRef}
      columnDefs={referredUsertableHeader}
      gridOptions={{
        autoGroupColumnDef: autoGroupColumnDef,
        serverSideInfiniteScroll: true,
        onGridReady,
        rowModelType: "serverSide",
        cacheBlockSize: LIMIT,
        suppressAggFuncInHeader: true,
        isServerSideGroupOpenByDefault: handleGroupOpen,
        onRowClicked: (e: any) => {
          if (e.node.level === 0) {
            e.node.setExpanded(!e.node.expanded);
          }
        },
      }}
    />
  );
};

export default ReferredUserTable;
