import { useParams } from "react-router-dom";

import { useShow } from "@pankod/refine-core";
import { IDataFeed, IIndexData, IIndicator } from "interfaces";
import {
  PageHeader, ListButton, Space, EditButton, useModalForm,
  Tabs, Table, useTable, DeleteButton
} from "@pankod/refine-antd";
import { CreateIndexData } from "./create";
import { EditIndexData } from "./edit";



export const ShowxIndexData: React.FC = () => {
  const routeParams = useParams();

  console.log(routeParams.id);

  const { tableProps, sorter, tableQueryResult } = useTable<IIndexData>({
    resource: "indiacharts_index_data",
    metaData: {
      fields: ["high_price", "low_price", "open_price", "close_price", "date", "index_id", "created_by", "created_at"],
    },
    initialPageSize: 20,
    initialSorter: [
      {
        field: "date",
        order: "desc",
      },
    ]
  });

  const { data, isLoading } = tableQueryResult;
  const record = data?.data;


  const { formProps, modalProps, show: showModal } = useModalForm<IIndexData>({
    resource: "indiacharts_index_data",
    action: "create",
    redirect: false,
    metaData: {
      fields: ["high_price", "low_price", "open_price", "close_price", "date", "index_id", "created_by", "created_at"],
    },
  });
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "indiacharts_momentum_daily_swing",
    action: "edit",
    redirect: false,
    metaData: {
      fields: ["high_price", "low_price", "open_price", "close_price", "date", "indicator_id", "index_id", "created_by", "created_at"],
    },
  });
  const columns = [
    {
      title: 'Index',
      dataIndex: 'index_id',
      key: 'index_id',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Open Price',
      dataIndex: 'open_price',
      key: 'open_price',
    },
    {
      title: 'Close Price',
      dataIndex: 'close_price',
      key: 'close_price',
    },
    {
      title: 'Low Price',
      dataIndex: 'low_price',
      key: 'low_price',
    },
    {
      title: 'High Price',
      dataIndex: 'high_price',
      key: 'high_price',
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: 'Actions',
      // dataIndex: 'actions',
      key: 'actions',
      render: (_: any, record: any) => (
        <Space>
          <EditButton hideText={true}
            size="small"
            type="link"
            recordItemId={record.id}
            onClick={() => editShow(record.id)}
          />
          <DeleteButton hideText={true}
            size="small"
            type="link"
            recordItemId={record.id}
            onClick={() => editShow(record.id)}
          />
        </Space>
      )
    },
  ];


  return <>
    <PageHeader
      // title={queryResult?.data?.data?.name}
      onBack={() => window.history.back()}
      extra={[<ListButton />]}
      footer={<Tabs>
        <Tabs.TabPane tab="Fields" key="item-2">
          <Table {...tableProps} rowKey={(record, index) => record.index_id + '_' + record.date} size="small" columns={columns} />
          <CreateIndexData modalProps={modalProps} formProps={formProps} />
          <EditIndexData modalProps={editModalProps} formProps={editFormProps} />

        </Tabs.TabPane>
      </Tabs>
      }
    ></PageHeader>
  </>

};


