import { useState, useEffect } from "react";
import { useLogin, useTranslate } from "@pankod/refine-core";
import {
  Row,
  Col,
  AntdLayout,
  Card,
  Form,
  Input,
  Button,
} from "@pankod/refine-antd";
import { useDispatch } from "react-redux";

// Image
import Background from "../../public/images/login_bg.jpeg";

// Styles
import "./style.less";

// Redux
import { setUserData } from "store/user/userSlice";

// Misc
// import { NONAME } from "dns";

export interface ILoginForm {
  username: string;
  password: string;
}

export const LoginPage: React.FC = () => {
  // Dispatch
  const dispatch = useDispatch();

  const [form] = Form.useForm<ILoginForm>();
  const [authError, setAuthError] = useState("");
  const t = useTranslate();

  const { mutate: login, isError, error } = useLogin<ILoginForm>();

  const CardTitle = (
    <div style={{ textAlign: "center" }}>
      <img
        alt="logo"
        width="200px"
        style={{ textAlign: "center", marginTop: "40px" }}
        src="logo.svg"
      />
    </div>
  );

  // Form Submit
  const onSubmit = (values: ILoginForm) => {
    login(values, {
      onSuccess: async (data: any) => {
        dispatch(setUserData(data?.user?.user));
      },
      onError: (error: any) => {
        console.log("Error :: ", error);
      },
    });
  };

  useEffect(() => {
    if (isError) setAuthError(error.message);
  }, [isError]);

  return (
    // <AntdLayout style={{ backgroundColor: "#e5e5e5", height: "100vh" }}>
    //   <Row
    //     style={{
    //       display: "flex",
    //       justifyContent: "center",
    //       marginTop: "8rem",
    //     }}
    //   >
    //     <Col xs={24} md={24} lg={12}>
    //       <div
    //         style={{
    //           maxWidth: "600px",
    //           margin: "auto",
    //           borderRadius: "2rem",
    //           maxHeight: "400px",
    //         }}
    //       >
    //         <Card
    //           title={CardTitle}
    //           headStyle={{ borderBottom: 0 }}
    //           style={{
    //             border: "0px",
    //             borderRadius: "1rem",
    //             boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    //             height: "420px",
    //           }}
    //         >
    //           <Form<ILoginForm>
    //             layout="vertical"
    //             form={form}
    //             onFinish={(values) => {
    //               const value = login(values);
    //             }}
    //             requiredMark={false}
    //           >
    //             <Form.Item
    //               name="username"
    //               rules={[{ required: true }]}
    //               style={{ marginBottom: "20px" }}
    //             >
    //               <Input placeholder="email" size="large" />
    //             </Form.Item>
    //             <Form.Item
    //               name="password"
    //               rules={[{ required: true, message: "enter password" }]}
    //             >
    //               <Input
    //                 type="password"
    //                 size="large"
    //                 placeholder="password"
    //                 onFocus={() => setAuthError("")}
    //               />
    //             </Form.Item>
    //             {authError && (
    //               <p style={{ color: "red", margin: "0px" }}>{authError}</p>
    //             )}
    //             <div style={{ marginBottom: "12px" }}>
    //               <a
    //                 href="/resetpassword"
    //                 style={{
    //                   float: "right",
    //                   fontSize: "12px",
    //                   marginBottom: "20px",
    //                 }}
    //               >
    //                 Forgot password?
    //               </a>
    //             </div>
    //             <Button type="primary" size="large" htmlType="submit" block>
    //               {t("pages.login.signin", "Sign in")}
    //             </Button>
    //           </Form>
    //           {/* <p style={{ marginTop: "50px", textAlign: "center" }}>
    //             Strike Admin{" "}
    //           </p>
    //           <p style={{ marginTop: "50px", textAlign: "center" }}>
    //             {" "}
    //             © indiacharts.com
    //           </p> */}
    //         </Card>
    //       </div>
    //     </Col>
    //   </Row>
    // </AntdLayout>
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "#e5e5e5",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <div
        style={{
          width: "500px",
          // margin: "auto",
          // borderRadius: "2rem",
          maxHeight: "400px",
        }}
      > */}
      {/* <span style={{ fontSize: "2rem", marginBottom: "1rem" }}>
        Welcome back!
      </span> */}
      <Card
        title={CardTitle}
        headStyle={{ borderBottom: 0 }}
        style={{
          border: "0px",
          borderRadius: "4px",
          boxShadow: "0px 4px 5px #00000011",
          height: "420px",
          width: "400px",
        }}
      >
        <Form<ILoginForm>
          layout="vertical"
          form={form}
          // onFinish={(values) => {
          //   const value = login(values);
          // }}
          onFinish={onSubmit}
          requiredMark={false}
        >
          <Form.Item
            name="username"
            rules={[{ required: true }]}
            style={{ marginBottom: "20px" }}
          >
            <Input placeholder="email" size="large" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "enter password" }]}
          >
            <Input
              type="password"
              size="large"
              placeholder="password"
              onFocus={() => setAuthError("")}
            />
          </Form.Item>
          {authError && (
            <p style={{ color: "red", margin: "0px" }}>{authError}</p>
          )}
          <div style={{ marginBottom: "12px" }}>
            <a
              href="/resetpassword"
              style={{
                float: "right",
                fontSize: "12px",
                marginBottom: "20px",
              }}
            >
              Forgot password?
            </a>
          </div>
          <Button type="primary" size="large" htmlType="submit" block>
            {t("pages.login.signin", "Log in")}
          </Button>
        </Form>
        {/* <p style={{ marginTop: "50px", textAlign: "center" }}>
                Strike Admin{" "}
              </p>
              <p style={{ marginTop: "50px", textAlign: "center" }}>
                {" "}
                © indiacharts.com
              </p> */}
      </Card>
      {/* </div> */}
    </div>
  );
};
