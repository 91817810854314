import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useShow } from "@pankod/refine-core";
import { PageHeader, ListButton, Tabs, Icons } from "@pankod/refine-antd";

// Interface
import { ICompanies } from "interfaces";

// Component
import { StocksList } from "./stocks_list";
import { DividendList } from "./company_dividend_list";
import { SplitsBonusList } from "./splits_bonus_list";
import { CompanySubscriptionList } from "./company_subscriptions_list";
import { CompanyRightsList } from "./company_rights";

const { BankOutlined } = Icons;

export const ShowCompanies: React.FC = () => {
  // Route Param
  const routeParams = useParams();
  const [currentBreadCrumb, setCurrentBreadCrumb] = useState("Stocks");

  // Companies By ID
  const { queryResult: companiesQueryResult } = useShow<ICompanies>({
    resource: "indiacharts_companies",
    id: routeParams?.id,
    metaData: {
      fields: ["id", "name"],
    },
  });

  const handleTabChange = (key: string) => {
    setCurrentBreadCrumb(key);
  };

  return (
    <>
      <div className="breadcrumb">
        <BankOutlined /> Companies / {currentBreadCrumb}
      </div>
      <PageHeader
        // breadcrumbRender={() => <></>}
        title={companiesQueryResult?.data?.data?.name}
        onBack={() => window.history.back()}
        extra={[<ListButton />]}
        footer={
          <Tabs onChange={handleTabChange}>
            <Tabs.TabPane tab="Stocks" key="Stocks">
              <StocksList />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Dividend" key="Dividend">
              <DividendList />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Splits Bonus" key="Splits Bonus">
              <SplitsBonusList />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Subscriptions" key="Subscriptions">
              <CompanySubscriptionList />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Rights" key="Rights">
              <CompanyRightsList />
            </Tabs.TabPane>
          </Tabs>
        }
      ></PageHeader>
    </>
  );
};
