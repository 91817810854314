import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  Row,
  Col,
  DatePicker,
  Select,
  useSelect,
} from "@pankod/refine-antd";
import { useCreate, useGetIdentity } from "@pankod/refine-core";
import dayjs from "dayjs";
import { IIndicator, IIndicatorData, IIndices } from "interfaces";

// Webhook
import { indicatorDataAPI } from "utility/webhook";

type CreateIndicatorDataProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  refetchTable: any;
  closeModal: any;
};
const { TextArea } = Input;

export const CreateIndicatorData: React.FC<CreateIndicatorDataProps> = ({
  modalProps,
  formProps,
  refetchTable,
  closeModal,
}) => {
  const { data: user } = useGetIdentity();

  const { selectProps: indicatorSelectProps } = useSelect<IIndicator>({
    resource: "indiacharts_indicators",
    optionLabel: "name",
    optionValue: "id",
    pagination: { pageSize: 999 },
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value: `%${value}%`,
      },
    ],
    metaData: {
      fields: ["id", "name"],
    },
  });

  const { mutateAsync: createIndicatorData } = useCreate<IIndicatorData>();

  // Form Submit
  const onFinish = async (formData: IIndicatorData) => {
    // console.log(formData);
    await createIndicatorData({
      resource: "indiacharts_indicator_data",
      values: {
        indicator_id: formData?.indicator_id,
        value: formData?.value,
        date: formData?.date,
        created_by: user?.id,
      },
      metaData: { fields: ["indicator_id", "value", "date", "created_by"] },
    }).then(() => {
      formProps?.form?.resetFields();
      refetchTable.refetch();
      indicatorDataAPI({
        indicator_id: formData?.indicator_id
      });
      closeModal();
    });
  };

  return (
    <Modal {...modalProps} title="Create Indicator Data">
      <Form {...formProps} size="large" layout="vertical" onFinish={onFinish}>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Indicator"
              name="indicator_id"
              rules={[{ required: true }]}
            >
              <Select
                {...indicatorSelectProps}
                showSearch
                filterOption={(input: string, option: any) => {
                  const label = option.label.toLowerCase().replace(/\s/g, "");
                  const inputValue = input.toLowerCase().replace(/\s/g, "");
                  return label.includes(inputValue);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Date"
              name="date"
              rules={[{ required: true }]}
              initialValue={dayjs()}
            >
              <DatePicker />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item label="Value" name="value" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="created_by" initialValue={user?.id}>
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
