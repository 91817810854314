import { useState } from "react";
import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  InputNumber,
  Select,
  Row,
  Col,
  Upload,
  Button,
  Icons,
  UploadProps,
  notification,
  Image,
  message,
} from "@pankod/refine-antd";
import { useGetIdentity, useCreate } from "@pankod/refine-core";

// Utility
import { nhost } from "utility/nhost";

// Interface
import { ISentimentIndicatorHeader } from "interfaces";

// Webhook
import { sentimentIndicatorAPI } from "utility/webhook";

// Icons
const { UploadOutlined } = Icons;

type Props = {
  modalProps: ModalProps;
  formProps: FormProps;
  closeModal: any;
  refetchTable: any;
};

export const CreateSentimentIndicatorHeader: React.FC<Props> = ({
  modalProps,
  formProps,
  closeModal,
  refetchTable,
}) => {
  const { data: user } = useGetIdentity();

  // State
  const [fileList, setFileList] = useState<any>([]);
  const [fileList2, setFileList2] = useState<any>([]);

  // Form Watch
  const formWatch = {
    icon_link: Form.useWatch("lightmode_icon_link", formProps.form),
  };
  const formDarkWatch = {
    dark_icon_link: Form.useWatch("darkmode_icon_link", formProps.form),
  };

  // Icon Upload
  const handleImageUpload = async (file: File) => {
    let file_response = await nhost.storage.upload({
      file,
      bucketId: "public",
    });
    let icon_url = await nhost.storage.getPublicUrl({
      fileId: file_response.fileMetadata?.id || "",
    });
    formProps?.form?.setFieldsValue({
      lightmode_icon_link: icon_url,
      lightmode_file_id: file_response.fileMetadata?.id,
    });
  };

  const handleDarkImageUpload = async (file: File) => {
    let file_response = await nhost.storage.upload({
      file,
      bucketId: "public",
    });
    let icon_url = await nhost.storage.getPublicUrl({
      fileId: file_response.fileMetadata?.id || "",
    });
    formProps?.form?.setFieldsValue({
      darkmode_icon_link: icon_url,
      darkmode_file_id: file_response.fileMetadata?.id,
    });
  };

  const thumbProps: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const isValidFileFormat =
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/svg+xml";
      if (!isValidFileFormat) {
        message.error(`only .png, .jpeg & .svg files are allowed to upload`);
        return isValidFileFormat;
      }
      setFileList([...fileList, file]);
      handleImageUpload(file);
      return false;
    },
    fileList,
  };

  const darkThumbProps: UploadProps = {
    onRemove: (file) => {
      const index = fileList2.indexOf(file);
      const newFileList = fileList2.slice();
      newFileList.splice(index, 1);
      setFileList2(newFileList);
    },
    beforeUpload: (file) => {
      const isValidFileFormat =
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/svg+xml";
      if (!isValidFileFormat) {
        message.error(`only .png, .jpeg & .svg files are allowed to upload`);
        return isValidFileFormat;
      }
      setFileList2([...fileList2, file]);
      handleDarkImageUpload(file);
      return false;
    },
    fileList: fileList2,
  };

  // Create
  const { mutateAsync: createSentimentIndicator } =
    useCreate<ISentimentIndicatorHeader>();

  // Form Submit
  const onFinish = async (formData: ISentimentIndicatorHeader) => {
    await createSentimentIndicator({
      resource: "indiacharts_sentiment_indicator_headers",
      values: {
        label: formData?.label,
        sortno: formData?.sortno,
        active: formData?.active,
        category: formData?.category,
        lightmode_icon_link: formData?.lightmode_icon_link,
        darkmode_icon_link: formData?.darkmode_icon_link,
        lightmode_file_id: formData?.lightmode_file_id,
        darkmode_file_id: formData?.darkmode_file_id,
        created_by: user?.id,
        updated_by: user?.id,
      },
    }).then(() => {
      formProps?.form?.resetFields();
      refetchTable.refetch();
      sentimentIndicatorAPI();
      closeModal();
    });
  };

  // Form Failed
  const onFinishFailed = (err: any) => {
    notification.open({
      message: "Error while creating sentiment indicator",
      type: "error",
    });
    console.log("ERROR : ", err);
  };

  return (
    <Modal
      {...modalProps}
      title="Create Sentiment Indicator Header"
      onCancel={() => {
        formProps?.form?.resetFields();
        closeModal();
      }}
    >
      <Form
        {...formProps}
        size="large"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Label"
              name="label"
              rules={[
                { required: true },
                {
                  // pattern: new RegExp("^\\S*$"), // No Space Allowed
                  pattern: new RegExp(/^[^\s]/), // Check For Space At Beginning Only
                  message: "No spaces allowed",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={8} lg={8}>
            <Form.Item
              label="Sorting No"
              name="sortno"
              rules={[{ required: true }]}
            >
              <InputNumber style={{ width: "100%" }} type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Category"
              name="category"
              initialValue="sentiment"
            >
              <Input defaultValue="sentiment" disabled />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Is Active"
              name="active"
              rules={[{ required: true }]}
              initialValue={true}
            >
              <Select
                options={[
                  {
                    value: true,
                    label: "True",
                  },
                  {
                    value: false,
                    label: "False",
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item label="Upload Light Mode Icon">
              <Upload
                {...thumbProps}
                accept="image/png, image/jpeg, image/svg+xml"
              >
                <Button icon={<UploadOutlined />}>Click To Upload Icon</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item name="lightmode_icon_link" label="Light Mode Icon Link">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item label="Light Mode Icon Preview">
              {formWatch.icon_link !== undefined ? (
                <Image
                  width={120}
                  height={120}
                  src={formWatch.icon_link}
                  alt={"Icon"}
                />
              ) : (
                <p>No Image Available For Preview</p>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item label="Upload Dark Mode Icon">
              <Upload
                {...darkThumbProps}
                accept="image/png, image/jpeg, image/svg+xml"
              >
                <Button icon={<UploadOutlined />}>Click To Upload Icon</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item name="darkmode_icon_link" label="Dark Mode Icon Link">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item label="Dark Mode Icon Preview">
              {formDarkWatch.dark_icon_link !== undefined ? (
                <Image
                  width={120}
                  height={120}
                  src={formDarkWatch.dark_icon_link}
                  alt={"Icon"}
                />
              ) : (
                <p>No Image Available For Preview</p>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
