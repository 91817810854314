import { Row, Col } from "@pankod/refine-antd";

// Components
import Stats from "components/partner-program/Stats";
import ReferredUserTable from "components/partner-program/ReferredUserTable";
import ReferralCode from "components/partner-program/ReferralCode";
import EarningsChart from "components/partner-program/EarningsChart";

const ReferredUser = () => {
  return (
    <div className="main-card referred-user">
      <Row gutter={[20, 20]}>
        <Col lg={24} md={24} sm={24}>
          <Stats />
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col
          lg={18}
          md={24}
          sm={24}
          style={{
            borderRight: "1px solid #e5e5e5",
          }}
        >
          <ReferredUserTable />
        </Col>

        <Col lg={6} md={24} sm={24}>
          <ReferralCode />
          <EarningsChart />
        </Col>
      </Row>
    </div>
  );
};

export default ReferredUser;
