import { useState } from "react";
import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  Upload,
  Icons,
  Row,
  UploadProps,
  Button,
  UploadFile,
  Progress,
  Space,
} from "@pankod/refine-antd";
import { useParams } from "react-router-dom";
import { useGetIdentity, useNotification } from "@pankod/refine-core";
import dayjs from "dayjs";
import { parse } from "papaparse";
import { GraphQLClient, gql } from "graphql-request";

// Utility
import { nhost } from "utility";

const { UploadOutlined } = Icons;

type Props = {
  modalProps: ModalProps;
  formProps: FormProps;
  close: Function;
  tableQueryResult: any;
};

export const IndexDataImport: React.FC<Props> = ({
  modalProps,
  formProps,
  close,
  tableQueryResult,
}) => {
  // Route Param
  const routeParams = useParams();

  const { open } = useNotification();
  const { data: user } = useGetIdentity();

  let token = nhost.auth.getAccessToken();

  // State
  const [csvFile, setCSVFile] = useState<UploadFile>();
  const [uploading, setUploading] = useState(false);
  const [iterationCount, setIterationCount] = useState(0);
  const [iteration, setIteration] = useState(0);

  const handleUpload = (e: any) => {
    setUploading(true);
    setIteration(0);
    setIterationCount(0);
    parse(csvFile as any, {
      complete: async ({ data }: { data: unknown[][] }) => {
        // console.log(data);
        if (data[0][0] === "Date") data.splice(0, 1);
        data = data.filter(
          (_d) => _d[1] && _d[1] !== "" && (_d[1] as string).trim() !== ""
        );
        let rec = data.map((item: Array<any>) => {
          let format = "MM/DD/YY";
          if (item[0].includes("-")) format = "DD-MM-YYYY";
          return {
            date: dayjs(item[0], format).format("YYYY-MM-DD"),
            open_price: item[1],
            high_price: item[2],
            low_price: item[3],
            close_price: item[4],
            ticker: item[5],
            symbol: item[6],
            index_id: routeParams?.id,
            created_by: user?.id,
            // close_price: item[1],
            // open_price: item[2],
            // low_price: item[3],
            // high_price: item[4],
          };
        });
        let affected_rows = 0;
        const graphQLClient = new GraphQLClient(
          `${process.env.REACT_APP_NHOST_BACKEND}/v1/graphql`,
          {
            headers: { authorization: "Bearer " + token },
          }
        );
        const mutation = gql`
          mutation InsertIndexData(
            $objects: [indiacharts_index_data_insert_input!]!
          ) {
            response: insert_indiacharts_index_data(
              objects: $objects
              on_conflict: {
                constraint: index_data_pkey
                update_columns: [close_price]
              }
            ) {
              affected_rows
            }
          }
        `;
        setIterationCount(Math.ceil(rec.length / 50));
        let c = 0;
        try {
          if (rec.length > 50) {
            let _in = rec.splice(0, 50);
            while (_in.length > 0) {
              c++;
              const result = await graphQLClient.request(mutation, {
                objects: _in,
              });
              setIteration(c);
              if (result.response && result.response.affected_rows) {
                affected_rows += Number(result.response.affected_rows);
              }
              setIteration(iteration + 1);
              _in = rec.splice(0, 50);
            }
          } else {
            c++;
            const result = await graphQLClient.request(mutation, {
              objects: rec,
            });
            setIteration(c);
            if (result.response && result.response.affected_rows) {
              affected_rows += Number(result.response.affected_rows);
            }
          }
          if (open) {
            open({
              key: `index-data-file-import`,
              message: "",
              type: "success",
              description: `Imported ${affected_rows} Records.`,
              undoableTimeout: 2000,
            });
          }
        } catch (error) {
          console.error(error);
          if (open) {
            open({
              key: `index-data-file-import`,
              message: "",
              type: "error",
              description: `Facing Issue while uploading your records.`,
              undoableTimeout: 2000,
            });
          }
        }
        setUploading(false);
        formProps.form?.resetFields();
        setCSVFile(undefined);
        close();
        tableQueryResult?.refetch();
      },
    });
  };

  const props: UploadProps = {
    maxCount: 1,
    onRemove: (file) => {
      setCSVFile(undefined);
    },
    beforeUpload: (file) => {
      setCSVFile(file);
      return false;
    },
    fileList: csvFile ? [csvFile] : undefined,
  };

  return (
    <Modal {...modalProps} title="Bulk Index Data Upload">
      <Form
        {...formProps}
        size="large"
        layout="vertical"
        onFinish={handleUpload}
      >
        <Row>
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>Upload CSV</Button>
          </Upload>
        </Row>
        <Form.Item name="created_by" initialValue={user?.id}>
          <Input type="hidden" />
        </Form.Item>
      </Form>
      {uploading ? (
        <Progress percent={Math.round((iteration / iterationCount) * 100)} />
      ) : (
        <Space></Space>
      )}
    </Modal>
  );
};
