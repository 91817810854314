import {
    Form,
    Input,
    ModalProps,
    FormProps,
    Modal,
    useSelect,
    Select, 
    Row, 
    Col, 
    DatePicker
  } from "@pankod/refine-antd";
  import { useGetIdentity } from "@pankod/refine-core";
import dayjs from "dayjs";
import { IHolidays } from "interfaces";

  type CreateHolidaysProps = {
    modalProps: ModalProps;
    formProps: FormProps;
  }

  export const CreateHolidays: React.FC<CreateHolidaysProps> = ({modalProps, formProps}) =>{

    const {data:user} = useGetIdentity();

    const {selectProps} = useSelect<IHolidays>({
        resource: "indiacharts_holidays",
        optionLabel: "Exchange",
        optionValue: "exchange_code",
        metaData:{
            fields:[
                "exchange_code"
            ]
        },
            onSearch: (value) => [
            {
                field: "exchange_code",
                operator: "eq",
                value,
            },
        ],
    })

    return(
        <Modal {...modalProps} title="Create Holiday" >
            <Form {...formProps} size="large" layout="vertical">
                <Row gutter={[16,0]}>
                <Col xs={8} lg={8}>
                        <Form.Item
                            label="Exchange"
                            name="exchange_code"
                            rules={[{ required: true }]}
                        >
                            <Select {...selectProps}/>
                        </Form.Item>
                    </Col>
                    <Col xs={8} lg={8}>
                        <Form.Item
                            label="Date"
                            name="holiday_date"
                            rules={[{ required: true }]}
                            initialValue={dayjs()}
                        >
                            <DatePicker/>
                        </Form.Item>
                    </Col>
                    <Col xs={8} lg={8}>
                        <Form.Item 
                            label="Purpose"
                            name="purpose"
                            rules={[{ required: true }]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                        <Form.Item name="created_at" initialValue={dayjs()} hidden >
                            <Input type="hidden"/>
                        </Form.Item>
                        <Form.Item name="modified_at" initialValue={dayjs()} hidden >
                            <Input type="hidden"/>
                        </Form.Item>
                </Row>
            </Form>
        </Modal>
    )
  }