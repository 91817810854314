import React from "react";
import { useParams } from "react-router-dom";
import { useShow } from "@pankod/refine-core";
import { PageHeader, ListButton, Tabs } from "@pankod/refine-antd";

// Interface
import { IIndicator } from "interfaces";

// Component
import { IndicatorDataList } from "./indicator_data_list";

export const ShowIndicator: React.FC = () => {
  const routeParams = useParams();

  // Indicator Data By ID
  const { queryResult } = useShow<IIndicator>({
    resource: "indiacharts_indicators",
    id: routeParams?.id,
    metaData: {
      fields: [
        "id",
        "name",
        "overbought",
        "oversold",
        "indexes",
        "created_at",
        "created_by",
        "updated_at",
        "updated_by",
      ],
    },
  });

  return (
    <>
      <PageHeader
        title={queryResult?.data?.data?.name}
        onBack={() => window.history.back()}
        extra={[<ListButton />]}
        footer={
          <Tabs>
            <Tabs.TabPane tab="Indicator Data" key="item-1">
              <IndicatorDataList />
            </Tabs.TabPane>
          </Tabs>
        }
      ></PageHeader>
    </>
  );
};
