import React from "react";
import {
  Table,
  useTable,
  List,
  RefreshButton,
  useModalForm,
  Space,
  EditButton,
  CreateButton,
  Icons,
  DeleteButton,
} from "@pankod/refine-antd";
import { useParams } from "react-router-dom";
import { useShow } from "@pankod/refine-core";

// Interface
import { IBroadIndustry, ISectors } from "interfaces";

// Component
import { CreateBroadIndustry } from "./broad_industry_create";
import { EditBroadIndustry } from "./broad_industry_edit";

// Icons
const { PlusOutlined } = Icons;

export const BroadIndustryList: React.FC = () => {
  // Route Param
  const routeParams = useParams();

  // Sector By ID
  const { queryResult: sectorQueryResult } = useShow<ISectors>({
    resource: "indiacharts_sector",
    id: routeParams?.id,
    metaData: {
      fields: ["id", "name", "code"],
    },
  });

  // Broad Industry Table
  const { tableProps, tableQueryResult } = useTable<IBroadIndustry>({
    resource: "indiacharts_broad_industry",
    metaData: {
      fields: ["id", "name", "code", "sector_code"],
    },
    initialPageSize: 20,
    initialSorter: [
      {
        field: "name",
        order: "asc",
      },
    ],
    permanentFilter: [
      {
        field: "sector_code",
        operator: "eq",
        value: sectorQueryResult?.data?.data?.code,
      },
    ],
  });

  // Create
  const {
    formProps: createFormProps,
    modalProps: createModalProps,
    show: createShowModal,
  } = useModalForm({
    resource: "indiacharts_broad_industry",
    action: "create",
    redirect: false,
    metaData: {
      fields: ["id", "name", "code", "sector_code"],
    },
  });

  // Edit
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShowModal,
  } = useModalForm({
    resource: "indiacharts_broad_industry",
    redirect: false,
    action: "edit",
    metaData: {
      fields: ["id", "name", "code"],
    },
  });

  return (
    <>
      {/* List */}
      <List
        breadcrumb={false}
        title={""}
        headerProps={{
          extra: [
            <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
            <CreateButton
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => createShowModal()}
            />,
          ],
        }}
      >
        <Table
          {...tableProps}
          rowKey={(record, index) => record.id}
          size="small"
        >
          <Table.Column<IBroadIndustry> dataIndex="name" title="Name" />
          <Table.Column<IBroadIndustry> dataIndex="code" title="Code" />
          <Table.Column<IBroadIndustry>
            title="Actions"
            dataIndex="actions"
            key="actions"
            render={(_, record: IBroadIndustry) => (
              <Space>
                {/* Edit Button */}
                <EditButton
                  hideText
                  size="small"
                  recordItemId={record.id}
                  onClick={() => editShowModal(record.id)}
                />

                {/* Delete Button */}
                <DeleteButton
                  hideText
                  size="small"
                  type="link"
                  recordItemId={record.id}
                  resourceNameOrRouteName="indiacharts_broad_industry"
                />
              </Space>
            )}
          />
        </Table>

        {/* Create */}
        <CreateBroadIndustry
          formProps={createFormProps}
          modalProps={createModalProps}
          sector_code={sectorQueryResult?.data?.data?.code}
        />

        {/* Edit */}
        <EditBroadIndustry
          modalProps={editModalProps}
          formProps={editFormProps}
        />
      </List>
    </>
  );
};
