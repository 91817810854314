import dayjs from "dayjs";

// Utility
import { formatDate, formatNumbers } from "utility";

// Earning Years

export const earningYears = Array.from({ length: 5 }, (_, i) => ({
  label: dayjs().year() - i,
  value: dayjs().year() - i,
}));

// Past Transactions Filter

export const statusDropdown = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Inactive",
    value: "inactive",
  },
];

export const dateRangeDropdown = [
  {
    label: "Past Month",
    value: "past-month",
  },
  {
    label: "Past 6 Month",
    value: "past-6-month",
  },
  {
    label: "Past Year",
    value: "past-year",
  },
  {
    label: "All Time",
    value: "all-time",
  },
  {
    label: "Custom",
    value: "custom",
  },
];

export const getDateRange = (dateType: string) => {
  let startDate = null;

  switch (dateType) {
    case "past-month":
      startDate = dayjs().subtract(1, "month");
      break;
    case "past-6-month":
      startDate = dayjs().subtract(6, "month");
      break;
    case "past-year":
      startDate = dayjs().subtract(1, "year");
      break;
    case "all-time":
      startDate = null;
      break;
    default:
      startDate = null;
      break;
  }

  return {
    startDate: dayjs(startDate).format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  };
};

// Past Transaction
export const pastTransactiontableHeader = [
  {
    field: "date",
    headerName: "DATE",
    headerClass: "ag-header-start",
    cellRenderer: (params: any) => formatDate(params?.value),
    flex: 1,
  },
  {
    field: "purchasedBy",
    headerName: "PURCHASED BY",
    headerClass: "ag-header-start",
    flex: 1,
  },
  {
    field: "planName",
    headerName: "PLAN",
    headerClass: "ag-header-start",
    flex: 1,
  },
  {
    field: "amount",
    headerName: "PURCHASE PRICE",
    headerClass: "ag-right-aligned-header",
    cellClass: "ag-right-aligned-cell",
    flex: 1,
    cellRenderer: (params: any) =>
      formatNumbers({
        value: parseFloat(params?.value),
        prefix: "₹",
        isThousandSeparated: true,
        fixedTo: 2,
      }),
  },
  {
    field: "commission",
    headerName: "COMMISSION EARNED",
    headerClass: "ag-right-aligned-header",
    cellClass: "ag-right-aligned-cell",
    flex: 1,
    cellRenderer: (params: any) =>
      formatNumbers({
        value: parseFloat(params?.value),
        prefix: "₹",
        isThousandSeparated: true,
        fixedTo: 2,
      }),
  },
];

// Settlement History

export const settlementPaidHistoryHeader = [
  {
    field: "created_at",
    headerName: "PAID AT",
    headerClass: "ag-header-start",
    flex: 1,
    cellRenderer: (params: any) =>
      dayjs(params?.value).format("DD MMM YYYY hh:mm A"),
  },
  {
    field: "amount",
    headerName: "PURCHASE PRICE",
    headerClass: "ag-right-aligned-header",
    cellClass: "ag-right-aligned-cell",
    flex: 1,
    cellRenderer: (params: any) =>
      formatNumbers({
        value: parseFloat(params?.value),
        prefix: "₹",
        isThousandSeparated: true,
        fixedTo: 2,
      }),
  },
];
