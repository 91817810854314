import { useState } from "react";
import {
  Form,
  ModalProps,
  FormProps,
  Modal,
  Select,
  Row,
  Col,
  Upload,
  Button,
  Icons,
  UploadProps,
  FormInstance,
  useSelect,
  Image,
} from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";
import { Input } from "antd";

// Interface
import { IAnalysts } from "interfaces";

// Util
import { nhost } from "utility/nhost";
import moment from "moment";
import { DatePicker } from "antd";
import type { RangePickerProps } from "antd/es/date-picker";

type Props = {
  modalProps: ModalProps;
  formProps: FormProps;
  form: FormInstance;
  closeModal: any;
};

const { UploadOutlined } = Icons;
const { TextArea } = Input;

export const CreateWebinars: React.FC<Props> = ({
  modalProps,
  formProps,
  form,
  closeModal,
}) => {
  const { data: user } = useGetIdentity();

  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  // State
  const [fileList, setFileList] = useState<any>([]);
  const [selectedHostAt, setSelectedHostAt] = useState<any>();
  const [selectedDuration, setSelectedDuration] = useState();

  // Form Watch
  const formWatch = {
    presenter_id: Form.useWatch("presenter_id", formProps.form),
    duration: Form.useWatch("duration", formProps.form),
    host_at: Form.useWatch("host_at", formProps.form),
    thumbnail: Form?.useWatch("thumbnail", formProps.form),
  };

  // from duration
  function setEndAt(duration: any, hostAtValue: any) {
    const originalDate = hostAtValue;
    const minutesToAdd = duration;
    const newDate = moment(originalDate, "YYYY-MM-DD HH:mm:ss").add(
      minutesToAdd,
      "minutes"
    );
    // console.log("New Date :::", newDate.format("YYYY-MM-DD HH:mm:ss"));
    form?.setFieldsValue({ end_at: newDate });
  }

  // from host_at
  function checkTime(x: any) {
    let currTime = moment().format("HH:mm");
    let currDate = moment().toDate().toString().slice(8, 10);
    let selectedDate = x._d.toString().slice(8, 10);
    let selectedHr = Number(x._d.toString().slice(16, 18));
    let selectedMin = Number(x._d.toString().slice(19, 21));
    let currHrTime = Number(currTime.slice(0, 2));
    let currMinTime = Number(currTime.slice(3, 5));
    setSelectedHostAt(x);
    if (selectedDate === currDate) {
      if (selectedHr < currHrTime) {
        alert("Please select future time");
      }
      if (selectedHr === currHrTime && selectedMin < currMinTime) {
        alert("Please select future time");
      }
    }
  }

  // Date and time restriction of host_at
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
  };

  // Image Upload
  const handleImageUpload = async (file: File) => {
    let fileres = await nhost.storage.upload({ file, bucketId: "public" });
    const imageUrl = await nhost.storage.getPublicUrl({
      fileId: fileres.fileMetadata?.id || "",
    });
    form.setFieldsValue({ thumbnail: imageUrl });
    return imageUrl;
  };

  const thumbProps: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      handleImageUpload(file);
      return false;
    },
    fileList,
  };

  const { selectProps, queryResult } = useSelect<IAnalysts>({
    resource: "indiacharts_analysts",
    metaData: {
      fields: ["name", "id", "image_url"],
    },
    optionLabel: "name",
    optionValue: "id",
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value: `%${value}%`,
      },
    ],
  });

  const { selectProps: webinarCategorySelectProps } = useSelect<IAnalysts>({
    resource: "indiacharts_webinar_categories",
    metaData: {
      fields: ["category", "id"],
    },
    optionLabel: "category",
    optionValue: "id",
    // filters: [
    //   {
    //     field: "is_active",
    //     operator: "eq",
    //     value: true,
    //   },
    // ],
    onSearch: (value) => [
      {
        field: "category",
        operator: "contains",
        value: `%${value}%`,
      },
    ],
  });

  const presenter = queryResult?.data?.data?.filter(
    (e) => e?.id === formWatch?.presenter_id
  );
  const presenter_id = presenter?.[0]?.id;

  return (
    <Modal
      {...modalProps}
      title="Create Webinar"
      onCancel={() => {
        formProps?.form?.resetFields();
        closeModal();
        setFileList([]);
      }}
    >
      <Form
        {...formProps}
        onFinish={(data: any) => {
          if (formProps.onFinish) {
            formProps?.onFinish({
              ...data,
              created_by: user?.id,
              updated_by: user?.id,
            });
            setFileList([]);
          }
        }}
        size="large"
        layout="vertical"
        initialValues={{ created_by: user?.id, updated_by: user?.id }}
        onAbort={() => {
          formProps?.form?.resetFields();
          closeModal();
        }}
      >
        <Row gutter={[16, 0]}>
          <Col xs={12} lg={12}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                },
                {
                  // pattern: new RegExp("^\\S*$"), // No Space Allowed
                  pattern: new RegExp(/^[^\s]/), // Check For Space At Beginning Only
                  message: "No spaces allowed",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} lg={12}>
            <Form.Item
              label="Host at "
              name="host_at"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker
                format="YYYY-MM-DD HH:mm"
                showTime
                // disabledDate={disabledDate}
                onOk={(value: any) => checkTime(value)}
                onChange={(value: any) => {
                  form?.resetFields(["duration"]);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={24} lg={24}>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                { required: true },
                {
                  pattern: new RegExp(/^[^\s]/), // Check For Space At Beginning Only
                  message: "No spaces allowed",
                },
              ]}
            >
              <TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Link"
              name="link"
              rules={[
                { required: true },
                {
                  pattern: new RegExp(/^[^\s]/), // Check For Space At Beginning Only
                  message: "No spaces allowed",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Presenter"
              name="presenter"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                {...selectProps}
                showSearch
                onSelect={(e: any, v: any) => {
                  const url = queryResult?.data?.data?.find((o) => o.id === e);
                  form.setFields([
                    {
                      name: "presenter",
                      value: v.label,
                    },
                    {
                      name: "presenter_img",
                      value: url?.image_url,
                    },
                    {
                      name: "presenter_id",
                      value: v.value,
                    },
                  ]);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              name="thumbnail"
              label="Thumbnail"
              rules={[{ required: false }]}
            >
              <Upload {...thumbProps}>
                <Button icon={<UploadOutlined />}>Select File To Upload</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item label="Thumbnail Preview" name="presenter_img">
              {formWatch.thumbnail !== undefined ? (
                <Image
                  width={120}
                  height={120}
                  src={formWatch.thumbnail}
                  alt={"Icon"}
                />
              ) : (
                <p>No Image Available For Preview</p>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Duration(minutes)"
              name="duration"
              rules={[
                { required: true },
                {
                  pattern: new RegExp(/^[^\s]/), // Check For Space At Beginning Only
                  message: "No spaces allowed",
                },
              ]}
            >
              <Input
                onChange={(e: any) => {
                  setSelectedDuration(e?.target?.value);
                  setEndAt(e?.target?.value, selectedHostAt);
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={8} lg={8}>
            <Form.Item
              label="Category"
              name="category_id"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select showSearch {...webinarCategorySelectProps} />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item name="end_at" label="End at">
              <DatePicker disabled showTime format={"YYYY-MM-DD HH:mm"} />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item name="presenter_img" label="Presenter image" hidden>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Link source"
              name="link_source"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select>
                <option key="Youtube" value="Youtube">
                  Youtube
                </option>
                <option key="Others" value="Others">
                  Others
                </option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={8} lg={8}>
            <Form.Item
              label="Recorded"
              name="recorded"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={[
                  {
                    value: true,
                    label: "True",
                  },
                  {
                    value: false,
                    label: "False",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Active"
              name="active"
              rules={[
                {
                  required: false,
                },
              ]}
              initialValue={true}
            >
              <Select
                options={[
                  {
                    value: true,
                    label: "True",
                  },
                  {
                    value: false,
                    label: "False",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Presenter id"
              name="presenter_id"
              rules={[
                {
                  required: false,
                },
              ]}
              hidden
            >
              <Input value={presenter_id} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
