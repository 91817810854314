import { IResourceComponentsProps } from "@pankod/refine-core";

import { Create, Form, Input, useForm, Row, Col } from "@pankod/refine-antd";

 
import { useGetIdentity } from "@pankod/refine-core";


import { IQuery } from "interfaces";



const { TextArea } = Input;

export const CreateQuery: React.FC<IResourceComponentsProps> = () => {
  const {  formProps, saveButtonProps } = useForm<IQuery>();
  const { data: user } = useGetIdentity();


  return (
    <Create saveButtonProps={saveButtonProps} >
      <Form  {...formProps} size="large" layout="vertical">
        <Row gutter={[24, 0]}>
          <Col xs={12} lg={12}>
            <Form.Item
              label="Name "
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

          </Col>
          <Col xs={12} lg={12}>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

          </Col>
         <Col xs={24} lg={24}>
            <Form.Item
              label="Config"
              name="config"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <TextArea     />
            </Form.Item>
              
          </Col>
        

        </Row>

        


        <Form.Item name="created_by"
          initialValue={user?.id}
        >
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Create>
  );
};
