import { useState, useMemo, useEffect } from "react";
import { Typography, Select } from "@pankod/refine-antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import dayjs from "dayjs";
import { useResource } from "@pankod/refine-core";
import { useQuery as useReactQuery } from "@tanstack/react-query";

// Config
import { earningYears } from "components/partner-program/config";
import getPartnerProgramEarningsChartOptions from "./partnerProgramChartConfig";

// API
import { getMonthlyEarnings } from "rest";

const { Text } = Typography;

const EarningsChart = () => {
  // URL Params
  const { id: partner_id } = useResource();

  // State
  const [earningYear, setEarningYear] = useState(dayjs().format("YYYY"));
  const [chartData, setChartData] = useState([]);

  // API
  const { data: earningsData } = useReactQuery({
    queryKey: [
      "GET_MONTHLY_EARNINGS",
      {
        year: earningYear,
        partner_id: partner_id,
      },
    ],
    queryFn: getMonthlyEarnings,
    refetchOnWindowFocus: false,
    enabled: true,
  });

  // Chart Options
  const chartOptions = useMemo(() => {
    return getPartnerProgramEarningsChartOptions({
      data: chartData,
    });
  }, [chartData]);

  // UseEffect
  useEffect(() => {
    if (Array.isArray(earningsData)) {
      let array: any = [
        {
          name: earningYear,
          color: "#00AEAE",
          data: earningsData.map((i) => [
            dayjs(i?.bucket).valueOf(),
            parseFloat(i?.earnings),
          ]),
        },
      ];
      setChartData(array);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [earningsData, earningYear]);

  return (
    <div className="earnings-chart">
      <div className="menu">
        <Text strong>Earnings</Text>
        <Select
          style={{ width: "150px" }}
          onChange={(e: any) => {
            setEarningYear(e);
          }}
          value={earningYear}
        >
          {earningYears?.map((i: any, idx: number) => (
            <option key={idx} value={i?.value}>
              {i?.label}
            </option>
          ))}
        </Select>
      </div>

      <div className="chart">
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </div>
    </div>
  );
};

export default EarningsChart;
