import React, { useState } from "react";
import {
  CreateButton,
  DateField,
  EditButton,
  Icons,
  List,
  RefreshButton,
  ShowButton,
  Space,
  Table,
  getDefaultSortOrder,
  message,
  useModalForm,
  useTable,
  useSelect,
  Select,
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useUpdate } from "@pankod/refine-core";

// Interface
import { IDiscounts } from "interfaces";

// Components
import DiscountCreate from "./create";
import CustomTag from "components/CustomTag";
import DiscountEdit from "./edit";

// Icons
const {
  PlusOutlined,
  CloseCircleOutlined,
  CheckOutlined,
  //   CheckCircleOutlined,
} = Icons;

const DiscountList: React.FC<IResourceComponentsProps> = () => {
  // State
  const [filterValue, setfilterValue] = useState<any>();

  const { mutate: update } = useUpdate();

  // Broker List For Filter Search
  const {
    selectProps: selectBrokerList,
    // queryResult: brokerQueryResult
  } = useSelect({
    resource: "indiacharts_brokers",
    metaData: {
      fields: ["id", "label"],
    },
    pagination: { pageSize: 99999 },
    optionValue: "id",
    optionLabel: "label",
    onSearch: (value) => [
      {
        field: "label",
        operator: "contains",
        value: `%${value}%`,
      },
    ],
  });

  // const brokerOptions: any = brokerQueryResult?.data?.data?.map(
  //   (item: any) => ({
  //     label: item?.label,
  //     value: item?.id,
  //   })
  // );

  // const options = [
  //   {
  //     label: "Strike",
  //     id: "strike",
  //   },
  //   ...brokerOptions,
  // ];

  // Discounts Table
  const { tableProps, sorter, setFilters, tableQueryResult } =
    useTable<IDiscounts>({
      resource: "indiacharts_discounts",
      metaData: {
        fields: [
          "id",
          "active",
          "name",
          "start_date",
          "expiry_date",
          "discount_type",
          "discount_value",
          "broker_id",
          {
            plan_mapping: ["id", "duration"],
          },
          {
            brokers: ["label"],
          },
        ],
      },
      initialSorter: [
        {
          field: "created_at",
          order: "asc",
        },
      ],
    });

  // Discounts Create
  const {
    formProps: createFormProps,
    modalProps: createModalProps,
    show: createShow,
    close: createClose,
  } = useModalForm({
    resource: "indiacharts_discounts",
    action: "create",
    metaData: {
      fields: [
        "id",
        "name",
        "description",
        "plan_id",
        "plan_mapping_id",
        "discount_type",
        "discount_value",
        "start_date",
        "expiry_date",
        "broker_id",
      ],
    },
    redirect: false,
    successNotification: false,
  });

  // Discounts Edit
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "indiacharts_discounts",
    action: "edit",
    metaData: {
      fields: [
        "id",
        "name",
        "description",
        "plan_id",
        "plan_mapping_id",
        "discount_type",
        "discount_value",
        "start_date",
        "expiry_date",
        "broker_id",
      ],
    },
    redirect: false,
    successNotification: false,
  });

  // Delete Plan
  const deletePlan = async (id: string, data: boolean) => {
    await update(
      {
        resource: "indiacharts_discounts",
        id,
        values: {
          active: data,
        },
        successNotification: false,
      },
      {
        onSuccess: () => {
          message.success("Discount Deactivated Successfully");
        },
      }
    );
  };

  return (
    <List
      pageHeaderProps={{
        extra: [
          <span>Filter:</span>,
          <Select
            {...selectBrokerList}
            // options={options}
            allowClear
            showSearch
            style={{ width: "200px" }}
            value={filterValue}
            onChange={(e: any) => {
              if (e === "all") {
                setFilters([
                  {
                    field: "broker_id",
                    operator: "eq",
                    value: null,
                  },
                ]);
              } else
                setFilters([
                  {
                    field: "broker_id",
                    operator: "eq",
                    value: e,
                  },
                ]);
            }}
            placeholder="Filter By Broker"
          />,
          <RefreshButton
            onClick={() => {
              setfilterValue(null);
              setFilters([
                {
                  field: "broker_id",
                  operator: "eq",
                  value: undefined,
                },
              ]);
              tableQueryResult?.refetch();
            }}
          />,
          <CreateButton
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => createShow()}
          />,
        ],
      }}
    >
      <Table size="small" {...tableProps}>
        <Table.Column<IDiscounts>
          dataIndex="name"
          title="Name"
          render={(_, record) => (
            <Space>
              <ShowButton
                size="small"
                type="text"
                icon={null}
                // resource="indiacharts_discounts" recordItemId={record?.id}
              >
                {record?.name}
              </ShowButton>
            </Space>
          )}
        />
        <Table.Column
          dataIndex="active"
          title="Active"
          render={(value) => (
            <CustomTag type={value} text={value ? "Active" : "In Active"} />
          )}
        />
        <Table.Column
          dataIndex="plan_mapping"
          title="Plan Duration"
          render={(record) => {
            return <>{record?.duration}</>;
          }}
        />
        <Table.Column
          dataIndex="start_date"
          title="Start Date"
          render={(value) => <DateField value={value} format="ll" />}
          defaultSortOrder={getDefaultSortOrder("start_date", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="expiry_date"
          title="Expiry Date"
          render={(value) => <DateField value={value} format="ll" />}
          defaultSortOrder={getDefaultSortOrder("start_date", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="discount_value"
          title="Disount Value"
          render={(value) => <>{value}</>}
        />
        <Table.Column
          dataIndex="discount_type"
          title="Disount Type"
          render={(value) => <>{value}</>}
        />
        <Table.Column
          dataIndex="brokers"
          title="Broker"
          render={(value) => {
            return <>{value?.label === undefined ? "Strike" : value?.label}</>;
          }}
        />
        <Table.Column
          title="Actions"
          // dataIndex="actions"
          render={(record) => {
            // console.log("record", record);
            return (
              <Space>
                <EditButton
                  hideText
                  size="small"
                  type="link"
                  recordItemId={record?.id}
                  onClick={() => editShow(record?.id)}
                />
                <EditButton
                  hideText
                  size="small"
                  type="link"
                  icon={
                    record?.active ? <CloseCircleOutlined /> : <CheckOutlined />
                  }
                  recordItemId={record.id}
                  onClick={() =>
                    deletePlan(record?.id, record?.active ? false : true)
                  }
                />
              </Space>
            );
          }}
        />
      </Table>
      <DiscountCreate
        formProps={createFormProps}
        modalProps={createModalProps}
        onClose={createClose}
      />
      <DiscountEdit formProps={editFormProps} modalProps={editModalProps} />
    </List>
  );
};

export default DiscountList;
