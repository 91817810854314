import { gql } from "graphql-request";
import { nhost } from "../utility/nhost";
import dayjs from "dayjs";

const SENTIMENT_HEADER_INDICATOR = gql`
  query MyQuery($_contains: jsonb!) {
    indiacharts_indicators(
      order_by: { sortno: asc_nulls_first }
      where: { headers: { _contains: $_contains } }
    ) {
      id
      name
      overbought
      oversold
      indexes
      headers
      isactive
      created_at
      updated_at
      updated_by
      db_field
      sortno
      overbought_zones
      oversold_zones
      category
      plot_type
      dual_color_enabled
    }
  }
`;

const GET_USERS = gql`
  query getUsers(
    $where: users_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [users_order_by!]
  ) {
    users(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      id
      displayName
      phoneNumber
      email
      defaultRole
      createdAt
      updatedAt
      disabled
    }
    usersAggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const getPlanMaps = async () => {
  let QUERY = ` query{
        indiacharts_plan_mappings{
          id
          cost
        }
      }
    `;
  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const getAllRoles = async () => {
  let QUERY = `query {
    authRoles(where:{role:{_nin:["user","me"]}}) {
    role,
  }
}`;
  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const getFiles = async () => {
  let query = `
  query MyQuery {
    files {
      id
      mimeType
    }
  }
  `;
  const { data } = await nhost.graphql.request(query);
  return data;
};

const GET_USERS_EMAIL = gql`
  query getEmails($where: users_bool_exp, $limit: Int, $offset: Int) {
    users(limit: $limit, offset: $offset, where: $where) {
      email
      id
      displayName
      phoneNumber
    }
  }
`;

const GET_INDUSTRY = gql`
  query getIndustries {
    indiacharts_industries {
      broad_industry_code
      code
      id
      name
    }
  }
`;

const GET_TICKETS_STATE_COUNTS = gql`
  query myQuery($state: String) {
    indiacharts_customer_tickets_aggregate(
      where: { state: { _eq: $state }, is_deleted: false }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const GET_ACTIVE_PAGES = gql`
  query getpages($active: Boolean!) {
    indiacharts_pages(where: { active: { _eq: $active } }) {
      id
      active
      name
      pods(where: { active: { _eq: $active } }) {
        id
        active
        name
        sub_headers(where: { active: { _eq: $active } }) {
          id
          active
          name
        }
      }
    }
  }
`;

const GET_OPEN_TICKETS_COUNT = gql`
  query getOpenTickets {
    indiacharts_customer_tickets_aggregate(
      where: { state: { _eq: "open" }, is_deleted: { _eq: false } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export async function getOpenTicketsCount() {
  const { data, error } = await nhost.graphql.request(GET_OPEN_TICKETS_COUNT);
  if (!error) {
    return data;
  } else {
    throw error;
  }
}

export async function getPages(active: boolean) {
  const { data, error } = await nhost.graphql.request(GET_ACTIVE_PAGES, {
    active,
  });
  if (!error) {
    return data;
  } else {
    throw error;
  }
}

export async function getTicketStateCounts(state: any) {
  const { data, error } = await nhost.graphql.request(
    GET_TICKETS_STATE_COUNTS,
    { state }
  );
  if (!error) {
    return data;
  } else {
    throw error;
  }
}

export async function getIndustry() {
  const { data, error } = await nhost.graphql.request(GET_INDUSTRY);
  if (!error) {
    return data;
  } else {
    throw error;
  }
}

export async function getUserEmails(variables: any) {
  const { data, error } = await nhost.graphql.request(
    GET_USERS_EMAIL,
    variables
  );
  if (!error) {
    return data;
  } else {
    throw error;
  }
}

export async function getSentimentHeaderIndicator(_contains: any) {
  const { data, error } = await nhost.graphql.request(
    SENTIMENT_HEADER_INDICATOR,
    {
      _contains,
    }
  );
  if (!error) {
    return data;
  } else {
    throw error;
  }
}

export const getUsers = async (variables: any) => {
  const { data, error } = await nhost.graphql.request(GET_USERS, variables);
  if (!error) {
    return data;
  } else {
    throw error;
  }
};

export const getSubsDetail = async () => {
  const GET_SUBS_DETAILS = gql`
    query getSubsDetails2 {
      duration_1_month: indiacharts_subscriptions_aggregate(
        where: {
          _and: {
            active: { _eq: true }
            plan_mapping: { duration: { _eq: "1" } }
          }
        }
      ) {
        aggregate {
          count
        }
      }
      duration_6_month: indiacharts_subscriptions_aggregate(
        where: {
          _and: {
            active: { _eq: true }
            plan_mapping: { duration: { _eq: "6" } }
          }
        }
      ) {
        aggregate {
          count
        }
      }
      duration_1_year: indiacharts_subscriptions_aggregate(
        where: {
          _and: {
            active: { _eq: true }
            plan_mapping: { duration: { _eq: "12" } }
          }
        }
      ) {
        aggregate {
          count
        }
      }
      duration_2_year: indiacharts_subscriptions_aggregate(
        where: {
          _and: {
            active: { _eq: true }
            plan_mapping: { duration: { _eq: "24" } }
          }
        }
      ) {
        aggregate {
          count
        }
      }
      duration_5_year: indiacharts_subscriptions_aggregate(
        where: {
          _and: {
            active: { _eq: true }
            plan_mapping: { duration: { _eq: "60" } }
          }
        }
      ) {
        aggregate {
          count
        }
      }
      freeSubs: indiacharts_subscriptions_aggregate(
        where: { plan: { name: { _eq: "free" } } }
      ) {
        aggregate {
          count
        }
      }
      totalUsers: usersAggregate(
        where: { roles: { role: { _eq: "customer" } } }
      ) {
        aggregate {
          count
        }
      }
    }
  `;
  const { data, error } = await nhost.graphql.request(GET_SUBS_DETAILS);
  if (!error) {
    return data;
  } else {
    throw error;
  }
};

export const getPlanMappingByPk = async (variables: any) => {
  const GET_PLAN_MAPPINGS_BY_PK = gql`
    query getPlanmappings($where: indiacharts_plan_mappings_bool_exp) {
      mappings: indiacharts_plan_mappings(where: $where) {
        id
        active
        duration
        cost
        plan_id
      }
    }
  `;
  const { data, error } = await nhost.graphql.request(
    GET_PLAN_MAPPINGS_BY_PK,
    variables
  );
  if (!error) {
    return data;
  } else {
    throw error;
  }
};

export const getUsersCounts = async (gte: string, lte: string) => {
  const GET_USER_COUNT = gql`
    query MyQuery($gte: timestamptz, $lte: timestamptz) {
      users(where: { createdAt: { _gte: $gte, _lte: $lte } }) {
        createdAt
        email
      }
    }
  `;
  const { data, error } = await nhost.graphql.request(GET_USER_COUNT, {
    gte,
    lte,
  });
  if (!error) {
    return data;
  } else {
    throw error;
  }
};

export const getActiveSubsDetails = async (todaysDate: string) => {
  const GET_ACTIVE_NON_ACTIVE_TODAY_SUBS = gql`
    query getActiveSubsDetails($todaysDate: timestamptz) {
      active_true: indiacharts_subscriptions_aggregate(
        where: {
          _and: { active: { _eq: true }, plan: { is_free: { _eq: false } } }
        }
        distinct_on: user_id
      ) {
        aggregate {
          count
        }
      }
      trial_period_users: indiacharts_subscriptions_aggregate(
        where: {
          _and: { active: { _eq: true }, plan: { is_free: { _eq: true } } }
        }
        distinct_on: user_id
      ) {
        aggregate {
          count
        }
      }
      active_false: indiacharts_subscriptions_aggregate(
        where: { active: { _eq: false } }
        distinct_on: user_id
      ) {
        aggregate {
          count
        }
      }
      todays_subs: indiacharts_subscriptions_aggregate(
        where: { created_at: { _gte: $todaysDate } }
      ) {
        aggregate {
          count
        }
      }
      open_tickets_count: indiacharts_customer_tickets_aggregate(
        where: { state: { _eq: "open" }, is_deleted: { _eq: false } }
      ) {
        aggregate {
          count
        }
      }
      totalUsers: usersAggregate(
        where: { roles: { role: { _eq: "customer" } } }
      ) {
        aggregate {
          count
        }
      }
    }
  `;
  const { data, error } = await nhost.graphql.request(
    GET_ACTIVE_NON_ACTIVE_TODAY_SUBS,
    {
      todaysDate,
    }
  );
  if (!error) {
    return data;
  } else {
    throw error;
  }
};

//get selected user's last plan expiry date
export const getSelectedUserLastPlanExpiryDate = async (id: string) => {
  const GET_SELECTED_USER_LAST_PLAN_EXPIRY_DATE = gql`
    query getSelectedUsersLastPlanExpiryDate($id: uuid!) {
      indiacharts_subscriptions(
        order_by: { expiry_date: desc }
        limit: 1
        where: { user_id: { _eq: $id } }
      ) {
        expiry_date
      }
    }
  `;
  const { data, error }: any = await nhost.graphql.request(
    GET_SELECTED_USER_LAST_PLAN_EXPIRY_DATE,
    {
      id,
    }
  );
  if (!error) {
    return (
      data?.indiacharts_subscriptions?.[0] || {
        expiry_date: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
      }
    );
  } else {
    throw error;
  }
};

export const getTotalPayments = async () => {
  const GET_TOTAL_PAYMENTS = gql`
    query getTotalPayments {
      aggr: indiacharts_payments_aggregate(
        where: { status: { _eq: "captured" } }
      ) {
        aggregate {
          sum {
            amount
          }
        }
      }
    }
  `;
  const { data, error }: any = await nhost.graphql.request(GET_TOTAL_PAYMENTS);
  if (!error) {
    return data?.aggr?.aggregate?.sum?.amount;
  } else {
    throw error;
  }
};

export const getUserEmailByID = async (id: any) => {
  const GET_USER_EMAIL_BY_ID = gql`
    query GetUserEmailByID($id: uuid!) {
      user(id: $id) {
        email
      }
    }
  `;

  const { data, error }: any = await nhost.graphql.request(
    GET_USER_EMAIL_BY_ID,
    {
      id,
    }
  );
  if (!error) {
    return data?.user;
  } else {
    throw error;
  }
};
