import { CrudFilters, IResourceComponentsProps } from "@pankod/refine-core";
import {
  useTable,
  List,
  RefreshButton,
  Table,
  useModalForm,
  Icons,
  Space,
  DateField,
  TextField,
  TagField,
  Menu,
  EmailField,
  Form,
  Col,
  Input,
  Button,
  MenuProps,
} from "@pankod/refine-antd";
import { ICustomerTicket } from "interfaces";
import { nhost } from "../../utility/nhost";
import { gql } from "graphql-request";
import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { EditTicketStatus } from "./editStatus";

// ANTD
import { Badge } from "antd";
import { useEffect, useState } from "react";

// Debounce
import debounce from "lodash/debounce";

// use navigation
import { useNavigation } from "@pankod/refine-core";
import { setCurrentPagination, setCustomerTicketsTab } from "store/user/userSlice";
import { useDispatch, useSelector } from "react-redux";

export const CustomerTicketsList: React.FC<IResourceComponentsProps> = () => {
  const { push } = useNavigation();
  const location = useLocation();
  // const [current, setCurrent] = useState("all");
  
  const { customerTicketsTab,currentPagination } = useSelector((state: any) => state.User);
  const dispatch = useDispatch();


  const isAccessedDirectly = location.pathname === "/tickets/customer_tickets";

  const [initialPageSize, setInitialPageSize] = useState(
    isAccessedDirectly ? 50 : 5
  );

  const { tableProps, setFilters, tableQueryResult, sorter, searchFormProps } =
    useTable<ICustomerTicket>({
      initialCurrent: currentPagination, 
   
       resource: "indiacharts_customer_tickets",
      initialPageSize: initialPageSize,
            metaData: {
        fields: [
          "id",
          "customer_id",
          { customer: ["email", "phoneNumber"] },
          "category",
          { enum_ticket_category: ["name", "comments"] },
          "type",
          { enum_ticket_type: ["name", "comments"] },
          "descriptions",
          "state",
          { enum_ticket_status: ["name", "comments", "hex_color"] },
          "title",
          "uid",
          "rejection_reason",
          "created_by",
          "updated_by",
          "created_at",
          "updated_at",
          "is_deleted",
          "handler_id",
          { creator: ["email"] },
          { modifier: ["email"] },
          { handler: ["id", "displayName"] },
        ],
      },
            permanentSorter: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
      initialFilter: [
        {
          field: "is_deleted",
          operator: "eq",
          value: "false",
        },
      ],
      onSearch: (values: any) => {
        const searchArray: CrudFilters = [
          {
            field: "customer.email",
            operator: "startswith",
            value: values?.input,
          },
          {
            field: "title",
            operator: "startswith",
            value: values.input,
          },
          {
            field: "handler.displayName",
            operator: "startswith",
            value: values.input,
          },
          {
            field:"descriptions",
            operator:"contains",
            value: `%${values.input.toLowerCase()}%`
          }
        ];

        const parentSearchArray: CrudFilters = [
          {
            operator: "or",
            value: searchArray,
          },
        ];

        if (!isNaN(parseInt(values.input))) {
          searchArray.push({
            field: "uid",
            operator: "eq",
            value: parseInt(values.input),
          });
        }
        return parentSearchArray;
      },
    });

  const onMenuClick: MenuProps["onClick"] = (e: any) => {
    console.log("click ", e);
    // setCurrent(e.key);
    dispatch(setCustomerTicketsTab(e.key))
  };

  const handleDebouncedSearch = debounce(() => {
    searchFormProps?.form?.submit();
  }, 500);

  // Get ticket status
  const getTicketStatuses = async () => {
    let newData: any;
    let newQuery = gql`
      query {
        indiacharts_enum_ticket_status {
          name
          comments
          hex_color
          customer_tickets_aggregate(where: { is_deleted: { _eq: false } }) {
            aggregate {
              count
            }
          }
        }
        indiacharts_customer_tickets_aggregate {
          aggregate {
            count
          }
        }
      }
    `;
    const { data } = await nhost.graphql.request(newQuery);
    newData = data;
    return newData;
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["statusCounts"],
    queryFn: () => getTicketStatuses(),
  });

  const {
    formProps: editStatusFormProps,
    modalProps: editStatusModalProps,
    show: editStatusShow,
  } = useModalForm({
    resource: "indiacharts_customer_tickets",
    redirect: false,
    action: "edit",
    metaData: {
      fields: [
        "id",
        "customer_id",
        "category",
        "type",
        "descriptions",
        "state",
        "handler_id",
        { handler: ["id", "displayName"] },
        // "title",
        // "rejection_reason",
        // "created_by",
        // "updated_by",
        // "created_at",
        // "updated_at",
        // "creator"
      ],
    },
  });


  useEffect(()=>{
    const {pagination}:any = tableProps
 dispatch(setCurrentPagination(pagination?.current))
  
  },[JSON.stringify(tableProps)])

  useEffect(()=>{
    if(customerTicketsTab==="all"){
      setFilters([], "replace")

    }
    else{

      setFilters([
        {
          field: "state",
          operator: "eq",
          value:customerTicketsTab ,
        },
      ]);
    }
  

  },[customerTicketsTab])


return (
    <List
      headerProps={{
        extra: [<RefreshButton onClick={() => tableQueryResult?.refetch()} />],
      }}
      title="Customer Tickets"
    >
      <Form {...searchFormProps}>
        <Col span={6}>
          <Form.Item name="input">
            <Input
              onChange={() => {
                handleDebouncedSearch();
              }}
              placeholder="Search ticket"
              prefix={<Icons.SearchOutlined />}
            />
          </Form.Item>
        </Col>
      </Form>
      <Menu
        selectedKeys={[customerTicketsTab]}
        mode="horizontal"
        onClick={onMenuClick}
        onSelect={(props:any) => {
          dispatch(setCustomerTicketsTab( props.key))
          if (props.key == "all") {
            setFilters([], "replace");
          } else {
            setFilters([
              {
                field: "state",
                operator: "eq",
                value: props.key,
              },
            ]);
          }
          tableQueryResult?.refetch();
        }}
      >
        <Menu.Item key="all">
          <Badge
            count={
              data?.indiacharts_customer_tickets_aggregate?.aggregate?.count
            }
          >
            <TagField value="All" />
          </Badge>
        </Menu.Item>
        {data?.indiacharts_enum_ticket_status.map((_d: any) => (
          <Menu.Item key={_d.name}>
            <Badge
              count={_d?.customer_tickets_aggregate?.aggregate?.count}
              showZero
              // count={2}
            >
              <TagField value={_d.comments} color={_d?.hex_color} />
            </Badge>
          </Menu.Item>
        ))}
      </Menu>
      <Table {...tableProps} rowKey="id" size="small" scroll={{ x: 1500 }} >
        <Table.Column<ICustomerTicket>
          width={90}
          dataIndex="uid"
          title="Ticket ID"
          render={(_, record) => (
            <Space>
              <span
                className="ticket-show-tag"
                onClick={() => {
                  const {pagination}:any = tableProps
                          dispatch(setCurrentPagination(pagination?.current))
                          push(`/tickets/customer_tickets/show/${record?.id}`);
                                }}
              >
                #{record?.uid}
              </span>
            </Space>
          )}
        />
          <Table.Column
          dataIndex="created_at"
          title="Created at"
          render={(value) => <DateField value={value} format="lll" />}
        />
        <Table.Column<ICustomerTicket>
          dataIndex="title"
          title="Title"
          render={(_, record) => <TextField value={record?.title} />}
        />
        <Table.Column<any>
          dataIndex="customer_id"
          title="Customer"
          render={(_, record) => <EmailField value={record?.customer?.email} />}
        />
        <Table.Column<any>
          dataIndex="phoneNumber"
          title="Phone No."
          render={(_, record) => (
            <TextField value={record?.customer?.phoneNumber} />
          )}
        />
        {/* <Table.Column<any>
          dataIndex="handler_id"
          title="Handler"
          render={(_, record) => (
            <TextField value={record?.handler?.displayName} />
          )}
        /> */}
        <Table.Column<any>
          dataIndex="category"
          title="Category"
          render={(_, record) => (
            <TextField value={record?.enum_ticket_category?.comments} />
          )}
        />
        <Table.Column<any>
          dataIndex="type"
          title="Type"
          render={(_, record) => (
            <TextField value={record?.enum_ticket_type?.comments} />
          )}
        />
        {/* <Table.Column<any>
          dataIndex="updated_by"
          title="Updated by"
          render={(_, record) => <EmailField value={record?.modifier?.email} />}
        /> */}
      
        <Table.Column<any>
          width={120}
          fixed="right"
          dataIndex="state"
          title="State"
          render={(_, record) => (
            <TagField
              value={record?.enum_ticket_status?.comments}
              color={record?.enum_ticket_status?.hex_color}
            />
          )}
        />
      </Table>
      {/* <CreateCustomerTickets modalProps={modalProps} formProps={formProps} /> */}
      {/* <EditCustomerTickets
        modalProps={editModalProps}
        formProps={editFormProps}
      /> */}
      <EditTicketStatus
        formProps={editStatusFormProps}
        modalProps={editStatusModalProps}
      />
    </List>
  );
};
