import {
  CreateButton,
  DeleteButton,
  EditButton,
  Icons,
  List,
  RefreshButton,
  Space,
  Table,
  useModalForm,
  useTable,
} from "@pankod/refine-antd";
// import { useUpdate } from "@pankod/refine-core";
import { Avatar } from "antd";

// Components
import BrokerCreate from "./create";
import BrokerEdit from "./edit";

const { PlusOutlined } = Icons;

const BrokerList = () => {
  // query for brokers
  const { tableProps, tableQueryResult } = useTable({
    resource: "indiacharts_brokers",
    metaData: {
      fields: ["id", "name", "label", "logo_url"],
    },
  });

  // create modalform
  const {
    formProps: createFormProps,
    modalProps: createModalProps,
    show: createShow,
    close: createClose,
    form,
  } = useModalForm({
    resource: "indiacharts_brokers",
    action: "create",
    metaData: {
      fields: ["id", "name", "label", "logo_url"],
    },
    redirect: false,
    successNotification: false,
  });
  //edit

  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
    form: editForm,
    close: editClose,
  } = useModalForm({
    resource: "indiacharts_brokers",
    action: "edit",
    metaData: {
      fields: ["id", "name", "label", "logo_url"],
    },
    redirect: false,
    successNotification: false,
  });

  return (
    <List
      pageHeaderProps={{
        extra: [
          <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
          <CreateButton
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => createShow()}
          />,
        ],
      }}
    >
      <Table size="small" {...tableProps}>
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column dataIndex="label" title="Label" />
        <Table.Column
          dataIndex="logo_url"
          title="Logo"
          render={(value: any) => (
            <Avatar
              style={{ objectFit: "cover" }}
              shape="square"
              size={70}
              src={value}
            />
          )}
        />
        <Table.Column
          title="Actions"
          // dataIndex="actions"
          render={(record) => {
            return (
              <Space>
                <EditButton
                  hideText
                  size="small"
                  type="link"
                  recordItemId={record?.id}
                  onClick={() => editShow(record?.id)}
                />
                <DeleteButton
                  hideText
                  size="small"
                  type="link"
                  recordItemId={record?.id}
                  resourceNameOrRouteName="indiacharts_brokers"
                />
              </Space>
            );
          }}
        />
      </Table>

      {/* Broker Create */}
      <BrokerCreate
        formProps={createFormProps}
        modalProps={createModalProps}
        onClose={createClose}
        form={form}
      />

      {/* Broker Edit */}
      <BrokerEdit
        formProps={editFormProps}
        modalProps={editModalProps}
        form={editForm}
        onClose={editClose}
      />
    </List>
  );
};

export default BrokerList;
