import { Form, Input, Row, Col } from "@pankod/refine-antd";

import { Modal, Select } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNotification } from "@pankod/refine-core";
import { useEffect, useState } from "react";
import { getAllRoles } from "graphql/queries";
import { mutatePartnerConfig } from "graphql/mutation";

type CreateUsersProps = {
  show: boolean;
  setShowModal: any;
};

export interface ISignup {
  email: string;
  password: string;
  displayName: string;
  defaultRole?: string;
  roles?: string[];
  created_by?: string;
}

export const CreateUsers: React.FC<CreateUsersProps> = ({
  show,
  setShowModal,
}) => {
  const qClient = useQueryClient();
  const [form] = Form.useForm<ISignup>();
  const { open } = useNotification();

  const formWatch = {
    commission_percentage: Form.useWatch("commission_percentage", form),
    discount_percentage: Form.useWatch("discount_percentage", form),
    free_trial_in_days: Form.useWatch("free_trial_in_days", form),
    email: Form.useWatch("email", form),
  };

  // Form Initial State
  const formInitialState = {
    displayName: "",
    email: "",
    password: "",
    defaultRole: [],
    discount_percentage: 0,
    commission_percentage: 0,
    free_trial_in_days: 0,
  };

  // State
  const [roleList, setRoleList] = useState([] as any[]);

  // UseEffect
  useEffect(() => {
    getAllRoles().then((response: any) => {
      setRoleList(response.authRoles);
    });
    // formProps?.form?.resetFields()
  }, []);

  const showNoti = open ? open : () => {};

  const createUser = async ({
    email,
    password,
    displayName,
    defaultRole,
  }: ISignup) => {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/v1/auth/signup/email-password`,
      {
        body: JSON.stringify({
          email: email,
          password: password,
          options: {
            defaultRole: defaultRole?.[0],
            displayName: displayName,
            allowedRoles: defaultRole,
          },
        }),
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    const jsonres = await res.json();
    return jsonres;
  };

  const { mutate, isLoading } = useMutation(createUser, {
    onSuccess: (data: any) => {
      // console.log(data);

      mutatePartnerConfig({
        objects: [
          {
            commission_percentage:
              parseInt(formWatch?.commission_percentage) || 0,
            discount_percentage: parseInt(formWatch?.discount_percentage) || 0,
            free_trial_in_days: parseInt(formWatch?.free_trial_in_days) || 0,
            email: formWatch?.email,
          },
        ],
      });

      showNoti({
        key: `create-user`,
        message: "",
        type: "success",
        description: `User created successfully.`,
        undoableTimeout: 2000,
      });
      qClient.invalidateQueries({ queryKey: ["get-users"] });
      setShowModal(false);
      form.resetFields();
    },
    onError: (e: any) => {
      console.log(e);
      showNoti({
        key: `create-user-error`,
        message: e?.message,
        type: "success",
        undoableTimeout: 2000,
      });
    },
  });

  return (
    <Modal
      title="Create Users"
      width={1000}
      visible={show}
      onCancel={() => setShowModal(false)}
      onOk={() => form.submit()}
      confirmLoading={isLoading}
    >
      <Form
        form={form}
        onFinish={(d) => {
          mutate(d);
        }}
        layout="vertical"
        initialValues={formInitialState}
      >
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8} md={8}>
            <Form.Item
              label="Display name"
              name="displayName"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8} md={8}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8} md={8}>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8} md={8}>
            <Form.Item
              label="Role"
              name="defaultRole"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select mode="multiple">
                {roleList.length > 0 &&
                  roleList?.map(
                    (item) =>
                      item?.role !== "user" &&
                      item?.role !== "me" && (
                        <option key={item?.role} value={item?.role}>
                          {item?.role}
                        </option>
                      )
                  )}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* For Partner Program */}
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8} md={8}>
            <Form.Item
              label="Discount Percentage"
              name="discount_percentage"
              rules={[
                // {
                //   required: true,
                // },
                {
                  pattern: new RegExp(/^\d+$/),
                  message: "Only numbers are allowed",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8} md={8}>
            <Form.Item
              label="Comission Percentage"
              name="commission_percentage"
              rules={[
                // {
                //   required: true,
                // },
                {
                  pattern: new RegExp(/^\d+$/),
                  message: "Only numbers are allowed",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8} md={8}>
            <Form.Item
              label="Free Trial ( In Days )"
              name="free_trial_in_days"
              rules={[
                // {
                //   required: true,
                // },
                {
                  pattern: new RegExp(/^\d+$/),
                  message: "Only numbers are allowed",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
