import {
    Form,
    Input,
    ModalProps,
    FormProps,
    Modal,
    InputNumber,
    useSelect,
    Select, Row, Col, DatePicker
  } from "@pankod/refine-antd";
  import { useGetIdentity } from "@pankod/refine-core";
import dayjs from "dayjs";
import { IEnumTicketStatus } from "interfaces";

  type EditEnumTicketStatusProps = {
    modalProps: ModalProps;
    formProps: FormProps;
  }

  export const EditEnumTicketStatus: React.FC<EditEnumTicketStatusProps> = ({modalProps, formProps}) =>{

    const {data:user} = useGetIdentity();

    const {selectProps} = useSelect<IEnumTicketStatus>({
        resource: "indiacharts_enum_ticket_status",
        metaData: {
            fields:["name"]
        }
    })

    return(
        <Modal {...modalProps} title="Edit Ticket Status" >
            <Form {...formProps} size="large" layout="vertical">
                <Row gutter={[16,0]}>
                <Col xs={8} lg={8}>
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{ required: true }]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col xs={8} lg={8}>
                        <Form.Item
                            label="Comments"
                            name="comments"
                            rules={[{ required: true }]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
  }