// import { useParams } from "react-router-dom";
import { useShow } from "@pankod/refine-core";
import { IIndicator } from "interfaces";
import {
  PageHeader,
  ListButton,
  Space,
  EditButton,
  useModalForm,
  Tabs,
  Table,
  useTable,
  DeleteButton,
} from "@pankod/refine-antd";

// Components
import { CreateIndicatorData } from "./create";
// import { EditIndicatorData } from "./edit";

export const ShowIndicatorData: React.FC = () => {
  // const routeParams = useParams();
  const { queryResult } = useShow<IIndicator>({
    resource: "indiacharts_indicator_data",
    metaData: {
      fields: ["value", "date", "indicator_id", "created_by", "created_at"],
    },
  });

  const { tableProps, sorter, tableQueryResult } = useTable({
    resource: "indiacharts_indicator_data",
    metaData: {
      fields: ["value", "date", "indicator_id", "created_by", "created_at"],
    },
    initialPageSize: 20,
    initialSorter: [
      {
        field: "date",
        order: "desc",
      },
    ],
  });

  const { data, isLoading } = tableQueryResult;
  const record = data?.data;

  const {
    formProps,
    modalProps,
    show: showModal,
    close: closeModal,
  } = useModalForm({
    resource: "indiacharts_indicator_data",
    action: "create",
    redirect: false,
    metaData: {
      fields: ["value", "date", "indicator_id", "created_by", "created_at"],
    },
  });
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "indiacharts_indicator_data",
    action: "edit",
    redirect: false,
    metaData: {
      fields: ["value", "date", "indicator_id", "created_by", "created_at"],
    },
  });
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
    // {
    //   title: "Indicator",
    //   dataIndex: "indicator_id",
    //   key: "indicator_id",
    // },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Actions",
      // dataIndex: 'actions',
      key: "actions",

      render: (_: any, record: any) => (
        <Space>
          <EditButton
            hideText={true}
            size="small"
            type="link"
            recordItemId={record.id}
            onClick={() => editShow(record.id)}
          />
          <DeleteButton
            hideText={true}
            size="small"
            type="link"
            recordItemId={record.id}
            onClick={() => editShow(record.id)}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <PageHeader
        title={queryResult?.data?.data?.name}
        onBack={() => window.history.back()}
        extra={[<ListButton />]}
        footer={
          <Tabs>
            <Tabs.TabPane tab="Fields" key="item-2">
              <Table
                {...tableProps}
                rowKey="id"
                size="small"
                columns={columns}
              />
              <CreateIndicatorData
                modalProps={modalProps}
                formProps={formProps}
                refetchTable={tableQueryResult}
                closeModal={closeModal}
              />
              {/* <EditIndicatorData modalProps={editModalProps} formProps={editFormProps} /> */}
            </Tabs.TabPane>
          </Tabs>
        }
      ></PageHeader>
    </>
  );
};
