import {
  List,
  useTable,
  Button,
  Table,
  CreateButton,
  useModalForm,
  DateField,
  Space,
  EditButton,
  notification,
  Col,
  Input,
  Icons,
  Select,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useGetIdentity,
  useList,
} from "@pankod/refine-core";
import debounce from "lodash/debounce";
import { useState } from "react";
import { Form, Typography, Popconfirm } from "antd";
import { ReloadOutlined } from "@ant-design/icons";

// Interface
import { IVideoResources } from "interfaces";

// Components
import { CreateVideoResource } from "./create";
import { EditVideoResource } from "./edit";

// GraphQL
import {
  mutateVideoResources,
  mutateVideoResourcesNotes,
} from "graphql/mutation";

import { Avatar } from "antd";

const { Text } = Typography;

export const VideosList: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity();

  // Icons
  const { PlusOutlined } = Icons;
  const { DeleteOutlined } = Icons;

  // State
  const [ellipsis, setEllipsis] = useState(true);
  const [selectedCategory] = useState<any>("all");
  const [rotating, setRotating] = useState(false);
  const [filterValue, setfilterValue] = useState<any>();

  const { form: tableForm, formProps: infoVideoTableForm } = useForm();

  // Table
  const { tableProps, tableQueryResult, setFilters } =
    useTable<IVideoResources>({
      resource: "indiacharts_video_resources",
      liveMode: "auto",
      metaData: {
        fields: [
          "id",
          "title",
          "description",
          "link",
          "link_source",
          "created_at",
          "updated_at",
          "created_by",
          "updated_by",
          "free",
          "duration",
          "sort_index",
          "category_id",
          "thumbnail",
          { category: ["category"] },
          { video_resources_notes: ["id", "video_id", "notes"] },
        ],
      },
      initialSorter: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
      ...(selectedCategory !== "all" && {
        permanentFilter: [
          {
            field: "category_id",
            operator: "eq",
            value: selectedCategory,
          },
        ],
      }),
    });

  // List
  const { selectProps: selectCategoryListResult } = useSelect({
    resource: "indiacharts_video_resources_categories",
    metaData: {
      fields: ["id", "category"],
    },
    pagination: { pageSize: 99999 },
    optionValue: "id",
    optionLabel: "category",
    onSearch: (value) => [
      {
        field: "category",
        operator: "contains",
        value: `%${value}%`,
      },
    ],
  });

  // Create
  const {
    formProps,
    modalProps,
    show: showModal,
    close: closeModal,
    form,
  } = useModalForm({
    resource: "indiacharts_video_resources",
    action: "create",
    redirect: false,
    metaData: {
      fields: [
        "id",
        "title",
        "host_at",
        "description",
        "link",
        "duration",
        "link_source",
        "created_at",
        "updated_at",
        "created_by",
        "updated_by",
        "free",
        "category_id",
        "thumbnail",
        "sort_index",
      ],
    },
  });

  // Edit
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
    form:editForm
  } = useModalForm({
    resource: "indiacharts_video_resources",
    action: "edit",
    redirect: false,
    metaData: {
      fields: [
        "id",
        "title",
        "description",
        "link",
        "duration",
        "host_at",
        "link_source",
        "created_at",
        "updated_at",
        "created_by",
        "updated_by",
        "free",
        "category_id",
        "thumbnail",
        "sort_index",
      ],
    },
  });

  // Handle Search
  const handleSearch = debounce((e: any) => {
    const searchValue = e;
    setFilters([
      {
        field: "title",
        operator: "contains",
        value: `%${searchValue}%`,
      },
    ]);
  }, 500);

  // Refresh Function
  const handleRefresh = () => {
    setRotating(true);

    setTimeout(() => {
      setRotating(false);
    }, 500);
  };

  const onClickButton = async (id: any) => {
    try {
      await mutateVideoResourcesNotes(id);
      await mutateVideoResources(id)
        .then(() => {
          notification.open({
            type: "success",
            message: "Successfully deleted info video!",
          });
        })
        .catch((error) => {
          notification.open({
            type: "error",
            message: "Error while deleting info video",
          });
        });
      tableQueryResult?.refetch();
    } catch (error) {
      console.error("Error occurred during mutation", error);
    }
  };

  return (
    <List
      headerProps={{
        extra: [
          <span>Filter:</span>,
          <Select
            {...selectCategoryListResult}
            showSearch
            style={{ width: "200px" }}
            value={filterValue}
            onChange={(e: any) => {
              if (e === "all") {
                setFilters([
                  {
                    field: "category_id",
                    operator: "eq",
                    value: null,
                  },
                ]);
              } else
                setFilters([
                  {
                    field: "category_id",
                    operator: "eq",
                    value: e,
                  },
                ]);
            }}
            placeholder="Filter with Category"
          />,
          // <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
          <Button
            type="default"
            icon={<ReloadOutlined spin={rotating} />}
            onClick={() => {
              handleRefresh();
              setfilterValue(null);
              setFilters([
                {
                  field: "category_id",
                  operator: "eq",
                  value: undefined,
                },
              ]);
              tableForm?.resetFields(["name"]);
              handleSearch("");
              tableQueryResult?.refetch();
            }}
          >
            Refresh
          </Button>,
          <CreateButton
            type="primary"
            onClick={() => showModal()}
            icon={<PlusOutlined />}
          />,
        ],
      }}
    >
      <Form {...infoVideoTableForm}>
        <Col span={6}>
          <Form.Item name="name">
            <Input
              onChange={(e: any) => handleSearch(e?.target?.value)}
              placeholder="Search by name"
              prefix={<Icons.SearchOutlined />}
            />
          </Form.Item>
        </Col>
      </Form>
      <Table
        {...tableProps}
        rowKey="id"
        size="small"
        //  scroll={{ x: 900 }}
      >
        <Table.Column
          width={100}
          dataIndex="title"
          title="Title"
          render={(value) => (
            <Text
              style={ellipsis ? { width: 200 } : undefined}
              ellipsis={ellipsis ? { tooltip: `${value}` } : false}
            >
              {value}
            </Text>
          )}
        />
        <Table.Column
          width={100}
          dataIndex="description"
          title="Description"
          render={(value) => (
            <Text
              style={ellipsis ? { width: 400 } : undefined}
              ellipsis={ellipsis ? { tooltip: `${value}` } : false}
            >
              {value}
            </Text>
          )}
        />
        <Table.Column
          width={100}
          dataIndex="thumbnail"
          title="Thumbnail"
          render={(value: any) => (
            <Avatar
              style={{ objectFit: "cover" }}
              shape="square"
              size={70}
              src={value}
            />
          )}
        />
        <Table.Column width={100} dataIndex="duration" title="Duration" />
        <Table.Column width={100} dataIndex="sort_index" title="Sort Order" />
        <Table.Column
          width={150}
          dataIndex={["category", "category"]}
          title="Category"
        />
        <Table.Column width={100} dataIndex="link_source" title="Link Source" />
        <Table.Column
          width={150}
          dataIndex="created_at"
          title="Created At"
          render={(value) => <DateField format="LL" value={value} />}
        />
        <Table.Column<IVideoResources>
          width={100}
          fixed="right"
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton
                hideText
                size="small"
                type="link"
                recordItemId={record.id}
                onClick={() => editShow(record.id)}
              />
              <Popconfirm
                placement="top"
                title="Delete Info Video?"
                okText="Yes"
                okButtonProps={{
                  style: {
                    backgroundColor: "transparent",
                    color: "red",
                    borderColor: "red",
                  },
                }}
                cancelText="No"
                onConfirm={() => onClickButton(record?.id)}
              >
                <Button
                  style={{ color: "red" }}
                  type="link"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Space>
          )}
        />
      </Table>

      {/* Create */}
      <CreateVideoResource
        modalProps={modalProps}
        formProps={formProps}
        refetchTable={tableQueryResult}
        closeModal={closeModal}
        currentUser={user?.id}
        form={form}
      />

      {/* Edit */}
      <EditVideoResource
        modalProps={editModalProps}
        formProps={editFormProps}
        form={editForm}
      />
    </List>
  );
};
