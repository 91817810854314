import { Tag, Icons } from "@pankod/refine-antd";
import React from "react";

const { CheckCircleOutlined, SyncOutlined, CloseCircleOutlined } = Icons;

interface CustomTagProps {
    type: any;
    text: string;
}

const CustomTag: React.FC<CustomTagProps> = ({ type, text }) => {
    return (
        <Tag
            // icon={<CheckCircleOutlined />}
            icon={type ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
            color={type ? "success" : "error"}
        >
            {text}
        </Tag>
    );
};

export default CustomTag;
