import { useEffect, useState } from "react";
import { InputNumber, Button, Typography, message } from "@pankod/refine-antd";
import {
  useQuery as useReactQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { useResource } from "@pankod/refine-core";
import { useDispatch, useSelector } from "react-redux";

// Redux
import {
  setStats,
  setSettlement,
} from "store/partner-program/partnerProgramSlice";

// API
import {
  getRefereesOverview,
  getSettlementTotalPaid,
  settlementPay,
} from "rest";

const { Text } = Typography;

const SettlementPay = () => {
  // URL Params
  const { id: partner_id } = useResource();

  // Query Client
  const queryClient = useQueryClient();

  // Dispatch
  const dispatch = useDispatch();

  // Redux
  const { email } = useSelector((store: any) => store.PartnerProgram);
  const { totalAmount } = useSelector(
    (store: any) => store.PartnerProgram?.settlement
  );

  // State
  const [amountToPay, setAmountToPay] = useState<any>(0);

  // API
  const { data, refetch: refetchOverview } = useReactQuery({
    queryKey: [
      "GET_REFEREES_OVERVIEW",
      {
        partner_id: partner_id,
      },
    ],
    queryFn: getRefereesOverview,
    refetchOnWindowFocus: false,
    enabled: true,
  });

  const { data: totalPaidData, refetch: refetchTotalPaid } = useReactQuery({
    queryKey: [
      "GET_TOTAL_PAID",
      {
        email: email,
      },
    ],
    queryFn: getSettlementTotalPaid,
    refetchOnWindowFocus: false,
    enabled: email === null ? false : true,
  });

  // Mutation
  const { mutate } = useMutation({
    mutationFn: () =>
      settlementPay([
        "SETTLEMENT_PAY",
        {
          email: email,
          amount: amountToPay,
        },
      ]),
    mutationKey: ["SETTLEMENT_PAY"],
    onSuccess: async (res: any) => {
      if (res?.message === "success") {
        await refetchOverview();
        await refetchTotalPaid();
        queryClient.removeQueries({
          queryKey: ["GET_SETTLEMENT_PAID_HISTORY"],
        });
        message.success("Amount paid successfully");
      } else {
        message.error("Failed to pay");
      }
    },
  });

  // UseEffect
  useEffect(() => {
    if (
      data !== undefined &&
      Object.keys(data).length &&
      totalPaidData?.status === true
    ) {
      dispatch(
        setStats({
          totalCommission: parseFloat(data?.totalCommission).toFixed(2),
          totalReferees: data?.totalReferees,
          monthlyAverageCommission: parseFloat(
            data?.monthlyAverageCommission
          ).toFixed(2),
        })
      );

      let calculation: any =
        parseFloat(data?.totalCommission) - parseFloat(totalPaidData?.amount);
      dispatch(
        setSettlement({
          totalAmount: parseFloat(calculation).toFixed(2),
        })
      );

      setAmountToPay(parseFloat(calculation).toFixed(2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, totalPaidData]);

  return (
    <>
      <div className="total-amount">
        <Text strong>Total Amount To Pay : </Text>
        <Text>₹{totalAmount}</Text>
      </div>
      <div className="pay-amount">
        <InputNumber
          value={amountToPay}
          onChange={(v: any) => {
            setAmountToPay(v);
          }}
        />
        <Button
          type="primary"
          disabled={
            amountToPay > totalAmount || amountToPay < 1 || totalAmount === 0
          }
          onClick={() => mutate()}
        >
          Pay
        </Button>
      </div>
    </>
  );
};

export default SettlementPay;
