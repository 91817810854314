import { Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  ReadyPage,
  ErrorComponent,
  Icons,
} from "@pankod/refine-antd";
import routerProvider from "@pankod/refine-react-router-v6";
import dataProvider from "@pankod/refine-nhost";
import { NhostAuthProvider } from "@nhost/react-auth";
import { useSelector } from "react-redux";
import { LicenseManager } from "ag-grid-enterprise";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// Style
// import "styles/antd.less";
import "styles/main.less";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
// import "ag-grid-community/styles/ag-theme-alpine-no-font.css";

// Utility
import { nhost } from "utility";

// Auth Provider
import { authProvider } from "./authProvider";

// Component
import {
  Title,
  Header,
  Sider,
  Footer,
  Layout,
  OffLayoutArea,
} from "components/layout";

// Page
import {
  LoginPage,
  WidgetList,
  PodList,
  DataFeedList,
  ShowDataFeed,
  DataFeedFieldList,
  QueryList,
  ShowQuery,
  ShowWidget,
} from "pages";
import { CreateQuery, EditQuery } from "pages/queries";
import { IndexList, ShowIndex } from "pages/indexes";
import { IndicatorList, ShowIndicator } from "pages/indicators";
import { IndexDataList, ShowxIndexData } from "pages/index_data";
import { IndicatorDataList, ShowIndicatorData } from "pages/indicator_data";
import { UsersList, PartnerProgram } from "pages/users";
import { ApplicationUpdateList } from "pages/application_updates";
import { VideosList } from "pages/video_resources";
import {
  SentimentIndicatorHeaderList,
  ShowSentimentIndicatorHeader,
} from "pages/sentiment_indicator_header";
import { CompanyDividendList } from "pages/company_dividend";
import { CompanyRightsList } from "pages/company_rights";
import { SplitsBonusList } from "pages/splits_bonus";
import {
  SubscriptionPlansList,
  ShowSubscriptionPlans,
} from "pages/subscription_plans";
import { StockNewsList } from "pages/stocks_news";
import {
  CustomerTicketsList,
  ShowCustomerTicket,
} from "pages/customer_tickets";
import { HolidaysList } from "pages/holidays";
import { EnumTicketCategoriesList } from "pages/enum_ticket_categories";
import { EnumTicketStatusList } from "pages/enum_ticket_status";
import { CompaniesList, ShowCompanies } from "pages/companies";
import { WebinarsList } from "pages/webinars";
import { EnumTicketTypeList } from "pages/enum_ticket_type";
import { AnalystsList } from "pages/analysts";
import {
  DiffusionIndicatorHeaderList,
  ShowDiffusionIndicatorHeader,
} from "pages/diffusion_headers";
import { IndustriesList } from "pages/industries";
import { BusinessGroupsList } from "pages/business_groups";
import { ShowIndustry } from "pages/industries/show";
import { SectorsList, ShowSector } from "pages/sectors";
import { BroadIndustryList, ShowBroadIndustry } from "pages/broad_industry";
import { WebinarCategoryList } from "pages/webinar_category";
import PlanList from "pages/plans/list";
import PlanManagementList from "pages/plan_management/list";
import PageList from "pages/enum_pages/list";
import PodsList from "pages/enum_pods/list";
import SubHeadersList from "pages/enum_sub_headers/list";
import ChildrenList from "pages/enum_childrens/list";
import { SubscriptionsList, UnSubscriptionsList } from "pages/subscriptions";
import DashboardList from "pages/dashboard/dashboard_list";
import { VideoCategoriesList } from "pages/video_resources_categories";
import DiscountList from "pages/discounts/list";
import { ChangePassword } from "pages/settings";

import { FiUsers } from "react-icons/fi";
import { VscCircleFilled } from "react-icons/vsc";
import {
  BsTags,
  BsTag,
  BsBuildings,
  BsNewspaper,
  BsPersonVideo,
  BsPersonVideo2,
  BsPersonVideo3,
  BsBalloon,
} from "react-icons/bs";
import { AiOutlineVideoCamera, AiOutlineVideoCameraAdd } from "react-icons/ai";
import {
  MdOutlineSubscriptions,
  MdOutlineVideoSettings,
  MdPassword,
} from "react-icons/md";

// Redux
import { setUserData } from "store/user/userSlice";
import BrokerList from "pages/brokers/list";

// ag grid licence
LicenseManager.setLicenseKey(
  "Using_this_{AG_Grid}_Enterprise_key_{AG-049425}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Indiacharts_share_tradng_Pvt_ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Indiacharts}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Indiacharts}_need_to_be_licensed___{Indiacharts}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{21_December_2024}____[v3]_[01]_MTczNDczOTIwMDAwMA==306afa5b812461af79005d43dfe8ee02"
);

// Icons
const {
  DatabaseOutlined,
  FundOutlined,
  ProjectOutlined,
  AlignLeftOutlined,
  ArrowUpOutlined,
  AccountBookOutlined,
  QuestionCircleOutlined,
  BuildOutlined,
  HomeOutlined,
  PercentageOutlined,
  SettingOutlined,
  // BorderInnerOutlined,
  // BorderTopOutlined,
  // MonitorOutlined,
  // PicLeftOutlined,
  // UsergroupAddOutlined,
  // UserOutlined,
  // VideoCameraAddOutlined,
  // DiffOutlined,
  // WarningOutlined,
  // DollarOutlined,
  // BankOutlined,
  // PlayCircleOutlined,
  // NotificationOutlined,
  // TagsOutlined,
  // TagOutlined,
  // BellOutlined,
  // VideoCameraOutlined,
  // UserSwitchOutlined,
} = Icons;

function App() {
  const queryClient = new QueryClient();

  // Redux
  const { userData } = useSelector((state: any) => state.User);

  // Roles
  const adminAccess = ["super_admin", "admin"];

  return (
    <QueryClientProvider client={queryClient}>
      <NhostAuthProvider nhost={nhost}>
        <Refine
          notificationProvider={notificationProvider}
          ReadyPage={ReadyPage}
          catchAll={<ErrorComponent />}
          routerProvider={routerProvider}
          dataProvider={dataProvider(nhost)}
          authProvider={authProvider}
          LoginPage={LoginPage}
          Title={Title}
          Header={Header}
          Sider={Sider}
          Footer={Footer}
          Layout={Layout}
          OffLayoutArea={OffLayoutArea}
          resources={[
            ...(adminAccess.includes(userData?.defaultRole)
              ? [
                  // Dashboard
                  {
                    name: "dashboard",
                    icon: <HomeOutlined />,
                    list: DashboardList,
                    options: { label: "Dashboard" },
                  },
                  // Auth
                  // { name: "auth", icon: <UsergroupAddOutlined /> },
                  {
                    name: "users",
                    icon: <FiUsers />,
                    // parentName: "auth",
                    list: UsersList,
                    show: PartnerProgram, // Partner Program
                  },
                ]
              : []),

            // Subscriptions
            // {
            //   name: "subscriptions",
            //   options: { label: "Subscriptions" },
            //   list: SubscriptionsList,
            //   icon: <MdOutlineSubscriptions />,
            // },

            { name: "subscription", icon: <MdOutlineSubscriptions /> },

            {
              name: "subscriptions",
              parentName: "subscription",
              options: { label: "All Subscriptions" },
              list: SubscriptionsList,
              icon: <MdOutlineSubscriptions />,
            },

            {
              name: "unsubscriptions",
              parentName: "subscription",
              options: { label: "Unsubscription Report" },
              list: UnSubscriptionsList,
              icon: <MdOutlineSubscriptions />,
            },

            ...(adminAccess.includes(userData?.defaultRole)
              ? [
                  // Headers
                  { name: "headers", icon: <AccountBookOutlined /> },

                  // Tickets
                  { name: "tickets", icon: <BsTags /> },
                  {
                    name: "customer_tickets",
                    options: { label: "All Tickets" },
                    parentName: "tickets",
                    list: CustomerTicketsList,
                    icon: <VscCircleFilled />,
                    show: ShowCustomerTicket,
                  },
                  {
                    name: "ticket_categories",
                    options: { label: "Categories" },
                    parentName: "tickets",
                    list: EnumTicketCategoriesList,
                    icon: <VscCircleFilled />,
                  },
                  {
                    name: "ticket_status",
                    options: { label: "Status" },
                    parentName: "tickets",
                    list: EnumTicketStatusList,
                    icon: <VscCircleFilled />,
                  },
                  {
                    name: "ticket_type",
                    options: { label: "Types" },
                    parentName: "tickets",
                    list: EnumTicketTypeList,
                    icon: <VscCircleFilled />,
                  },

                  // Indicators
                  {
                    name: "indicators",
                    options: { label: "Indicators" },
                    icon: <ProjectOutlined />,
                  },
                  {
                    name: "sentiment_headers",
                    options: { label: "Sentiment Headers" },
                    icon: <VscCircleFilled />,
                    parentName: "indicators",
                    list: SentimentIndicatorHeaderList,
                    show: ShowSentimentIndicatorHeader,
                  },
                  {
                    name: "diffusion_headers",
                    options: { label: "Diffusion Headers" },
                    icon: <VscCircleFilled />,
                    parentName: "indicators",
                    list: DiffusionIndicatorHeaderList,
                    show: ShowDiffusionIndicatorHeader,
                  },
                  {
                    name: "indicators_list",
                    options: { label: "Indicators" },
                    icon: <VscCircleFilled />,
                    parentName: "indicators",
                    list: IndicatorList,
                    show: ShowIndicator,
                  },
                  {
                    name: "indicator_data",
                    options: { label: "Indicator Data" },
                    icon: <VscCircleFilled />,
                    parentName: "indicators",
                    list: IndicatorDataList,
                    show: ShowIndicatorData,
                  },
                  {
                    name: "webinars",
                    options: { label: "Webinars" },
                    icon: <AiOutlineVideoCamera />,
                    // parentName: "help",
                    list: WebinarsList,
                  },

                  // Layout
                  // { name: "layout", icon: <BorderInnerOutlined /> },
                  // {
                  //   name: "widgets",
                  //   list: WidgetList,
                  //   icon: <FundOutlined />,
                  //   parentName: "layout",
                  //   show: ShowWidget,
                  // },
                  // {
                  //   name: "pods",
                  //   list: PodList,
                  //   icon: <BorderTopOutlined />,
                  //   parentName: "layout",
                  // },

                  // Queries
                  // {
                  //   name: "queries",
                  //   list: QueryList,
                  //   show: ShowQuery,
                  //   create: CreateQuery,
                  //   edit: EditQuery,
                  //   icon: <MonitorOutlined />,
                  // },

                  // Datafeeds
                  // {
                  //   name: "datafeeds",
                  //   list: DataFeedList,
                  //   show: ShowDataFeed,
                  //   icon: <DatabaseOutlined />,
                  // },

                  //{ name: "datafeed_fields", list: DataFeedFieldList }

                  // Help
                  {
                    name: "help",
                    options: { label: "Help" },
                    icon: <QuestionCircleOutlined />,
                  },
                  {
                    name: "video_resources",
                    options: { label: "Info Videos" },
                    icon: <VscCircleFilled />,
                    parentName: "help",
                    list: VideosList,
                  },
                  {
                    name: "video_resources_categories",
                    options: { label: "Info Videos Categories" },
                    icon: <VscCircleFilled />,
                    parentName: "help",
                    list: VideoCategoriesList,
                  },
                  // {
                  //   name: "webinars",
                  //   options: { label: "Webinars" },
                  //   icon: <VideoCameraAddOutlined />,
                  //   // parentName: "help",
                  //   list: WebinarsList,
                  // },
                  {
                    name: "webinar_category",
                    options: { label: "Webinar Category" },
                    icon: <VscCircleFilled />,
                    parentName: "help",
                    list: WebinarCategoryList,
                  },
                  {
                    name: "analysts",
                    options: { label: "Analysts" },
                    icon: <VscCircleFilled />,
                    parentName: "help",
                    list: AnalystsList,
                  },
                  {
                    name: "system_updates",
                    options: { label: "System Updates" },
                    icon: <VscCircleFilled />,
                    parentName: "help",
                    list: ApplicationUpdateList,
                  },
                  // // Company
                  // {
                  //   name: "company",
                  //   options: { label: "Company" },
                  //   icon: <BankOutlined />,
                  // },
                  // {
                  //   name: "company_dividend",
                  //   options: { label: "Company Dividend" },
                  //   icon: <DiffOutlined />,
                  //   parentName: "company",
                  //   list: CompanyDividendList,
                  // },
                  // {
                  //   name: "split_bonus",
                  //   options: { label: "Split Bonus" },
                  //   icon: <DollarOutlined />,
                  //   parentName: "company",
                  //   list: SplitsBonusList,
                  // },
                  // {
                  //     name: "subscription_plans",
                  //     options: { label: "Subscriptions" },
                  //     icon: <PlayCircleOutlined />,
                  //     parentName: "company",
                  //     list: SubscriptionPlansList,
                  //     show: ShowSubscriptionPlans,
                  // },
                  // {
                  //   name: "rights",
                  //   options: { label: "Rights" },
                  //   icon: <WarningOutlined />,
                  //   parentName: "company",
                  //   list: CompanyRightsList,
                  // },

                  // Master Data
                  {
                    name: "master_data",
                    icon: <DatabaseOutlined />,
                    options: { label: "Master Data" },
                  },
                  {
                    name: "indices",
                    options: { label: "Index" },
                    icon: <VscCircleFilled />,
                    parentName: "master_data",
                    list: IndexList,
                    show: ShowIndex,
                  },
                  {
                    name: "index_data",
                    options: { label: "Index Data" },
                    icon: <VscCircleFilled />,
                    parentName: "master_data",
                    list: IndexDataList,
                    show: ShowxIndexData,
                  },
                  {
                    name: "holidays",
                    options: { label: "Holidays" },
                    icon: <VscCircleFilled />,
                    parentName: "master_data",
                    list: HolidaysList,
                  },
                  {
                    name: "industries",
                    options: { label: "Industries" },
                    icon: <VscCircleFilled />,
                    parentName: "master_data",
                    list: IndustriesList,
                    show: ShowIndustry,
                  },
                  {
                    name: "business_group",
                    options: { label: "Business Groups" },
                    icon: <VscCircleFilled />,
                    parentName: "master_data",
                    list: BusinessGroupsList,
                  },
                  {
                    name: "sectors",
                    options: { label: "Sectors" },
                    icon: <VscCircleFilled />,
                    parentName: "master_data",
                    list: SectorsList,
                    show: ShowSector,
                  },
                  {
                    name: "broad_industry",
                    options: { label: "Broad Industry" },
                    icon: <VscCircleFilled />,
                    parentName: "master_data",
                    list: BroadIndustryList,
                    show: ShowBroadIndustry,
                  },
                  {
                    name: "companies",
                    options: { label: "Companies" },
                    icon: <BsBuildings />,
                    // parentName: "company",
                    list: CompaniesList,
                    show: ShowCompanies,
                  },
                  {
                    name: "stock_news",
                    options: { label: "Stock News" },
                    icon: <BsNewspaper />,
                    // parentName: "help",
                    list: StockNewsList,
                  },
                  // Subscription Plans
                  {
                    name: "subscription_plan",
                    icon: <MdOutlineVideoSettings />,
                    options: { label: "Subscription Settings" },
                  },
                  {
                    name: "subscription_plans",
                    options: {
                      label: "Plans",
                    },
                    icon: <VscCircleFilled />,
                    parentName: "subscription_plan",
                    list: PlanList,
                  },
                  {
                    name: "plan_mappings",
                    options: {
                      label: "Plan Configuration",
                    },
                    icon: <VscCircleFilled />,
                    parentName: "subscription_plan",
                    list: PlanManagementList,
                  },
                  {
                    name: "pages",
                    options: {
                      label: "Pages",
                    },
                    icon: <VscCircleFilled />,
                    parentName: "subscription_plan",
                    list: PageList,
                  },
                  {
                    name: "pods",
                    options: {
                      label: "Pods",
                    },
                    icon: <VscCircleFilled />,
                    parentName: "subscription_plan",
                    list: PodsList,
                  },
                  {
                    name: "sub_headers",
                    options: {
                      label: "Sub headers",
                    },
                    icon: <VscCircleFilled />,
                    parentName: "subscription_plan",
                    list: SubHeadersList,
                  },
                  {
                    name: "childrens",
                    options: {
                      label: "Childrens",
                    },
                    icon: <VscCircleFilled />,
                    parentName: "subscription_plan",
                    list: ChildrenList,
                  },
                  {
                    name: "discounts",
                    options: {
                      label: "Discounts",
                    },
                    parentName: "subscription_plan",
                    icon: <VscCircleFilled />,
                    list: DiscountList,
                  },
                  {
                    name: "brokers",
                    options: {
                      label: "Brokers",
                    },
                    parentName: "subscription_plan",
                    icon: <VscCircleFilled />,
                    list: BrokerList,
                  },

                  // Settings
                  { name: "settings", icon: <SettingOutlined /> },
                  {
                    name: "change_password",
                    icon: <VscCircleFilled />,
                    parentName: "settings",
                    list: ChangePassword,
                    options: { label: "Change Password" },
                  },
                ]
              : []),
          ]}
        />
      </NhostAuthProvider>
    </QueryClientProvider>
  );
}

export default App;
