import { useState } from "react";
import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  InputNumber,
  useSelect,
  Select,
  Row,
  Col,
  Radio,
} from "@pankod/refine-antd";
import { useCreate, useGetIdentity } from "@pankod/refine-core";

// Interface
import { IIndicator, IIndices, ISentimentIndicatorHeader } from "interfaces";

// Webhook
import { sentimentIndicatorAPI, diffusionIndicatorAPI } from "utility/webhook";

type Props = {
  modalProps: ModalProps;
  formProps: FormProps;
  refetchTable: any;
  closeModal: any;
  currentHeader: any;
};

export const CreateIndicator: React.FC<Props> = ({
  modalProps,
  formProps,
  closeModal,
  refetchTable,
  currentHeader,
}) => {
  const { data: user } = useGetIdentity();

  // Overbought
  const [ob, setOb] = useState<any>([
    { title: "Overbought 1", value: "", color: "#ff0000" },
    { title: "Overbought 2", value: "", color: "#00ff00" },
  ]);

  // Oversold
  const [os, setOs] = useState<any>([
    { title: "Oversold 1", value: "", color: "#0000ff" },
    { title: "Oversold 2", value: "", color: "#ffff00" },
  ]);

  // Over sold and bought colors
  const ObOsColors = [
    {
      key: "red",
      value: "#ff0000",
      label: "Red",
    },
    {
      key: "blue",
      value: "#0000ff",
      label: "Blue",
    },
    {
      key: "green",
      value: "#00ff00",
      label: "Green",
    },
    {
      key: "yellow",
      value: "#ffff00",
      label: "Yellow",
    },
    {
      key: "orange",
      value: "#ff6600",
      label: "Orange",
    },
    {
      key: "cyan",
      value: "#00ffff",
      label: "Cyan",
    },
    {
      key: "violet",
      value: "#9317B5",
      label: "Violet",
    },
    {
      key: "pink",
      value: "#ff0066",
      label: "Pink",
    },
  ];

  // Plot types
  const plotTypes = [
    {
      key: "line",
      value: "line",
      label: "Line",
    },
    {
      key: "histogram",
      value: "histogram",
      label: "Histogram",
    },
    {
      key: "cross",
      value: "cross",
      label: "Cross",
    },
    {
      key: "area",
      value: "area",
      label: "Area",
    },
    {
      key: "columns",
      value: "columns",
      label: "Columns",
    },
    {
      key: "circles",
      value: "circles",
      label: "Circles",
    },
    {
      key: "line_with_breaks",
      value: "line_with_breaks",
      label: "Line with Breaks",
    },
    {
      key: "area_with_breaks",
      value: "area_with_breaks",
      label: "Area with Breaks",
    },
    {
      key: "step_line",
      value: "step_line",
      label: "Step Line",
    },
  ];

  const { selectProps } = useSelect<IIndices>({
    resource: "indiacharts_indices",
    optionLabel: "name",
    optionValue: "id",
    fetchSize: 10,
    metaData: {
      fields: ["id", "name"],
    },
    filters: [
      {
        field: "ic_active",
        operator: "eq",
        value: true,
      },
    ],
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value: `%${value}%`,
      },
    ],
  });

  const { selectProps: headerSelector } = useSelect<ISentimentIndicatorHeader>({
    resource: "indiacharts_sentiment_indicator_headers",
    optionLabel: "label",
    optionValue: "id",
    fetchSize: 999,
    metaData: {
      fields: ["id", "label"],
    },
    onSearch: (value) => [
      {
        field: "label",
        operator: "startswith",
        value: `%${value}%`,
      },
    ],
  });

  const { mutateAsync: createIndicator } = useCreate<IIndicator>();

  // Form Submit
  const onFinish = async (formData: IIndicator) => {
    console.log(formData);
    await createIndicator({
      resource: "indiacharts_indicators",
      values: {
        name: formData?.name,
        headers: formData?.headers,
        category: "sentiment",
        plot_type: formData?.plot_type,
        overbought_zones: ob,
        oversold_zones: os,
        indexes: formData?.indexes,
        sortno: formData?.sortno,
        isactive: formData?.isactive,
        db_field: formData?.db_field,
        created_by: user?.id,
        updated_by: user?.id,
      },
    }).then(() => {
      formProps?.form?.resetFields();
      refetchTable();
      sentimentIndicatorAPI();
      diffusionIndicatorAPI();
      closeModal();
    });
  };

  return (
    <Modal
      {...modalProps}
      title="Create Indicator"
      onCancel={() => {
        formProps?.form?.resetFields();
        closeModal();
      }}
    >
      <Form {...formProps} size="large" layout="vertical" onFinish={onFinish}>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                { required: true },
                {
                  // pattern: new RegExp("^\\S*$"), // No Space Allowed
                  pattern: new RegExp(/^[^\s]/), // Check For Space At Beginning Only
                  message: "No spaces allowed",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Headers"
              name="headers"
              rules={[{ required: false }]}
              initialValue={currentHeader}
            >
              <Select
                {...headerSelector}
                mode="multiple"
                defaultValue={currentHeader}
              />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Category"
              name="category"
              rules={[{ required: false }]}
            >
              <Select defaultValue={`sentiment`}>
                <option key="diffusion" value="diffusion">
                  diffusion
                </option>
                <option key="sentiment" value="sentiment">
                  sentiment
                </option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Active"
              name="isactive"
              rules={[{ required: false }]}
              initialValue={true}
            >
              <Radio.Group defaultValue={true}>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Dual color enabled"
              name="dual_color_enabled"
              rules={[{ required: false }]}
              initialValue={false}
            >
              <Radio.Group defaultValue={false}>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Plot type"
              name="plot_type"
              rules={[{ required: true }]}
              initialValue={true}
            >
              <Select>
                {plotTypes.map((item: any) => (
                  <option key={item?.key} value={item?.value}>
                    {item?.label}
                  </option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Linked Index"
              name="indexes"
              rules={[{ required: true }]}
            >
              <Select showSearch {...selectProps} mode="multiple" />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item label="Sorting No" name="sortno">
              <InputNumber style={{ width: "100%" }} type="number" />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item name="db_field" label="DB field">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item name="overbought_zone1_title" label="Overbought title 1">
              <Input
                defaultValue="Overbought 1"
                onChange={(e: any) => {
                  const updatedOb = [...ob];
                  updatedOb[0].title = e?.target?.value;
                  setOb(updatedOb);
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={8} lg={8}>
            <Form.Item name="overbought_zone1_color" label="Overbought color 1">
              <Select
                defaultValue="#ff0000"
                onSelect={(e: any) => {
                  const updatedOb = [...ob];
                  updatedOb[0].color = e;
                  setOb(updatedOb);
                }}
              >
                {ObOsColors.map((item: any) => (
                  <option key={item?.key} value={item?.value}>
                    {item?.label}
                  </option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item name="overbought_zone1_value" label="Overbought value 1">
              <InputNumber
                onChange={(e: any) => {
                  const updatedOb = [...ob];
                  updatedOb[0].value = e;
                  setOb(updatedOb);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item name="oversold_zone1_title" label="Oversold title 1">
              <Input
                defaultValue="Oversold 1"
                onChange={(e: any) => {
                  const updatedOs = [...os];
                  updatedOs[1].title = e?.target?.value;
                  setOb(updatedOs);
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={8} lg={8}>
            <Form.Item name="oversold_zone1_color" label="Oversold color 1">
              <Select
                defaultValue="#0000ff"
                onChange={(e: any) => {
                  const updatedOs = [...os];
                  updatedOs[0].color = e;
                  setOs(updatedOs);
                }}
              >
                {ObOsColors.map((item: any) => (
                  <option key={item?.key} value={item?.value}>
                    {item?.label}
                  </option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item name="oversold_zone1_value" label="Oversold value 1">
              <InputNumber
                onChange={(e: any) => {
                  const updatedOs = [...os];
                  updatedOs[0].value = e;
                  setOs(updatedOs);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item name="overbought_zone2_title" label="Overbought title 2">
              <Input
                defaultValue="Overbought 2"
                onChange={(e: any) => {
                  const updatedOb = [...ob];
                  updatedOb[1].title = e?.target?.value;
                  setOb(updatedOb);
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={8} lg={8}>
            <Form.Item name="overbought_zone2_color" label="Overbought color 2">
              <Select
                defaultValue="#00ff00"
                onChange={(e: any) => {
                  const updatedOb = [...ob];
                  updatedOb[1].color = e;
                  setOb(updatedOb);
                }}
              >
                {ObOsColors.map((item: any) => (
                  <option key={item?.key} value={item?.value}>
                    {item?.label}
                  </option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item name="overbought_zone2_value" label="Overbought value 2">
              <InputNumber
                onChange={(e: any) => {
                  const updatedOb = [...ob];
                  updatedOb[1].value = e;
                  setOb(updatedOb);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item name="oversold_zone2_title" label="Oversold title 2">
              <Input
                defaultValue="Oversold 2"
                onChange={(e: any) => {
                  const updatedOs = [...os];
                  updatedOs[1].value = e?.target?.value;
                  setOs(updatedOs);
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={8} lg={8}>
            <Form.Item name="oversold_zone2_color" label="Oversold color 2">
              <Select
                defaultValue="#ffff00"
                onChange={(e: any) => {
                  const updatedOs = [...os];
                  updatedOs[1].color = e;
                  setOs(updatedOs);
                }}
              >
                {ObOsColors.map((item: any) => (
                  <option key={item?.key} value={item?.value}>
                    {item?.label}
                  </option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item name="oversold_zone2_value" label="Oversold value 2">
              <InputNumber
                onChange={(e: any) => {
                  const updatedOs = [...os];
                  updatedOs[1].value = e;
                  setOs(updatedOs);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="created_by" initialValue={user?.id}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="updated_by" initialValue={user?.id}>
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
