import { getActiveSubsDetails } from "graphql/queries";
import React, { useEffect, useState } from "react";

import dayjs from "dayjs";

import { LoadingOutlined } from "@ant-design/icons";
const currentDate = dayjs().format("YYYY-MM-DD");

const DashboardSubscribers = () => {
  const [isLoadingActive, setIsLoadingActive] = useState(true);
  const [activeSubscribersData, setActiveSubscribersData] = useState<any>([]);

  // Loading Elements =================================================================>

  const loadingActiveElement = (
    <LoadingOutlined style={{ fontSize: 50 }} spin />
  );

  useEffect(() => {
    getActiveSubsDetails(currentDate).then((res: any) => {
      setActiveSubscribersData(res);
      setIsLoadingActive(false);
    });
  }, []);

  return (
    <div className="subscribers-container">
      <div className="subscribers-container-box">
        <div className="subscribers-container-box-text">
          <div className="subscribers-container-box-text-value">
            {isLoadingActive
              ? loadingActiveElement
              : activeSubscribersData?.active_true?.aggregate?.count}
          </div>
          <div className="subscribers-container-box-text-header">
            Total Active Paid Subscribers
          </div>
        </div>
      </div>
      <div className="subscribers-container-box">
        <div className="subscribers-container-box-text">
          <div className="subscribers-container-box-text-value">
            {isLoadingActive
              ? loadingActiveElement
              : activeSubscribersData?.trial_period_users?.aggregate?.count}
          </div>
          <div className="subscribers-container-box-text-header">
            Total Trial Period Users
          </div>
        </div>
      </div>
      <div className="subscribers-container-box">
        <div className="subscribers-container-box-text">
          <div className="subscribers-container-box-text-value">
            {isLoadingActive
              ? loadingActiveElement
              : activeSubscribersData?.totalUsers?.aggregate?.count -
                activeSubscribersData?.active_true?.aggregate?.count}
          </div>
          <div className="subscribers-container-box-text-header">
            Non-subscribed Users
          </div>
        </div>
      </div>
      <div className="subscribers-container-box">
        <div className="subscribers-container-box-text">
          <div className="subscribers-container-box-text-value">
            {/* {isLoadingActive ? loadingActiveElement : activeCounts[0]} */}
            {isLoadingActive
              ? loadingActiveElement
              : activeSubscribersData?.todays_subs?.aggregate?.count}
          </div>
          <div className="subscribers-container-box-text-header">
            Subscribers Today
          </div>
        </div>
      </div>
      <div className="subscribers-container-box">
        <div className="subscribers-container-box-text">
          <div className="subscribers-container-box-text-value">
            {isLoadingActive
              ? loadingActiveElement
              : activeSubscribersData?.open_tickets_count?.aggregate?.count}
          </div>
          <div className="subscribers-container-box-text-header">
            Total Open Tickets
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardSubscribers;
