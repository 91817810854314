import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  useSelect,
  Select,
  Row,
  Col,
} from "@pankod/refine-antd";
import { IEnumTicketStatus } from "interfaces";

type EditTicketStatusProps = {
  modalProps: ModalProps;
  formProps: FormProps;
};

export const EditTicketStatus: React.FC<EditTicketStatusProps> = ({
  modalProps,
  formProps,
}) => {
  const { selectProps: selectTicketStatus } = useSelect<IEnumTicketStatus>({
    resource: "indiacharts_enum_ticket_status",
    metaData: {
      fields: ["name", "comments"],
    },
    optionLabel: "comments",
    optionValue: "name",
    ...(formProps?.initialValues?.state === "open" && {
      filters: [
        {
          operator: "or",
          value: [
            {
              field: "name",
              operator: "eq",
              value: "in_progress",
            },
            {
              field: "name",
              operator: "eq",
              value: "resolved",
            },
            {
              field: "name",
              operator: "eq",
              value: "not_viable",
            },
            {
              field: "name",
              operator: "eq",
              value: "open",
            },
          ],
        },
      ],
    }),
    ...(formProps?.initialValues?.state === "in_progress" && {
      filters: [
        {
          operator: "or",
          value: [
            {
              field: "name",
              operator: "eq",
              value: "resolved",
            },
            {
              field: "name",
              operator: "eq",
              value: "closed",
            },
            {
              field: "name",
              operator: "eq",
              value: "cancelled",
            },
            {
              field: "name",
              operator: "eq",
              value: "reported",
            },
            {
              field: "name",
              operator: "eq",
              value: "in_progress",
            },
          ],
        },
      ],
    }),

    ...(formProps?.initialValues?.state === "cancelled" && {
      filters: [
        {
          field: "name",
          operator: "eq",
          value: "cancelled",
        },
      ],
    }),
    ...(formProps?.initialValues?.state === "closed" && {
      filters: [
        {
          field: "name",
          operator: "eq",
          value: "closed",
        },
      ],
    }),
    ...(formProps?.initialValues?.state === "resolved" && {
      filters: [
        {
          field: "name",
          operator: "eq",
          value: "closed",
        },
        {
          field: "name",
          operator: "eq",
          value: "resolved",
        },
      ],
    }),
  });

  return (
    <Modal {...modalProps} title="Edit Customer Ticket" destroyOnClose={true}>
      <Form {...formProps} size="large" layout="vertical">
        {/* <Row gutter={[16, 0]}>
                    <Col xs={8} lg={8}>
                        <Form.Item
                            label="Type"
                            name="type"
                            rules={[{ required: true }]}
                        >
                            <Select {...selectTicketType} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 0]}>
                    <Col>
                        <Form.Item
                            label="Description"
                            name="descriptions"
                            rules={[{ required: false }]}
                        >
                            <TextArea cols={100} rows={6} />
                        </Form.Item>
                    </Col>
                </Row> */}
        <Row gutter={[16, 0]}>
          {/* <Col xs={8} lg={8}>
            <Form.Item
              label="Assign to"
              name="handler_id"
              rules={[{ required: false }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  (option?.label ?? "").includes(input)
                }
              >
                {data?.users?.map((item: any) => (
                  <Select.Option
                    label={item?.email}
                    key={item?.email}
                    value={item?.id}
                  >
                    {item?.email}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col> */}
          <Col xs={8} lg={8}>
            <Form.Item label="State" name="state" rules={[{ required: true }]}>
              <Select {...selectTicketStatus} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
