import {
  CreateButton,
  List,
  RefreshButton,
  Icons,
  Table,
  useTable,
  DateField,
  getDefaultSortOrder,
  useModalForm,
  message,
  useStepsForm,
  Space,
  EditButton,
  DeleteButton,
} from "@pankod/refine-antd";
import CustomTag from "components/CustomTag";
import React, { useState } from "react";
import { transformers } from "utility/transforms";
import PlanCreation from "./create";
import { useQuery } from "@tanstack/react-query";
import { getPages } from "graphql/queries";
import PlanEdit from "./edit";
import { useUpdate } from "@pankod/refine-core";

const { PlusOutlined, CloseCircleOutlined, CheckOutlined } = Icons;

const PlanList: React.FC = () => {
  const { mutate: update } = useUpdate();
  const { tableProps, tableQueryResult, sorter } = useTable({
    resource: "indiacharts_subscription_plans",
    initialSorter: [
      {
        field: "name",
        order: "desc",
      },
    ],
    initialPageSize: 10,
    metaData: {
      fields: [
        "id",
        "active",
        "name",
        "label",
        "updated_at",
        "plan_config",
        "plan_description",
        "is_free",
      ],
    },
  });
  const { data } = useQuery<any>({
    queryKey: ["getActivepages"],
    queryFn: () => getPages(true),
  });
  const pages = transformers(data?.indiacharts_pages);
  const {
    form: createForm,
    formProps: createFormProps,
    modalProps: createModalProps,
    show: createShow,
  } = useModalForm({
    resource: "indiacharts_subscription_plans",
    action: "create",
    metaData: {
      fields: [
        "id",
        "name",
        "label",
        "plan_config",
        "plan_description",
        "is_free",
      ],
    },
    redirect: false,
    successNotification: false,
    onMutationSuccess: () => {
      message.success("Plan Created Successfully");
    },
  });
  const {
    form: editForm,
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "indiacharts_subscription_plans",
    action: "edit",
    metaData: {
      fields: [
        "id",
        "name",
        "label",
        "plan_config",
        "plan_description",
        "is_free",
        "trial_period_days",
      ],
    },
    redirect: false,
    successNotification: false,
    onMutationSuccess: () => {
      message.success("Plan Updated Successfully");
    },
  });
  const deletePlan = async (id: string, data: boolean) => {
    await update(
      {
        resource: "indiacharts_subscription_plans",
        id,
        values: {
          active: data,
        },
        successNotification: false,
      },
      {
        onSuccess: () => {
          message.success("Plan Deactivated Successfully");
        },
      }
    );
  };
  return (
    <List
      pageHeaderProps={{
        extra: [
          <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
          <CreateButton
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => createShow()}
          />,
        ],
      }}
    >
      <Table {...tableProps} rowKey="id" size="small">
        <Table.Column dataIndex="label" title="Name" />
        <Table.Column
          dataIndex="active"
          title="Is Active"
          render={(value: any) => (
            <CustomTag type={value} text={value ? "Active" : "In Active"} />
          )}
        />
        <Table.Column
          dataIndex="updated_at"
          title="Updated Date"
          render={(value) => <DateField value={value} format="lll" />}
          defaultSortOrder={getDefaultSortOrder("updated_at", sorter)}
          sorter
        />
        <Table.Column
          title="Actions"
          // dataIndex="actions"
          render={(record) => {
            // console.log("record", record);
            return (
              <Space>
                <EditButton
                  hideText
                  size="small"
                  type="link"
                  recordItemId={record?.id}
                  onClick={() => editShow(record?.id)}
                />
                {/* <DeleteButton hideText size="small" type="link" recordItemId={record?.id} /> */}
                <EditButton
                  hideText
                  size="small"
                  type="link"
                  icon={
                    record?.active ? <CloseCircleOutlined /> : <CheckOutlined />
                  }
                  recordItemId={record.id}
                  onClick={() =>
                    deletePlan(record?.id, record?.active ? false : true)
                  }
                />
              </Space>
            );
          }}
        />
      </Table>
      <PlanCreation
        formProps={createFormProps}
        modalProps={createModalProps}
        pages={pages}
      />
      <PlanEdit
        form={editForm}
        formProps={editFormProps}
        modalProps={editModalProps}
        pages={pages}
      />
    </List>
  );
};

export default PlanList;
