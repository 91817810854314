import { IResourceComponentsProps, useExport, useNavigation, useImport } from "@pankod/refine-core";

import {
    List,
    RefreshButton,
    CreateButton,
    ExportButton,
    ShowButton,
    Table,
    useTable,
    Space,
    EditButton, 
    Modal, 
    Form, Create, Input, Radio,
    DeleteButton,
    DateField,
    getDefaultSortOrder,
    useModalForm, Icons, ImportButton,
} from "@pankod/refine-antd";

const { PlusOutlined } = Icons;


export interface IDiiDetails{
    Date: Date
    buy:  number
    sell: number
    netinvestment: number
}

export const DiiDetails: React.FC<IResourceComponentsProps> = () => {
    const {tableProps, sorter, tableQueryResult}= useTable<IDiiDetails>({
        resource: "diidetails",
        metaData:{
            fields:["Date", "buy", "sell", "netinvestment"]
        }
    })

    const { formProps, modalProps, show} = useModalForm({
        resource: "diidetails",
        action: "create",
        metaData: {
            fields: ["id", "buy", "sell", "netinvestment"],
        },
    });

    return(
        <>
        <List
            pageHeaderProps={{
                extra:[
                    <RefreshButton onClick={()=> tableQueryResult?.refetch()}/>,
                    <CreateButton type="primary" icon={<PlusOutlined />} onClick={() => show()} />,
                ]
            }}
        >
            <Table {...tableProps}>
                <Table.Column
                    dataIndex="Date"
                    title="Date"
                />
                <Table.Column
                    dataIndex="buy"
                    title="Buy"
                />
                <Table.Column
                    dataIndex="sell"
                    title="Sell"
                />
                <Table.Column
                    dataIndex="netinvestment"
                    title="Net Investment"
                />
            </Table>
            
        </List>
        <Modal {...modalProps}>
                <Form {...formProps} layout="vertical">
                    <Form.Item label="Buy" name="buy">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Sell" name="sell">
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Net Investment" name="netinvestment">
                        <Input/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
