import {
  CreateButton,
  DateField,
  DeleteButton,
  EditButton,
  getDefaultSortOrder,
  Icons,
  List,
  // Menu,
  message,
  notification,
  RefreshButton,
  Space,
  Table,
  Tag,
  useModalForm,
  useTable,
} from "@pankod/refine-antd";
import CustomTag from "components/CustomTag";
import React, { useState } from "react";
import PodsCreate from "./create";
import PodsEdit from "./edit";
import { useList } from "@pankod/refine-core";

import type { MenuProps } from "antd";
import { Menu } from "antd";

const { PlusOutlined } = Icons;

const PodsList: React.FC = () => {
  const [current, setCurrent] = useState("all");
  const { tableProps, tableQueryResult, sorter, setFilters } = useTable({
    resource: "indiacharts_pods",
    initialSorter: [
      {
        field: "created_at",
        order: "asc",
      },
    ],
    initialPageSize: 10,
    metaData: {
      fields: [
        "id",
        "active",
        "name",
        "updated_at",
        "page_id",
        "is_route",
        "route_name",
        "info_video_id",
        {
          page: ["id", "name"],
        },
      ],
    },
  });
  const {
    // form: createForm,
    formProps: createFormProps,
    modalProps: createModalProps,
    show: createShow,
    close: closeCreateShow,
  } = useModalForm({
    resource: "indiacharts_pods",
    action: "create",
    metaData: {
      fields: ["id", "active", "name", "page_id", "is_private", "route_name"],
    },
    redirect: false,
    successNotification: false,
    onMutationSuccess: () => {
      // message.success("Pods Added !");
      notification.open({
        type: "success",
        message: "Pods Added !",
      });
    },
  });
  const {
    // form: editForm,
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "indiacharts_pods",
    action: "edit",
    metaData: {
      fields: [
        "id",
        "active",
        "name",
        "page_id",
        "is_private",
        "route_name",
        "info_video_id",
      ],
    },
    redirect: false,
    successNotification: false,
    onMutationSuccess: () => {
      // notification.success({
      //   message: "Pods Updated Sucessfully",
      //   placement: "topRight",
      //   duration: 2000,
      // });
      notification.open({
        type: "success",
        message: "Pods Updated Successfully !",
      });
    },
  });

  const pageListQuery = useList({
    resource: "indiacharts_pages",
    metaData: {
      fields: ["id", "name"],
    },
    config: {
      pagination: {
        pageSize: 999,
      },
    },
  });

  // console.log("Pages list :::", pageListQuery);

  const onClick: MenuProps["onClick"] = (e) => {
    // console.log('click ', e);
    setCurrent(e.key);
  };

  return (
    <List
      pageHeaderProps={{
        extra: [
          <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
          <CreateButton
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => createShow()}
          />,
        ],
      }}
    >
      <Menu
        onClick={onClick}
        selectedKeys={[current]}
        mode="horizontal"
        onSelect={(props) => {
          if (props.key === "all") {
            setFilters([], "replace");
            tableQueryResult?.refetch();
          } else {
            setFilters([
              {
                field: "page_id",
                operator: "eq",
                value: props.key,
              },
            ]);
          }
          // tableQueryResult?.refetch()
        }}
      >
        <Menu.Item key="all">All</Menu.Item>
        {pageListQuery?.data?.data?.map((_f) => (
          <Menu.Item key={_f.id}>{_f.name}</Menu.Item>
        ))}
      </Menu>
      <Table {...tableProps} rowKey="id" size="small" scroll={{ x: 1000 }}>
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column dataIndex="route_name" title="Route name" />
        <Table.Column
          dataIndex="active"
          title="Is Active"
          render={(value: any) => (
            <CustomTag type={value} text={value ? "Active" : "In Active"} />
          )}
        />
        <Table.Column
          dataIndex="page"
          title="Page"
          render={(record: any) => {
            // console.log(record);
            return <Tag color="default">{record?.name}</Tag>;
          }}
        />
        <Table.Column
          dataIndex="updated_at"
          title="Updated Date"
          render={(value) => <DateField value={value} format="lll" />}
          defaultSortOrder={getDefaultSortOrder("updated_at", sorter)}
          sorter
        />
        <Table.Column
          width={100}
          fixed="right"
          title="Actions"
          render={(record) => (
            <Space>
              <EditButton
                hideText
                size="small"
                type="link"
                recordItemId={record?.id}
                onClick={() => editShow(record?.id)}
              />
              {/* <DeleteButton
                hideText
                size="small"
                type="link"
                recordItemId={record?.id}
              /> */}
            </Space>
          )}
        />
      </Table>
      <PodsCreate
        formProps={createFormProps}
        modalProps={createModalProps}
        closeCreateShow={closeCreateShow}
      />
      <PodsEdit formProps={editFormProps} modalProps={editModalProps} />
    </List>
  );
};

export default PodsList;
