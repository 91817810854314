import { gql } from "graphql-request";
import { NhostClient } from "@nhost/nhost-js";
import { nhost } from "../utility/nhost";
import { IIndexData, IIndicatorData } from "interfaces";

// export const nhost= new NhostClient({
//     backendUrl: process.env.REACT_APP_NHOST_BACKEND || ""
// })

const DELETE_INDICATOR_DATA = gql`
  mutation MyMutation($date: date!, $indicator_id: uuid!) {
    delete_indiacharts_indicator_data_by_pk(
      date: $date
      indicator_id: $indicator_id
    ) {
      date
      indicator_id
    }
  }
`;

const DELETE_VIDEO_RESOURCES_NOTES = gql`
  mutation MyMutation($id: uuid!) {
    delete_indiacharts_video_resources_notes(
      where: { video_id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;

const DELETE_VIDEO_RESOURCES = gql`
  mutation MyMutation($id: uuid!) {
    delete_indiacharts_video_resources_by_pk(id: $id) {
      id
    }
  }
`;

const DELETE_WEBINARS_NOTES = gql`
  mutation MyMutation($id: uuid!) {
    delete_indiacharts_webinar_notes(where: { webinar_id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

const DELETE_WEBINARS = gql`
  mutation MyMutation($id: uuid!) {
    delete_indiacharts_webinars_by_pk(id: $id) {
      id
    }
  }
`;

const DELETE_WEBINARS_FAVOURITES = gql`
  mutation MyMutation($id: uuid!) {
    delete_indiacharts_webinar_favourites(where: { webinar_id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

const DELETE_BULK_INDICATOR_DATA = gql`
  mutation MyMutation($indicator_id: uuid!) {
    delete_indiacharts_indicator_data(
      where: { indicator_id: { _eq: $indicator_id } }
    ) {
      affected_rows
    }
  }
`;

const DELETE_INDEX_DATA = gql`
  mutation MyMutation($date: date!, $index_id: uuid!) {
    delete_indiacharts_index_data_by_pk(date: $date, index_id: $index_id) {
      date
      index_id
    }
  }
`;

const DELETE_COMPANY_DIVIDEND = gql`
  mutation MyMutation($execution_date: date!, $id: uuid!) {
    delete_indiacharts_company_dividend_by_pk(
      execution_date: $execution_date
      id: $id
    ) {
      execution_date
      id
    }
  }
`;

const DELETE_COMPANY_RIGHTS = gql`
  mutation MyMutation($execution_date: date!, $company_code: string!) {
    delete_indiacharts_company_rights_by_pk(
      execution_date: $execution_date
      company_code: $company_code
    ) {
      execution_date
      company_code
    }
  }
`;

const DELETE_SPLITS_BONUS = gql`
  mutation MyMutation($execution_date: date!, $id: uuid!) {
    delete_indiacharts_splits_bonus_by_pk(
      execution_date: $execution_date
      id: $id
    ) {
      execution_date
      id
    }
  }
`;

const DELETE_STOCK_NEWS = gql`
  mutation MyMutation($headlines: string!, $news_date: date!) {
    delete_indiacharts_stock_news_by_pk(
      headlines: $headlines
      news_date: $news_date
    ) {
      headlines
      news_date
    }
  }
`;

const DELETE_HOLIDAYS = gql`
  mutation MyMutation($exchange_code: string!, $holiday_date: date!) {
    delete_indiacharts_holidays_by_pk(
      exchange_code: $exchange_code
      holiday_date: $holiday_date
    ) {
      exchange_code
      holiday_date
    }
  }
`;

const INSERT_INDEX_DATA_ONE = gql`
  mutation insert_indiacharts_index_data_one(
    $object: indiacharts_index_data_insert_input!
  ) {
    insert_indiacharts_index_data_one(object: $object) {
      created_at
      index_id
    }
  }
`;

const UPDATE_INDEX_DATA_BY_PK = gql`
  mutation MyMutation(
    $_set: indiacharts_index_data_set_input = {}
    $pk_columns: indiacharts_index_data_pk_columns_input!
  ) {
    update_indiacharts_index_data_by_pk(pk_columns: $pk_columns, _set: $_set) {
      index_id
      date
    }
  }
`;

const UPDATE_TICKET_HANDLER = gql`
  mutation MyMutation($handler_id: uuid!, $id: uuid!) {
    update_indiacharts_customer_tickets_by_pk(
      _set: { handler_id: $handler_id }
      pk_columns: { id: $id }
    ) {
      handler_id
      id
    }
  }
`;

const UPDATE_USER_DISABLED = gql`
  mutation MyMutation($id: uuid!, $disabled: Boolean!) {
    updateUser(pk_columns: { id: $id }, _set: { disabled: $disabled }) {
      disabled
    }
  }
`;

const TEMP_DELETE_CUST_TICKET = gql`
  mutation MyMutation($id: uuid!, $is_deleted: Boolean!) {
    update_indiacharts_customer_tickets_by_pk(
      pk_columns: { id: $id }
      _set: { is_deleted: $is_deleted }
    ) {
      is_deleted
    }
  }
`;

const INSERT_INDICATOR_DATA_ONE = gql`
  mutation insert_indiacharts_indicator_data_one(
    $object: indiacharts_indicator_data_insert_input!
  ) {
    insert_indiacharts_indicator_data_one(object: $object) {
      created_at
      indicator_id
    }
  }
`;

const UPDATE_INDICATOR_DATA_BY_PK = gql`
  mutation update_indiacharts_indicator_data_by_pk(
    $_set: indiacharts_indicator_data_set_input = {}
    $pk_columns: indiacharts_indicator_data_pk_columns_input!
  ) {
    update_indiacharts_indicator_data_by_pk(
      pk_columns: $pk_columns
      _set: $_set
    ) {
      indicator_id
      date
      value
      updated_at
    }
  }
`;

const UPDATE_USER_BY_PK = gql`
  mutation MyMutation(
    $pk_columns: users_pk_columns_input!
    $_set: users_set_input = {}
  ) {
    updateUser(pk_columns: $pk_columns, _set: $_set) {
      id
      email
      displayName
      phoneNumber
      updatedAt
    }
  }
`;

const UPDATE_INDICATOR = gql`
  mutation MyMutation($headers: jsonb!, $id: uuid!) {
    update_indiacharts_indicators(
      where: { id: { _eq: $id } }
      _append: { headers: $headers }
    ) {
      affected_rows
      returning {
        id
        headers
      }
    }
  }
`;

const UPSERT_PARTNER_CONFIG = gql`
  mutation MyMutation($objects: [auth_partner_config_insert_input!]!) {
    insert_auth_partner_config(
      objects: $objects
      on_conflict: {
        constraint: partner_config_email_key
        update_columns: [
          commission_percentage
          discount_percentage
          free_trial_in_days
        ]
      }
    ) {
      returning {
        commission_percentage
        discount_percentage
        email
        free_trial_in_days
        id
      }
    }
  }
`;

const GET_PARTNER_CONFIG_BY_EMAIL = gql`
  query MyQuery($email: citext!) {
    auth_partner_config(where: { email: { _eq: $email } }) {
      commission_percentage
      discount_percentage
      email
      free_trial_in_days
    }
  }
`;

export async function mutateIndicatorHeader(headers: any, id: any) {
  return nhost.graphql.request(UPDATE_INDICATOR, { headers, id });
}

export async function mutateVideoResources(id: any) {
  return nhost.graphql.request(DELETE_VIDEO_RESOURCES, { id });
}

export async function mutateVideoResourcesNotes(id: any) {
  return nhost.graphql.request(DELETE_VIDEO_RESOURCES_NOTES, { id });
}

export async function mutateWebinars(id: any) {
  return nhost.graphql.request(DELETE_WEBINARS, { id });
}

export async function mutateWebinarsNotes(id: any) {
  return nhost.graphql.request(DELETE_WEBINARS_NOTES, { id });
}

export async function mutateWebinarsFavourites(id: any) {
  return nhost.graphql.request(DELETE_WEBINARS_FAVOURITES, { id });
}

export async function mutateUserDisabled(id: any, disabled: any) {
  return nhost.graphql.request(UPDATE_USER_DISABLED, { id, disabled });
}

export async function tempDeleteCustTicket(id: any, is_deleted: any) {
  return nhost.graphql.request(TEMP_DELETE_CUST_TICKET, { id, is_deleted });
}

export async function mutateTicketHandler(handler_id: any, id: any) {
  return nhost.graphql.request(UPDATE_TICKET_HANDLER, { handler_id, id });
}

export async function mutateHolidays(exchange_code: any, holiday_date: string) {
  return nhost.graphql.request(DELETE_HOLIDAYS, {
    exchange_code,
    holiday_date,
  });
}

export async function mutateIndicatorData(date: any, indicator_id: string) {
  return nhost.graphql.request(DELETE_INDICATOR_DATA, { date, indicator_id });
}

export async function mutateBulkIndicatorData(indicator_id: string) {
  return nhost.graphql.request(DELETE_BULK_INDICATOR_DATA, { indicator_id });
}

export async function mutateIndexData(date: any, index_id: string) {
  return nhost.graphql.request(DELETE_INDEX_DATA, { date, index_id });
}

export async function mutateCompanyDividend(execution_date: any, id: string) {
  return nhost.graphql.request(DELETE_COMPANY_DIVIDEND, { execution_date, id });
}

export async function mutateCompanyRights(execution_date: any, id: string) {
  return nhost.graphql.request(DELETE_COMPANY_RIGHTS, { execution_date, id });
}

export async function mutateSplitsBonus(execution_date: any, id: string) {
  return nhost.graphql.request(DELETE_SPLITS_BONUS, { execution_date, id });
}

export async function mutateStockNews(headlines: any, news_date: any) {
  return nhost.graphql.request(DELETE_STOCK_NEWS, { headlines, news_date });
}

export async function insertIndexDataOne(Object: IIndexData) {
  return nhost.graphql.request(INSERT_INDEX_DATA_ONE, {
    object: {
      ...Object,
    },
  });
}

export async function updateIndexDataByPK(Object: any, pkColumns: any) {
  return nhost.graphql.request(UPDATE_INDEX_DATA_BY_PK, {
    _set: {
      ...Object,
    },
    pk_columns: {
      ...pkColumns,
    },
  });
}

export async function insertIndicatorDataOne(Object: IIndicatorData) {
  return nhost.graphql.request(INSERT_INDICATOR_DATA_ONE, {
    object: {
      ...Object,
    },
  });
}

export async function updateIndicatorDataByPK(Object: any, pkColumns: any) {
  return nhost.graphql.request(UPDATE_INDICATOR_DATA_BY_PK, {
    _set: {
      ...Object,
    },
    pk_columns: {
      ...pkColumns,
    },
  });
}

export async function updateUserByPK(Object: any, pkColumns: any) {
  return nhost.graphql.request(UPDATE_USER_BY_PK, {
    _set: {
      ...Object,
    },
    pk_columns: {
      ...pkColumns,
    },
  });
}

export async function mutatePartnerConfig(objects: any) {
  return nhost.graphql.request(UPSERT_PARTNER_CONFIG, objects);
}

export async function getPartnerConfigByEmail(email: any) {
  return nhost.graphql.request(GET_PARTNER_CONFIG_BY_EMAIL, {
    email,
  });
}
