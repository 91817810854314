import React from "react";
import { useParams } from "react-router-dom";
import {
  Table,
  useTable,
  List,
  RefreshButton,
  useModalForm,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
} from "@pankod/refine-antd";

// Interface
import { IIndicesStocks } from "interfaces";

// Component
import { IndicesStocksEdit } from "./indices_stocks_edit";

export const IndicesStocksList: React.FC = () => {
  // Route Param
  const routeParams = useParams();

  // Indices Stocks Table
  const { tableProps, tableQueryResult, sorter } = useTable<IIndicesStocks>({
    resource: "indiacharts_indices_stocks",
    metaData: {
      fields: [
        "id",
        "indices_id",
        "ticker_name",
        "security_code",
        "updated_at",
        "created_at",
        "company_code",
        "company_id",
        "isincode",
        "deleted",
        "symbol",
        "description",
      ],
    },
    initialPageSize: 20,
    initialSorter: [
      {
        field: "ticker_name",
        order: "asc",
      },
    ],
    permanentFilter: [
      {
        field: "indices_id",
        operator: "eq",
        value: routeParams?.id,
      },
    ],
  });

  // Edit
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "indiacharts_indices_stocks",
    action: "edit",
    metaData: {
      fields: [
        "id",
        "ticker_name",
        "security_code",
        "company_code",
        "isincode",
        "symbol",
        "description",
      ],
    },
    redirect: false,
  });

  return (
    <>
      <List
        title=""
        breadcrumb={false}
        // headerProps={{
        //   extra: [
        //     <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
        //   ],
        // }}
      >
        <Table
          {...tableProps}
          rowKey={(record, index) =>
            record.indices_id + "_" + record.created_at
          }
          size="small"
          scroll={{ x: 1500 }}
        >
          <Table.Column<IIndicesStocks>
            dataIndex="ticker_name"
            title="Ticker Name"
          />
          <Table.Column<IIndicesStocks>
            dataIndex="ticker_name"
            title="Ticker"
          />
          <Table.Column<IIndicesStocks>
            dataIndex="security_code"
            title="Security Code"
          />
          <Table.Column<IIndicesStocks>
            dataIndex="company_code"
            title="Company Code"
          />
          <Table.Column<IIndicesStocks>
            dataIndex="isincode"
            title="ISIN Code"
          />
          <Table.Column<IIndicesStocks> dataIndex="symbol" title="Symbol" />
          <Table.Column<IIndicesStocks>
            dataIndex="description"
            title="Description"
          />
          <Table.Column<IIndicesStocks>
            dataIndex="created_at"
            title="Created At"
            render={(value) => <DateField value={value} format="lll" />}
            defaultSortOrder={getDefaultSortOrder("date", sorter)}
            sorter
          />
          <Table.Column<IIndicesStocks>
            title="Actions"
            fixed="right"
            width={70}
            dataIndex="actions"
            key="actions"
            render={(_, record: IIndicesStocks) => (
              <Space>
                <EditButton
                  hideText
                  size="small"
                  recordItemId={record.id}
                  onClick={() => editShow(record.id)}
                />
              </Space>
            )}
          />
        </Table>
      </List>

      {/* Edit */}
      <IndicesStocksEdit
        editModalProps={editModalProps}
        editFormProps={editFormProps}
      />
    </>
  );
};
