import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useShow } from "@pankod/refine-core";
import { PageHeader, ListButton, Tabs, Icons } from "@pankod/refine-antd";

// Interface
import { ISectors } from "interfaces";

// Component
import { BroadIndustryList } from "./broad_industry_list";

const { BuildOutlined } = Icons;

export const ShowSector: React.FC = () => {
  // Route Param
  const routeParams = useParams();
  const [currentBreadCrumb, setCurrentBreadCrumb] = useState("Broad Industry");

  // Sector By ID
  const { queryResult: sectorQueryResult } = useShow<ISectors>({
    resource: "indiacharts_sector",
    id: routeParams?.id,
    metaData: {
      fields: ["id", "name"],
    },
  });

  const handleTabChange = (key: string) => {
    setCurrentBreadCrumb(key);
  };
  return (
    <>
      <div className="breadcrumb">
        <BuildOutlined /> Sectors / {currentBreadCrumb}
      </div>
      <PageHeader
        title={sectorQueryResult?.data?.data?.name}
        onBack={() => window.history.back()}
        extra={[<ListButton />]}
        footer={
          <Tabs onChange={handleTabChange}>
            <Tabs.TabPane tab="Broad Industry" key="Broad Industry">
              <BroadIndustryList />
            </Tabs.TabPane>
          </Tabs>
        }
      ></PageHeader>
    </>
  );
};
