import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  Row,
  Col,
} from "@pankod/refine-antd";

type Props = {
  modalProps: ModalProps;
  formProps: FormProps;
};

export const EditCompany: React.FC<Props> = ({ modalProps, formProps }) => {
  const { TextArea } = Input;

  return (
    <Modal {...modalProps} title="Edit Company">
      <Form {...formProps} layout="vertical" size="large">
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item label="Name" name="name">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Short name"
              name="short_name"
              rules={[{ required: false }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item label="Cinno" name="cinno">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item label="Incorporated on" name="incorporated_on">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Remarks"
              name="remarks"
              rules={[{ required: false }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item label="Short name" name="short_name">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col>
            <Form.Item name="slug" label="Slug">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col>
            <Form.Item name="description" label="Description">
              <TextArea cols={120} rows={6} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
