import {
  List,
  useTable,
  Button,
  Table,
  RefreshButton,
  CreateButton,
  useModal,
  useModalForm,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  TextField,
  Col,
  Input,
  Icons,
  BooleanField,
  Menu,
  useForm,
  MenuProps,
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useGetIdentity } from "@pankod/refine-core";

import { IVideoResourcesCategories } from "interfaces";
import { Form, Switch, Typography } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useState } from "react";
import { CreateVideoResourceCategories } from "./create";
import { EditVideoResourceCategories } from "./edit";
import { title } from "process";

const { Paragraph, Text } = Typography;
const { CheckCircleOutlined, CloseCircleOutlined } = Icons;

export const VideoCategoriesList: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity();

  const { PlusOutlined } = Icons;
  const [ellipsis, setEllipsis] = useState(true);
  const [rotating, setRotating] = useState(false);

  const { form: tableForm, formProps: infoVideoCategoriesTable } = useForm();

  const { tableProps, tableQueryResult, setFilters, searchFormProps } =
    useTable<IVideoResourcesCategories>({
      resource: "indiacharts_video_resources_categories",
      metaData: {
        fields: [
          "id",
          "category",
          "is_active",
          "created_at",
          "updated_at",
          "created_by",
          "sort_no"
        ],
      },
      initialFilter: [
        {
          field: "is_active",
          operator: "eq",
          value: true,
        },
      ],
    });

  const {
    formProps,
    modalProps,
    form: createForm,
    show: showModal,
    close: closeCreateModal,
  } = useModalForm({
    resource: "indiacharts_video_resources_categories",
    action: "create",
    redirect: false,
    metaData: {
      fields: [
        "id",
        "category",
        "is_active",
        "created_at",
        "updated_at",
        "created_by",
        "sort_no"
      ],
    },
  });

  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "indiacharts_video_resources_categories",
    action: "edit",
    redirect: false,
    metaData: {
      fields: [
        "id",
        "category",
        "is_active",
        "created_at",
        "updated_at",
        "created_by",
        "sort_no"
      ],
    },
  });

  // Refresh button func
  const handleRefresh = () => {
    setRotating(true);

    setTimeout(() => {
      setRotating(false);
    }, 500);
  };

  const [current, setCurrent] = useState("true");

  const onMenuClick: MenuProps["onClick"] = (e: any) => {
    console.log("click ", e);
    setCurrent(e.key);
  };

  return (
    <List
      headerProps={{
        extra: [
          <Button
            type="default"
            icon={<ReloadOutlined spin={rotating} />}
            onClick={() => {
              tableQueryResult?.refetch();
              handleRefresh();
              tableForm?.resetFields(["name"]);
              setFilters([
                {
                  field: "category",
                  operator: "contains",
                  value: null,
                },
              ]);
            }}
          >
            Refresh
          </Button>,
          ,
          <CreateButton
            type="primary"
            onClick={() => showModal()}
            icon={<PlusOutlined />}
          />,
        ],
      }}
    >
      <Form {...infoVideoCategoriesTable}>
        <Col span={6}>
          <Form.Item name="name">
            <Input
              onChange={(e) =>
                setFilters([
                  {
                    field: "category",
                    operator: "contains",
                    value: `%${e?.target?.value}%`,
                  },
                ])
              }
              placeholder="Search by category"
              prefix={<Icons.SearchOutlined />}
            />
          </Form.Item>
        </Col>
      </Form>
      <Menu
        selectedKeys={[current]}
        onClick={onMenuClick}
        mode="horizontal"
        onSelect={(props) => {
          setFilters([
            {
              field: "is_active",
              operator: "eq",
              value: props.key,
            },
          ]);
          tableQueryResult?.refetch();
        }}
      >
        <Menu.Item key="true">Active</Menu.Item>
        <Menu.Item key="false">In Active</Menu.Item>
      </Menu>
      <Table {...tableProps} rowKey="id" size="small">
        <Table.Column
          dataIndex="category"
          title="Category"
          render={(value) => (
            <Text
              style={ellipsis ? { width: 200 } : undefined}
              ellipsis={ellipsis ? { tooltip: `${value}` } : false}
            >
              {value}
            </Text>
          )}
        />
        <Table.Column
          dataIndex="is_active"
          title="Active"
          render={(value) => (
            <BooleanField
              value={value === true}
              trueIcon={<CheckCircleOutlined style={{ color: "green" }} />}
              falseIcon={<CloseCircleOutlined style={{ color: "red" }} />}
              valueLabelTrue="true"
              valueLabelFalse="false"
            />
          )}
        />
        <Table.Column
          dataIndex="created_at"
          title="Created At"
          render={(value) => <DateField format="LL" value={value} />}
        />
        <Table.Column
          dataIndex="updated_at"
          title="Updated At"
          render={(value) => <DateField format="LL" value={value} />}
        />
        <Table.Column
        dataIndex="sort_no"
        title="Sorting"/>
        <Table.Column<IVideoResourcesCategories>
          title="Actions"
          fixed="right"
          width={100}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton
                hideText
                size="small"
                type="link"
                recordItemId={record.id}
                onClick={() => editShow(record.id)}
              />
              <DeleteButton
                hideText
                size="small"
                type="link"
                recordItemId={record.id}
                resourceNameOrRouteName="indiacharts_video_resources_categories"
              />
            </Space>
          )}
        />
      </Table>
      <CreateVideoResourceCategories
        modalProps={modalProps}
        formProps={formProps}
        createForm={createForm}
        closeCreateModal={closeCreateModal}
        currentUser={user?.id}
      />
      <EditVideoResourceCategories
        modalProps={editModalProps}
        formProps={editFormProps}
      />
    </List>
  );
};
