import React from "react";
import { useParams } from "react-router-dom";
import {
  Table,
  useTable,
  List,
  useModalForm,
  DateField,
  Space,
  EditButton,
  BooleanField,
  Icons,
  DeleteButton,
} from "@pankod/refine-antd";

import { ICompanies, IIndustries } from "interfaces";
import { useList } from "@pankod/refine-core";
import { EditCompanies } from "./companies_edit";
const { CheckCircleOutlined, CloseCircleOutlined } = Icons;

export const IndustriesCompanyList: React.FC = () => {
  const routeParams = useParams();
  const id = routeParams?.id;

  const { data } = useList<IIndustries>({
    resource: "indiacharts_industries",
    metaData: {
      fields: ["id", "name", "code", "broad_industry_code"],
    },
  });

  const needCode = data?.data?.find((_o: any) => _o.id === id);

  const { tableProps, tableQueryResult } = useTable<ICompanies>({
    resource: "indiacharts_companies",
    metaData: {
      fields: [
        "id",
        "code",
        "name",
        "industry_code",
        "business_group_code",
        "short_name",
        "industry_id",
        "business_group_id",
        "cinno",
        "remarks",
        "deleted",
        "updated_at",
        "incorporated_on",
        "first_public_issued_on",
        "created_at",
      ],
    },

    permanentFilter: [
      {
        field: "industry_code",
        operator: "eq",
        value: needCode?.code,
      },
    ],
  });

  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "indiacharts_companies",
    action: "edit",
    metaData: {
      fields: [
        "id",
        "code",
        "name",
        "industry_code",
        "business_group_code",
        "short_name",
        "industry_id",
        "business_group_id",
        "cinno",
        "remarks",
        "deleted",
        "updated_at",
        "incorporated_on",
        "first_public_issued_on",
        "created_at",
      ],
    },
  });

  return (
    <List>
      <Table {...tableProps} size="small">
        <Table.Column dataIndex="code" title="Code" />
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column dataIndex="industry_code" title="Industry code" />
        <Table.Column
          dataIndex="business_group_code"
          title="Business group code"
        />
        <Table.Column dataIndex="short_name" title="Short name" />
        {/* <Table.Column
                    dataIndex="industry_id"
                    title="Industry ID"
                />
                <Table.Column
                    dataIndex="business_group_id"
                    title="Business group id"
                /> */}
        <Table.Column dataIndex="cinno" title="Cinno" />
        <Table.Column dataIndex="remarks" title="Remarks" />
        <Table.Column
          dataIndex="deleted"
          title="Deleted"
          render={(value) => (
            <BooleanField
              value={value === true}
              trueIcon={<CheckCircleOutlined />}
              falseIcon={<CloseCircleOutlined />}
              valueLabelTrue="true"
              valueLabelFalse="false"
            />
          )}
        />
        <Table.Column
          dataIndex="updated_at"
          title="Updated at"
          render={(value) => <DateField value={value} format="lll" />}
        />
        <Table.Column dataIndex="incorporated_on" title="Incorporated on" />
        <Table.Column
          dataIndex="first_public_issued_on"
          title="First public issued on"
        />
        <Table.Column
          dataIndex="created_at"
          title="Created at"
          render={(value) => <DateField value={value} format="lll" />}
        />
        <Table.Column<ICompanies>
          title="Actions"
          dataIndex="actions"
          key="actions"
          render={(_, record: ICompanies) => (
            <Space>
              <EditButton
                hideText
                size="small"
                recordItemId={record.id}
                onClick={() => editShow(record.id)}
              />
              <DeleteButton hideText size="small" recordItemId={record?.id} />
            </Space>
          )}
        />
      </Table>
      <EditCompanies modalProps={editModalProps} formProps={editFormProps} />
    </List>
  );
};
