import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Table,
  useTable,
  List,
  CreateButton,
  useModalForm,
  Space,
  EditButton,
  DeleteButton,
} from "@pankod/refine-antd";

import { IBroadIndustry, IIndustries } from "interfaces";
import { useList } from "@pankod/refine-core";
import { EditBroadIndustry } from "./broad_industry_edit";
import { CreateBroadIndustry } from "./broad_industry_create";

export const BroadIndustryList: React.FC = (props) => {
  const routeParams = useParams();

  const id = routeParams?.id;

  const { data } = useList<IIndustries>({
    resource: "indiacharts_industries",
    metaData: {
      fields: ["id", "name", "code", "broad_industry_code"],
    },
  });

  const needCode = data?.data?.find((_o: any) => _o.id === id);

  console.log("needCode", needCode);
  console.log(needCode?.broad_industry_code);
  console.log(needCode?.code);
  console.log(needCode?.name);

  const { tableProps, tableQueryResult } = useTable<IBroadIndustry>({
    resource: "indiacharts_broad_industry",
    metaData: {
      fields: ["id", "code", "name", "sector_code"],
    },
    permanentFilter: [
      {
        field: "code",
        operator: "eq",
        value: needCode?.broad_industry_code,
      },
    ],
  });

  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "indiacharts_broad_industry",
    action: "edit",
    metaData: {
      fields: ["name", "code", "sector_code"],
    },
    redirect: false,
  });

  const {
    formProps,
    modalProps,
    show: createShow,
  } = useModalForm({
    resource: "indiacharts_broad_industry",
    action: "create",
    metaData: {
      fields: ["name", "code", "sector_code"],
    },
    redirect: false,
  });

  return (
    <List
      pageHeaderProps={{
        extra: [<CreateButton onClick={() => createShow()} />],
      }}
    >
      <Table {...tableProps}>
        <Table.Column dataIndex="code" title="Code" />
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column dataIndex="sector_code" title="Sector code" />
        <Table.Column<IBroadIndustry>
          title="Actions"
          dataIndex="actions"
          key="actions"
          render={(_, record: IBroadIndustry) => (
            <Space>
              <EditButton
                hideText
                size="small"
                recordItemId={record.id}
                onClick={() => editShow(record.id)}
              />
              <DeleteButton hideText size="small" recordItemId={record?.id} />
            </Space>
          )}
        />
      </Table>
      <CreateBroadIndustry modalProps={modalProps} formProps={formProps} />
      <EditBroadIndustry
        modalProps={editModalProps}
        formProps={editFormProps}
      />
    </List>
  );
};
