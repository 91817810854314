export const transformers = (data: any[] | undefined) => {
    const d = data?.map((page: any) => ({
        title: page?.name,
        key: page?.id,
        children: page?.pods?.map((pod: any) => ({
            title: pod?.name,
            key: pod?.id,
            children: pod?.sub_headers?.map((sub_header: any) => ({
                title: sub_header?.name,
                key: sub_header?.id,
                children: sub_header?.children?.map((child: any) => ({
                    title: child?.name,
                    key: child?.id,
                })),
            })),
        })),
    }));
    return d;
};
