import { ListButton, PageHeader, Tabs,useSelect } from "@pankod/refine-antd";
import {  useList, useShow } from "@pankod/refine-core";
import { IIndustries } from "interfaces";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query"
import { BroadIndustryList } from "./broad_industry_list";
import { IndustriesCompanyList } from "./companies_list";
import { getIndustry } from "graphql/queries";

export const ShowIndustry: React.FC = () => {
  const routeParams = useParams();

  const [currIndId, setCurrIndId]= useState();

  const id: any= routeParams?.id;

  // const {data: QueryData}= useQuery({
  //   queryKey: ["get-industries"],
  //   queryFn: () => getIndustry()
  // })

  // console.log("data", QueryData);

  // const {  data }= useList<IIndustries>({
  //   resource:"indiacharts_industries",
  //   metaData:{
  //     fields:[
  //       "id",
  //       "name",
  //       "code",
  //       "broad_industry_code"
  //     ]
  //   },
  // })

  
  // const needCode= data?.data?.find((_o: any)=> _o.id === id)
  
  // console.log("queryData", data);
  // console.log("needed", needCode);
  // console.log(needCode?.broad_industry_code)
  // console.log(needCode?.code)
  // console.log(needCode?.name)

  // const neededIndCode: any= needCode?.code

  // setCurrIndId(neededIndCode)
  

  const { queryResult } = useShow<IIndustries>({
    resource: "indiacharts_industries",
    id: routeParams?.id,
    metaData: {
      fields: ["id", "name", "code", "broad_industry_code"],
    },
  });


  return (
    <>
      <PageHeader
        title={queryResult?.data?.data?.name}
        onBack={() => window.history.back()}
        extra={[<ListButton />]}
        footer={
          <Tabs>
            <Tabs.TabPane tab="Broad Industry" key="item-1">
              <BroadIndustryList />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Companies" key="item-2">
              <IndustriesCompanyList />
            </Tabs.TabPane>
          </Tabs>
        }
      ></PageHeader>
    </>
  );
};
