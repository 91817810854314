import { Switch, Typography } from "antd";
import {
  Form,
  Col,
  Input,
  useTable,
  List,
  RefreshButton,
  Table,
  useModalForm,
  Icons,
  CreateButton,
  EditButton,
  Button,
  DateField,
  useSelect,
  Menu,
} from "@pankod/refine-antd";

import debounce from "lodash/debounce";

// antd
import { Select, Space } from "antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { mutateStockNews } from "graphql/mutation";
import { IStockNews } from "interfaces";
import { useState } from "react";
import { CreateStockNews } from "./create";
import { EditStockNews } from "./edit";
const { PlusOutlined } = Icons;
const { Text } = Typography;

export const StockNewsList: React.FC<IResourceComponentsProps> = () => {
  const [ellipsis, setEllipsis] = useState(true);
  const { DeleteOutlined } = Icons;

  const [filterValue, setfilterValue] = useState<any>();

  const { tableProps, tableQueryResult, sorter, setFilters, searchFormProps } =
    useTable<IStockNews>({
      resource: "indiacharts_stock_news",
      metaData: {
        fields: [
          "company_id",
          "company_code",
          "headlines",
          "source_name",
          "exchange",
          "news_classification",
          "content",
          "news_date",
          "modified_at",
          "created_at",
          "news_type",
        ],
      },
    });

  const {
    formProps,
    modalProps,
    show: showModal,
    close: closeCreateShow,
  } = useModalForm({
    resource: "indiacharts_stock_news",
    action: "create",
    metaData: {
      fields: [
        "company_id",
        "company_code",
        "headlines",
        "source_name",
        "exchange",
        "news_classification",
        "content",
        "news_date",
        "modified_at",
        "created_at",
        "news_type",
      ],
    },
  });

  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "indiacharts_stock_news",
    action: "edit",
    metaData: {
      fields: [
        "company_id",
        "company_code",
        "headlines",
        "source_name",
        "exchange",
        "news_classification",
        "content",
        "news_date",
        "modified_at",
        "created_at",
        "news_type",
      ],
    },
  });

  const { selectProps } = useSelect<IStockNews>({
    resource: "indiacharts_stock_news",
    metaData: {
      fields: ["news_classification"],
    },
    optionLabel: "news_classification",
    optionValue: "news_classification",
  });

  const handleSearch = debounce((e: any) => {
    const searchValue = e?.target?.value;
    setFilters([
      {
        field: "headlines",
        operator: "contains",
        value: `%${searchValue}%`,
      },
    ]);
  }, 500);

  return (
    <List
      headerProps={{
        extra: [
          <span>Filter:</span>,
          <Select
            value={filterValue}
            placeholder="Classification"
            style={{ width: 200 }}
            onChange={(value: string) => {
              setfilterValue(value);
              if (value === "announcement") {
                setFilters([
                  {
                    field: "news_classification",
                    operator: "eq",
                    value: "announcement",
                  },
                ]);
              } else if (value === "news") {
                setFilters([
                  {
                    field: "news_classification",
                    operator: "eq",
                    value: "news",
                  },
                ]);
              } else setFilters([], "replace");
            }}
            options={[
              { value: "announcement", label: "Announcement" },
              { value: "news", label: "News" },
            ]}
          />,
          <RefreshButton
            onClick={() => {
              setfilterValue(null);
              setFilters([
                {
                  field: "news_classification",
                  operator: "eq",
                  value: undefined,
                },
              ]);
              tableQueryResult?.refetch();
            }}
          />,
          <CreateButton
            type="primary"
            onClick={() => showModal()}
            icon={<PlusOutlined />}
          />,
        ],
      }}
    >
      <Form {...searchFormProps}>
        <Col span={6}>
          <Form.Item name="name">
            <Input
              onChange={(e: any) => handleSearch(e)}
              placeholder="Search by name"
              prefix={<Icons.SearchOutlined />}
            />
          </Form.Item>
        </Col>
      </Form>
      <Menu
        mode="horizontal"
        onSelect={(props) => {
          console.log("Menu key :::", props);
          if (props.key === "All") {
            setFilters([
              {
                field: "exchange",
                operator: "eq",
                value: null,
              },
            ]);
          } else {
            setFilters([
              {
                field: "exchange",
                operator: "eq",
                value: props.key,
              },
            ]);
          }
          tableQueryResult?.refetch();
        }}
      >
        <Menu.Item key="All">All</Menu.Item>
        <Menu.Item key="NSE">NSE</Menu.Item>
        <Menu.Item key="BSE">BSE</Menu.Item>
        <Menu.Item key="General">General</Menu.Item>
      </Menu>
      <Table {...tableProps} size="small" scroll={{ x: 1500 }}>
        <Table.Column
          dataIndex="headlines"
          title="Headline"
          render={(value) => (
            <Text
              style={ellipsis ? { width: 200 } : undefined}
              ellipsis={ellipsis ? { tooltip: `${value}` } : false}
            >
              {value}
            </Text>
          )}
        />
        <Table.Column dataIndex="source_name" title="Source name" />
        <Table.Column dataIndex="exchange" title="Exchange" />
        <Table.Column
          dataIndex="news_classification"
          title="News Classification"
        />
        <Table.Column dataIndex="news_type" title="News type" />
        <Table.Column
          dataIndex="content"
          title="Content"
          render={(value) => (
            <Text
              style={ellipsis ? { width: 200 } : undefined}
              ellipsis={ellipsis ? { tooltip: `${value}` } : false}
            >
              {value}
            </Text>
          )}
        />
        <Table.Column
          dataIndex="news_date"
          title="News date"
          render={(value) => <DateField value={value} format="lll" />}
        />
        {/* <Table.Column
          width={180}
          dataIndex="modified_at"
          title="Modified at"
          render={(value) => <DateField value={value} format="lll" />}
        /> */}
        <Table.Column
          width={180}
          dataIndex="created_at"
          title="Created at"
          render={(value) => <DateField value={value} format="lll" />}
        />
        <Table.Column<IStockNews>
          title="Actions"
          fixed="right"
          width={100}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton
                hideText
                size="small"
                type="link"
                recordItemId={record.news_date}
                onClick={() => editShow(record.news_date)}
              />
              <Button
                type="link"
                icon={<DeleteOutlined style={{ color: "red" }} />}
                onClick={() =>
                  mutateStockNews(record?.headlines, record?.news_date)
                }
              />
            </Space>
          )}
        />
      </Table>
      <CreateStockNews
        modalProps={modalProps}
        formProps={formProps}
        closeCreateShow={closeCreateShow}
      />
      <EditStockNews modalProps={editModalProps} formProps={editFormProps} />
    </List>
  );
};
