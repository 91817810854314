import React from "react";
import { Modal, Form } from "@pankod/refine-antd";
import { Row, Col, Input, notification } from "antd";
import { DatePicker } from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import moment from "moment";

// Interface
import { IIndicatorData } from "interfaces";

// Mutation
import { updateIndicatorDataByPK } from "graphql/mutation";

// Webhook
import { indicatorDataAPI } from "utility/webhook";

type props = {
  dataObject: IIndicatorData;
  setDataObject: React.Dispatch<React.SetStateAction<IIndicatorData | any>>;
  tableQueryResult: any;
};

export const IndicatorDataEdit: React.FC<props> = ({
  dataObject,
  setDataObject,
  tableQueryResult,
}) => {
  // Initialize Form
  const [form] = Form.useForm();

  // Disable Future Date
  const disabledFutureDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current > moment().endOf("day");
  };

  // Form Submit
  const onFinish = async (formData: any) => {
    await updateIndicatorDataByPK(
      {
        value: formData?.value,
        date: moment(formData?.date).format("YYYY-MM-DD"),
      },
      {
        date: dataObject?.date,
        indicator_id: dataObject?.indicator_id,
      }
    )
      .then((res: any) => {
        if (
          res?.error &&
          res?.error[0]?.extensions?.code === "constraint-violation"
        ) {
          notification.open({
            message: "Error",
            type: "error",
            description: "Primary key duplication error",
          });
        }
        indicatorDataAPI({
          indicator_id: dataObject?.indicator_id
        });
        tableQueryResult.refetch();
        setDataObject({});
      })
      .catch((err) => {
        notification.open({
          message: "Error",
          type: "error",
          description: "Error while updating index data.",
        });
        console.log("ERR ::: ", err);
      });
  };

  return (
    <Modal
      title="Edit Indicator Data"
      width={1000}
      visible={Object.keys(dataObject).length > 0 ? true : false}
      onCancel={() => {
        setDataObject({});
      }}
      onOk={() =>
        onFinish({
          value: form?.getFieldValue("value"),
          date: form?.getFieldValue("date"),
        })
      }
    >
      <Form
        form={form}
        size="large"
        layout="vertical"
        initialValues={{
          value: dataObject?.value,
          date: moment(dataObject?.date),
        }}
      >
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Value"
              name="value"
              rules={[{ required: true, message: "Value is required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Date"
              name="date"
              rules={[
                {
                  required: true,
                  message: "Date is required",
                },
              ]}
            >
              <DatePicker
                format={"YYYY-MM-DD"}
                style={{ width: "100%" }}
                placement="bottomLeft"
                disabledDate={disabledFutureDate}
              ></DatePicker>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
