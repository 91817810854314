import {
  ModalProps,
  FormProps,
  Form,
  Modal,
  useSelect,
  Row,
  Col,
  Select,
  DatePicker,
  Input,
  FormInstance,
  notification,
} from "@pankod/refine-antd";
import { useCreate, useGetIdentity, useList } from "@pankod/refine-core";
import {
  getSelectedUserLastPlanExpiryDate,
  getUserEmails,
} from "graphql/queries";
import { IPlanMappings, ISubscriptions } from "interfaces";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { createSubscription } from "rest";
import { nhost } from "utility";
import { debounce } from 'lodash';

type Props = {
  modalProps: ModalProps;
  formProps: FormProps;
  closeModal: any;
  tableQueryResult: any;
  form: FormInstance;
};

export const CreateSubscriptions: React.FC<Props> = ({
  modalProps,
  formProps,
  closeModal,
  tableQueryResult,
  form,
}) => {
  const { data: user } = useGetIdentity();
  // States
  const [planSelected, setPlanSelected] = useState<any>("");
  const [startDate, setStartDate] = useState<any>(dayjs());
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [selectedPlanName, setSelectedPlanName] = useState<string>("");
  const [selectedDuration, setSelectedDuration] = useState<string>("");
  const [newSearch,setNewSearch] = useState("")
  const [limit,setLimit] = useState(50);


  const { selectProps: selectSubscriptionPlanProps, queryResult } =
    useSelect<ISubscriptions>({
      resource: "indiacharts_subscription_plans",
      metaData: {
        fields: ["id", "name", "label"],
      },
      optionLabel: "label",
      optionValue: "id",
      filters: [
        {
          operator: "and",
          value: [
            {
              field: "name",
              operator: "ne",
              value: "free",
            },
            {
              field: "active",
              operator: "eq",
              value: true,
            },
          ],
        },
      ],
    });

  const { selectProps: selectPlanMapProps } = useSelect<IPlanMappings>({
    resource: "indiacharts_plan_mappings",
    metaData: {
      fields: ["id", "duration", "plan_id"],
    },
    optionLabel: "duration",
    optionValue: "id",
    filters: [
      {
        field: "plan_id",
        operator: "eq",
        value: planSelected,
      },
    ],
    queryOptions: {
      enabled: !!planSelected,
    },
  });

  // Get user email, displayName
  const { data ,refetch} = useQuery<any>({
    queryKey: ["get-user-emails"],
    queryFn: () => getUserEmails({
      where: {
        _or: [
          { displayName: { _ilike: `%${newSearch}%` } },
          { email: { _ilike: `%${newSearch}%` } },
          { phoneNumber: { _ilike: `%${newSearch}%` } },
        ],
      },
      offset:0,
      limit: limit,
      }),
  });

  


  // List data from Plan mapping table
  const ListData = useList<IPlanMappings>({
    resource: "indiacharts_plan_mappings",
    metaData: {
      fields: ["id", "duration", "cost"],
    },
  });

  //
  const { data: selectedUserLastExpiryDate } = useQuery<any>({
    queryKey: ["GETUserLastPlanExpiryDate", selectedUser],
    queryFn: () => getSelectedUserLastPlanExpiryDate(selectedUser || ""),
    enabled: Boolean(selectedUser?.length) && Boolean(selectedUser),
  });

  useEffect(() => {
    if (selectedUserLastExpiryDate?.expiry_date) {
      if (
        selectedUserLastExpiryDate?.expiry_date < dayjs().format("YYYY-MM-DD")
      ) {
        form?.setFieldsValue({ start_date: dayjs() });
      } else {
        form?.setFieldsValue({
          start_date: dayjs(selectedUserLastExpiryDate?.expiry_date).add(
            1,
            "day"
          ),
        });
      }
    } else {
      form?.setFieldsValue({ start_date: dayjs() });
    }
  }, [JSON.stringify(selectedUserLastExpiryDate)]);

  function findCost(idValue: any) {
    const foundId: any = ListData?.data?.data?.find(
      (_o: any) => idValue == _o.id
    );
    form.setFields([{ name: "cost", value: foundId.cost * foundId.duration }]);
  }

  function setEndDate(durationId: any) {
    const foundDuration: any = ListData?.data?.data?.find(
      (_o: any) => durationId == _o.id
    );
    const futureDate = form
      ?.getFieldValue("start_date")
      .add(foundDuration.duration, "month");
    console.log(futureDate, ":date");
    form?.setFieldsValue({ expiry_date: futureDate });
  }

  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = async (formData: ISubscriptions) => {
    try {
      setLoading(true);
      await createSubscription({
        user_id: formData?.user_id,
        start_date: dayjs(formData?.start_date).format("YYYY-MM-DD"),
        expiry_date: dayjs(formData?.expiry_date).format("YYYY-MM-DD"),
        plan_id: formData?.plan_id,
        plan_mapping_id: formData?.plan_mapping_id,
        amount: parseInt(String(formData?.cost)),
        transaction_id: formData?.transaction_id,
        active: true,
        payment_method: "offline",
        plan_name: selectedPlanName,
        duration: selectedDuration,
      }).then((res: any) => {
        closeModal();
        formProps?.form?.resetFields();
        setSelectedUser(null);
        notification.open({
          message: "Subscription created successfully!",
          type: "success",
        });
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  //options
  const userFilterOptions = (input: any, option: any) => {
    setNewSearch(input)
    const checkPhoneNumber =data?.users
      ?.filter((dt: any) => dt?.phoneNumber?.includes(input))
      ?.map((imx: any) => imx?.id);
    const checkEmail = data?.users
      ?.filter((dt: any) => dt?.email?.includes(input))
      ?.map((imx: any) => imx?.id);
    const checkInput = (item: String) =>
      (item?.toLowerCase() ?? "").includes(input?.toLowerCase());

    return (
      checkInput(option?.label) ||
      checkPhoneNumber?.includes(option?.key) ||
      checkEmail?.includes(option?.key)
    );
  };

  const handlePopupScroll = (e:any) => {
    const { target } = e;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      // Scrolled to the bottom, fetch more data
      setLimit(limit+50)  
      refetch() 
     }
  };

  const handleDropdownVisibleChange = (visible:any) => {
    if (!visible) {

      // Dropdown is closed, call your function here
      setLimit(50)
    
    }
  };
  
  const debouncedSearch = debounce((term) => {
    refetch()
  }, 600); 

  useEffect(() => {
    debouncedSearch(newSearch);
    return () => {
      debouncedSearch.cancel();
    };
  }, [newSearch, debouncedSearch]);


  return (
    <Modal
      {...modalProps}
      title="Create Subscriptions"
      onCancel={() => {
        setSelectedUser(null);
        setPlanSelected("");
        formProps?.form?.resetFields();
        closeModal();
      }}
    >
      <Form size="large" layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={[16, 0]}>
          <Col xs={16} lg={16}>
            <Form.Item
              name="user_id"
              label="Select user"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                              placeholder={"Username, email or phone number"}
                showSearch
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  userFilterOptions(input, option)
                }
                onSelect={(v: string) => setSelectedUser(v)}
                onPopupScroll={handlePopupScroll}
                onDropdownVisibleChange={handleDropdownVisibleChange}
              >
                {data?.users?.map((item: any) => (
                  <Select.Option
                    label={`${item?.displayName}-${item?.phoneNumber}-${item?.email}`}
                    key={item?.id}
                    value={item?.id}
                  >
                    {`${item?.displayName}-(${item?.phoneNumber})-(${item?.email})`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col xs={8} lg={8}>
            <Form.Item
              label="Active"
              name="active"
              rules={[{ required: true }]}
            >
              <Radio.Group defaultValue={true}>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col> */}
          <Col xs={8} lg={8}>
            <Form.Item
              name="plan_id"
              label="Plan"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                {...selectSubscriptionPlanProps}
                onSelect={(e: any, option: any) => {
                  setSelectedPlanName(option.label);
                  setPlanSelected(e);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              name="plan_mapping_id"
              label="Duration(months)"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                {...selectPlanMapProps}
                onChange={(e: any) => {
                  const presentDate = dayjs(
                    form?.getFieldValue(["start_date"])
                  ).endOf("day");
                  const currentDuration: any = ListData?.data?.data?.find(
                    (_o: any) => e == _o.id
                  );
                  const newCost = currentDuration?.cost;
                  const newFutureDate = presentDate.add(
                    currentDuration?.duration,
                    "month"
                  );

                  form?.setFieldsValue({
                    expiry_date: newFutureDate,
                    plan_mapping: { cost: newCost },
                  });
                  setSelectedDuration(currentDuration?.duration);
                }}
                disabled={!planSelected.length}
              />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              name="start_date"
              label="Start date"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker
                disabled
                onMouseEnter={() => {}}
                onMouseUp={() => {}}
                onChange={(e: any) => {
                  setEndDate(form?.getFieldValue("plan_mapping_id"));
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item name="expiry_date" label="Expiry date">
              <DatePicker disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item label="Cost (&#8377;)" name="cost">
              <Input />
            </Form.Item>
          </Col>

          <Col xs={8} lg={8}>
            <Form.Item label="Transaction ID" name="transaction_id">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
