import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  InputNumber,
  UploadProps,
  useSelect,
  Select,
  Row,
  Col,
  Image,
  Upload,
  Button,
  Icons,
} from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";
import { useState } from "react";
import { nhost } from "utility/nhost";

type EditAnalystsProps = {
  modalProps: ModalProps;
  formProps: FormProps;
};

export const EditAnalysts: React.FC<EditAnalystsProps> = ({
  modalProps,
  formProps,
}) => {
  const { UploadOutlined } = Icons;
  const { data: user } = useGetIdentity();
  const [fileList, setFileList] = useState<any>([]);
  const formWatch = {
    image_url: Form.useWatch("image_url", formProps.form),
  };

  // Image Upload
  const handleImageUpload = async (file: File) => {
    let fileres = await nhost.storage.upload({ file, bucketId: "public" });
    const imageUrl = await nhost.storage.getPublicUrl({
      fileId: fileres.fileMetadata?.id || "",
    });
    formProps?.form?.setFieldsValue({ image_url: imageUrl });
    return imageUrl;
  };

  const thumbProps: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      handleImageUpload(file);
      return false;
    },
    fileList,
  };

  return (
    <Modal {...modalProps} title="Edit Analysts">
      <Form {...formProps} size="large" layout="vertical">
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item label="Email" name="email" rules={[{ required: false }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Position"
              name="position"
              rules={[{ required: false }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Contact no"
              name="contact_no"
              rules={[{ required: false }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Biography"
              name="biography"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="External"
              name="external"
              rules={[{ required: true }]}
            >
              <Select
                options={[
                  {
                    value: true,
                    label: "True",
                  },
                  {
                    value: false,
                    label: "False",
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Organisation"
              name="organisation"
              rules={[{ required: false }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Form.Item name="image_url" label="Image url" hidden>
            <Input />
          </Form.Item>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Image url"
              name="image_url"
              rules={[{ required: false }]}
            >
              <Upload {...thumbProps}>
                <Button icon={<UploadOutlined />}>Select File To Upload</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item name="image_url" label="Image preview">
              {formWatch.image_url !== null ? (
                <Image
                  width={120}
                  height={120}
                  src={formWatch.image_url}
                  alt={"Icon"}
                />
              ) : (
                <p>No Image Available For Preview</p>
              )}
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Active"
              name="active"
              rules={[{ required: false }]}
            >
              <Select
                options={[
                  {
                    value: true,
                    label: "true",
                  },
                  {
                    value: false,
                    label: "false",
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
