import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  Row,
  Col,
  DatePicker,
} from "@pankod/refine-antd";

type Props = {
  modalProps: ModalProps;
  formProps: FormProps;
};

export const CreateCompany: React.FC<Props> = ({ modalProps, formProps }) => {
  const { TextArea } = Input;

  return (
    <Modal {...modalProps} title="Create Company">
      <Form {...formProps} layout="vertical" size="large">
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item label="Code" name="code" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Industry Code"
              name="industry_code"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Short name"
              name="short_name"
              rules={[{ required: false }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Industry ID"
              name="industry_id"
              rules={[{ required: false }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Business group code"
              name="business_group_code"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Business group ID"
              name="business_group_id"
              rules={[{ required: false }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item label="Cinno" name="Cinno" rules={[{ required: false }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Remarks"
              name="remarks"
              rules={[{ required: false }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Deleted"
              name="deleted"
              rules={[{ required: false }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Incorporated on"
              name="incorporated_on"
              rules={[{ required: false }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="First public issued on"
              name="first_public_issued_on"
              rules={[{ required: false }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item label="Slug" name="slug" rules={[{ required: false }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: false }]}
            >
              <TextArea cols={120} rows={6} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
