import {
  List,
  Input,
  // RefreshButton
} from "@pankod/refine-antd";
import { useRef, useEffect, useCallback, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";

// Utility
import { formatDate } from "utility";

// Components
import CustomAgGrid from "components/CustomAgGrid";

// API
import { getUnsubscriptionsList } from "rest";

// Hooks
import { useDebounce } from "hooks/useDebounce";

export const UnSubscriptionsList: React.FC = () => {
  // Ref
  const gridRef = useRef<any>(null);

  // Variable
  const LIMIT = 10;

  // State
  const [searchInput, setSearchInput] = useState("");

  // Hook
  const debouncedSearchInput = useDebounce(searchInput, 500);

  // Columns
  const columnDefs = [
    {
      field: "display_name",
      headerName: "Name",
      headerClass: "ag-header-start",
      width: 180,
    },
    {
      field: "email",
      headerName: "Email",
      headerClass: "ag-header-start",
      flex: 1,
    },
    {
      field: "phone_number",
      headerName: "Phone No.",
      headerClass: "ag-header-start",
      width: 140,
    },
    {
      field: "plan_name",
      headerName: "Plan",
      headerClass: "ag-header-start",
      width: 130,
    },
    {
      field: "duration",
      headerName: "Duration",
      headerClass: "ag-header-start",
      width: 100,
    },
    {
      field: "start_date",
      headerName: "Start Date",
      headerClass: "ag-header-start",
      cellRenderer: (params: any) => formatDate(params?.value),
      width: 120,
    },
    {
      field: "expiry_date",
      headerName: "End Date",
      headerClass: "ag-header-start",
      cellRenderer: (params: any) => formatDate(params?.value),
      width: 120,
    },
    {
      field: "account_created_date",
      headerName: "Created At",
      headerClass: "ag-header-start",
      cellRenderer: (params: any) => formatDate(params?.value),
      width: 130,
    },
  ];

  // API
  const { fetchNextPage, isLoading } = useInfiniteQuery({
    queryKey: [
      "GET_UNSUBSCRIPTION_LIST",
      {
        offset: 0,
        limit: LIMIT,
        type: "unsubscription",
        search: debouncedSearchInput,
      },
    ],
    queryFn: ({ pageParam = 0 }) =>
      getUnsubscriptionsList([
        "GET_UNSUBSCRIPTION_LIST",
        {
          offset: pageParam * LIMIT,
          limit: LIMIT,
          type: "unsubscription",
          search: debouncedSearchInput,
        },
      ]),
    getNextPageParam: (lastPage: any, pages: any) => lastPage.nextCursor,
    getPreviousPageParam: (firstPage: any, pages: any) => firstPage.prevCursor,
    enabled: false,
  });

  // On Grid Ready
  const onGridReady = useCallback((params: any) => {
    const dataSource = {
      getRows: (params: any) => {
        const page = params?.request?.endRow / LIMIT ?? 0;
        fetchNextPage({
          pageParam: page - 1,
        }).then((res: any) => {
          let lastRow = -1;
          const rowData = res?.data?.pages?.[page - 1];
          params.success({ rowData, lastRow });
        });
      },
    };

    gridRef.current = params;
    params.api.setServerSideDatasource(dataSource);
    params.api.sizeColumnsToFit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // UseEffect
  useEffect(() => {
    if (gridRef.current?.api) {
      gridRef.current.api.refreshServerSide({ route: undefined, purge: true });
    }
  }, [gridRef, debouncedSearchInput]);

  return (
    <List
      pageHeaderProps={{
        extra: [
          // <RefreshButton
          //   onClick={() => {
          //     handleRefresh();
          //   }}
          // />,
        ],
      }}
    >
      <div style={{ marginBottom: "10px", width: "200px" }}>
        <Input
          placeholder="Search"
          value={searchInput}
          onChange={(e: any) => {
            setSearchInput(e.target.value);
          }}
        />
      </div>
      <div style={{ height: "450px" }}>
        <CustomAgGrid
          ref={gridRef}
          columnDefs={columnDefs}
          isLoading={isLoading}
          gridOptions={{
            rowModelType: "serverSide",
            onGridReady,
            cacheBlockSize: LIMIT,
            serverSideInfiniteScroll: true,
          }}
        />
      </div>
    </List>
  );
};
