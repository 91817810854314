import {
  Col,
  Form,
  FormProps,
  Input,
  Modal,
  ModalProps,
  Radio,
  Row,
  Select,
  useSelect,
  UploadProps,
  Upload,
  message,
  Image,
  Button,
  Icons,
} from "@pankod/refine-antd";
import { useCreate } from "@pankod/refine-core";
import React, { useState } from "react";
import { nhost } from "utility/nhost";

const { UploadOutlined } = Icons;
interface SubHeadersCreateProps {
  formProps: FormProps;
  modalProps: ModalProps;
  form: any;
  closeModal: any;
  refetchTable: any;
}

const SubHeadersCreate: React.FC<SubHeadersCreateProps> = ({
  formProps,
  modalProps,
  form,
  closeModal,
  refetchTable,
}) => {
  const [fileList, setFileList] = useState<any>([]);
  const [fileList2, setFileList2] = useState<any>([]);

  // Form Watch
  const page_id = Form.useWatch("page_id", form);
  const formWatch = {
    icon_link: Form.useWatch("lightmode_icon_link", formProps.form),
  };
  const formDarkWatch = {
    dark_icon_link: Form.useWatch("darkmode_icon_link", formProps.form),
  };

  // Icon Upload
  const handleImageUpload = async (file: File) => {
    let file_response = await nhost.storage.upload({
      file,
      bucketId: "public",
    });
    let icon_url = await nhost.storage.getPublicUrl({
      fileId: file_response.fileMetadata?.id || "",
    });
    // console.log("Light mode file id :::", file_response.fileMetadata?.id);
    formProps?.form?.setFieldsValue({
      lightmode_icon_link: icon_url,
      lightmode_file_id: file_response?.fileMetadata?.id,
    });
  };

  const handleDarkImageUpload = async (file: File) => {
    let file_response = await nhost.storage.upload({
      file,
      bucketId: "public",
    });
    let icon_url = await nhost.storage.getPublicUrl({
      fileId: file_response.fileMetadata?.id || "",
    });
    // console.log("Dark mode file id :::", file_response.fileMetadata?.id);
    formProps?.form?.setFieldsValue({
      darkmode_icon_link: icon_url,
      darkmode_file_id: file_response?.fileMetadata?.id,
    });
  };

  const thumbProps: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const isValidFileFormat =
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/svg+xml";
      if (!isValidFileFormat) {
        message.error(`only .png, .jpeg & .svg files are allowed to upload`);
        return isValidFileFormat;
      }
      setFileList([...fileList, file]);
      handleImageUpload(file);
      return false;
    },
    fileList,
  };

  const darkThumbProps: UploadProps = {
    onRemove: (file) => {
      const index = fileList2.indexOf(file);
      const newFileList = fileList2.slice();
      newFileList.splice(index, 1);
      setFileList2(newFileList);
    },
    beforeUpload: (file) => {
      const isValidFileFormat =
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/svg+xml";
      if (!isValidFileFormat) {
        message.error(`only .png, .jpeg & .svg files are allowed to upload`);
        return isValidFileFormat;
      }
      setFileList2([...fileList2, file]);
      handleDarkImageUpload(file);
      return false;
    },
    fileList: fileList2,
  };

  // console.log(page_id);
  const { selectProps: pageProps } = useSelect({
    resource: "indiacharts_pages",
    optionLabel: "name",
    optionValue: "id",
    pagination: {
      pageSize: 999,
    },
    metaData: {
      fields: ["id", "active", "name"],
    },
  });
  const { selectProps: podProps } = useSelect({
    resource: "indiacharts_pods",
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "active", "name"],
    },
    filters: [
      {
        field: "page_id",
        operator: "eq",
        value: page_id,
      },
    ],
    queryOptions: {
      enabled: !!page_id,
    },
    pagination: {
      pageSize: 9999,
    },
  });

  const { mutateAsync: createSubHeader } = useCreate();

  // Form Submit
  const onFinish = async (formData: any) => {
    await createSubHeader({
      resource: "indiacharts_sub_headers",
      values: {
        name: formData?.name,
        page_id: formData?.page_id,
        pod_id: formData?.pod_id,
        is_private: formData?.is_private,
        lightmode_icon_link: formData?.lightmode_icon_link,
        darkmode_icon_link: formData?.darkmode_icon_link,
        darkmode_file_id: formData?.darkmode_file_id,
        lightmode_file_id: formData?.lightmode_file_id,
        slug: formData?.slug,
      },
    }).then(() => {
      setFileList([]);
      setFileList2([]);
      formProps?.form?.resetFields();
      refetchTable.refetch();
      closeModal();
    });
  };

  return (
    <Modal
      {...modalProps}
      title="Create Sub headers"
      onCancel={() => {
        form?.resetFields();
        closeModal();
      }}
    >
      <Form
        {...formProps}
        form={form}
        size="large"
        layout="vertical"
        onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col lg={12} xs={24}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Field Required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item
              name="page_id"
              label="Pages"
              rules={[
                {
                  required: true,
                  message: "Field Required",
                },
              ]}
            >
              <Select {...pageProps} />
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item
              name="pod_id"
              label="Pods"
              rules={[
                {
                  required: true,
                  message: "Field Required",
                },
              ]}
            >
              <Select {...podProps} disabled={page_id === undefined} />
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item
              label="Slug"
              name="slug"
              rules={[
                { required: false },
                {
                  pattern: new RegExp(/^[^\s]/), // Check For Space At Beginning Only
                  message: "No spaces allowed",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item
              label="Private Access"
              name="is_private"
              rules={[
                {
                  required: true,
                  message: "Field Required",
                },
              ]}
            >
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item label="Upload Light Mode Icon">
              <Upload
                {...thumbProps}
                accept="image/png, image/jpeg, image/svg+xml"
              >
                <Button icon={<UploadOutlined />}>Click To Upload Icon</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item label="Light Mode Icon Preview">
              {formWatch.icon_link !== undefined ? (
                <Image
                  width={120}
                  height={120}
                  src={formWatch.icon_link}
                  alt={"Icon"}
                />
              ) : (
                <p>No Image Available For Preview</p>
              )}
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              name="lightmode_icon_link"
              label="Light Mode Icon Link"
              hidden
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              name="lightmode_file_id"
              label="Light Mode Icon Link"
              hidden
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item label="Upload Dark Mode Icon">
              <Upload
                {...darkThumbProps}
                accept="image/png, image/jpeg, image/svg+xml"
              >
                <Button icon={<UploadOutlined />}>Click To Upload Icon</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item label="Dark Mode Icon Preview">
              {formDarkWatch.dark_icon_link !== undefined ? (
                <Image
                  width={120}
                  height={120}
                  src={formDarkWatch.dark_icon_link}
                  alt={"Icon"}
                />
              ) : (
                <p>No Image Available For Preview</p>
              )}
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              name="darkmode_icon_link"
              label="Dark Mode Icon Link"
              hidden
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item name="darkmode_file_id" label="darkmode_file_id" hidden>
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default SubHeadersCreate;
