import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Table,
  useTable,
  List,
  RefreshButton,
  useModalForm,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  Form,
  Col,
  Input,
  Icons,
  Button,
} from "@pankod/refine-antd";
import { useShow } from "@pankod/refine-core";

import debounce from "lodash/debounce";

// Interface
import { IStocks, ICompanies } from "interfaces";

// Component
import { IndicesStocksEdit } from "./stocks_edit";
import { ViewStockPriceList } from "./view_company_stock_price";

export const StocksList: React.FC = () => {
  // Route Param
  const routeParams = useParams();

  // States
  const [searchTerm, setSearchTerm] = useState<any>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getId, setGetId] = useState<any>();
  const [tableName, setTableName] = useState<any>();

  // Companies By ID
  const { queryResult: companiesQueryResult } = useShow<ICompanies>({
    resource: "indiacharts_companies",
    id: routeParams?.id,
    metaData: {
      fields: ["id", "code"],
    },
  });

  // Stocks Table
  const { tableProps, tableQueryResult, sorter, setFilters, searchFormProps } =
    useTable<IStocks>({
      resource: "indiacharts_stocks",
      metaData: {
        fields: [
          "id",
          "ticker",
          "symbol",
          "security_code",
          "company_id",
          "isincode",
          "description",
          "deleted",
          "created_at",
          "updated_at",
          "listed_date",
          "listed_price",
          "company_code",
          { current_price: ["current_price", "high_price", "low_price"] },
        ],
      },
      initialPageSize: 20,
      initialSorter: [
        {
          field: "ticker",
          order: "asc",
        },
      ],
      permanentFilter: [
        {
          field: "company_id",
          operator: "eq",
          value: routeParams?.id,
        },
      ],
    });

  // Edit
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "indiacharts_stocks",
    action: "edit",
    metaData: {
      fields: [
        "id",
        "ticker",
        "symbol",
        "security_code",
        "company_id",
        "isincode",
        "description",
        "deleted",
        "created_at",
        "updated_at",
        "listed_date",
        "listed_price",
        "company_code",
      ],
    },
    redirect: false,
  });

  const handleSearch = debounce((e: any) => {
    const searchTerm = e;
    setFilters([
      {
        field: "ticker",
        operator: "contains",
        value: `%${searchTerm}%`,
      },
    ]);
  }, 500);

  return (
    <>
      {/* List */}
      <List
        title=""
        // headerProps={{
        //   extra: [
        //     <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
        //   ],
        // }}
        breadcrumb={false}
      >
        <Form {...searchFormProps}>
          <Col span={6}>
            <Form.Item name="name">
              {/* <Input
              onChange={(e) =>
                setFilters([
                  {
                    field: "ticker",
                    operator: "startswith",
                    value: e.target.value,
                  },
                ])
              }
              placeholder="Search by name"
              prefix={<Icons.SearchOutlined />}
            /> */}
              <Input
                // onChange={handleInputChange}
                onChange={(e: any) => {
                  // setSearchTerm(e?.target?.value)
                  handleSearch(e?.target?.value);
                }}
                placeholder="Search by name"
                value={searchTerm}
                prefix={<Icons.SearchOutlined />}
              />
            </Form.Item>
          </Col>
          {/* <SaveButton onClick={searchFormProps.form?.submit} /> */}
        </Form>
        <Table
          {...tableProps}
          rowKey={(record, index) => record.id + "_" + record.created_at}
          size="small"
        >
          <Table.Column<IStocks> dataIndex="ticker" title="Ticker" />
          <Table.Column<IStocks>
            dataIndex="security_code"
            title="Security Code"
          />
          <Table.Column<IStocks> dataIndex="symbol" title="Symbol" />
          <Table.Column<IStocks> dataIndex="isincode" title="ISIN Code" />
          <Table.Column<IStocks> dataIndex="description" title="Description" />
          <Table.Column<IStocks>
            dataIndex="created_at"
            title="Created At"
            render={(value) => <DateField value={value} format="lll" />}
            defaultSortOrder={getDefaultSortOrder("date", sorter)}
            sorter
          />
          <Table.Column<IStocks>
            dataIndex="current_price.current_price"
            title="Curr Price"
          />
          <Table.Column<IStocks>
            title="Actions"
            dataIndex="actions"
            key="actions"
            render={(_, record: IStocks) => (
              <Space>
                <EditButton
                  hideText
                  size="small"
                  recordItemId={record.id}
                  onClick={() => editShow(record.id)}
                />
                <Button
                  size="small"
                  onClick={() => {
                    setIsModalOpen(true);
                    setGetId(record?.ticker);
                    setTableName("stocks");
                  }}
                >
                  Current Price
                </Button>
              </Space>
            )}
          />
        </Table>
      </List>

      {/* Edit */}
      <IndicesStocksEdit
        editModalProps={editModalProps}
        editFormProps={editFormProps}
      />

      <ViewStockPriceList
        open={isModalOpen}
        setOpen={setIsModalOpen}
        currId={getId}
        tableName={tableName}
      />
    </>
  );
};
