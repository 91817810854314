import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  useTable,
  List,
  RefreshButton,
  Table,
  useModalForm,
  Icons,
  CreateButton,
  DateField,
  Form,
  Col,
  Input,
  getDefaultSortOrder,
} from "@pankod/refine-antd";
import { ICompanyDividend } from "interfaces";
import debounce from "lodash/debounce";
import { useParams } from "react-router-dom";
import { useState } from "react";
const { PlusOutlined } = Icons;

export const DividendList: React.FC<IResourceComponentsProps> = () => {
  const routeParams = useParams();
  const [searchTerm, setSearchTerm] = useState<any>("");
  const { tableProps, sorter, tableQueryResult, setFilters, searchFormProps } =
    useTable<ICompanyDividend>({
      resource: "indiacharts_company_dividend",
      initialSorter: [
        {
          field: "execution_date",
          order: "desc",
        },
      ],
      metaData: {
        fields: [
          "company_id",
          "company_code",
          "announcement_date",
          "type",
          "dividend_per_share",
          "dividend_percentage",
          "execution_date",
          "remarks",
          "modified_at",
          "instrument_type",
          "instrument_type_description",
          "id",
        ],
      },
      permanentFilter: [
        {
          field: "company_id",
          operator: "eq",
          value: routeParams?.id,
        },
      ],
    });

  const {
    formProps,
    modalProps,
    show: showModal,
  } = useModalForm({
    resource: "indiacharts_company_dividend",
    action: "create",
    metaData: {
      fields: [
        "company_id",
        "company_code",
        "announcement_date",
        "type",
        "dividend_per_share",
        "dividend_percentage",
        "execution_date",
        "remarks",
        "modified_at",
        "instrument_type",
        "instrument_type_description",
        "id",
      ],
    },
  });

  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "indiacharts_company_dividend",
    action: "edit",
    metaData: {
      fields: [
        "company_id",
        "company_code",
        "announcement_date",
        "type",
        "dividend_per_share",
        "dividend_percentage",
        "execution_date",
        "remarks",
        "modified_at",
        "instrument_type",
        "instrument_type_description",
        "id",
      ],
    },
  });

  const handleSearch = debounce((e: any) => {
    const searchTerm = e;
    setFilters([
      {
        field: "instrument_type_description",
        operator: "contains",
        value: `%${searchTerm}%`,
      },
    ]);
  }, 500);

  return (
    <List
      title=""
      // pageHeaderProps={{
      //   extra: [
      //     <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
      //     <CreateButton
      //       type="primary"
      //       icon={<PlusOutlined />}
      //       onClick={() => showModal()}
      //     />,
      //   ],
      // }}
    >
      <Form {...searchFormProps}>
        <Col span={6}>
          <Form.Item name="name">
            <Input
              onChange={(e: any) => {
                handleSearch(e?.target?.value);
              }}
              placeholder="Search by name"
              value={searchTerm}
              prefix={<Icons.SearchOutlined />}
            />
          </Form.Item>
        </Col>
      </Form>
      <Table {...tableProps} rowKey="id" size="small">
        {/* <Table.Column dataIndex="company_code" title="Company code" /> */}
        <Table.Column dataIndex="announcement_date" title="Announcement date" />
        <Table.Column dataIndex="type" title="Type" />
        <Table.Column
          dataIndex="dividend_per_share"
          title="Dividend per share"
        />
        <Table.Column
          dataIndex="dividend_percentage"
          title="Dividend percentage"
        />
        <Table.Column
          dataIndex="execution_date"
          title="Execution date"
          sorter={{ multiple: 2 }}
          defaultSortOrder={getDefaultSortOrder("execution_date", sorter)}
        />
        <Table.Column dataIndex="remarks" title="Remarks" />
        <Table.Column
          dataIndex="modified_at"
          title="Modified at"
          render={(value) => <DateField value={value} format="lll" />}
        />
        <Table.Column dataIndex="instrument_type" title="Instrument type" />
        <Table.Column
          dataIndex="instrument_type_description"
          title="Instrument type description"
        />
      </Table>
    </List>
  );
};
