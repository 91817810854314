import { Modal, useTable, Table } from "@pankod/refine-antd";
import { IStockPrice } from "interfaces";
import { useParams } from "react-router-dom";

type Props = {
  open: any;
  setOpen: any;
  currId: any;
  tableName: any;
};

export const ViewStockPriceList: React.FC<Props> = ({
  open,
  setOpen,
  currId,
  tableName,
}) => {
  const routeParams = useParams();
  // console.log("params", routeParams?.id);

  const { tableProps } = useTable<IStockPrice>({
    resource: "indiacharts_view_stock_price",
    metaData: {
      fields: [
        "ticker",
        "company_id",
        "current_price",
        "high_price",
        "low_price",
        "open_price",
        "close_price",
        "total_trade_quantity",
      ],
    },
    ...(tableName === "companies" && {
      permanentFilter: [
        {
          field: "company_id",
          operator: "eq",
          value: currId,
        },
      ],
    }),

    ...(tableName === "stocks" && {
      permanentFilter: [
        {
          field: "ticker",
          operator: "eq",
          value: currId,
        },
      ],
    }),
  });

  return (
    <Modal
      visible={open}
      onCancel={() => setOpen(false)}
      onOk={() => setOpen(false)}
    >
      <Table {...tableProps} pagination={false}>
        <Table.Column dataIndex="current_price" title="Current price" />
        <Table.Column dataIndex="high_price" title="High price" />
        <Table.Column dataIndex="low_price" title="Low price" />
        <Table.Column dataIndex="open_price" title="Open price" />
        <Table.Column dataIndex="close_price" title="Close price" />
        <Table.Column
          dataIndex="total_trade_quantity"
          title="Total traded quantity"
        />
      </Table>
    </Modal>
  );
};
