import {
  CreateButton,
  DateField,
  EditButton,
  getDefaultSortOrder,
  Icons,
  List,
  message,
  notification,
  RefreshButton,
  Space,
  Table,
  useModalForm,
  useTable,
} from "@pankod/refine-antd";
import CustomTag from "components/CustomTag";
import React from "react";
import PageCreate from "./create";
import PageEdit from "./edit";

const { PlusOutlined } = Icons;

const PageList: React.FC = () => {
  const { tableProps, tableQueryResult, sorter } = useTable({
    resource: "indiacharts_pages",
    initialSorter: [
      {
        field: "created_at",
        order: "asc",
      },
    ],
    initialPageSize: 10,
    metaData: {
      fields: ["id", "active", "name", "updated_at"],
    },
  });
  const {
    // form: createForm,
    formProps: createFormProps,
    modalProps: createModalProps,
    show: createShow,
    close: closeCreateShow,
  } = useModalForm({
    resource: "indiacharts_pages",
    action: "create",
    metaData: {
      fields: ["id", "active", "name", "route_name", "is_private"],
    },
    redirect: false,
    successNotification: false,
    onMutationSuccess: () => {
      // message.success("Pages Added !");
      notification.open({
        type: "success",
        message: "Pages Added !",
      });
    },
  });
  const {
    // form: createForm,
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "indiacharts_pages",
    action: "edit",
    metaData: {
      fields: ["id", "active", "name", "route_name", "is_private"],
    },
    redirect: false,
    successNotification: false,
    onMutationSuccess: () => {
      // message.success("Pages Added !");
      notification.open({
        type: "success",
        message: "Page Updated Successfully !",
      });
    },
  });
  return (
    <List
      pageHeaderProps={{
        extra: [
          <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
          <CreateButton
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => createShow()}
          />,
        ],
      }}
    >
      <Table {...tableProps} rowKey="id" size="small">
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column
          dataIndex="active"
          title="Is Active"
          render={(value: any) => (
            <CustomTag type={value} text={value ? "Active" : "In Active"} />
          )}
        />
        <Table.Column
          dataIndex="updated_at"
          title="Updated Date"
          render={(value) => <DateField value={value} format="lll" />}
          defaultSortOrder={getDefaultSortOrder("updated_at", sorter)}
          sorter
        />
        <Table.Column
          title="Actions"
          // dataIndex="actions"
          render={(record) => (
            <Space>
              <EditButton
                hideText
                size="small"
                type="link"
                recordItemId={record?.id}
                onClick={() => editShow(record?.id)}
              />
              {/* <DeleteButton hideText size="small" type="link" recordItemId={record?.id} /> */}
            </Space>
          )}
        />
      </Table>
      <PageCreate
        formProps={createFormProps}
        modalProps={createModalProps}
        closeCreateShow={closeCreateShow}
      />
      <PageEdit formProps={editFormProps} modalProps={editModalProps} />
    </List>
  );
};

export default PageList;
