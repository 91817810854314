import {
    Form,
    Input,
    ModalProps,
    FormProps,
    Modal,
    InputNumber,
    useSelect,
    Select,
    Row,
    Col,
    DatePicker,
  } from "@pankod/refine-antd";
  import { useGetIdentity } from "@pankod/refine-core";
  import dayjs from "dayjs";
  
  type CreateIndustriesProps = {
    modalProps: ModalProps;
    formProps: FormProps;
  };
  const { TextArea } = Input;
  
  export const CreateIndustry: React.FC<CreateIndustriesProps> = ({
    modalProps,
    formProps,
  }) => {
    return (
      <Modal {...modalProps} title="Create Industry">
        <Form {...formProps}   size="large" layout="vertical">
          <Row gutter={[16, 0]}>
            <Col xs={8} lg={8}>
              <Form.Item label="Code" name="code"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={8} lg={8}>
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={8} lg={8}>
              <Form.Item label="Broad Industry Code" name="broad_industry_code"
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
            </Col>
            {/* <Col xs={8} lg={8}>
              <Form.Item
                label="Business group code"
                name="business_group_code"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col> */}
          </Row>
        </Form>
      </Modal>
    );
  };
  