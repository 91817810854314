import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  useTable,
  List,
  RefreshButton,
  Table,
  useModalForm,
  Icons,
  CreateButton,
  EditButton,
  DeleteButton,
  Space,
  DateField,
  BooleanField,
  ShowButton,
  Form,
  Input,
  Typography,
  Col,
} from "@pankod/refine-antd";

import { Button } from "antd";

import debounce from "lodash/debounce";

// Interfaces
import { ICompanies } from "interfaces";

// Component
import { EditCompany } from "./edit";
import { CreateCompany } from "./create";
import { useState } from "react";
import { ViewStockPriceList } from "./view_company_stock_price";

// Icons
const { PlusOutlined, CheckCircleOutlined, CloseCircleOutlined } = Icons;
const { Text } = Typography;

export const CompaniesList: React.FC<IResourceComponentsProps> = () => {
  // States
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ellipsis, setEllipsis] = useState(true);
  const [getId, setGetId] = useState<any>();
  const [tableName, setTableName] = useState<any>();

  // Table
  const { tableProps, tableQueryResult, searchFormProps, setFilters } =
    useTable<ICompanies>({
      resource: "indiacharts_companies",
      metaData: {
        fields: [
          "id",
          "code",
          "name",
          "industry_code",
          "business_group_code",
          "short_name",
          "industry_id",
          "business_group_id",
          "cinno",
          "remarks",
          "deleted",
          "updated_at",
          "incorporated_on",
          "first_public_issued_on",
          "created_at",
          "slug",
          "description",
        ],
      },
      //   onSearch: (values: any) => {
      //     return [
      //         {
      //             field: "name",
      //             operator: "contains",
      //             value: values.name,
      //         },
      //     ];
      // },
    });

  // Create
  const {
    formProps,
    modalProps,
    show: createModal,
  } = useModalForm({
    resource: "indiacharts_companies",
    action: "create",
    metaData: {
      fields: [
        "id",
        "code",
        "name",
        "industry_code",
        "business_group_code",
        "short_name",
        "industry_id",
        "business_group_id",
        "cinno",
        "remarks",
        "deleted",
        "updated_at",
        "incorporated_on",
        "first_public_issued_on",
        "created_at",
        "slug",
        "description",
      ],
    },
  });

  // Edit
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "indiacharts_companies",
    action: "edit",
    metaData: {
      fields: [
        "id",
        "code",
        "name",
        "industry_code",
        "business_group_code",
        "short_name",
        "industry_id",
        "business_group_id",
        "cinno",
        "remarks",
        "deleted",
        "updated_at",
        "incorporated_on",
        "first_public_issued_on",
        "created_at",
        "slug",
        "description",
      ],
    },
  });

  const handleSearch = debounce((e: any) => {
    const searchValue = e?.target?.value;
    setFilters([
      {
        field: "name",
        operator: "contains",
        value: `%${searchValue}%`,
      },
    ]);
  }, 500);

  return (
    <List
      headerProps={{
        extra: [
          <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
          <CreateButton
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => createModal()}
          />,
        ],
      }}
    >
      <Form {...searchFormProps}>
        <Col span={6}>
          <Form.Item name="name">
            <Input
              // onChange={handleInputChange}
              onChange={(e: any) => handleSearch(e)}
              // value={searchTerm}
              placeholder="Search by name"
              prefix={<Icons.SearchOutlined />}
            />
          </Form.Item>
        </Col>
        {/* <SaveButton onClick={searchFormProps.form?.submit} /> */}
      </Form>
      {/* Table */}
      <Table {...tableProps} size="small" scroll={{ x: 3000 }}>
        <Table.Column<ICompanies>
          // width={150}
          dataIndex="code"
          title="Code"
          render={(_, record) => (
            <Space>
              <ShowButton
                size="small"
                type="link"
                icon={null}
                resource="indiacharts_companies"
                recordItemId={record?.id}
              >
                {record.code}
              </ShowButton>
            </Space>
          )}
        />
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column dataIndex="industry_code" title="Industry code" />
        <Table.Column
          dataIndex="business_group_code"
          title="Business group code"
        />
        <Table.Column dataIndex="short_name" title="Short name" />
        <Table.Column dataIndex="cinno" title="Cinno" />
        <Table.Column dataIndex="remarks" title="Remarks" />
        <Table.Column
          dataIndex="deleted"
          title="Deleted"
          render={(value) => (
            <BooleanField
              value={value === true}
              trueIcon={<CheckCircleOutlined style={{ color: "green" }} />}
              falseIcon={<CloseCircleOutlined style={{ color: "red" }} />}
              valueLabelTrue="true"
              valueLabelFalse="false"
            />
          )}
        />
        <Table.Column
          // width={200}
          dataIndex="updated_at"
          title="Updated at"
          render={(value) => <DateField value={value} format="lll" />}
        />
        <Table.Column dataIndex="incorporated_on" title="Incorporated on" />
        <Table.Column
          dataIndex="first_public_issued_on"
          title="First public issued on"
        />
        <Table.Column
          dataIndex="created_at"
          title="Created at"
          render={(value) => <DateField value={value} format="lll" />}
        />
        <Table.Column dataIndex="slug" title="Slug" />
        <Table.Column
          dataIndex="description"
          title="Description"
          render={(value) => (
            <Text
              style={ellipsis ? { width: 200 } : undefined}
              ellipsis={ellipsis ? { tooltip: `${value}` } : false}
            >
              {value}
            </Text>
          )}
        />
        <Table.Column<ICompanies>
          width={200}
          fixed="right"
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton
                hideText
                size="small"
                type="link"
                recordItemId={record.id}
                onClick={() => editShow(record.id)}
              />
              <DeleteButton
                hideText
                size="small"
                type="link"
                recordItemId={record.id}
                resourceNameOrRouteName="indiacharts_companies"
              />
              <Button
                // recordItemId={record?.id}
                icon={null}
                size="small"
                onClick={() => {
                  setIsModalOpen(true);
                  setGetId(record?.id);
                  setTableName("companies");
                }}
              >
                Current Price
              </Button>
            </Space>
          )}
        />
      </Table>

      {/* Create */}
      <CreateCompany formProps={formProps} modalProps={modalProps} />

      {/* Edit */}
      <EditCompany formProps={editFormProps} modalProps={editModalProps} />

      {/* Modal */}
      <ViewStockPriceList
        open={isModalOpen}
        setOpen={setIsModalOpen}
        currId={getId}
        tableName={tableName}
      />
    </List>
  );
};
