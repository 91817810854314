import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  useTable,
  List,
  RefreshButton,
  Table,
  useModalForm,
  Icons,
  CreateButton,
  EditButton,
  Space,
  Button,
  DateField,
  Typography,
  Menu,
} from "@pankod/refine-antd";
import { IHolidays } from "interfaces";
import { mutateHolidays } from "graphql/mutation";
import { CreateHolidays } from "./create";
import { EditHolidays } from "./edit";
import { ImportHolidays } from "./import";
const { PlusOutlined, ImportOutlined, DeleteOutlined } = Icons;

const { Text } = Typography;

export const HolidaysList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, tableQueryResult, setFilters } = useTable<IHolidays>({
    resource: "indiacharts_holidays",
    metaData: {
      fields: [
        "exchange_code",
        "holiday_date",
        "purpose",
        "created_at",
        "modified_at",
      ],
    },
    initialSorter: [
      {
        field: "holiday_date",
        order: "asc",
      },
    ],
  });

  const {
    formProps,
    modalProps,
    show: showModal,
  } = useModalForm({
    resource: "indiacharts_holidays",
    action: "create",
    metaData: {
      fields: [
        "exchange_code",
        "holiday_date",
        "purpose",
        "created_at",
        "modified_at",
      ],
    },
  });

  const {
    formProps: importFormProps,
    modalProps: importModalProps,
    show: showBulkInsertModal,
    close: closeBulkInsertModal,
  } = useModalForm<IHolidays>({
    resource: "indiacharts_holidays",
    action: "create",
    metaData: {
      fields: [
        "exchange_code",
        "holiday_date",
        "purpose",
        "created_at",
        "modified_at",
      ],
    },
  });

  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "indiacharts_holidays",
    action: "edit",
    metaData: {
      fields: [
        "exchange_code",
        "holiday_date",
        "purpose",
        "created_at",
        "modified_at",
      ],
    },
  });

  return (
    <List
      pageHeaderProps={{
        extra: [
          <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
          <CreateButton
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => showModal()}
          />,
          <CreateButton
            icon={<ImportOutlined />}
            children={<Text>Import</Text>}
            onClick={() => showBulkInsertModal()}
          />,
        ],
      }}
    >
      <Menu
        mode="horizontal"
        onSelect={(props) => {
          setFilters([
            {
              field: "exchange_code",
              operator: "eq",
              value: props.key,
            },
          ]);
          tableQueryResult?.refetch();
        }}
      >
        <Menu.Item key="BSE">BSE</Menu.Item>
        <Menu.Item key="NSE">NSE</Menu.Item>
      </Menu>
      <Table {...tableProps} size="small">
        <Table.Column dataIndex="exchange_code" title="Exchange" />
        <Table.Column
          dataIndex="holiday_date"
          title="Date"
          render={(value) => <DateField value={value} format="lll" />}
        />
        <Table.Column dataIndex="purpose" title="Purpose" />
        <Table.Column
          dataIndex="created_at"
          title="Created At"
          render={(value) => <DateField value={value} format="lll" />}
        />
        <Table.Column
          dataIndex="modified_at"
          title="Modified At"
          render={(value) => <DateField value={value} format="lll" />}
        />
        <Table.Column<IHolidays>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton
                hideText
                size="small"
                type="link"
                recordItemId={record?.holiday_date}
                onClick={() => editShow(record?.holiday_date)}
              />
              <Button
                type="link"
                icon={<DeleteOutlined style={{ color: "red" }} />}
                onClick={() =>
                  mutateHolidays(record?.exchange_code, record?.holiday_date)
                }
              />
            </Space>
          )}
        />
      </Table>
      <CreateHolidays modalProps={modalProps} formProps={formProps} />
      <EditHolidays modalProps={editModalProps} formProps={editFormProps} />
      <ImportHolidays
        modalProps={importModalProps}
        formProps={importFormProps}
        close={closeBulkInsertModal}
      />
    </List>
  );
};
