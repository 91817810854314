import {
  Col,
  Form,
  FormProps,
  Input,
  Modal,
  ModalProps,
  Radio,
  Row,
  Select,
  useSelect,
} from "@pankod/refine-antd";
import React from "react";

interface PodsEditProps {
  formProps: FormProps;
  modalProps: ModalProps;
}

const PodsEdit: React.FC<PodsEditProps> = ({ formProps, modalProps }) => {
  const { selectProps } = useSelect({
    resource: "indiacharts_pages",
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "active", "name"],
    },
    pagination: {
      pageSize: 9999,
    },
  });

  const { selectProps: selectInfoVideo } = useSelect({
    resource: "indiacharts_video_resources",
    optionLabel: "title",
    optionValue: "id",
    metaData: {
      fields: ["id", "title"],
    },
    pagination: {
      pageSize: 9999,
    },
    onSearch: (value) => [
      {
        field: "title",
        operator: "contains",
        value: `%${value}%`,
      },
    ],
  });

  return (
    <Modal {...modalProps} title="Edit Pods">
      <Form
        {...formProps}
        size="large"
        layout="vertical"
        // onFinish={(fd: any) => {
        //   console.log("fd : ", fd);
        // }}
        onFinish={(values: any) => {
          return (
            formProps.onFinish &&
            formProps.onFinish({
              ...values,
              info_video_id:
                values?.info_video_id === undefined
                  ? null
                  : values?.info_video_id,
            })
          );
        }}
      >
        <Row gutter={16}>
          <Col lg={12} xs={24}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Field Required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item
              name="page_id"
              label="Page"
              rules={[
                {
                  required: true,
                  message: "Field Required",
                },
              ]}
            >
              <Select {...selectProps} />
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item
              label="Private Access"
              name="is_private"
              rules={[
                {
                  required: true,
                  message: "Field Required",
                },
              ]}
            >
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item
              label="Active"
              name="active"
              rules={[
                {
                  required: true,
                  message: "Field Required",
                },
              ]}
            >
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item label="Route name" name="route_name">
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item label="Info video" name="info_video_id">
              <Select allowClear {...selectInfoVideo} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default PodsEdit;
