
import { IResourceComponentsProps, useExport, useNavigation } from "@pankod/refine-core";

import {
    List,
    RefreshButton,
    CreateButton,
    ExportButton,
    ShowButton,
    Table,
    useTable,
    Space,
    EditButton,
    DeleteButton,
    DateField,
    getDefaultSortOrder,
    useModalForm, Icons
} from "@pankod/refine-antd";




import { IQuery } from "interfaces";
import { CreateQuery } from "./create";
import { EditQuery } from "./edit";
const { PlusOutlined } = Icons;

export const QueryList: React.FC<IResourceComponentsProps> = () => {
    const { show } = useNavigation()

    const { tableProps, sorter, tableQueryResult } = useTable<IQuery>({
        initialSorter: [
            {
                field: "name",
                order: "asc",
            },
        ],
        metaData: {
            fields: ["id", "name", "description", "created_by","query_type", "config"],
        },
    });
   
   
    const { triggerExport, isLoading } = useExport<IQuery>();


    return (
        <List
        >
            <Table {...tableProps} rowKey="id" size="small">
            <Table.Column<IQuery> dataIndex="name" title="Label"
                    render={(_, record) => (
                        <Space>
                            <ShowButton size="small"
                                type="link"
                                icon={null}
                                resource="queries"
                                recordItemId={record.id}>
                                {record.name}
                            </ShowButton>

                        </Space>
                    )}

                />
                <Table.Column dataIndex="name" title="Name" />
                <Table.Column dataIndex="widget_type" title="Type" />

                <Table.Column dataIndex="description" title="Description" />
                <Table.Column dataIndex="config" title="Config" />

                <Table.Column
                    dataIndex="created_at"
                    title="Created Date"
                    render={(value) => <DateField value={value} format="lll" />}
                    defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
                    sorter
                />
                <Table.Column<IQuery>
                    title="Actions"
                    dataIndex="actions"
                    render={ (_, record) => (
                   
                    
                        <Space>
                             <EditButton
                                size="small"
                                icon={null}
                                type="link"
                                recordItemId={record.id}
                            />
                            <DeleteButton
                                size="small"
                                type="link"
                                icon={null}
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
            

        </List>
    );
};
