import {
  Form,
  Input,
  InputNumber,
  ModalProps,
  FormProps,
  Modal,
  Row,
  Col,
} from "@pankod/refine-antd";

type Props = {
  modalProps: ModalProps;
  formProps: FormProps;
  broad_industry_code: any;
};

export const CreateIndustry: React.FC<Props> = ({
  modalProps,
  formProps,
  broad_industry_code,
}) => {
  return (
    <Modal {...modalProps} title="Create Industry">
      <Form {...formProps} size="large" layout="vertical">
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={8} lg={8}>
            <Form.Item
              label="Code"
              name="code"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} type={"number"} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="broad_industry_code"
          initialValue={broad_industry_code}
        >
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
