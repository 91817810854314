import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Table,
  useTable,
  List,
  RefreshButton,
  CreateButton,
  Icons,
  useModalForm,
  Space,
  getDefaultSortOrder,
  DateField,
  EditButton,
  Button,
  Typography,
} from "@pankod/refine-antd";

// Interface
import { IIndexData } from "interfaces";

// Component
import { IndexDataCreate } from "./index_data_create";
import { IndexDataEdit } from "./index_data_edit";
import { IndexDataImport } from "./index_data_import";

// GraphQl
import { mutateIndexData } from "graphql/mutation";

// Icons
const { PlusOutlined, DeleteOutlined, ImportOutlined } = Icons;

// Typography
const { Text } = Typography;

export const IndexDataList: React.FC = () => {
  // Route Param
  const routeParams = useParams();

  // State
  const [object, setObject] = useState<IIndexData | any>({});

  // Indices Stocks Table
  const { tableProps, tableQueryResult, sorter } = useTable<IIndexData>({
    resource: "indiacharts_index_data",
    liveMode: "auto",
    metaData: {
      fields: [
        "created_at",
        "date",
        "ticker",
        "symbol",
        "index_id",
        "close_price",
        "created_by",
        "open_price",
        "high_price",
        "low_price",
      ],
    },
    initialPageSize: 20,
    initialSorter: [
      {
        field: "date",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "index_id",
        operator: "eq",
        value: routeParams?.id,
      },
    ],
  });

  // Create
  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createModalShow,
    close: createModalClose,
  } = useModalForm<IIndexData>({
    resource: "indiacharts_index_data",
    action: "create",
    redirect: false,
    metaData: {
      fields: [
        "ticker",
        "close_price",
        "open_price",
        "high_price",
        "low_price",
        "index_id",
        "symbol",
        "date",
        "created_by",
      ],
    },
  });

  // Import
  const {
    formProps: importFormProps,
    modalProps: importModalProps,
    show: showBulkInsertModal,
    close: closeBulkInsertModal,
  } = useModalForm<IIndexData>({
    resource: "indiacharts_index_data",
    action: "create",
    metaData: {
      fields: [
        "high_price",
        "low_price",
        "open_price",
        "close_price",
        "date",
        "index_id",
        "created_by",
        "created_at",
      ],
    },
  });

  return (
    <>
      {/* List */}
      <List
        title={""}
        breadcrumb={false}
        // headerProps={{
        //   extra: [
        //     <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
        //     <CreateButton
        //       type="primary"
        //       icon={<PlusOutlined />}
        //       onClick={() => createModalShow()}
        //     />,
        //     <CreateButton
        //       icon={<ImportOutlined />}
        //       children={<Text>Import</Text>}
        //       onClick={() => showBulkInsertModal()}
        //     />,
        //   ],
        // }}
      >
        <Table
          {...tableProps}
          rowKey={(record, index) => record.index_id + "_" + record.date}
          size="small"
          scroll={{ x: 1500 }}
        >
          <Table.Column<IIndexData>
            dataIndex="close_price"
            title="Close Price"
          />
          <Table.Column<IIndexData> dataIndex="open_price" title="Open Price" />
          <Table.Column<IIndexData> dataIndex="low_price" title="Low Price" />
          <Table.Column<IIndexData> dataIndex="high_price" title="High Price" />
          <Table.Column
            dataIndex="date"
            title="Date"
            render={(value) => <DateField value={value} format="YYYY-MM-DD" />}
            defaultSortOrder={getDefaultSortOrder("date", sorter)}
            sorter
          />
          <Table.Column<IIndexData>
            title="Actions"
            fixed="right"
            width={70}
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                {/* Edit Button */}
                <EditButton
                  hideText
                  size="small"
                  type="link"
                  onClick={() => {
                    setObject(record);
                  }}
                />
                {/* Delete Button */}
                <Button
                  type="link"
                  icon={<DeleteOutlined style={{ color: "#fa541c" }} />}
                  onClick={() =>
                    mutateIndexData(record?.date, record?.index_id).then(
                      (_r) => {
                        tableQueryResult?.refetch();
                      }
                    )
                  }
                />
              </Space>
            )}
          />
        </Table>
      </List>

      {/* Create */}
      <IndexDataCreate
        createModalProps={createModalProps}
        createFormProps={createFormProps}
        close={createModalClose}
        tableQueryResult={tableQueryResult}
      />

      {/* Edit */}
      <IndexDataEdit
        dataObject={object}
        setDataObject={setObject}
        tableQueryResult={tableQueryResult}
      />

      {/* Import  */}
      <IndexDataImport
        modalProps={importModalProps}
        formProps={importFormProps}
        close={closeBulkInsertModal}
        tableQueryResult={tableQueryResult}
      />
    </>
  );
};
