import {
  useTable,
  List,
  RefreshButton,
  Table,
  useModalForm,
  Icons,
  CreateButton,
  EditButton,
  DeleteButton,
  Space,
  Button,
  DateField,
} from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { ICompanyRights } from "interfaces";
import { useParams } from "react-router-dom";

const { PlusOutlined } = Icons;

export const CompanyRightsList: React.FC<IResourceComponentsProps> = () => {
  const routeParams = useParams();
  const { tableProps, tableQueryResult, sorter } = useTable<ICompanyRights>({
    resource: "indiacharts_rights",
    metaData: {
      fields: [
        "company_id",
        "company_code",
        "announcement_date",
        "existing_instrument_type_description",
        "offered_instrument_type_description",
        "existing_ratio",
        "offered_ratio",
        "existing_face_value",
        "offered_face_value",
        "issue_price",
        "record_date",
        "execution_date",
        "remarks",
        "deleted",
        "modified_at",
      ],
    },
    permanentFilter: [
      {
        field: "company_id",
        operator: "eq",
        value: routeParams?.id,
      },
    ],
  });

  const {
    formProps,
    modalProps,
    show: showModal,
  } = useModalForm({
    resource: "indiacharts_rights",
    action: "create",
    metaData: {
      fields: [
        "company_id",
        "company_code",
        "announcement_date",
        "existing_instrument_type_description",
        "offered_instrument_type_description",
        "existing_ratio",
        "offered_ratio",
        "existing_face_value",
        "offered_face_value",
        "issue_price",
        "record_date",
        "execution_date",
        "remarks",
        "deleted",
        "modified_at",
      ],
    },
  });

  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "indiacharts_rights",
    action: "edit",
    metaData: {
      fields: [
        "company_id",
        "company_code",
        "announcement_date",
        "existing_instrument_type_description",
        "offered_instrument_type_description",
        "existing_ratio",
        "offered_ratio",
        "existing_face_value",
        "offered_face_value",
        "issue_price",
        "record_date",
        "execution_date",
        "remarks",
        "deleted",
        "modified_at",
      ],
    },
  });

  return (
    <>
      <List
        title=""
        // pageHeaderProps={{
        //   extra: [
        //     <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
        //     <CreateButton
        //       type="primary"
        //       icon={<PlusOutlined />}
        //       onClick={() => showModal()}
        //     />,
        //   ],
        // }}
      >
        <Table {...tableProps} size="small">
          <Table.Column
            dataIndex="announcement_date"
            title="Announcement date"
          />
          <Table.Column
            dataIndex="existing_instrument_type_description"
            title="Existing instrument type description"
          />
          <Table.Column
            dataIndex="offered_instrument_type_description"
            title="Offered instrument type description"
          />
          <Table.Column dataIndex="existing_ratio" title="Existing ratio" />
          <Table.Column
            dataIndex="offered_ratio"
            title="Offered instrument type"
          />
          <Table.Column
            dataIndex="existing_face_value"
            title="Existing share value"
          />
          <Table.Column
            dataIndex="offered_face_value"
            title="Offered share value"
          />
          <Table.Column dataIndex="issue_price" title="Issue price" />
          <Table.Column dataIndex="record_date" title="Record date" />
          <Table.Column dataIndex="execution_date" title="Execution date" />
          <Table.Column dataIndex="remarks" title="Remarks" />
          <Table.Column dataIndex="deleted" title="Deleted" />
          <Table.Column
            dataIndex="modified_at"
            title="Modified at"
            render={(value) => <DateField value={value} format="lll" />}
          />
          {/* <Table.Column<ICompanyRights>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record)=>(
                        <Space>
                            <EditButton hideText
                            size="small"
                            type="link"
                            recordItemId={record.id}
                            onClick={()=> editShow(record.id)}
                        />
                        <DeleteButton hideText
                            size="small"
                            type="link"
                            recordItemId={record.id}
                            resourceNameOrRouteName="indiacharts_rights"
                        />
                        <Button
                            type="link"
                            icon={<DeleteFilled/>}
                            onClick={()=> mutateCompanyRights(record?.execution_date, record?.company_code)}
                        />
                        </Space>
                    )}
                /> */}
        </Table>
        {/* <CreateCompanyRights formProps={formProps} modalProps={modalProps} /> */}
        {/* <EditCompanyRights formProps={editFormProps} modalProps={editModalProps} /> */}
      </List>
    </>
  );
};
