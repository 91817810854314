import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  InputNumber,
  useSelect,
  Select,
  Row,
  Col,
  notification,
} from "@pankod/refine-antd";
import {
  useGetIdentity,
  useCreate,
  useList,
  useOne,
} from "@pankod/refine-core";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

// Interface
import { IIndices, ISentimentIndicatorHeader, IIndicator } from "interfaces";
import { useState } from "react";
import { gql } from "graphql-request";
import { mutateIndicatorHeader } from "graphql/mutation";

// Webhook
import { diffusionIndicatorAPI, sentimentIndicatorAPI } from "utility/webhook";

type Props = {
  modalProps: ModalProps;
  formProps: FormProps;
  refetchTable: any;
  createModalClose: any;
};

export const AddIndicator: React.FC<Props> = ({
  modalProps,
  formProps,
  refetchTable,
  createModalClose,
}) => {
  // Route Param
  const routeParams = useParams();

  const ListData = useList<ISentimentIndicatorHeader>({
    resource: "indiacharts_sentiment_indicator_headers",
    metaData: {
      fields: ["id", "label"],
    },
  });

  // console.log("listData", ListData);

  const [presentName, setPresentName] = useState<any>();

  const currObj = ListData?.data?.data?.find(
    (_o: any) => routeParams?.id == _o.id
  );
  const currName = currObj?.label;
  const currId: any = currObj?.id;

  // const { data }= useQuery<any>({
  //   queryKey: [currId],
  //   queryFn: (currId)=> gql`
  //     query{
  //       indiacharts_indicators(where: {id: $id}) {
  //         id
  //         headers
  //       }
  //     }
  //   `
  // })

  // console.log("queryData", currObj);
  // console.log("queryData", currName);
  // console.log("queryData", currId);

  const { data: user } = useGetIdentity();

  const { selectProps } = useSelect<IIndicator>({
    resource: "indiacharts_indicators",
    optionLabel: "name",
    optionValue: "id",
    fetchSize: 500,
    filters: [
      {
        field: "category",
        operator: "eq",
        value: "diffusion",
      },
    ],
    metaData: {
      fields: ["id", "name"],
    },
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value: `%${value}%`,
      },
    ],
  });

  // const { selectProps: headerSelector } = useSelect<ISentimentIndicatorHeader>({
  //   resource: "indiacharts_sentiment_indicator_headers",
  //   optionLabel: "label",
  //   optionValue: "id",
  //   fetchSize: 10,
  //   metaData: {
  //     fields: ["id", "label"],
  //   },
  //   onSearch: (value) => [
  //     {
  //       field: "label",
  //       operator: "startswith",
  //       value,
  //     },
  //   ],
  // });

  // Form Submit
  const onFinish = async (formData: IIndicator) => {
    const presentName = formData?.name;
    await mutateIndicatorHeader(currId, presentName)
      .then((res: any) => {
        notification.open({
          message: "Indicator",
          type: "success",
          description: "Add indicator",
        });
        refetchTable();
        diffusionIndicatorAPI();
        sentimentIndicatorAPI();
        createModalClose();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal {...modalProps} title="Add Indicator">
      <Form {...formProps} size="large" layout="vertical" onFinish={onFinish}>
        {/* <Row gutter={[16, 0]} >
            <Col xs={8} lg={8}>
              <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                <Input defaultValue={currName} />
              </Form.Item>
            </Col>
          </Row> */}
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Add Indicator"
              name="name"
              rules={[{ required: true }]}
            >
              <Select
                {...selectProps}
                showSearch
                filterOption={(input: string, option: any) => {
                  const label = option.label.toLowerCase().replace(/\s/g, "");
                  const inputValue = input.toLowerCase().replace(/\s/g, "");
                  return label.includes(inputValue);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
