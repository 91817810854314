import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  useTable,
  List,
  RefreshButton,
  Table,
  useModalForm,
  Icons,
  DateField,
} from "@pankod/refine-antd";
import { ISubscriptionPlans } from "interfaces";
import { useParams } from "react-router-dom";

export const CompanySubscriptionList: React.FC<
  IResourceComponentsProps
> = () => {
  const routeParams = useParams();

  const { tableProps, tableQueryResult, sorter, filters } =
    useTable<ISubscriptionPlans>({
      resource: "indiacharts_company_subscriptions",
      metaData: {
        fields: [
          "company_code",
          "company_id",
          "issue_date",
          "category",
          "subcategory",
          "shares_offered_reserved",
          "shares_bid_for",
          "total_meant_for_category",
          "modified_at",
        ],
      },
      permanentFilter: [
        {
          field: "company_id",
          operator: "eq",
          value: routeParams?.id,
        },
      ],
    });

  // console.log("sub", tableProps);

  const {
    formProps,
    modalProps,
    show: showModal,
  } = useModalForm({
    resource: "indiacharts_company_subscriptions",
    action: "create",
    metaData: {
      fields: [
        "company_code",
        "company_id",
        "issue_date",
        "category",
        "subcategory",
        "shares_offered_reserved",
        "shares_bid_for",
        "total_meant_for_category",
        "modified_at",
      ],
    },
  });

  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "indiacharts_company_subscriptions",
    action: "edit",
    metaData: {
      fields: [
        "company_code",
        "company_id",
        "issue_date",
        "category",
        "subcategory",
        "shares_offered_reserved",
        "shares_bid_for",
        "total_meant_for_category",
        "modified_at",
      ],
    },
  });

  return (
    <List
      title=""
      // pageHeaderProps={{
      //   extra: [
      //     <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
      //     // <CreateButton type="primary" icon={<PlusOutlined />} onClick={() => showModal()} />,
      //   ],
      // }}
    >
      <Table {...tableProps} size="small">
        {/* <Table.Column
                    dataIndex="company_code"
                    title="Company code"
                /> */}
        {/* <Table.Column
                    dataIndex="company_id"
                    title="company_id"
                /> */}
        <Table.Column dataIndex="category" title="Category" />
        <Table.Column
          dataIndex="issue_date"
          title="Issue date"
          render={(value) => <DateField format="LLL" value={value} />}
        />
        <Table.Column dataIndex="subcategory" title="Subcategory" />
        <Table.Column
          dataIndex="shares_offered_reserved"
          title="Shares offered reserved"
        />
        <Table.Column dataIndex="shares_bid_for" title="Shares bid for" />
        <Table.Column
          dataIndex="total_meant_for_category"
          title="Total meant for category"
        />
        <Table.Column
          dataIndex="modified_at"
          title="Modified at"
          render={(value) => <DateField format="LLL" value={value} />}
        />
        {/* <Table.Column<ICompanySubscriptions>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record)=>(
                        <Space>
                            <EditButton hideText
                            size="small"
                            type="link"
                            recordItemId={record.id}
                            onClick={()=> editShow(record.id)}
                        />
                        <DeleteButton hideText
                            size="small"
                            type="link"
                            recordItemId={record.id}
                            resourceNameOrRouteName="indiacharts_company_subscriptions"
                        />
                        </Space>
                    )}
                /> */}
      </Table>
      {/* <CreateSubscriptionPlans formProps={formProps} modalProps={modalProps} />
            <EditSubscriptionPlans formProps={editFormProps} modalProps={editModalProps} /> */}
    </List>
  );
};
