import React from "react";
import { useGetIdentity, useCustom, useApiUrl } from "@pankod/refine-core";
import { AntdLayout, Typography, Avatar, Space } from "@pankod/refine-antd";
import GraphiQL from "graphiql";
import { nhost } from "utility";
import { IQuery } from "interfaces";
import "graphiql/graphiql.min.css";
const { Text } = Typography;

interface QueryEditorProps{
    query:IQuery | undefined
}

interface PostUniqueCheckResponse {
    isAvailable: boolean;
}


const defaultQuery = `
{
    auditors {
      agencyname
    }
  }
`;



export const QueryEditor: React.FC<QueryEditorProps> = ({ query }) => {
    const { data: user } = useGetIdentity();
    const apiUrl = useApiUrl();
    // var queryConfig = `${query?.config}`

    function graphQLFetcher(graphQLParams: any) {
        return fetch(apiUrl, {
            method: "post",
            credentials: "omit",
            headers: { "Content-Type": "application/json",Authorization: 'Bearer ' + nhost.auth.getAccessToken()  },
            body: JSON.stringify(graphQLParams || {})
        }).then((response) => response.json());
    }
    return <>
        <div style={{ height: "600px" }}>
            {query &&  <GraphiQL fetcher={graphQLFetcher} query={query?.config} />}
        </div>

    </>;
};
