import {
  ListButton,
  Table,
  useModalForm,
  Space,
  EditButton,
  DeleteButton,
  CreateButton,
  Icons,
  List,
  Button,
  BooleanField,
} from "@pankod/refine-antd";
import { useParams, Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useList, useNavigation, useShow } from "@pankod/refine-core";
import moment from "moment";

// GraphQL
import { getSentimentHeaderIndicator } from "graphql/queries";

// Interface
import { IIndicator, IIndices, ISentimentIndicatorHeader } from "interfaces";

// Component
import { CreateIndicator } from "./indicator_create";
import { EditIndicator } from "./indicator_edit";
import { useState } from "react";
import { AddIndicator } from "./add_indicator";

// Webhook
import { diffusionIndicatorAPI } from "utility/webhook";

// Icons
const { PlusOutlined, CheckCircleOutlined, CloseCircleOutlined } = Icons;

export const ShowDiffusionIndicatorHeader: React.FC = () => {
  // Route Param
  const routeParams = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [object, setObject] = useState<IIndicator | any>({});

  const [getId, setGetId] = useState<any>();

  // Navigation
  const { goBack } = useNavigation();

  // Indicators Table Data
  const currID: any = routeParams?.id;
  const {
    data,
    isLoading,
    isFetching,
    refetch: refetchTable,
  } = useQuery<any>({
    queryKey: currID,
    queryFn: () => getSentimentHeaderIndicator(currID),
  });

  // Sentiment Indicator Header By ID
  const { queryResult } = useShow<ISentimentIndicatorHeader>({
    resource: "indiacharts_sentiment_indicator_headers",
    id: routeParams?.id,
    metaData: {
      fields: ["id", "label"],
    },
  });

  // Create
  const {
    formProps,
    modalProps,
    show: showModal,
    close: closeModal,
  } = useModalForm({
    resource: "indiacharts_indicators",
    action: "create",
    redirect: false,
    metaData: {
      fields: [
        "id",
        "name",
        "overbought",
        "oversold",
        "indexes",
        "category",
        "headers",
        "created_at",
        "created_by",
        "sortno",
        "updated_at",
        "updated_by",
        "db_field",
        "overbought_zones",
        "oversold_zones",
        "plot_type",
        "dual_color_enabled",
      ],
    },
  });

  const {
    modalProps: createIndicatorModalProps,
    formProps: createIndicatorFormProps,
    show: createIndicatorModalShow,
    close: createIndicatorModalClose,
  } = useModalForm({
    resource: "indiacharts_indicators",
    action: "create",
    redirect: false,
    metaData: {
      fields: [
        "id",
        "name",
        "overbought",
        "oversold",
        "overbought_zones",
        "oversold_zones",
        "category",
        "indexes",
        "sortno",
        "headers",
        "db_field",
        "created_at",
        "created_by",
        "updated_at",
        "updated_by",
        "plot_type",
        "dual_color_enabled",
      ],
    },
  });

  // Edit
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "indiacharts_indicators",
    action: "edit",
    redirect: false,
    metaData: {
      fields: [
        "id",
        "name",
        "overbought",
        "oversold",
        "indexes",
        "headers",
        "category",
        "created_at",
        "created_by",
        "sortno",
        "updated_at",
        "updated_by",
        "db_field",
        "overbought_zones",
        "plot_type",
        "oversold_zones",
        "dual_color_enabled",
      ],
    },
  });

  const postListQueryResult = useList<IIndices>({
    resource: "indiacharts_indices",
    metaData: {
      fields: ["id", "name"],
    },
    config: {
      pagination: { current: 1, pageSize: 250 },
    },
  });

  const sentimentHeaderQueryResult = useList<ISentimentIndicatorHeader>({
    resource: "indiacharts_sentiment_indicator_headers",
    metaData: {
      fields: ["id", "label"],
    },
    config: {
      pagination: { current: 1, pageSize: 20 },
    },
  });

  // Indicators Table Columns
  const columns: any = [
    {
      title: "Sort no.",
      width: 100,
      dataIndex: "sortno",
      key: "sortno",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_: any, record: any) => (
        <Link to={`/indicators/indicators_list/show/${record?.id}`}>
          {record?.name}
        </Link>
      ),
    },
    {
      title: "Is Active",
      width: 100,
      dataIndex: "isactive",
      render: (value: any) => (
        <BooleanField
          value={value === true}
          trueIcon={<CheckCircleOutlined style={{ color: "green" }} />}
          falseIcon={<CloseCircleOutlined style={{ color: "red" }} />}
          valueLabelTrue="true"
          valueLabelFalse="false"
        />
      ),
    },
    {
      title: "Linked Indexes",
      dataIndex: "indexes",
      key: "indexes",
      render: (_: any, record: any) => (
        <Space>
          {postListQueryResult.isFetching
            ? record.indexes?.join(", ")
            : postListQueryResult?.data?.data
                ?.filter((_p) => record.indexes.includes(_p.id))
                .map((_p) => _p.name)
                .join(",")}
        </Space>
      ),
    },
    {
      title: "Linked Headers",
      dataIndex: "headers",
      key: "headers",
      render: (_: any, record: any) => (
        <Space>
          {sentimentHeaderQueryResult.isFetching
            ? record.headers?.join(", ")
            : sentimentHeaderQueryResult?.data?.data
                ?.filter((_p) => record.headers.includes(_p.id))
                .map((_p) => _p.label)
                .join(",")}
        </Space>
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    // {
    //   title: "Plot type",
    //   dataIndex: "plot_type",
    //   key: "plot_type",
    // },
    {
      title: "DB Field",
      dataIndex: "db_field",
      key: "db_field",
    },
    {
      title: "Overbought 1",
      dataIndex: "overbought_zones",
      render: (_: any, record: any) => {
        // console.log("Value :::", _[0]?.value);
        // console.log("Value _ :::", _);
        const firstValue = _[0]?.value;
        // console.log(firstTitle);
        return <span>{firstValue}</span>;
      },
    },
    {
      title: "Oversold 1",
      dataIndex: "oversold_zones",
      render: (_: any, record: any) => {
        // console.log("Value :::", _[0]?.value);
        // console.log("Value _ :::", _);
        const firstValue = _[0]?.value;
        // console.log(firstTitle);
        return <span>{firstValue}</span>;
      },
    },
    {
      title: "Overbought 2",
      dataIndex: "overbought_zones",
      render: (_: any, record: any) => {
        // console.log("Value :::", _[1]?.value);
        // console.log("Value _ :::", _);
        const firstValue = _[1]?.value;
        // console.log(firstTitle);
        return <span>{firstValue}</span>;
      },
    },
    {
      title: "Oversold 2",
      dataIndex: "oversold_zones",
      render: (_: any, record: any) => {
        // console.log("Value :::", _[1]?.value);
        // console.log("Value _ :::", _);
        const firstValue = _[1]?.value;
        // console.log(firstTitle);
        return <span>{firstValue}</span>;
      },
    },
    // {
    //   title: "Over Bought",
    //   dataIndex: "overbought",
    //   key: "overbought",
    // },
    // {
    //   title: "Over Sold",
    //   dataIndex: "oversold",
    //   key: "oversold",
    // },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (_: any, record: any) => {
        return moment(record?.creates_at).format("lll");
      },
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (_: any, record: any) => {
        return moment(record?.updated_at).format("lll");
      },
    },
    {
      title: "Action",
      fixed: "right",
      width: 100,
      render: (_: any, record: any) => (
        <Space>
          <EditButton
            hideText={true}
            size="small"
            type="link"
            recordItemId={record.id}
            onClick={() => {
              setObject(record);
              console.log("Edit this record :::", record);
            }}
          />
          <DeleteButton
            hideText
            size="small"
            type="link"
            recordItemId={record.id}
            resourceNameOrRouteName="indiacharts_indicators"
            onSuccess={() => {
              refetchTable();
              diffusionIndicatorAPI();
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <List
      title={queryResult?.data?.data?.label}
      headerProps={{
        onBack: () => goBack(),
        extra: [
          <ListButton />,
          <Button type="primary" onClick={() => createIndicatorModalShow()}>
            <PlusOutlined />
            Add Indicator
          </Button>,
          <CreateButton
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => showModal()}
          />,
        ],
      }}
    >
      {/* Table */}
      <Table
        loading={isLoading || isFetching}
        rowKey="id"
        columns={columns}
        dataSource={data?.indiacharts_indicators}
        size="small"
        scroll={{ x: 2000 }}
      />

      {/* Create */}
      <CreateIndicator
        modalProps={modalProps}
        formProps={formProps}
        refetchTable={refetchTable}
        closeModal={closeModal}
        currentHeader={currID}
      />

      <AddIndicator
        modalProps={createIndicatorModalProps}
        formProps={createIndicatorFormProps}
        refetchTable={refetchTable}
        createModalClose={createIndicatorModalClose}
      />

      {/* Edit */}
      <EditIndicator
        modalProps={editModalProps}
        formProps={editFormProps}
        dataObject={object}
        setDataObject={setObject}
        refetchTable={refetchTable}
      />
    </List>
  );
};
