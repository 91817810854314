import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  List,
  RefreshButton,
  CreateButton,
  Table,
  useTable,
  DateField,
  useModalForm,
  Icons,
} from "@pankod/refine-antd";
import { ISplitsBonus } from "interfaces";
import { useParams } from "react-router-dom";
const { PlusOutlined } = Icons;

export const SplitsBonusList: React.FC<IResourceComponentsProps> = () => {
  const routeParams = useParams();

  const { tableProps, sorter, filters, tableQueryResult } =
    useTable<ISplitsBonus>({
      resource: "indiacharts_splits_bonus",
      metaData: {
        fields: [
          "id",
          "company_id",
          "company_code",
          "announcement_date",
          "type",
          "existing_value",
          "new_value",
          "recorded_date",
          "execution_date",
          "remarks",
          "modified_date",
        ],
      },
      permanentFilter: [
        {
          field: "company_id",
          operator: "eq",
          value: routeParams?.id,
        },
      ],
    });

  const {
    formProps,
    modalProps,
    show: showModal,
  } = useModalForm({
    resource: "indiacharts_splits_bonus",
    action: "create",
    metaData: {
      fields: [
        "id",
        "company_id",
        "company_code",
        "annonucement_date",
        "type",
        "existing_value",
        "new_value",
        "recorded_date",
        "execution_date",
        "remarks",
        "modified_date",
      ],
    },
  });

  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "indiacharts_splits_bonus",
    action: "edit",
    metaData: {
      fields: [
        "id",
        "company_id",
        "company_code",
        "annonucement_date",
        "type",
        "existing_value",
        "new_value",
        "recorded_date",
        "execution_date",
        "remarks",
        "modified_date",
      ],
    },
  });

  // console.log("spit", tableProps);

  return (
    <List
      title=""
      // pageHeaderProps={{
      //   extra: [
      //     <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
      //     <CreateButton
      //       type="primary"
      //       icon={<PlusOutlined />}
      //       onClick={() => showModal()}
      //     />,
      //   ],
      // }}
    >
      <Table {...tableProps} size="small">
        {/* <Table.Column
                    dataIndex="company_code"
                    title="Company code"
                /> */}
        <Table.Column dataIndex="announcement_date" title="Announcement date" />
        <Table.Column dataIndex="type" title="Type" />
        <Table.Column dataIndex="existing_value" title="Existing value" />
        <Table.Column dataIndex="new_value" title="New value" />
        <Table.Column dataIndex="recorded_date" title="Recorded date" />
        <Table.Column dataIndex="execution_date" title="Execution date" />
        <Table.Column dataIndex="remarks" title="Remarks" />
        <Table.Column
          dataIndex="modified_date"
          title="Modified date"
          render={(value) => <DateField value={value} format="lll" />}
        />
        {/* <Table.Column<ISplitsBonus>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton hideText
                                size="small"
                                type="link"
                                recordItemId={record.id}
                                onClick={() => editShow(record.id)}
                            />
                            <DeleteButton hideText
                                size="small"
                                type="link"
                                recordItemId={record.id}
                                resourceNameOrRouteName="indiacharts_splits_bonus"
                            />
                            <Button
                                type="link"
                                icon={<DeleteFilled/>}
                                onClick={()=> mutateSplitsBonus(record?.execution_date, record?.id)}
                            />
                        </Space>
                    )}
                /> */}
      </Table>
      {/* <CreateSplitsBonus formProps={formProps} modalProps={modalProps} />
            <EditSplitsBonus formProps={editFormProps} modalProps={editModalProps} /> */}
    </List>
  );
};
