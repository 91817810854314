import {
  Col,
  Form,
  FormProps,
  Input,
  Modal,
  ModalProps,
  Radio,
  Row,
} from "@pankod/refine-antd";
import React from "react";
import { isLowerCase } from "utility";

interface PageProps {
  formProps: FormProps;
  modalProps: ModalProps;
  closeCreateShow: any;
}

const PageCreate: React.FC<PageProps> = ({
  formProps,
  modalProps,
  closeCreateShow,
}) => {
  return (
    <Modal
      {...modalProps}
      title="Create Pages"
      onCancel={() => {
        formProps?.form?.resetFields();
        closeCreateShow();
      }}
    >
      <Form {...formProps} size="large" layout="vertical">
        <Row gutter={16}>
          <Col lg={12} xs={24}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Field Required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item
              name="route_name"
              label="Route Name"
              rules={[
                {
                  required: true,
                  message: "Field Required",
                },
                {
                  validator: (_, value) =>
                    isLowerCase(value) ? Promise.resolve() : Promise.reject(),
                  message: "Characters Should be in Lower case",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item
              label="Active"
              name="active"
              rules={[
                {
                  required: true,
                  message: "Field Required",
                },
              ]}
            >
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item
              label="Private Access"
              name="is_private"
              rules={[
                {
                  required: true,
                  message: "Field Required",
                },
              ]}
            >
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default PageCreate;
