import { useState } from "react";
import { List, Menu, Empty } from "@pankod/refine-antd";
import { useResource } from "@pankod/refine-core";

// Components
import ReferredUser from "components/partner-program/ReferredUser";
import PastTransactions from "components/partner-program/PastTransactions";
import SettlementHistory from "components/partner-program/SettlementHistory";

export const PartnerProgram = () => {
  const { id: partner_id } = useResource();

  // State
  const [currentMenu, setCurrentMenu] = useState<any>("Referred User");

  // Menu Handler
  const handleMenu = (e: any) => {
    setCurrentMenu(e?.key);
  };
  return (
    <>
      <List title="Partner Program">
        {partner_id ? (
          <>
            {/* Menu */}
            <Menu
              onClick={handleMenu}
              selectedKeys={[currentMenu]}
              mode="horizontal"
            >
              <Menu.Item key="Referred User">Referred User</Menu.Item>
              <Menu.Item key="Past Transactions">Past Transactions</Menu.Item>
              <Menu.Item key="Settlement History">Settlement History</Menu.Item>
            </Menu>

            <div className="partner-program">
              {/* Referred User */}
              {currentMenu === "Referred User" && <ReferredUser />}

              {/* Past Transactions */}
              {currentMenu === "Past Transactions" && <PastTransactions />}

              {/* Settlement History */}
              {currentMenu === "Settlement History" && <SettlementHistory />}
            </div>
          </>
        ) : (
          <Empty description="No Partner Data Found" />
        )}
      </List>
    </>
  );
};
