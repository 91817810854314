import { useRef, useEffect, useCallback } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useResource } from "@pankod/refine-core";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

// Components
import CustomAgGrid from "components/CustomAgGrid";

// Configs
import { pastTransactiontableHeader } from "components/partner-program/config";

// API
import { getRefereesTransaction } from "rest/partner-program";

const PastTransactionsTable = () => {
  // Ref
  const gridRef = useRef<any>(null);

  // URL Params
  const { id: partner_id } = useResource();

  // Redux
  const { userName, plan, status, startDate, endDate } = useSelector(
    (store: any) => store.PartnerProgram?.filterBy
  );

  // Variable
  const LIMIT = 50;

  // API
  const { fetchNextPage, isLoading } = useInfiniteQuery({
    queryKey: [
      "GET_REFEREES_TRANSACTION",
      {
        offset: 0,
        limit: LIMIT,
        refereeId: userName,
        planId: plan,
        isActive:
          status === "active" ? true : status === "inactive" ? false : status,
        startDate: dayjs(startDate).format("YYYY-MM-DD"),
        endDate: dayjs(endDate).format("YYYY-MM-DD"),
        partner_id: partner_id,
      },
    ],
    queryFn: ({ pageParam = 0 }) =>
      getRefereesTransaction([
        "GET_REFEREES_TRANSACTION",
        {
          offset: pageParam * LIMIT,
          limit: LIMIT,
          refereeId: userName,
          planId: plan,
          isActive:
            status === "active" ? true : status === "inactive" ? false : status,
          startDate: dayjs(startDate).format("YYYY-MM-DD"),
          endDate: dayjs(endDate).format("YYYY-MM-DD"),
          partner_id: partner_id,
        },
      ]),
    getNextPageParam: (lastPage: any, pages: any) => lastPage.nextCursor,
    getPreviousPageParam: (firstPage: any, pages: any) => firstPage.prevCursor,
    enabled: false,
  });

  // On Grid Ready
  const onGridReady = useCallback((params: any) => {
    const dataSource = {
      getRows: (params: any) => {
        const page = params?.request?.endRow / LIMIT ?? 0;
        fetchNextPage({
          pageParam: page - 1,
        }).then((res: any) => {
          let lastRow = -1;
          const rowData = res?.data?.pages?.[page - 1];
          params.success({ rowData, lastRow });
        });
      },
    };

    gridRef.current = params;
    params.api.setServerSideDatasource(dataSource);
    params.api.sizeColumnsToFit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // UseEffect
  useEffect(() => {
    if (gridRef.current?.api) {
      gridRef.current.api.refreshServerSide({ route: undefined, purge: true });
    }
  }, [gridRef, userName, plan, status, startDate, endDate]);

  return (
    <div className="table">
      <CustomAgGrid
        ref={gridRef}
        columnDefs={pastTransactiontableHeader}
        isLoading={isLoading}
        gridOptions={{
          rowModelType: "serverSide",
          onGridReady,
          cacheBlockSize: LIMIT,
          serverSideInfiniteScroll: true,
        }}
      />
    </div>
  );
};

export default PastTransactionsTable;
