import { IResourceComponentsProps, useGetIdentity } from "@pankod/refine-core";
import {
  Table,
  List,
  useTable,
  useModalForm,
  RefreshButton,
  CreateButton,
  Icons,
  EditButton,
  Space,
  DeleteButton,
  DateField,
} from "@pankod/refine-antd";

// Interface
import { IApplicationUpdates } from "interfaces";

// Component
import { CreateAppUpdate } from "./create";
import { EditApplicationUpdate } from "./edit";

export const ApplicationUpdateList: React.FC<IResourceComponentsProps> = () => {
  const { PlusOutlined } = Icons;

  const { data: user } = useGetIdentity();

  // Table
  const { tableProps, tableQueryResult } = useTable<IApplicationUpdates>({
    resource: "indiacharts_application_updates",
    metaData: {
      fields: [
        "id",
        "description",
        "date",
        "created_at",
        "updated_at",
        "created_by",
        "updated_by",
        "tag",
        "tag_bg_color",
        "tag_text_color",
      ],
    },
    initialSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
  });

  // Create
  const {
    formProps,
    modalProps,
    show: showModal,
    close: closeModal,
    form,
  } = useModalForm({
    resource: "indiacharts_application_updates",
    action: "create",
    redirect: false,
    metaData: {
      fields: [
        "description",
        "date",
        "created_by",
        "updated_by",
        "tag",
        "tag_bg_color",
        "tag_text_color",
      ],
    },
  });

  // Edit
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
    form: editForm,
  } = useModalForm({
    resource: "indiacharts_application_updates",
    action: "edit",
    redirect: false,
    metaData: {
      fields: [
        "id",
        "description",
        "date",
        "created_at",
        "created_by",
        "updated_at",
        "updated_by",
        "tag",
        "tag_bg_color",
        "tag_text_color",
      ],
    },
  });

  return (
    <List
      headerProps={{
        extra: [
          <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
          <CreateButton
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => showModal()}
          />,
        ],
      }}
    >
      {/* Table */}
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="description" title="Description" />
        <Table.Column dataIndex="date" title="Date" />
        <Table.Column
          dataIndex="created_at"
          title="Created At"
          render={(value) => <DateField format="LLL" value={value} />}
        />
        <Table.Column
          dataIndex="updated_at"
          title="Updated At"
          render={(value) => <DateField format="LLL" value={value} />}
        />
        <Table.Column dataIndex="tag" title="Tag" />
        {/* <Table.Column
          dataIndex="tag_bg_color"
          title="Bg Color"
        />
        <Table.Column
          dataIndex="tag_text_color"
          title="Text color"
        /> */}
        {/* <Table.Column
                    dataIndex="created_by"
                    title="Created By"
                />
                <Table.Column
                    dataIndex="updated_by"
                    title="Updated By"
                /> */}
        <Table.Column<IApplicationUpdates>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton
                hideText
                size="small"
                type="link"
                recordItemId={record.id}
                onClick={() => editShow(record.id)}
              />
              <DeleteButton
                size="small"
                hideText={true}
                recordItemId={record.id}
                resourceNameOrRouteName="indiacharts_application_updates"
              />
            </Space>
          )}
        />
      </Table>

      {/* Create */}
      <CreateAppUpdate
        modalProps={modalProps}
        formProps={formProps}
        closeModal={closeModal}
        refetchTable={tableQueryResult}
        form={form}
        currentUser={user?.id}
      />

      {/* Edit */}
      <EditApplicationUpdate
        modalProps={editModalProps}
        formProps={editFormProps}
        form={editForm}
      />
    </List>
  );
};
