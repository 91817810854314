import { createSlice } from "@reduxjs/toolkit";

const initialUser = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user") || "{}")
  : null;

const initialState = {
  userData: initialUser,
  customerTicketsTab:"all",
  currentPagination:1,
};

export const UserSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    setUserData: (state: any, action: any) => {
      state.userData = action.payload;
    },
    setLogout: (state: any) => {
      state.userData = null;
    },
    setCustomerTicketsTab: (state: any, action: any) => {
      state.customerTicketsTab = action.payload;
    },
    setCurrentPagination: (state: any, action: any) => {
      state.currentPagination = action.payload;
    },
   
  },
});

export const { setUserData, setLogout,setCustomerTicketsTab,setCurrentPagination, } = UserSlice.actions;

export default UserSlice.reducer;
