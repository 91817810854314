import { IResourceComponentsProps, useExport, useNavigation, useImport } from "@pankod/refine-core";

import {
    List,
    RefreshButton,
    CreateButton,
    ExportButton,
    ShowButton,
    Table,
    useTable,
    Space,
    EditButton,
    DeleteButton,
    DateField,
    getDefaultSortOrder,
    useModalForm, Icons, ImportButton,
} from "@pankod/refine-antd";
const { PlusOutlined } = Icons;


export interface IAdvanceDecline{
    advances: number
    declines: number
    unchanged: number
}

const columns= [
    {
        dataIndex: 'advances',
        title: 'Advances',
        key: 'Advances'
    },
    {
        dataIndex: 'declines',
        title: 'Declines',
        key: 'Declines'
    },
    {
        dataIndex: 'unchanged',
        title: 'Unchanged',
        key: 'Unchanged'
    },
]

export const AdvanceDeclineList: React.FC<IResourceComponentsProps> = () => {
    // const {tableQueryResult}= useTable()
    return (
        <List
            pageHeaderProps={{
                extra:[
                    // <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
                    <CreateButton type="primary" icon={<PlusOutlined />}/>,
                ]
            }}
        >
            <Table>
                <Table.Column
                    dataIndex={columns[0].dataIndex}
                    title="Advances"
                />
                <Table.Column
                    dataIndex={columns[1].dataIndex}
                    title="Declines"
                />
                <Table.Column
                    dataIndex={columns[2].dataIndex}
                    title="Unchanged"
                />
            </Table>
        </List>
    )
}