import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useTable,
  List,
  RefreshButton,
  CreateButton,
  Icons,
  useModalForm,
  Space,
  getDefaultSortOrder,
  DateField,
  EditButton,
  Button,
  Typography,
  notification,
} from "@pankod/refine-antd";

// Interface
import { IIndicatorData } from "interfaces";

// GraphQl
import { mutateBulkIndicatorData, mutateIndicatorData } from "graphql/mutation";

// Component
import { IndicatorDataCreate } from "./indicator_data_create";
import { IndicatorDataEdit } from "./indicator_data_edit";
import { IndicatorDataImport } from "./indicator_data_import";

// antd
import { Checkbox, Table } from "antd";
import { Popconfirm } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { AnySrvRecord } from "dns";

// Webhook
import { indicatorDataAPI } from "utility/webhook";

// Icons
const { PlusOutlined, DeleteOutlined, ImportOutlined } = Icons;

// Typography
const { Text } = Typography;

export const IndicatorDataList = () => {
  // Record id for multi select delete
  const [object, setObject] = useState<IIndicatorData | any>([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const routeParams = useParams();

  // Deselect all
  const start = () => {
    setLoading(true);
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };

  const onSelectChange = (newSelectedRowKeys: any) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const hasSelected = selectedRowKeys.length > 0;

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // Bulk delete
  const deleteBulk = async () => {
    const currId: any = routeParams?.id;
    await Promise.all(
      selectedRowKeys.map((value: any) => {
        return mutateIndicatorData(value, currId);
      })
    );
    notification.open({
      message: "Successfully deleted indicator data",
      type: "success",
    });
    indicatorDataAPI({
      indicator_id: routeParams?.id
    });
    tableQueryResult?.refetch();
    setSelectedRowKeys([]);
  };

  // Delete all

  const deleteAll = async () => {
    const currId: any = routeParams?.id;
    await mutateBulkIndicatorData(currId)
      .then((res) => {
        notification.open({
          message: "Indicator Data",
          type: "success",
          description: "Successfully deleted all indicator data",
        });
      })
      .then(() => {
        indicatorDataAPI({
          indicator_id: currId
        });
        tableQueryResult?.refetch();
      });
  };

  // Indicator Data Table
  const { tableProps, tableQueryResult, sorter } = useTable<IIndicatorData>({
    resource: "indiacharts_indicator_data",
    liveMode: "auto",
    metaData: {
      fields: ["created_at", "date", "indicator_id", "value", "created_by"],
    },
    initialPageSize: 20,
    initialSorter: [
      {
        field: "date",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "indicator_id",
        operator: "eq",
        value: routeParams?.id,
      },
    ],
  });

  // Create
  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createModalShow,
    close: createModalClose,
  } = useModalForm<IIndicatorData>({
    resource: "indiacharts_indicator_data",
    action: "create",
    redirect: false,
  });

  // Import
  const {
    formProps: importFormProps,
    modalProps: importModalProps,
    show: showBulkInsertModal,
    close: closeBulkInsertModal,
  } = useModalForm({
    resource: "indiacharts_indicator_data",
    action: "create",
    metaData: {
      fields: ["value", "date", "indicator_id", "created_by", "created_at"],
    },
  });

  return (
    <>
      <List
        breadcrumb={false}
        title={""}
        headerProps={{
          extra: [
            <Popconfirm
              placement="top"
              title="Delete all records?"
              okText="Yes"
              cancelText="No"
              onConfirm={deleteAll}
            >
              <Button
                style={{
                  borderColor: "red",
                  backgroundColor: "red",
                  color: "white",
                }}
              >
                Delete All
              </Button>
            </Popconfirm>,
            <Popconfirm
              placement="top"
              title="Delete selected records?"
              okText="Yes"
              cancelText="No"
              onConfirm={deleteBulk}
            >
              <Button
                // disabled={buttonDisabled}
                style={{ borderColor: "red" }}
                disabled={!hasSelected}
              >
                Delete Bulk
              </Button>
            </Popconfirm>,
            // <Button disabled={buttonDisabled} onClick={() => handleDeselectAll()}>Deselect All</Button>,
            // <Button
            //   type="primary"
            //   onClick={start}
            //   disabled={!hasSelected}
            //   loading={loading}
            // >
            //   Deselect All
            // </Button>,
            <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
            <CreateButton
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => createModalShow()}
            />,
            <CreateButton
              icon={<ImportOutlined />}
              children={<Text>Import</Text>}
              onClick={() => showBulkInsertModal()}
            />,
          ],
        }}
      >
        <Table
          {...tableProps}
          // rowKey={(record, index) => record.indicator_id + "_" + record.date}
          rowKey="date"
          size="small"
          rowSelection={rowSelection}
        >
          <Table.Column<IIndicatorData> dataIndex="value" title="Value" />
          <Table.Column
            dataIndex="date"
            title="Date"
            render={(value) => <DateField value={value} format="YYYY-MM-DD" />}
            defaultSortOrder={getDefaultSortOrder("date", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="created_at"
            title="Created At"
            render={(value) => <DateField value={value} format="lll" />}
            defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
            sorter
          />
          <Table.Column<IIndicatorData>
            title="Actions"
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                {/* Edit Button */}
                <EditButton
                  hideText
                  size="small"
                  type="link"
                  onClick={() => {
                    setObject(record);
                  }}
                />
                {/* Delete Button */}
                <Popconfirm
                  placement="top"
                  title={"Delete record?"}
                  // description={""}
                  onConfirm={() =>
                    mutateIndicatorData(
                      record?.date,
                      record?.indicator_id
                    ).then((_r) => {
                      indicatorDataAPI({
                        indicator_id: record?.indicator_id
                      });
                      tableQueryResult?.refetch();
                    })
                  }
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    type="link"
                    icon={<DeleteOutlined style={{ color: "#fa541c" }} />}
                  />
                </Popconfirm>
              </Space>
            )}
          />
        </Table>
      </List>

      {/* Create */}
      <IndicatorDataCreate
        createModalProps={createModalProps}
        createFormProps={createFormProps}
        close={createModalClose}
        tableQueryResult={tableQueryResult}
      />

      {/* Edit */}
      <IndicatorDataEdit
        dataObject={object}
        setDataObject={setObject}
        tableQueryResult={tableQueryResult}
      />

      {/* Import */}
      <IndicatorDataImport
        modalProps={importModalProps}
        formProps={importFormProps}
        close={closeBulkInsertModal}
        tableQueryResult={tableQueryResult}
      />
    </>
  );
};
