import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  useTable,
  List,
  RefreshButton,
  Typography,
  Table,
  useModalForm,
  Icons,
  CreateButton,
  EditButton,
  DeleteButton,
  Space,
  DateField,
  BooleanField,
  Menu,
  Avatar,
  Form,
  Col,
  Input,
  MenuProps,
} from "@pankod/refine-antd";
import debounce from "lodash/debounce";
import { IAnalysts } from "interfaces";
import { CreateAnalysts } from "./create";
import { EditAnalysts } from "./edit";
import { useState } from "react";
const { Text } = Typography;

const { PlusOutlined } = Icons;

export const AnalystsList: React.FC<IResourceComponentsProps> = () => {
  const [ellipsis, setEllipsis] = useState(true);
  const [searchTerm, setSearchTerm] = useState<any>("");
  const [current, setCurrent] = useState("true");

  const onMenuClick: MenuProps["onClick"] = (e: any) => {
    console.log("click ", e);
    setCurrent(e.key);
  };

  const { tableProps, tableQueryResult, filters, setFilters, searchFormProps } =
    useTable<IAnalysts>({
      resource: "indiacharts_analysts",
      metaData: {
        fields: [
          "id",
          "name",
          "email",
          "position",
          "contact_no",
          "biography",
          "external",
          "organisation",
          "image_url",
          "created_at",
          "updated_at",
          "active",
        ],
      },
      initialFilter: [
        {
          field: "active",
          operator: "eq",
          value: "true",
        },
      ],
    });

  const {
    formProps,
    modalProps,
    show: showModal,
    form,
  } = useModalForm({
    resource: "indiacharts_analysts",
    action: "create",
    metaData: {
      fields: [
        "id",
        "name",
        "email",
        "position",
        "contact_no",
        "biography",
        "external",
        "organisation",
        "image_url",
        "created_at",
        "updated_at",
        "active",
      ],
    },
  });

  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "indiacharts_analysts",
    action: "edit",
    redirect: false,
    metaData: {
      fields: [
        "id",
        "name",
        "email",
        "position",
        "contact_no",
        "biography",
        "external",
        "organisation",
        "image_url",
        "created_at",
        "updated_at",
        "active",
      ],
    },
  });

  const handleSearch = debounce((e: any) => {
    const searchTerm = e;
    setFilters([
      {
        field: "name",
        operator: "contains",
        value: `%${searchTerm}%`,
      },
    ]);
  }, 500);

  return (
    <List
      headerProps={{
        extra: [
          <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
          <CreateButton
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => showModal()}
          />,
        ],
      }}
    >
      <Form {...searchFormProps}>
        <Col span={6}>
          <Form.Item name="name">
            {/* <Input
              onChange={(e) =>
                setFilters([
                  {
                    field: "name",
                    operator: "startswith",
                    value: e.target.value,
                  },
                ])
              }
              placeholder="Search by name"
              prefix={<Icons.SearchOutlined />}
            /> */}
            <Input
              // onChange={handleInputChange}
              onChange={(e: any) => {
                // setSearchTerm(e?.target?.value)
                handleSearch(e?.target?.value);
              }}
              placeholder="Search by name"
              value={searchTerm}
              prefix={<Icons.SearchOutlined />}
            />
          </Form.Item>
        </Col>
        {/* <SaveButton onClick={searchFormProps.form?.submit} /> */}
      </Form>
      <Menu
        selectedKeys={[current]}
        onClick={onMenuClick}
        mode="horizontal"
        onSelect={(props) => {
          setFilters([
            {
              field: "active",
              operator: "eq",
              value: props.key,
            },
          ]);
          tableQueryResult?.refetch();
        }}
      >
        <Menu.Item key="true">Active</Menu.Item>
        <Menu.Item key="false">In Active</Menu.Item>
      </Menu>
      <Table {...tableProps} size="small">
        <Table.Column
          dataIndex="image_url"
          title="Profile image"
          render={(value) => <Avatar src={value} alt="img" size={50} />}
        />
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column dataIndex="email" title="Email" />
        <Table.Column dataIndex="position" title="Position" />
        <Table.Column dataIndex="contact_no" title="Contact no" />
        <Table.Column
          dataIndex="biography"
          title="Biography"
          render={(value) => (
            <Text
              style={ellipsis ? { width: 200 } : undefined}
              ellipsis={ellipsis ? { tooltip: `${value}` } : false}
            >
              {value}
            </Text>
          )}
        />
        <Table.Column
          dataIndex="external"
          title="Guest"
          render={(value) => (
            <BooleanField
              value={value === true}
              trueIcon="Yes"
              falseIcon="No"
              valueLabelTrue="true"
              valueLabelFalse="false"
            />
          )}
        />
        <Table.Column dataIndex="organisation" title="Organisation" />
        <Table.Column
          dataIndex="created_at"
          title="Created at"
          render={(value) => <DateField value={value} format="lll" />}
        />
        <Table.Column<IAnalysts>
          title="Actions"
          dataIndex="actions"
          fixed="right"
          width={100}
          render={(_, record) => (
            <Space>
              <EditButton
                hideText
                size="small"
                type="link"
                recordItemId={record.id}
                onClick={() => editShow(record.id)}
              />
              <DeleteButton
                hideText
                size="small"
                type="link"
                recordItemId={record.id}
                resourceNameOrRouteName="indiacharts_analysts"
              />
            </Space>
          )}
        />
      </Table>
      <CreateAnalysts
        formProps={formProps}
        modalProps={modalProps}
        form={form}
      />
      <EditAnalysts formProps={editFormProps} modalProps={editModalProps} />
    </List>
  );
};
