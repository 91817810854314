import { IResourceComponentsProps, useExport, useNavigation } from "@pankod/refine-core";

import {
    List,
    RefreshButton,
    CreateButton,
    ExportButton,
    ShowButton,
    FilterDropdown,
    Select,
    useSelect,
    Table,
    useTable,
    Space,
    EditButton,
    DeleteButton,
    DateField,
    getDefaultSortOrder,
    useModalForm, Icons, Modal, Form, Input
} from "@pankod/refine-antd"
const { PlusOutlined } = Icons;


export interface IFiiderivatives{
    fiidate: DataView
    buyvalue: number
    sellvalue: number
    instrumenttype: string
    denomination: string
    buynoofcontract: number
    sellnoofcontract: number
    openinterestvalue: number
    openinterestnoofcontract: number
}

export interface IInstrumentType{
    instrumenttype: string
}

export const FIIderivatives: React.FC<IResourceComponentsProps> = () => {
    const {tableProps, sorter, tableQueryResult} = useTable<IFiiderivatives>({
        // initialSorter:[
        //     {
        //         field: "instrumentype",
        //         order: "asc"
        //     }
        // ],
        resource: "fiiderivatives",
        metaData: {
            fields:["fiidate", "buyvalue", "sellvalue", "instrumenttype", "denomination", "buynoofcontract", "sellnoofcontract", "openinterestvalue", "openinterestnoofcontract"]
        }
    })

    const {modalProps, formProps, show}= useModalForm({
        resource: "fiiderivatives",
        action: "create",
        metaData:{
            fields:["fiidate", "buy", "sell", "netinvestment"]
        }
    })

    const { selectProps: instrumentSelectProps } = useSelect<IFiiderivatives>({
        resource: "fiiderivatives",
        metaData:{
            fields:["instrumenttype"]
        },
        optionLabel: "instrumenttype",
        optionValue: "instrumenttype"
    });

    return (
        <>
        <List
        pageHeaderProps={{
            extra: [    
                <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
                <CreateButton type="primary" icon={<PlusOutlined />} onClick={()=> show()}/>,
            ],

        }}
        >
            <Table {...tableProps}>
                <Table.Column
                    dataIndex="fiidate"
                    title= "FIIdate"
                />
                <Table.Column
                    dataIndex="buyvalue"
                    title= "Buy Value"
                />
                <Table.Column
                    dataIndex="sellvalue"
                    title= "Sell Value"
                />
                <Table.Column
                    dataIndex="denomination"
                    title= "Denomination"
                />
                <Table.Column
                    dataIndex="buynoofcontract"
                    title= "Buynoofcontract"
                />
                <Table.Column
                    dataIndex="sellnoofcontract"
                    title= "Sellnoffcontract"
                />
                <Table.Column
                    dataIndex="openinterestvalue"
                    title= "openinterestvalue"
                />
                <Table.Column
                    dataIndex="openinterestnoofcontract"
                    title= "openinterestnoofcontract"
                />
                <Table.Column
                    dataIndex="instrumenttype"
                    title= "Instrument type"
                    filterDropdown={(props) => (
                        <FilterDropdown {...props}>
                            <Select
                                style={{ minWidth: 200 }}
                                mode="multiple"
                                placeholder="Select instrument type"
                                {...instrumentSelectProps}
                            />
                        </FilterDropdown>
                    )}
                />
            </Table>
        </List>
        <Modal {...modalProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item label="Buy">
                    <Input/>
                </Form.Item>
                <Form.Item label="Sell">
                    <Input/>
                </Form.Item>
                <Form.Item label="Net Investment">
                    <Input/>
                </Form.Item>
            </Form>
        </Modal>
        </>
    )
}
