import {
  Col,
  Form,
  FormInstance,
  FormProps,
  Input,
  Modal,
  ModalProps,
  Row,
  Select,
  useSelect,
  DatePicker,
} from "@pankod/refine-antd";
import { useList } from "@pankod/refine-core";
// import { DatePicker } from "antd";
import dayjs from "dayjs";
import { IPlanMappings, ISubscriptions } from "interfaces";
import { useState } from "react";

type Props = {
  modalProps: ModalProps;
  formProps: FormProps;
  closeModal: any;
  tableQueryResult: any;
  form: FormInstance;
};

export const EditSubscriptions: React.FC<Props> = ({
  modalProps,
  formProps,
  form,
}) => {
  // States
  const [planSelected, setPlanSelected] = useState<any>(
    form?.getFieldValue(["expiry_date"])
  );

  const { selectProps: selectSubscriptionPlanProps, queryResult } =
    useSelect<ISubscriptions>({
      resource: "indiacharts_subscription_plans",
      metaData: {
        fields: ["id", "name", "label"],
      },
      optionLabel: "label",
      optionValue: "id",
      filters: [
        {
          operator: "and",
          value: [
            {
              field: "name",
              operator: "ne",
              value: "free",
            },
            {
              field: "active",
              operator: "eq",
              value: true,
            },
          ],
        },
      ],
    });

  const { selectProps: selectPlanMapProps } = useSelect<IPlanMappings>({
    resource: "indiacharts_plan_mappings",
    metaData: {
      fields: ["id", "duration", "plan_id"],
    },
    optionLabel: "duration",
    optionValue: "id",
    filters: [
      {
        field: "plan_id",
        operator: "eq",
        value: planSelected,
      },
    ],
    queryOptions: {
      enabled: !!planSelected,
    },
  });

  const ListData = useList<IPlanMappings>({
    resource: "indiacharts_plan_mappings",
    metaData: {
      fields: ["id", "duration", "cost"],
    },
  });

  return (
    <Modal {...modalProps} title="Edit Subscriptions">
      <Form
        size="large"
        layout="vertical"
        {...formProps}
        form={form}
        onFinish={(values: any) => {
          const { start_date, expiry_date, user_id, plan_id, plan_mapping_id } =
            values;
          const start_da_te = dayjs(start_date).format("YYYY/MM/DD");
          const end_da_te = dayjs(expiry_date).format("YYYY/MM/DD");

          return (
            formProps.onFinish &&
            formProps.onFinish({
              user_id: user_id,
              plan_id: plan_id,
              plan_mapping_id: plan_mapping_id,
              start_date: start_da_te,
              expiry_date: end_da_te,
            })
          );
        }}
      >
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item name={["user", "displayName"]} label="User">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item name="plan_id" label="Plan">
              <Select
                {...selectSubscriptionPlanProps}
                onSelect={(e: any) => {
                  setPlanSelected(e);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item name="plan_mapping_id" label="Duration(months)">
              <Select
                {...selectPlanMapProps}
                onChange={(e: any) => {
                  const currentId = e;

                  const presentDate = dayjs(
                    form?.getFieldValue(["start_date"])
                  ).endOf("day");
                  // console.log("Present Date changed :::", presentDate);
                  const currentDuration: any = ListData?.data?.data?.find(
                    (_o: any) => e == _o.id
                  );
                  const newCost = currentDuration?.cost;
                  const newFutureDate = presentDate.add(
                    currentDuration?.duration,
                    "month"
                  );

                  form?.setFieldsValue({
                    expiry_date: newFutureDate,
                    plan_mapping: { cost: newCost },
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item name="user_id" label="User" hidden>
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              name="start_date"
              label="Start date"
              getValueProps={(value) => ({
                value: value ? dayjs(value) : dayjs(),
              })}
            >
              <DatePicker
                format={(v: any) => dayjs.tz(v).format("YYYY-MM-DD")}
                disabled
                // onChange={(value: any) => {
                //   const currentDurationId = form?.getFieldValue([
                //     "plan_mapping_id",
                //   ]);
                //   const currentDuration = ListData?.data?.data?.find(
                //     (_o: any) => currentDurationId == _o.id
                //   );
                //   const newFutureDate = value.add(
                //     currentDuration?.duration,
                //     "month"
                //   );
                //   form?.setFieldsValue({
                //     expiry_date: newFutureDate,
                //   });
                // }}
              />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              name="expiry_date"
              label="Expiry date"
              getValueProps={(value) => ({
                value: value ? dayjs(value) : dayjs(),
              })}
            >
              <DatePicker
                format={(v: any) => dayjs.tz(v).format("YYYY-MM-DD")}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item name={["plan_mapping", "cost"]} label="Cost (&#8377;)">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
