import {
    CreateButton,
    DateField,
    EditButton,
    getDefaultSortOrder,
    Icons,
    List,
    message,
    RefreshButton,
    Space,
    Table,
    useModalForm,
    useTable,
} from "@pankod/refine-antd";
import CustomTag from "components/CustomTag";
import PlanCreationManagement from "./create";
import PlanEditManagement from "./edit";

const { PlusOutlined } = Icons;

function PlanManagementList() {
    const { tableProps, tableQueryResult, sorter } = useTable({
        resource: "indiacharts_plan_mappings",
        initialPageSize: 10,
        metaData: {
            fields: [
                "id",
                "active",
                "plan_id",
                "cost",
                "duration",
                "updated_at",
                {
                    plan: ["id", "label", "name"],
                },
            ],
        },
    });
    const {
        form: createForm,
        formProps: createFormProps,
        modalProps: createModalProps,
        show: createShow,
    } = useModalForm({
        resource: "indiacharts_plan_mappings",
        action: "create",
        metaData: {
            fields: ["id", "plan_id", "cost", "duration"],
        },
        redirect: false,
        successNotification: false,
        onMutationSuccess: () => {
            message.success("Plan Configured Successfully");
        },
    });
    const {
        form: editForm,
        formProps: editFormProps,
        modalProps: editModalProps,
        show: editShow,
    } = useModalForm({
        resource: "indiacharts_plan_mappings",
        action: "edit",
        metaData: {
            fields: ["id", "plan_id", "cost", "duration"],
        },
        redirect: false,
        successNotification: false,
        onMutationSuccess: () => {
            message.success("Plan Configured Successfully");
        },
    });
    return (
        <List
            pageHeaderProps={{
                extra: [
                    <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
                    <CreateButton type="primary" icon={<PlusOutlined />} onClick={() => createShow()} />,
                ],
            }}
        >
            <Table {...tableProps} rowKey="id" size="small">
                <Table.Column
                    dataIndex="plan"
                    title="Plan"
                    render={(record: any) => {
                        return <>{record?.label}</>;
                    }}
                />
                <Table.Column
                    dataIndex="active"
                    title="Is Active"
                    render={(value: any) => <CustomTag type={value} text={value ? "Active" : "In Active"} />}
                />
                <Table.Column dataIndex="duration" title="Duration" />
                <Table.Column dataIndex="cost" title="Cost" />
                <Table.Column
                    dataIndex="updated_at"
                    title="Updated Date"
                    render={(value) => <DateField value={value} format="lll" />}
                    defaultSortOrder={getDefaultSortOrder("updated_at", sorter)}
                    sorter
                />
                <Table.Column
                    title="Actions"
                    // dataIndex="actions"
                    render={(record) => {
                        // console.log("record", record);
                        return (
                            <Space>
                                <EditButton hideText size="small" type="link" recordItemId={record?.id} onClick={() => editShow(record?.id)} />
                                {/* <DeleteButton hideText size="small" type="link" recordItemId={record?.id} /> */}
                            </Space>
                        );
                    }}
                />
            </Table>
            <PlanCreationManagement formProps={createFormProps} modalProps={createModalProps} />
            <PlanEditManagement formProps={editFormProps} modalProps={editModalProps} />
        </List>
    );
}

export default PlanManagementList;
