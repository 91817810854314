import {
  IResourceComponentsProps,
  useExport,
  useNavigation,
  useImport,
  useNotification,
} from "@pankod/refine-core";
import { CloseCircleOutlined, CheckOutlined } from "@ant-design/icons";
import {
  List,
  CreateButton,
  ShowButton,
  Table,
  Menu,
  useTable,
  Space,
  EditButton,
  DeleteButton,
  DateField,
  getDefaultSortOrder,
  useModalForm,
  Icons,
  Form,
  Col,
  useForm,
  Input,
  Button,
  MenuProps,
  Tooltip,
} from "@pankod/refine-antd";
import { GraphQLClient, gql } from "graphql-request";

// Interface
import { IIndices } from "interfaces";

// Component
import { CreateIndex } from "./create";
import { EditIndex } from "./edit";

// Utility
import { nhost } from "utility";
import debounce from "lodash/debounce";
import { ReloadOutlined } from "@ant-design/icons";
import { useState } from "react";

// Icons
const { PlusOutlined } = Icons;

export const IndexList: React.FC<IResourceComponentsProps> = () => {
  // Constants
  const { show } = useNavigation();
  const { form: tableForm, formProps: indexTableForm } = useForm();
  const [rotating, setRotating] = useState(false);
  const [current, setCurrent] = useState("true");

  let token = nhost.auth.getAccessToken();
  const { open } = useNotification();

  // List
  const { filters, setFilters, tableProps, sorter, tableQueryResult } =
    useTable<IIndices>({
      resource: "indiacharts_indices",
      initialSorter: [
        {
          field: "name",
          order: "asc",
        },
      ],
      initialPageSize: 20,
      initialFilter: [
        {
          field: "ic_active",
          operator: "eq",
          value: "true",
        },
      ],
      metaData: {
        fields: [
          "id",
          "name",
          "security_code",
          "exchange",
          "symbol",
          "ic_active",
          "deleted",
        ],
      },
    });

  // Create
  const {
    formProps,
    modalProps,
    show: showModal,
  } = useModalForm({
    resource: "indiacharts_indices",
    action: "create",
    redirect: false,
    metaData: {
      fields: [
        "id",
        "name",
        "security_code",
        "exchange",
        "symbol",
        "ic_active",
        "deleted",
        "created_at",
        "updated_at",
      ],
    },
  });

  // Edit
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "indiacharts_indices",
    action: "edit",
    redirect: false,
    metaData: {
      fields: ["name", "security_code", "ic_active", "exchange", "symbol"],
    },
  });

  const toggleActiveStatus = async function (pk: string, status: boolean) {
    let affected_rows = 0;
    const graphQLClient = new GraphQLClient(
      `${process.env.REACT_APP_NHOST_BACKEND}/v1/graphql`,
      {
        headers: {
          authorization: "Bearer " + token,
        },
      }
    );
    const mutation = gql`
      mutation UpdateIndices($id: uuid!, $ic_active: Boolean) {
        update_indiacharts_indices_by_pk(
          pk_columns: { id: $id }
          _set: { ic_active: $ic_active }
        ) {
          updated_at
          name
          security_code
        }
      }
    `;
    const result = await graphQLClient.request(mutation, {
      id: pk,
      ic_active: status,
    });
    if (open) {
      open({
        key: `active-status-update`,
        message: "",
        type: "success",
        description: `Updated the record.`,
        undoableTimeout: 2000,
      });
    }
    tableQueryResult?.refetch();
  };

  const importProps = useImport<IIndices>({
    mapData: (item) => {
      return {
        name: item.name,
        security_code: item.security_code,
        exchange: item.exchange,
        symbol: item.symbol,
        ic_active: item.ic_active,
        deleted: item.deleted,
        created_at: item.created_at,
        updated_at: item.updated_at,
      };
    },
  });

  const { triggerExport, isLoading: exportLoading } = useExport<IIndices>({
    mapData: (item) => {
      return {
        name: item.name,
        security_code: item.security_code,
        exchange: item.exchange,
        symbol: item.symbol,
        ic_active: item.ic_active,
        deleted: item.deleted,
        created_at: item.created_at,
        updated_at: item.updated_at,
      };
    },
  });

  // Menu select
  const onMenuClick: MenuProps["onClick"] = (e: any) => {
    console.log("click ", e);
    setCurrent(e.key);
  };

  // Refresh button func
  const handleRefresh = () => {
    setRotating(true);

    setTimeout(() => {
      setRotating(false);
    }, 500);
  };

  // Search input
  const handleSearch = debounce((e: any) => {
    const searchValue = e;
    setFilters([
      {
        field: "name",
        operator: "contains",
        value: `%${searchValue}%`,
      },
    ]);
  }, 500);

  return (
    <List
      pageHeaderProps={{
        extra: [
          <Button
            type="default"
            icon={<ReloadOutlined spin={rotating} />}
            onClick={() => {
              handleRefresh();
              handleSearch("");
              tableForm?.resetFields(["name"]);
              tableQueryResult?.refetch();
            }}
          >
            Refresh
          </Button>,
          <CreateButton
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => showModal()}
          />,
        ],
      }}
    >
      <Form {...indexTableForm}>
        <Col span={6}>
          <Form.Item name="name">
            <Input
              onChange={(e: any) => handleSearch(e?.target?.value)}
              placeholder="Search by name"
              prefix={<Icons.SearchOutlined />}
            />
          </Form.Item>
        </Col>
      </Form>
      {/* Menu */}
      <Menu
        selectedKeys={[current]}
        onClick={onMenuClick}
        mode="horizontal"
        onSelect={(props) => {
          setFilters([
            {
              field: "ic_active",
              operator: "eq",
              value: props.key,
            },
          ]);
          tableQueryResult?.refetch();
        }}
      >
        <Menu.Item key="true">Active</Menu.Item>
        <Menu.Item key="false">In Active</Menu.Item>
      </Menu>

      {/* Table */}
      <Table {...tableProps} rowKey="id" size="small" scroll={{ x: 1300 }}>
        <Table.Column<IIndices>
          width={250}
          dataIndex="name"
          title="Name"
          responsive={["lg", "md"]}
          ellipsis
          render={(_, record) => (
            <Space>
              <Tooltip title={record?.name}>
                <ShowButton
                  size="small"
                  type="link"
                  icon={null}
                  resource="indiacharts_indices"
                  recordItemId={record.id}
                >
                  {record.name}
                </ShowButton>
              </Tooltip>
            </Space>
          )}
        />
        <Table.Column dataIndex="security_code" title="Security Code" />
        <Table.Column width={100} dataIndex="exchange" title="Exchange" />
        <Table.Column dataIndex="symbol" title="Symbol" />
        <Table.Column
          dataIndex="created_at"
          title="Created Date"
          render={(value) => <DateField value={value} format="lll" />}
          defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="updated_at"
          title="Updated Date"
          render={(value) => <DateField value={value} format="lll" />}
          defaultSortOrder={getDefaultSortOrder("updated_at", sorter)}
          sorter
        />
        <Table.Column<IIndices>
          width={100}
          fixed="right"
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton
                hideText
                size="small"
                type="link"
                recordItemId={record.id}
                onClick={() => editShow(record.id)}
              />
              {record.ic_active && (
                <EditButton
                  hideText
                  size="small"
                  type="link"
                  icon={<CloseCircleOutlined />}
                  recordItemId={record.id}
                  onClick={() => toggleActiveStatus(record.id, false)}
                />
              )}
              {!record.ic_active && (
                <EditButton
                  hideText
                  size="small"
                  type="link"
                  icon={<CheckOutlined />}
                  recordItemId={record.id}
                  onClick={() => toggleActiveStatus(record.id, true)}
                />
              )}
              <DeleteButton
                hideText
                size="small"
                type="link"
                recordItemId={record.id}
                resourceNameOrRouteName="indiacharts_indices"
              />
            </Space>
          )}
        />
      </Table>

      {/* Create */}
      <CreateIndex modalProps={modalProps} formProps={formProps} />

      {/* Edit */}
      <EditIndex modalProps={editModalProps} formProps={editFormProps} />
    </List>
  );
};
