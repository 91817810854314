import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  Row,
  Col,
  DatePicker,
  Select,
  useSelect,
  Radio,
} from "@pankod/refine-antd";

import { useGetIdentity } from "@pankod/refine-core";
import { IVideoResourcesCategories } from "interfaces";

type EditVideoResourceCategories = {
  modalProps: ModalProps;
  formProps: FormProps;
};

const { TextArea } = Input;

export const EditVideoResourceCategories: React.FC<
  EditVideoResourceCategories
> = ({ modalProps, formProps }) => {
  const { data: user } = useGetIdentity();

  return (
    <Modal {...modalProps} title="Edit Info Video Category">
      <Form
        {...formProps}
        size="large"
        layout="vertical"
        initialValues={{ created_by: user?.id }}
      >
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item label="Category" name="category">
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Active"
              name="is_active"
              rules={[{ required: false }]}
            >
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item label="Sorting" name="sort_no">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Created By"
          name="created_by"
          initialValue={user?.id}
          hidden={true}
        ></Form.Item>
      </Form>
    </Modal>
  );
};
