import { Row, Col } from "@pankod/refine-antd";

// Components
import SettlementHistoryTable from "components/partner-program/SettlementHistoryTable";
import SettlementPay from "components/partner-program/SettlementPay";

const SettlementHistory = () => {
  return (
    <div className="main-card settlement-history">
      <Row gutter={[20, 20]}>
        <Col lg={24} md={24} sm={24}>
          <SettlementPay />
        </Col>
        <Col lg={24} md={24} sm={24}>
          <SettlementHistoryTable />
        </Col>
      </Row>
    </div>
  );
};

export default SettlementHistory;
