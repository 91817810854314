import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { includes } from "lodash";

const CustomAgGrid = React.forwardRef<any, any>(
  (
    {
      columnDefs,
      rowData,
      gridOptions,
      isLoading,
      containerStyle = {
        height: "100%",
        width: "100%",
        boxSizing: "border-box",
      },
      containerClassname = "",
      defaultColDefMod,
      hiddenColumns = [],
      showHiddenColumns = false,
      suppressHorizontalScrollToggle = true,
    },
    ref
  ) => {
    // State
    const [gridApi, setGridApi] = useState<any>(null);
    const [columnApi, setColumnApi] = useState<any>(null);

    useEffect(() => {
      if (gridApi) {
        gridApi.sizeColumnsToFit();
      }
    }, [rowData, columnDefs]);

    useEffect(() => {
      if (gridApi) {
        if (isLoading) {
          gridApi?.showLoadingOverlay();
        } else if (Array.isArray(rowData) && rowData?.length === 0) {
          gridApi?.showNoRowsOverlay();
        } else {
          gridApi?.hideOverlay(); //if data is set and loading is false then hide all overlays
        }
      }
    }, [isLoading, rowData, gridApi]);

    const handleGridReady = (params: any) => {
      setGridApi(params.api);
      setColumnApi(params.columnApi);
      // params.api.showLoadingOverlay(); //showing loading overlay initially until data is set
      params.api.sizeColumnsToFit();

      if (gridOptions && typeof gridOptions.addOnGridReady === "function") {
        gridOptions.addOnGridReady(params);
      }
    };

    const defaultColDef = {
      sortable: false,
      resizable: false,
      filter: false,
      sortIcon: true,
      unSortIcon: true,
      suppressMenu: true,
      suppressMovable: true,
      valueFormatter: ({ value }: any) =>
        includes([null, undefined, ""], value) ? "-" : value,
      ...defaultColDefMod,
    };

    useEffect(() => {
      if (hiddenColumns.length && columnApi) {
        columnApi.setColumnsVisible(hiddenColumns, showHiddenColumns);
        // hiddenColumns.map((column) => {
        //   columnApi.setColumnVisible(column, showHiddenColumns);
        // });
      }
    }, [hiddenColumns, showHiddenColumns, columnApi]);

    return (
      <div className="ag-theme-alpine" style={containerStyle}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          onGridReady={handleGridReady}
          ref={ref}
          suppressScrollOnNewData
          scrollbarWidth={8}
          suppressHorizontalScroll={suppressHorizontalScrollToggle}
          defaultColDef={defaultColDef}
          suppressCellFocus
          popupParent={document.querySelector("body")}
          {...gridOptions} //Should always be at the bottom. add options above this
        />
      </div>
    );
  }
);
CustomAgGrid.displayName = "CustomAgGrid";
export default CustomAgGrid;
