import React from "react";
import { useParams } from "react-router-dom";
import { useShow } from "@pankod/refine-core";
import { PageHeader, ListButton, Tabs } from "@pankod/refine-antd";

// Interface
import { IBroadIndustry } from "interfaces";

// Component
import { IndustriesList } from "./industries_list";

export const ShowBroadIndustry: React.FC = () => {
  // Route Param
  const routeParams = useParams();

  // Sector By ID
  const { queryResult: broadIndustryQueryResult } = useShow<IBroadIndustry>({
    resource: "indiacharts_broad_industry",
    id: routeParams?.id,
    metaData: {
      fields: ["id", "name"],
    },
  });

  return (
    <>
      <PageHeader
        title={broadIndustryQueryResult?.data?.data?.name}
        onBack={() => window.history.back()}
        extra={[<ListButton />]}
        footer={
          <Tabs>
            <Tabs.TabPane tab="Industries" key="item-1">
              <IndustriesList />
            </Tabs.TabPane>
          </Tabs>
        }
      ></PageHeader>
    </>
  );
};
