import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  Row,
  Col,
  DatePicker,
  Select,
  FormInstance,
} from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useState } from "react";

dayjs.extend(utc);
dayjs.extend(timezone);

type Props = {
  modalProps: ModalProps;
  formProps: FormProps;
  form: FormInstance;
};

export const EditApplicationUpdate: React.FC<Props> = ({
  modalProps,
  formProps,
  form
}) => {
  const { data: user } = useGetIdentity();
  const [releaseBgColor, setReleaseBgColor] = useState("#FB9650")
  const [updateBgColor, setUpdateBgColor] = useState("#166531")
  const [textColor, setTextColor] = useState("#FFFFFF")
  return (
    <Modal {...modalProps} title="Edit Application Update">
      <Form
        {...formProps}
        layout="vertical"
        size="large"
        onFinish={(values: any) => {
          const { date, ...rest } = values;
          // console.log(date);
          const da_te = dayjs(date).format("YYYY/MM/DD");

          return (
            formProps.onFinish &&
            formProps.onFinish({
              ...rest,
              date: da_te,
            })
          );
        }}
      >
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                { required: true },
                {
                  // pattern: new RegExp("^\\S*$"), // No Space Allowed
                  pattern: new RegExp(/^[^\s]/), // Check For Space At Beginning Only
                  message: "No spaces allowed",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Date"
              name="date"
              rules={[{ required: true }]}
              getValueProps={(value) => ({
                value: value ? dayjs(value) : dayjs(),
              })}
            >
              <DatePicker
                format={(v: any) => dayjs.tz(v).format("YYYY-MM-DD")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item name="tag" label="Tag">
              <Select
                onSelect={(e: any) => {
                  console.log("From select :::", e);
                  if (e === "Release") {
                    // console.log("Selected release")
                    form?.setFieldsValue({
                      tag_bg_color: releaseBgColor,
                      tag_text_color: textColor,
                    });
                  }
                  if (e === "Update") {
                    form?.setFieldsValue({
                      tag_bg_color: updateBgColor,
                      tag_text_color: textColor,
                    });
                  }
                }}
              >
                <option key="Release" value="Release">
                  Release
                </option>
                <option key="Update" value="Update">
                  Update
                </option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item name="tag_bg_color" label="Background Color" hidden>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item name="tag_text_color" label="Text Color" hidden>
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="updated_by" initialValue={user?.id}>
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
