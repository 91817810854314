import { IResourceComponentsProps, useGetIdentity } from "@pankod/refine-core";
import {
  useTable,
  List,
  Table,
  useModalForm,
  Icons,
  CreateButton,
  EditButton,
  DeleteButton,
  Space,
  BooleanField,
  Menu,
  Col,
  Input,
  DateField,
  Button,
  useForm,
  MenuProps,
} from "@pankod/refine-antd";

// Interface
import { IWebinarCategories } from "interfaces";

// Component
import { CreateWebinarCategory } from "./create";
import { EditWebinarCategory } from "./edit";

import { Form } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useState } from "react";

const { PlusOutlined, CheckCircleOutlined, CloseCircleOutlined } = Icons;

export const WebinarCategoryList: React.FC<IResourceComponentsProps> = () => {
  const [rotating, setRotating] = useState(false);
  const [current, setCurrent] = useState("true");
  const onMenuClick: MenuProps["onClick"] = (e: any) => {
    console.log("click ", e);
    setCurrent(e.key);
  };

  const { form: tableForm, formProps: webinarsCategoriesTable } = useForm();

  const { data: user } = useGetIdentity();

  // Table
  const { tableProps, tableQueryResult, setFilters, searchFormProps } =
    useTable<IWebinarCategories>({
      resource: "indiacharts_webinar_categories",
      metaData: {
        fields: [
          "id",
          "category",
          "is_active",
          "sort_no",
          "created_at",
          "updated_at",
          "created_by",
          "",
        ],
      },
      initialFilter: [
        {
          field: "is_active",
          operator: "eq",
          value: "true",
        },
      ],
    });

  // Create
  const {
    formProps,
    modalProps,
    show: showModal,
    close: closeModal,
  } = useModalForm({
    resource: "indiacharts_webinar_categories",
    action: "create",
    redirect: false,
    metaData: {
      fields: [
        "id",
        "category",
        "is_active",
        "created_at",
        "updated_at",
        "created_by",
        "sort_no"
      ],
    },
  });

  // Edit
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "indiacharts_webinar_categories",
    action: "edit",
    redirect: false,
    metaData: {
      fields: [
        "id",
        "category",
        "is_active",
        "created_at",
        "updated_at",
        "sort_no"
        // "created_by",
      ],
    },
  });

  // Refresh button func
  const handleRefresh = () => {
    setRotating(true);

    setTimeout(() => {
      setRotating(false);
    }, 500);
  };

  return (
    <List
      headerProps={{
        title: "Webinar Category",
        extra: [
          <Button
            type="default"
            icon={<ReloadOutlined spin={rotating} />}
            onClick={() => {
              tableQueryResult?.refetch();
              handleRefresh();
              tableForm?.resetFields(["name"]);
              setFilters([
                {
                  field: "category",
                  operator: "contains",
                  value: null,
                },
              ]);
            }}
          >
            Refresh
          </Button>,
          <CreateButton
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => showModal()}
          />,
        ],
      }}
    >
      <Form {...webinarsCategoriesTable}>
        <Col span={6}>
          <Form.Item name="name">
            <Input
              onChange={(e) =>
                setFilters([
                  {
                    field: "category",
                    operator: "contains",
                    value: `%${e?.target?.value}%`,
                  },
                ])
              }
              placeholder="Search by category"
              prefix={<Icons.SearchOutlined />}
            />
          </Form.Item>
        </Col>
      </Form>
      <Menu
        // defaultActiveFirst
        selectedKeys={[current]}
        mode="horizontal"
        onClick={onMenuClick}
        onSelect={(props) => {
          setFilters([
            {
              field: "is_active",
              operator: "eq",
              value: props.key,
            },
          ]);
          tableQueryResult?.refetch();
        }}
      >
        <Menu.Item key="true">Active</Menu.Item>
        <Menu.Item key="false">In Active</Menu.Item>
      </Menu>

      {/* Table */}
      <Table {...tableProps} size="small">
        <Table.Column dataIndex="category" title="Category" />
        <Table.Column
          dataIndex="is_active"
          title="Is Active"
          render={(value) => (
            <BooleanField
              value={value === true}
              trueIcon={<CheckCircleOutlined style={{ color: "green" }} />}
              falseIcon={<CloseCircleOutlined style={{ color: "red" }} />}
              valueLabelTrue="true"
              valueLabelFalse="false"
            />
          )}
        />
        <Table.Column
          dataIndex="created_at"
          title="Created at"
          render={(value) => <DateField format="LLL" value={value} />}
        />
        <Table.Column
          dataIndex="updated_at"
          title="Updated at"
          render={(value) => <DateField format="LLL" value={value} />}
        />
        <Table.Column
        dataIndex="sort_no"
        title="Sorting"
        />
        <Table.Column<IWebinarCategories>
          title="Actions"
          fixed="right"
          width={100}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton
                hideText
                size="small"
                type="link"
                recordItemId={record.id}
                onClick={() => editShow(record.id)}
              />
              <DeleteButton
                hideText
                size="small"
                type="link"
                recordItemId={record.id}
                resourceNameOrRouteName="indiacharts_webinar_categories"
              />
            </Space>
          )}
        />
      </Table>

      {/* Create */}
      <CreateWebinarCategory
        modalProps={modalProps}
        formProps={formProps}
        tableQueryResult={tableQueryResult}
        closeModal={closeModal}
        currentUser={user?.id}
      />

      {/* Edit */}
      <EditWebinarCategory
        modalProps={editModalProps}
        formProps={editFormProps}
      />
    </List>
  );
};
