import React from "react";
import {
  Table,
  useTable,
  List,
  RefreshButton,
  useModalForm,
  Space,
  EditButton,
  CreateButton,
  Icons,
  DeleteButton,
  ShowButton,
} from "@pankod/refine-antd";

// Interface
import { IBroadIndustry } from "interfaces";

// Component
import { CreateBroadIndustry } from "./create";
import { EditBroadIndustry } from "./edit";

// Icons
const { PlusOutlined } = Icons;

export const BroadIndustryList: React.FC = () => {
  // Broad Industry Table
  const { tableProps, tableQueryResult } = useTable<IBroadIndustry>({
    resource: "indiacharts_broad_industry",
    metaData: {
      fields: ["id", "name", "code", "sector_code"],
    },
    initialPageSize: 20,
    initialSorter: [
      {
        field: "name",
        order: "asc",
      },
    ],
  });

  // Create
  const {
    formProps: createFormProps,
    modalProps: createModalProps,
    show: createShowModal,
  } = useModalForm({
    resource: "indiacharts_broad_industry",
    action: "create",
    redirect: false,
    metaData: {
      fields: ["id", "name", "code", "sector_code"],
    },
  });

  // Edit
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShowModal,
  } = useModalForm({
    resource: "indiacharts_broad_industry",
    redirect: false,
    action: "edit",
    metaData: {
      fields: ["id", "name", "code", "sector_code"],
    },
  });

  return (
    <>
      {/* List */}
      <List
        title={"Broad Indutries"}
        headerProps={{
          extra: [
            <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
            <CreateButton
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => createShowModal()}
            />,
          ],
        }}
      >
        <Table
          {...tableProps}
          rowKey={(record, index) => record.id}
          size="small"
        >
          <Table.Column<IBroadIndustry>
            dataIndex="name"
            title="Name"
            render={(_, record) => (
              <Space>
                <ShowButton
                  size="small"
                  type="link"
                  icon={null}
                  resource="indiacharts_broad_industry"
                  recordItemId={record.id}
                >
                  {record.name}
                </ShowButton>
              </Space>
            )}
          />
          <Table.Column<IBroadIndustry> dataIndex="code" title="Code" />
          <Table.Column<IBroadIndustry>
            title="Actions"
            dataIndex="actions"
            key="actions"
            render={(_, record: IBroadIndustry) => (
              <Space>
                {/* Edit Button */}
                <EditButton
                  hideText
                  size="small"
                  recordItemId={record.id}
                  onClick={() => editShowModal(record.id)}
                />

                {/* Delete Button */}
                <DeleteButton
                  hideText
                  size="small"
                  type="link"
                  recordItemId={record.id}
                  resourceNameOrRouteName="indiacharts_broad_industry"
                />
              </Space>
            )}
          />
        </Table>

        {/* Create */}
        <CreateBroadIndustry
          formProps={createFormProps}
          modalProps={createModalProps}
        />

        {/* Edit */}
        <EditBroadIndustry
          modalProps={editModalProps}
          formProps={editFormProps}
        />
      </List>
    </>
  );
};
