import { Select } from "@pankod/refine-antd";
import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import dayjs from "dayjs";
// import { useQuery } from "@tanstack/react-query";

// Query
import { fetchUserData } from "utility/subscribersChart";

const SubscribersChart: React.FC = () => {
  const filterOptions = [
    {
      label: "1 Week",
      value: 7,
      type: "day",
    },
    {
      label: "2 Weeks",
      value: 14,
      type: "day",
    },
    ,
    {
      label: "1 Month",
      value: 30,
      type: "day",
    },
    {
      label: "6 Months",
      value: 6,
      type: "month",
    },
    {
      label: "12 Months",
      value: 12,
      type: "month",
    },
  ];

  const [filterValue, setFilterValue] = useState(7);
  const [xAxisData, setXAxisData] = useState([]);
  const [yAxisData, setYAxisData] = useState([]);

  // Chart Function
  let options = {
    chart: {
      type: "column",
    },
    title: {
      text: " ",
    },
    xAxis: {
      categories: xAxisData,
      title: {
        text: "Date",
      },
    },
    yAxis: {
      title: {
        text: "No. of users registered",
      },
      labels: {
        format: "{value:.0f}", // Set format to display y-axis values as integers
      },
      tickInterval: 1,
      gridLineWidth: 0,
    },
    series: [
      {
        name: "Count",
        data: yAxisData,
      },
    ],
    credits: {
      enabled: false,
    },
  };

  // Filter Change
  function handleFilterChange(v: any) {
    setFilterValue(v);
    // console.log("Chart filter :::", v);
    let dateFilterObject: any = filterOptions.filter(
      (e: any) => e.value === v
    )[0];
    let dateValue: any = dateFilterObject?.value;
    let dateType: any = dateFilterObject?.type;
    const newFromDate: any = dayjs().subtract(dateValue, dateType);
    const newToDate: any = dayjs();

    fetchUserData(newFromDate, newToDate)
      .then((data: any) => {
        // console.log(" new Chart Data :::::", data);
        let tempxAxisData: any = [];
        let tempyAxisData: any = [];
        data.map((item: any) => {
          if (dateType === "month") {
            tempxAxisData.push(dayjs(item?.time).format("MMM YYYY"));
          } else {
            tempxAxisData.push(dayjs(item?.time).format("Do MMM"));
          }
          tempyAxisData.push(item?.count);
          setXAxisData(tempxAxisData);
          setYAxisData(tempyAxisData);
          // console.log("Date :::", item?.time);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    const toDate = dayjs();
    const fromDate = dayjs().subtract(7, "day");

    fetchUserData(fromDate, toDate)
      .then((data) => {
        // console.log("Chart Data :::::", data);
        let tempxAxisData: any = [];
        let tempyAxisData: any = [];
        data.map((item: any) => {
          tempxAxisData.push(dayjs(item?.time).format("Do MMM"));
          tempyAxisData.push(item?.count);
          setXAxisData(tempxAxisData);
          setYAxisData(tempyAxisData);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <div className="subscribers_chart_container">
        <div className="subscribers_chart_container-header">
          <div className="subscribers_chart_container-header-title">
            Subscribers Chart
          </div>
          <div className="subscribers_chart_container-header-filter">
            <Select
              style={{ width: "150px" }}
              onChange={(e: any) => {
                handleFilterChange(e);
              }}
              value={filterValue}
            >
              <option value={7}>1 Week</option>
              <option value={14}>2 Week</option>
              <option value={30}>1 Month</option>
              <option value={6}>6 Month</option>
              <option value={12}>1 Year</option>
            </Select>
          </div>
        </div>
        <div className="subscribers_chart_container-table">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      </div>
    </>
  );
};

export default SubscribersChart;
