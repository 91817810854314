import {
  IResourceComponentsProps,
  useExport,
  useNavigation,
  useImport,
} from "@pankod/refine-core";

import {
  List,
  RefreshButton,
  CreateButton,
  Table,
  useTable,
  Space,
  EditButton,
  DeleteButton,
  DateField,
  getDefaultSortOrder,
  useModalForm,
  Icons,
} from "@pankod/refine-antd";
import { IDataFeedField } from "interfaces";
import { CreateDataFeedField } from "./create";
import { EditDataFeedField } from "./edit";
const { PlusOutlined } = Icons;

export const DataFeedFieldList: React.FC<IResourceComponentsProps> = () => {
  const { show } = useNavigation();

  const { tableProps, sorter, tableQueryResult } = useTable<IDataFeedField>({
    initialSorter: [
      {
        field: "datafeed_id",
        order: "asc",
      },
    ],
    metaData: {
      fields: [
        "id",
        "name",
        "label",
        "datafeed_id",
        "data_type",
        { datafeed: ["id", "name", "label"] },
      ],
    },
  });
  const {
    formProps,
    modalProps,
    show: showModal,
  } = useModalForm({
    resource: "datafeed_fields",
    action: "create",
    metaData: {
      fields: [
        "id",
        "name",
        "label",
        "datafeed_id",
        "data_type",
        { datafeed: ["id", "name", "label"] },
      ],
    },
  });
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "datafeed_fields",
    action: "edit",
    metaData: {
      fields: [
        "id",
        "name",
        "label",
        "datafeed_id",
        "data_type",
        { datafeed: ["id", "name", "label"] },
      ],
    },
  });

  const importProps = useImport<IDataFeedField>({
    mapData: (item) => {
      return {
        name: item.name,
        label: item.label,
      };
    },
  });

  const { triggerExport, isLoading: exportLoading } = useExport<IDataFeedField>(
    {
      mapData: (item) => {
        return {
          name: item.name,
          label: item.label,
        };
      },
    }
  );

  return (
    <List
      pageHeaderProps={{
        extra: [
          // <ExportButton onClick={triggerExport} loading={exportLoading}/>,
          <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
          <CreateButton
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => showModal()}
          />,
        ],
      }}
    >
      <Table {...tableProps} rowKey="id" size="small">
        <Table.Column<IDataFeedField> dataIndex="label" title="Label" />

        <Table.Column dataIndex="name" title="Name" />
        <Table.Column dataIndex={["datafeed", "label"]} title="Feed" />

        <Table.Column
          dataIndex="created_at"
          title="Created Date"
          render={(value) => <DateField value={value} format="lll" />}
          defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
          sorter
        />
        <Table.Column<IDataFeedField>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton
                size="small"
                type="link"
                icon={null}
                recordItemId={record.id}
                onClick={() => editShow(record.id)}
              />
              <DeleteButton
                size="small"
                type="link"
                icon={null}
                recordItemId={record.id}
              />
            </Space>
          )}
        />
      </Table>
      <CreateDataFeedField modalProps={modalProps} formProps={formProps} />
      <EditDataFeedField
        modalProps={editModalProps}
        formProps={editFormProps}
      />
    </List>
  );
};
