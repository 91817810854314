import { useState } from "react";
import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  Select,
  Row,
  Col,
  UploadProps,
  Upload,
  Button,
  Icons,
  useSelect,
  Image,
} from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";

// Interface
import { IAnalysts } from "interfaces";

// Util
import { nhost } from "utility/nhost";
import dayjs from "dayjs";
import moment from "moment";
import { Space, DatePicker, FormInstance } from "antd";
import type { DatePickerProps, RangePickerProps } from "antd/es/date-picker";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

type Props = {
  modalProps: ModalProps;
  formProps: FormProps;
  form: FormInstance;
};

const { UploadOutlined } = Icons;
const { TextArea } = Input;

export const EditWebinars: React.FC<Props> = ({
  modalProps,
  formProps,
  form,
}) => {
  const { data: user } = useGetIdentity();
  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  // State
  const [fileList, setFileList] = useState<any>([]);
  const [selectedHostAt, setSelectedHostAt] = useState<any>(
    form?.getFieldValue("host_at")
  );
  const [selectedDuration, setSelectedDuration] = useState(
    form?.getFieldValue("duration")
  );
  // console.log(selectedHostAt)

  // Form Watch
  const formWatch = {
    presenter_id: Form.useWatch("presenter_id", formProps.form),
    presenter_img: Form.useWatch("presenter_img", formProps.form),
    thumbnail: Form.useWatch("thumbnail", formProps.form),
  };
  // Date and time restriction of host_at

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
  };

  function setEndAt(duration: any, hostAtValue: any) {
    const originalDate = hostAtValue;
    const minutesToAdd = duration;
    const newDate = moment(originalDate, "YYYY-MM-DD HH:mm").add(
      minutesToAdd,
      "minutes"
    );
    // console.log("New Date :::", originalDate)
    // console.log("New Duration :::", minutesToAdd)
    // console.log("New Date :::", newDate.format("YYYY-MM-DD HH:mm"));
    form?.setFieldsValue({ end_at: newDate });
  }

  function checkTime(x: any) {
    let currTime = moment().format("HH:mm");
    let currDate = moment().toDate().toString().slice(8, 10);
    // console.log("Selected time :::", x);
    let selectedDate = x._d.toString();
    let currHrTime = Number(currTime.slice(0, 2));
    let currMinTime = Number(currTime.slice(3, 5));
    if (selectedDate === currDate) {
      if (x.$H < currHrTime) {
        alert("Please select future time");
      }
      if (x.$H === currHrTime && x.$m < currMinTime) {
        alert("Please select future time");
      }
    }
  }

  // Image Upload
  const handleImageUpload = async (file: File) => {
    let fileres = await nhost.storage.upload({ file, bucketId: "public" });
    const imageUrl = await nhost.storage.getPublicUrl({
      fileId: fileres.fileMetadata?.id || "",
    });
    formProps?.form?.setFieldsValue({ thumbnail: imageUrl });
    return imageUrl;
  };

  const thumbProps: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      handleImageUpload(file);
      return false;
    },
    fileList,
  };

  // Analyst
  const { selectProps, queryResult } = useSelect<IAnalysts>({
    resource: "indiacharts_analysts",
    metaData: {
      fields: ["name", "id", "image_url"],
    },
    pagination: {
      pageSize: 999,
    },
    optionLabel: "name",
    optionValue: "id",
  });

  // Webinar Category
  const { selectProps: webinarCategorySelectProps } = useSelect<IAnalysts>({
    resource: "indiacharts_webinar_categories",
    metaData: {
      fields: ["category", "id"],
    },
    pagination: {
      pageSize: 999,
    },
    optionLabel: "category",
    optionValue: "id",
    filters: [
      {
        field: "is_active",
        operator: "eq",
        value: true,
      },
    ],
  });

  const presenter = queryResult?.data?.data?.filter(
    (e) => e?.id === formWatch?.presenter_id
  );
  const presenter_id = presenter?.[0]?.id;

  return (
    <Modal {...modalProps} title="Edit Webinar">
      <Form
        {...formProps}
        size="large"
        layout="vertical"
        onFinish={(data: any) => {
          if (formProps.onFinish) {
            formProps?.onFinish({
              ...data,
              updated_by: user?.id,
            });
            setFileList([]);
          }
        }}
      >
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                },
                {
                  // pattern: new RegExp("^\\S*$"), // No Space Allowed
                  pattern: new RegExp(/^[^\s]/), // Check For Space At Beginning Only
                  message: "No spaces allowed",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} lg={12}>
            <Form.Item
              label="Host at"
              name="host_at"
              getValueProps={(value) => ({
                value: value ? moment(value) : moment(),
              })}
              rules={[{ required: true }]}
            >
              <DatePicker
                format="YYYY-MM-DD HH:mm"
                showTime
                // disabledDate={disabledDate}
                onOk={(value: any) => {
                  checkTime(value);
                }}
                onChange={(value: any) => {
                  // console.log("Changed");
                  setSelectedHostAt(value.format("YYYY-MM-DD HH:mm"));
                  form?.setFieldsValue({ duration: "" });
                  setEndAt(selectedDuration, selectedHostAt);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={24} lg={24}>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                },
                {
                  pattern: new RegExp(/^[^\s]/), // Check For Space At Beginning Only
                  message: "No spaces allowed",
                },
              ]}
            >
              <TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}></Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Presenter"
              name="presenter"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                {...selectProps}
                onSelect={(e: any, v: any) => {
                  const url = queryResult?.data?.data?.find((o) => o.id === e);
                  formProps?.form?.setFields([
                    {
                      name: "presenter",
                      value: v.label,
                    },
                    {
                      name: "presenter_img",
                      value: url?.image_url,
                    },
                    {
                      name: "presenter_id",
                      value: v.value,
                    },
                  ]);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item name="presenter_img" label="Presenter image" hidden>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item name="thumbnail" label="Thumbnail">
              <Upload {...thumbProps}>
                <Button icon={<UploadOutlined />}>Select File To Upload</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item label="Thumbnail Preview" name="presenter_img">
              {formWatch.thumbnail !== null ? (
                <Image
                  width={120}
                  height={120}
                  src={formWatch.thumbnail}
                  alt={"Icon"}
                />
              ) : (
                <p>No Image Available For Preview</p>
              )}
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Link"
              name="link"
              rules={[
                {
                  required: true,
                },
                {
                  pattern: new RegExp(/^[^\s]/), // Check For Space At Beginning Only
                  message: "No spaces allowed",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Duration(minutes)"
              name="duration"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                onChange={(e: any) => {
                  setSelectedDuration(e?.target?.value);
                  const prevDate = form?.getFieldValue("host_at");
                  const convertedPrevDate =
                    moment(prevDate).format("YYYY-MM-DD HH:mm");
                  // console.log("Converted :::", convertedPrevDate)
                  setEndAt(e?.target?.value, convertedPrevDate);
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={8} lg={8}>
            <Form.Item
              label="Category"
              name="category_id"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select {...webinarCategorySelectProps}>
                {/* <option key="indiacharts" value="indiacharts">
                  indiacharts
                </option>
                <option key="guest" value="guest">
                  guest
                </option> */}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              name="end_at"
              label="End at"
              getValueProps={(value) => ({
                value: value ? moment(value) : moment(),
              })}
            >
              <DatePicker disabled showTime format={"YYYY-MM-DD HH:mm"} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Link source"
              name="link_source"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select>
                <option key="Youtube" value="Youtube">
                  Youtube
                </option>
                <option key="Others" value="Others">
                  Others
                </option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={8} lg={8}>
            <Form.Item
              label="Recorded"
              name="recorded"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={[
                  {
                    value: true,
                    label: "True",
                  },
                  {
                    value: false,
                    label: "False",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Active"
              name="active"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={[
                  {
                    value: true,
                    label: "True",
                  },
                  {
                    value: false,
                    label: "False",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Presenter id"
              name="presenter_id"
              rules={[
                {
                  required: false,
                },
              ]}
              hidden
            >
              <Input value={presenter_id} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
