import { IResourceComponentsProps, useExport, useNavigation, useImport } from "@pankod/refine-core";

import {
    List,
    RefreshButton,
    CreateButton,
    ShowButton,
    Table,
    Menu,
    useTable,
    Space,
    EditButton,
    DeleteButton,
    DateField,
    getDefaultSortOrder,
    useModalForm, Icons
} from "@pankod/refine-antd";
import { IDataFeed } from "interfaces";
import { CreateDataFeed } from "./create";
import { EditDataFeed } from "./edit";
const { PlusOutlined } = Icons;

export const DataFeedList: React.FC<IResourceComponentsProps> = () => {
    const { show } = useNavigation()



    const { filters, setFilters, tableProps, sorter, tableQueryResult } = useTable<IDataFeed>({
        initialSorter: [
            {
                field: "name",
                order: "asc",
            },
        ],
        initialFilter: [
            {
                field: "group",
                operator: "eq",
                value: "Prices",
            },
        ],
        metaData: {
            fields: ["id", "name", "label"],
        },
    });
    const { formProps, modalProps, show: showModal } = useModalForm({
        resource: "datafeeds",
        action: "create",
        metaData: {
            fields: ["id", "name", "label"],
        },
    });
    const {
        formProps: editFormProps,
        modalProps: editModalProps,
        show: editShow,
    } = useModalForm({
        resource: "datafeeds",
        action: "edit",
        metaData: {
            fields: ["name", "label"],
        },
    });


    const importProps = useImport<IDataFeed>({
        mapData: (item) => {
            return {
                name: item.name,
                label: item.label
            };
        },
    });

    const { triggerExport, isLoading: exportLoading } = useExport<IDataFeed>({
        mapData: (item) => {
            return {
                name: item.name,
                label: item.label
            };
        },
    });






    return (
        <List
            pageHeaderProps={{
                extra: [
                    // <ExportButton onClick={triggerExport} loading={exportLoading}/>,
                    <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
                    <CreateButton type="primary" icon={<PlusOutlined />} onClick={() => showModal()} />,
                ],

            }}
        >
            <Menu mode="horizontal" onSelect={(props) => {
                // console.log(props)
                
                setFilters([
                    {
                        field: 'group',
                        operator: 'eq',
                        value: props.key,
                    },
                ])
                tableQueryResult?.refetch()
            }}>
                <Menu.Item key="Prices">
                    Prices
                </Menu.Item>
                <Menu.Item key="Masters" >
                    Masters
                </Menu.Item>
                <Menu.Item key="Details" >
                    Details
                </Menu.Item>

            </Menu>
            <Table {...tableProps} rowKey="id" size="small">
                <Table.Column<IDataFeed> dataIndex="label" title="Label"
                    render={(_, record) => (
                        <Space>
                            <ShowButton size="small"
                                type="link"
                                icon={null}
                                resource="datafeed_fields"
                                recordItemId={record.id}>
                                {record.label}
                            </ShowButton>

                        </Space>
                    )}

                />

                <Table.Column dataIndex="name" title="Name" />


                <Table.Column
                    dataIndex="created_at"
                    title="Created Date"
                    render={(value) => <DateField value={value} format="lll" />}
                    defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
                    sorter
                />
                <Table.Column<IDataFeed>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>

                            <EditButton
                                size="small"
                                type="link"
                                icon={null}
                                recordItemId={record.id}
                                onClick={() => editShow(record.id)}
                            />
                            <DeleteButton
                                size="small"
                                type="link"
                                icon={null}
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
            <CreateDataFeed modalProps={modalProps} formProps={formProps} />
            <EditDataFeed modalProps={editModalProps} formProps={editFormProps} />

        </List>
    );
};
