import { useState } from "react";
import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  Row,
  Col,
  Select,
  useSelect,
  UploadProps,
  Upload,
  Button,
  Icons,
  Image,
} from "@pankod/refine-antd";
import { useCreate } from "@pankod/refine-core";
import { DatePicker } from "antd";

// Interface
import { IVideoResources, IVideoResourcesCategories } from "interfaces";

// Util
import { nhost } from "utility/nhost";
import moment from "moment";

// Props
type props = {
  modalProps: ModalProps;
  formProps: FormProps;
  refetchTable: any;
  closeModal: any;
  currentUser: any;
  form:any
};

const { TextArea } = Input;
const { UploadOutlined } = Icons;

export const CreateVideoResource: React.FC<props> = ({
  modalProps,
  formProps,
  closeModal,
  refetchTable,
  currentUser,
  form
}) => {
  // State
  const [fileList, setFileList] = useState<any>([]);
  const [selectedHostAt, setSelectedHostAt] = useState<any>();
  const [selectedDuration, setSelectedDuration] = useState();

  function setEndAt(duration: any, hostAtValue: any) {
    const originalDate = hostAtValue;
    const minutesToAdd = duration;
    const newDate = moment(originalDate, "YYYY-MM-DD HH:mm:ss").add(
      minutesToAdd,
      "minutes"
    );
    // console.log("New Date :::", newDate.format("YYYY-MM-DD HH:mm:ss"));
    form?.setFieldsValue({ end_at: newDate });
  }

  // Form Watch
  const formWatch = {
    thumbnail: Form?.useWatch("thumbnail", formProps.form),
  };

  function checkTime(x: any) {
    let currTime = moment().format("HH:mm");
    let currDate = moment().toDate().toString().slice(8, 10);
    let selectedDate = x?._d.toString().slice(8, 10);
    let selectedHr = Number(x._d.toString().slice(16, 18));
    let selectedMin = Number(x._d.toString().slice(19, 21));
    let currHrTime = Number(currTime.slice(0, 2));
    let currMinTime = Number(currTime.slice(3, 5));
    setSelectedHostAt(x);
    if (selectedDate === currDate) {
      if (selectedHr < currHrTime) {
        alert("Please select future time");
      }
      if (selectedHr === currHrTime && selectedMin < currMinTime) {
        alert("Please select future time");
      }
    }
  }

  // Category Select
  const { selectProps: videoCategorySelectProps } =
    useSelect<IVideoResourcesCategories>({
      resource: "indiacharts_video_resources_categories",
      optionLabel: "category",
      optionValue: "id",
      pagination: { pageSize: 999 },
      metaData: {
        fields: ["id", "category"],
      },
      onSearch: (value) => [
        {
          field: "category",
          operator: "contains",
          value: `%${value}%`,
        },
      ],
    });

  // Mutation
  const { mutateAsync: createVideoResource } = useCreate<IVideoResources>();

  // Image Upload
  const handleImageUpload = async (file: File) => {
    let fileResponse = await nhost.storage.upload({ file, bucketId: "public" });
    const imageUrl = await nhost.storage.getPublicUrl({
      fileId: fileResponse.fileMetadata?.id || "",
    });
    formProps?.form?.setFieldsValue({ thumbnail: imageUrl });
    return imageUrl;
  };

  const thumbProps: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      handleImageUpload(file);
      return false;
    },
    fileList,
  };

  // Form Submit
  const onFinish = async (formData: IVideoResources) => {
    await createVideoResource({
      resource: "indiacharts_video_resources",
      values: {
        title: formData?.title,
        duration: formData?.duration,
        description: formData?.description,
        category_id: formData?.category_id,
        link: formData?.link,
        link_source: formData?.link_source,
        created_by: currentUser,
        updated_by: currentUser,
        thumbnail: formWatch?.thumbnail,
        sort_index: formData?.sort_index
      },
    }).then(() => {
      setFileList([]);
      formProps?.form?.resetFields();
      refetchTable.refetch();
      closeModal();
    });
  };

  return (
    <Modal
      {...modalProps}
      onCancel={() => {
        formProps?.form?.resetFields();
        closeModal();
      }}
      title="Create Info Video"
    >
      <Form {...formProps} size="large" layout="vertical" onFinish={onFinish}>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                { required: true },
                {
                  pattern: new RegExp(/^[^\s]/), // Check For Space At Beginning Only
                  message: "No spaces allowed",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Video Link"
              name="link"
              rules={[
                { required: true },
                {
                  pattern: new RegExp(/^[^\s]/), // Check For Space At Beginning Only
                  message: "No spaces allowed",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Category"
              name="category_id"
              rules={[{ required: true }]}
            >
              <Select {...videoCategorySelectProps} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              name="duration"
              label="Duration"
              rules={[
                {
                  required: true,
                },
                {
                  pattern: new RegExp(/^[^\s]/), // Check For Space At Beginning Only
                  message: "No spaces allowed",
                },
              ]}
            >
              <Input onChange=
              {(e: any) => {
                setSelectedDuration(e?.target?.value);
                setEndAt(e?.target?.value, selectedHostAt);
              }}
              />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              name="sort_index"
              label="Sort Order"
            >
              <Input type="number"/>
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Date"
              name="host_at"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker
                format="YYYY-MM-DD HH:mm"
                showTime
                // disabledDate={disabledDate}
                onOk={(value: any) => checkTime(value)}
                // onChange={(value: any) => {
                //   form?.resetFields(["duration"]);
                // }}
         
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                { required: true },
                {
                  pattern: new RegExp(/^[^\s]/), // Check For Space At Beginning Only
                  message: "No spaces allowed",
                },
              ]}
            >
              <TextArea cols={120} rows={5} />
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item
              label="Video Source"
              name="link_source"
              initialValue={"Youtube"}
              hidden={true}
            ></Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={8} lg={8}>
            <Form.Item
              name="thumbnail"
              label="Thumbnail"
              rules={[{ required: false }]}
            >
              <Upload {...thumbProps}>
                <Button icon={<UploadOutlined />}>Select File To Upload</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={8} lg={8}>
            <Form.Item label="Thumbnail Preview" name="presenter_img">
              {formWatch.thumbnail !== undefined ? (
                <Image
                  width={120}
                  height={120}
                  src={formWatch.thumbnail}
                  alt={"Icon"}
                />
              ) : (
                <p>No Image Available For Preview</p>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label="Created By"
          name="created_by"
          initialValue={currentUser}
          hidden={true}
        ></Form.Item>
        <Form.Item
          label="Updated By"
          name="updated_by"
          initialValue={currentUser}
          hidden={true}
        ></Form.Item>
      </Form>
    </Modal>
  );
};
